<ng-container *ngIf="authService.isLoggedIn && userPercentileRanking && userPercentileRanking.pointsScored > 0">
  <p>Congratulations! You've ranked in the <strong>{{userPercentileRanking?.percentileRank | ordinal }}</strong>
    percentile overall ({{
    userPercentileRanking?.pointsScored }} points)</p>
</ng-container>
<ng-container *ngIf="authService.isLoggedIn && userPercentileRanking && userPercentileRanking.pointsScored == 0">
  <p>You haven't gained any points. Visit "My Picks" and submit some picks!</p>
</ng-container>
<ng-container *ngIf="!authService.isLoggedIn && league?.features.allowsPickLocks">
  <p>Log in to see your ranking</p>
</ng-container>

<div class="button-row">
  <button mat-button mat-raised-button (click)="prevWeek()" [disabled]="selectedWeek == 0">
    <mat-icon>arrow_left</mat-icon>
  </button>
  <mat-form-field appearance="fill">
    <mat-label>Week</mat-label>
    <mat-select [(value)]="selectedWeek" (selectionChange)="selectWeek($event)">
      <mat-option *ngFor="let option of weeks" [value]="option.value">{{
        option.viewValue
        }}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-button mat-raised-button (click)="nextWeek()" [disabled]="selectedWeek == weekService.weeks.length">
    <mat-icon>arrow_right</mat-icon>
  </button>
</div>
<ng-container *ngIf="resultingData.length > 0">

  <table mat-table [dataSource]="resultingData" class="min-width-50">
    <ng-container matColumnDef="absRank">
      <th mat-header-cell *matHeaderCellDef>Rank</th>
      <td mat-cell *matCellDef="let element">{{
        element.absRank }}</td>
    </ng-container>
    <ng-container matColumnDef="percentileRank">
      <th mat-header-cell *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element" [class.gold]="element.percentileRank == 100"
        [class.purple]="element.percentileRank > 90" [class.blue]="element.percentileRank > 70"
        [class.green]="element.percentileRank > 60" [class.gray]="element.percentileRank <= 60">{{
        element.percentileRank }}</td>
    </ng-container>
    <ng-container matColumnDef="pointsScored">
      <th mat-header-cell *matHeaderCellDef>Points</th>
      <td mat-cell *matCellDef="let element">{{ element.pointsScored }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['absRank','percentileRank', 'name', 'pointsScored']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['absRank','percentileRank', 'name', 'pointsScored']"></tr>
  </table>
</ng-container>
<ng-container *ngIf="resultingData.length == 0">
  <p>No data yet.</p>
</ng-container>
