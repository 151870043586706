import { Component, OnDestroy, OnInit } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { SubscriptionService } from '@services/subscription/subscription.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit, OnDestroy {
  interval;
  destroy$ = new Subject<boolean>();
  data: any;
  constructor(private subscriptionService: SubscriptionService, 
    private route: ActivatedRoute,
    private authService: AuthenticationService
    ) { }

  ngOnInit(): void {
    this.route.queryParams
    .pipe(
      map((params: ParamMap) => {
        return this.getSubscription(params["session_id"]);
      }),
      takeUntil(this.destroy$)
    )
    .subscribe(() => {
    });
    if(!this.authService.isAdsFree){
      this.interval = setInterval(()=>{
        this.authService.refreshToken();
      }, 500)
    }else{
      clearInterval(this.interval);
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }

  getSubscription(subscriptionId){
    this.subscriptionService.success(subscriptionId).subscribe(res => {
      this.data = res;
    })
  }

}
