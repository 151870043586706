import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
    }else{
      this.title.setTitle("Playoff Predictors");
    }
  }

  updateOgUrl(url: string) {
    if (url) {
      this.meta.updateTag({ name: "og:url", content: url });
    }
  }

  updateDescription(desc: string) {
    if (desc) {
      this.meta.updateTag({ name: "description", content: desc });
    }else{
      this.meta.updateTag({ name: 'description', content: "Playoff Predictors is a suite of playoff machines that allows you to pick every game of the season (up to the championship), and will correctly rank teams according to the league's rules. Supports the NFL, NHL, NBA, and MLB."})
    }
  }
}
