import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ChartDataService {
  constructor(private http: HttpClient) { }
  getWeeklyChartData(leagueGuid:string, teamName:string){
    return this.http.get(`${environment.ChartEngineUrl}charts/SeasonPerformance/${leagueGuid}/${teamName}/36/52`).pipe();
  }
  getDailyChartData(leagueGuid:string, teamName:string, start:Date, end:Date){
    var yearStart = new Date(start.getFullYear(), 0, 0);
    let startDiff = start.getTime() - yearStart.getTime();
    let endDiff = end.getTime() - yearStart.getTime();
    var oneDay = 1000 * 60 * 60 * 24;
    return this.http.get(`${environment.ChartEngineUrl}charts/PerfByDay/${leagueGuid}/${teamName}/${Math.floor(startDiff / oneDay)}/${Math.floor(endDiff / oneDay)}`).pipe();
  }
  getPowerRank(leagueGuid:string){
    return this.http.get(`${environment.ChartEngineUrl}charts/PowerRank/${leagueGuid}`).pipe();
  }
}
