import { Component, OnInit, Inject,LOCALE_ID } from '@angular/core';
import { SubscriptionService } from '@services/subscription/subscription.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {
  loaded = false;
  data:any[] = [];
  underscoreRegex = new RegExp(/\_+/gi)
  constructor(public authService: AuthenticationService, private subscriptionService: SubscriptionService, @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
    
    this.subscriptionService.getSubscriptions().subscribe(
      (res:any[]) => {
        this.loaded = true;
        let flattened = res.map((sub) => {
          
          let ret = {
            Status: sub.status,
            Subscription_Start: formatDate(new Date(sub.start_date * 1000), 'mediumDate', this.locale),
            hasDiscount: sub.discount != null,
            ID: sub.items.data[0].plan.id,
            Price:(sub.items.data[0].plan.amount / 100),
            
          }
          if(ret.hasDiscount){
            ret['Normal_Price'] = `$${ret.Price.toFixed(2)}`;
            ret['Price_To_You'] = `$${(ret.Price * (sub?.discount?.coupon?.percent_off / 100)).toFixed(2)}`
          }else{
            ret['Price To You'] = `$${ret.Price.toFixed(2)}`;
          }
          delete ret['Price'];
          delete ret['hasDiscount'];
          ret['Billing Frequency'] = sub.items.data[0].plan.interval+'ly';
          ret['Current Cycle Start'] = formatDate(new Date(sub.current_period_start * 1000), 'mediumDate', this.locale);
          ret['Current Cycle End'] = formatDate(new Date(sub.current_period_end * 1000), 'mediumDate', this.locale);
          return ret;
        })

        for(let sub of flattened){
          for(let key in sub){
            this.data.push({key: key.replace(this.underscoreRegex, ' '), value: sub[key]})
          }
        }


    })
  }

  manageSubscription(){
    this.subscriptionService.portalLink().subscribe(res => {
      location.replace(res.url);
    })
  }

}
