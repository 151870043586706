import { UserSettingsService } from '@services/user-settings/user-settings.service';
import { League, UserConfig } from '@models';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LeagueService } from '../../services/league/league.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  userSettings: UserConfig;
  league:League;
  @Output()
  settingsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private userSettingsService: UserSettingsService, private leagueService: LeagueService) {}

  ngOnInit() {
    this.userSettings = this.userSettingsService.userConfig;
    this.userSettingsService.userConfig.hasChangedEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.settingsChanged.next(true);
    });
    this.leagueService.league$.pipe(takeUntil(this.destroy$)).subscribe(l => this.league = l);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
