import { Injectable } from '@angular/core';
import { Helpers } from '@helpers/helpers';
import { LeagueService } from 'app/football/nfl/services/league/league.service';
import { interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UrlSerializerService {
  league;
  private updateSubject = new Subject<boolean>();
  constructor(private leagueService: LeagueService) {
    this.leagueService.league$.subscribe((l) => {
      this.league = l;
    });
    this.updateSubject.pipe()
      .subscribe(()=>{
        if(!this.league){
          this.saveString = '';
          return;
        }
        const lzString = Helpers.compress(this.gameSerializationArray.join(''));
        const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?L=' + lzString;
        if (!this.lastUpdate) {
          this.lastUpdate = lzString;
          this.saveString = lzString;
        }
        if (this.lastUpdate !== newurl) {
          window.history.pushState({ path: newurl }, '', newurl);
          this.lastUpdate = newurl;
          this.saveString = lzString;
          window.localStorage[this.league.leagueGuid] = lzString;
        }
      });
  }
  gameSerializationArray: number[] = [];
  lastUpdate: string;
  public saveString: string;
  updateUrl() {
    this.updateSubject.next(true);
  }

  setSerializationArrayItem(position: number, value: number, blockUpdate: boolean) {
    this.gameSerializationArray[position] = value;
    if (!blockUpdate) {
      this.updateSubject.next(true);
    }
  }

  initializeSerializationArray(range: number) {
    this.gameSerializationArray = [];
    for (let i = 0; i < range; i++) {
      this.gameSerializationArray[i] = 0;
    }
  }
}
