import { Component, OnInit } from '@angular/core';
import { PlaySheetPost } from '@models';
import { CategoryMap, PlaysheetService } from '@services/playsheet/playsheet.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-playsheet-posts',
  templateUrl: './playsheet-posts.component.html',
  styleUrls: ['./playsheet-posts.component.css']
})
export class PlaysheetPostsComponent implements OnInit {

  destroyed$ = new Subject<boolean>();
  nflPosts:PlaySheetPost[] = [];
  constructor(private playSheetService: PlaysheetService) { }

  ngOnInit(): void {
    this.playSheetService.getPostsInCategory(CategoryMap.Nfl2023Season, 3).pipe(takeUntil(this.destroyed$)).subscribe((posts)=>{
      this.nflPosts = posts;
    })
  }

}
