import { PlayoffTeam } from './playoff-team.model';

export class PlayoffGame {
  conference: string;
  round: string;
  home?: PlayoffTeam = null;
  away?: PlayoffTeam = null;
  homeScore?:number;
  awayScore?:number;
  saveIndex: number;
  _teamSelected: PlayoffTeam;
  key:string;
  set teamSelected(team: PlayoffTeam) {
    if (!this.away || !this.home || !team) {
      this._teamSelected = null;
    }
    if (team !== this.away && team !== this.home) {
      this._teamSelected = null;
    } else {
      this._teamSelected = team;
    }
  }
  get teamSelected(): PlayoffTeam {
    if (!this.away || !this.home) {
      return null;
    }
    if(this._teamSelected && this._teamSelected.team){
      this._teamSelected.team.conferenceRank = this._teamSelected.seed;
    }

    return this._teamSelected;
  }
  get teamThatLost(){
    if (!this.away || !this.home) {
      return null;
    }
    if(this.teamSelected !== this.away){
      this.away.team.conferenceRank = this.away.seed;
      return this.away;
    }else{
      this.home.team.conferenceRank = this.home.seed;
      return this.home;
    }
  }
  get selectedTeamCss(): string {
    if (this.teamSelected && this.teamSelected.team && this.teamSelected.team.teamAbbreviation && (this.teamSelected === this.away || this.teamSelected === this.home)) {
      return `${this.teamSelected.team.teamAbbreviation} team-selected`;
    }
    return '';
  }
  isTeamSelected():boolean{
    return this.teamSelected != null;
  }

  constructor(away: PlayoffTeam, home: PlayoffTeam, conference: string, round: string, saveIndex: number, key: string) {
    const self = this;
    self.key = key;
    self.saveIndex = saveIndex;
    self.away = away;
    self.home = home;
    self.conference = conference;
    self.round = round;
  }
}
