import { GameRecord } from './game-record.model';
import { Team } from './team.model';
import { Game } from './game.model';
import { Helpers } from '@helpers/helpers';
import { SelectionType } from './SelectionType';

export class GameRecords {
  divisionRecord: GameRecord;
  conferenceRecord: GameRecord;
  allRecord: GameRecord;
  homeRecord: GameRecord;
  awayRecord: GameRecord;
  winningTeamsRecord: GameRecord;
  losingTeamsRecord: GameRecord;
  playoffTeamsRecord: GameRecord;
  wins: Array<Game>;
  losses: Array<Game>;
  ties: Array<Game>;
  team: Team;
  gamesPlayed: number;
  constructor(team: Team) {
    this.wins = [];
    this.losses = [];
    this.ties = [];
    this.team = team;
    this.gamesPlayed = 0;
    this.allRecord = new GameRecord();
    this.divisionRecord = new GameRecord();
    this.conferenceRecord = new GameRecord();
    this.homeRecord = new GameRecord();
    this.awayRecord = new GameRecord();
    this.winningTeamsRecord = new GameRecord();
    this.losingTeamsRecord = new GameRecord();
    this.playoffTeamsRecord = new GameRecord();
  }

  notify(type: string, game: Game, otherTeam: Team) {
    if (game.week > 18) {
      return;
    }
    const otherRecord = otherTeam.gameRecords;
    if (type === 'win') {
      this.updateRecords('win', game);
      otherRecord.updateRecords('loss', game);
    } else if (type === 'none') {
      this.updateRecords('none', game);
      otherRecord.updateRecords('none', game);
    } else {
      this.updateRecords('tie', game);
      otherRecord.updateRecords('tie', game);
    }
  }

  updateRecords(record: string, gameRecord: Game) {
    let idx = this.wins.indexOf(gameRecord);
    if (idx >= 0) {
      this.wins.splice(idx, 1);
    }
    idx = this.losses.indexOf(gameRecord);

    if (idx >= 0) {
      this.losses.splice(idx, 1);
    }

    idx = this.ties.indexOf(gameRecord);
    if (idx >= 0) {
      this.ties.splice(idx, 1);
    }
    switch (record) {
      case 'win':
        {
          this.wins.push(gameRecord);
        }
        break;
      case 'loss':
        {
          this.losses.push(gameRecord);
        }
        break;
      case 'tie':
        {
          this.ties.push(gameRecord);
        }
        break;
      case 'none':
        this.allRecord.losses = this.losses.length;
        this.allRecord.ties = this.ties.length;
        this.allRecord.wins = this.wins.length;
        break;
    }
    this.clearLosses();
    this.losses.map(game => {
      if (Helpers.IsSameConference(game.home, game.away)) {
        this.conferenceRecord.losses++;
      }
      if (Helpers.IsSameDivision(game.home, game.away)) {
        this.divisionRecord.losses++;
      }
      if (this.team === game.home) {
        this.homeRecord.losses++;
      }
      if (this.team === game.away) {
        this.awayRecord.losses++;
      }
    });
    this.clearWins();
    this.wins.map(game => {
      if (Helpers.IsSameConference(game.home, game.away)) {
        this.conferenceRecord.wins++;
      }
      if (Helpers.IsSameDivision(game.home, game.away)) {
        this.divisionRecord.wins++;
      }
      if (this.team === game.home) {
        this.homeRecord.wins++;
      }
      if (this.team === game.away) {
        this.awayRecord.wins++;
      }
    });
    this.clearTies();

    this.ties.map(game => {
      if (Helpers.IsSameConference(game.home, game.away)) {
        this.conferenceRecord.ties++;
      }
      if (Helpers.IsSameDivision(game.home, game.away)) {
        this.divisionRecord.ties++;
      }
      if (this.team === game.home) {
        this.homeRecord.ties++;
      }
      if (this.team === game.away) {
        this.awayRecord.ties++;
      }
    });

    this.team.gameRecords.wins
      .concat(this.team.gameRecords.ties)
      .concat(this.team.gameRecords.losses)
      .filter(game => game.selectionType !== 0)
      .map(game => {
        if (game.teamThatWon === this.team) {
          if (Helpers.getOppPct(this.team, game) < 0.5) {
            this.losingTeamsRecord.wins++;
          } else {
            this.winningTeamsRecord.wins++;
          }
        } else if (game.teamThatWon !== this.team) {
          if (Helpers.getOppPct(this.team, game) < 0.5) {
            this.losingTeamsRecord.losses++;
          } else {
            this.winningTeamsRecord.losses++;
          }
        }
      });

    this.allRecord.losses = this.losses.length;
    this.allRecord.ties = this.ties.length;
    this.allRecord.wins = this.wins.length;
    this.gamesPlayed = this.allRecord.losses + this.allRecord.ties + this.allRecord.wins;
  }

  clearAll() {
    this.wins.splice(0, this.wins.length);
    this.losses.splice(0, this.losses.length);
    this.ties.splice(0, this.ties.length);

    this.clearWins();
    this.clearLosses();
    this.clearTies();
  }

  clearWins() {
    this.allRecord.wins = 0;
    this.conferenceRecord.wins = 0;
    this.divisionRecord.wins = 0;
    this.homeRecord.wins = 0;
    this.awayRecord.wins = 0;
    this.winningTeamsRecord.wins = 0;
    this.losingTeamsRecord.wins = 0;
  }

  clearLosses() {
    this.allRecord.losses = 0;
    this.conferenceRecord.losses = 0;
    this.divisionRecord.losses = 0;
    this.homeRecord.losses = 0;
    this.awayRecord.losses = 0;
    this.winningTeamsRecord.losses = 0;
    this.losingTeamsRecord.losses = 0;
  }
  clearTies() {
    this.allRecord.ties = 0;
    this.conferenceRecord.ties = 0;
    this.divisionRecord.ties = 0;
    this.homeRecord.ties = 0;
    this.awayRecord.ties = 0;
    this.winningTeamsRecord.ties = 0;
    this.losingTeamsRecord.ties = 0;
  }
}
