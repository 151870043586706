import { catchError, debounce, map, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { interval, Subject, timer } from 'rxjs';
import { Resulting, SubmitPicksToPoolRequest, UserPickResponse } from '@models';
import { UserPercentileRanking } from 'app/models/export/user-percentile-ranking.model';


@Injectable({
  providedIn: 'root'
})
export class ResultingService implements OnDestroy {
  constructor(private http: HttpClient) {
  }
  private destroy$ = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }


  getUserPercentileRankForLeague(poolGuid: string){
    return this.http.get<UserPercentileRanking>(`${environment.resultingEngineUrl}pools/ranks/get-user-percentile-rank/${poolGuid}`).pipe(
      debounce(()=> interval(250)),
      map(r => r),
      takeUntil(this.destroy$)
    );
  }
  
  getTopForPool(poolGuid: string) {
    return this.http.get<Resulting[]>(`${environment.resultingEngineUrl}pools/ranks/get-top-for-pool/${poolGuid}`).pipe(
      debounce(() => timer(500)),
      map((r) => {
        return r;
      }),
      takeUntil(this.destroy$)
    );
  }

  getTopForWeek(poolGuid: string, weekNum: number){
    if(weekNum == 0){
      return this.getTopForPool(poolGuid);
    }
    return this.http.get<Resulting[]>(`${environment.resultingEngineUrl}pools/ranks/get-top-for-week/${poolGuid}/${weekNum}`).pipe(
      debounce(() => timer(500)),
      map((r) => {
        return r;
      }),
      takeUntil(this.destroy$)
    );
  }
}
