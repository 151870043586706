import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  title: string;
  message: string;
  status: number;
  email: string;
  navigateRoute: string;
  routerLink: string;
  linkText: string;
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent {
  constructor(private router: Router, public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onOkClick(): void {
    if (this.data.status === 200) {
      this.router.navigate([this.data.navigateRoute]);
    }
    this.dialogRef.close();
  }
}
