<ng-container *ngIf="data">
    <mat-card>
        <mat-card-header> <mat-card-title>Payment Successful!</mat-card-title> </mat-card-header>
        <mat-card-content>  
            <ng-container *ngIf="data.mode == 'subscription'">
                <p>Thank you for subscribing to Playoff Predictors.</p>
                <p>You should receive your entitlements shortly. If you don't see them after a short wait, please contact <a href="mailto:help@playoffpredictors.com">Help@PlayoffPredictors.com</a></p>
                <p>You can cancel your subscription any time by visiting the <a [routerLink]="['/account/subscriptions']">subscriptions</a> page</p>            
            </ng-container>
            <ng-container *ngIf="data.mode == 'payment'">
                <p>Thank you for your payment.</p>
                <p>You should receive your entitlements shortly. If you don't see them after a short wait, please contact <a href="mailto:help@playoffpredictors.com">Help@PlayoffPredictors.com</a></p>
                <p>You will continue to receieve benefits through the duration of your payment term.</p>
            </ng-container>
            <p>Please be advised that you may need to log-out and back-in to the main site at <a href="https://PlayoffPredictors.com">https://PlayoffPredictors.com</a> if you don't see your entitlements right away</p>
    </mat-card-content>
    </mat-card>
</ng-container>