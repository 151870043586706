import { paths } from './../app-paths';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HomeComponent } from './home/home.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SubscribersOnlyComponent } from './subscribers-only/subscribers-only.component';
import { FaqComponent } from './faq/faq.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoadMapComponent } from './road-map/road-map.component';
import { HateAdsComponent } from './hate-ads/hate-ads.component';
import { AccessibilityStatementComponent } from './accessibility-statement/accessibility-statement.component';

const routes: Routes = [
  {
    path: paths.home.path,
    component: HomeComponent,
    children: [
      {
        path: '',
        component: HomePageComponent
      },
      {
        path: paths.home_faq.path,
        component: FaqComponent
      },
      {
        path: paths.home_subscribersOnly.path,
        component: SubscribersOnlyComponent
      },
      {
        path: paths.home_privacyPolicy.path,
        component: PrivacyPolicyComponent
      },
      {
        path: paths.home_contactUs.path,
        component: ContactUsComponent
      },
      {
        path: paths.home_roadMap.path,
        component: RoadMapComponent
      },
      {
        path: paths.home_hateAds.path,
        component: HateAdsComponent
      },
      {
        path: paths.home_accessibilityStatement.path,
        component: AccessibilityStatementComponent
      },
      {
        path: '**',
        redirectTo: '/not-found'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
