import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-team-logo",
  templateUrl: "./team-logo.component.html",
  styleUrls: ["./team-logo.component.css"],
})
export class TeamLogoComponent implements OnInit {
  @Input()
  public teamName?: string;

  @Input('sportType')
  public sportType: number;

  @Input()
  public teamAbbreviation?: string;

  sportName:string;
  constructor() {}

  ngOnInit(): void {
    switch(this.sportType){
      case 1:
        {
          this.sportName = "nfl";
        }
    }
    if (this.teamAbbreviation == null && this.teamName != null) {
      this.teamAbbreviation = this.convertTeamNameToAbbreviation(this.teamName);
    }
  }

  private convertTeamNameToAbbreviation(teamName){
    switch (teamName) {
      case "49ers":
        return "SF";
      case "Bears":
        return "CHI";
      case "Bengals":
        return "CIN";
      case "Bills":
        return "BUF";
      case "Broncos":
        return "DEN";
      case "Browns":
        return "CLE";
      case "Buccaneers":
        return "TB";
      case "Cardinals":
        return "ARI";
      case "Chargers":
        return "LAC";
      case "Chiefs":
        return "KC";
      case "Colts":
        return "IND";
      case "Cowboys":
        return "DAL";
      case "Dolphins":
        return "MIA";
      case "Eagles":
        return "PHI";
      case "Falcons":
        return "ATL";
      case "Commanders":
        return "2022-WAS";
      case "Giants":
        return "NYG";
      case "Jaguars":
        return "JAC";
      case "Jets":
        return "NYJ";
      case "Lions":
        return "DET";
      case "Packers":
        return "GB";
      case "Panthers":
        return "CAR";
      case "Patriots":
        return "NE";
      case "Raiders":
        return "LV";
      case "Rams":
        return "LA";
      case "Ravens":
        return "BAL";
      case "Saints":
        return "NO";
      case "Seahawks":
        return "SEA";
      case "Steelers":
        return "PIT";
      case "Texans":
        return "HOU";
      case "Titans":
        return "TEN";
      case "Vikings":
        return "MIN";
        default:
          console.warn("%s could not be matched to a known team", teamName);
    }
  }
}
