<h1 mat-dialog-title>Login</h1>
<mat-dialog-content #dialog>
    <form (ngSubmit)="onSubmit()">
    <mat-form-field>
        <input matInput placeholder="Email" [formControl]="username" required type="text" />
        <mat-error *ngIf="username.invalid"> <span *ngIf="username.hasError('required')"> Username is required </span> <span *ngIf="username.hasError('email')"> Username must be an email. </span> </mat-error>
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Password" type="password" [formControl]="password" required autocomplete="current-password" />
        <mat-error> <span *ngIf="password.hasError('required')"> Password is required </span> </mat-error>
    </mat-form-field>
    </form>
   
</mat-dialog-content> 
<mat-dialog-actions> 
    <button mat-raised-button [disabled]="username.invalid || password.invalid || loading" (click)="onSubmit()" color="accent">{{ loading ? 'logging you in...' : 'Login' }}</button> 
    <button mat-button [routerLink]="'/account/user/register'">I'm not a user</button> 
</mat-dialog-actions>