import { ApplicationRef, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "@env/environment";
import { concat, interval } from "rxjs";
import { first } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class UpdateService {
  constructor(
    public updates: SwUpdate,
    appRef: ApplicationRef,
    private matSnackbar: MatSnackBar
  ) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );

    const pwaInterval$ = interval(environment.pwaInterval);
    const pwaIntervalAppIsStable$ = concat(appIsStable$, pwaInterval$);
    if (environment.enableServiceWorker) {
      //schedule checks
      pwaIntervalAppIsStable$.subscribe(() => updates.checkForUpdate());
      this.updates.available.subscribe((event) => {
        this.matSnackbar
          .open("A new version of Playoff Predictors is available", "REFRESH", {
            politeness: "assertive",
            horizontalPosition: "left"
          })
          .onAction()
          .subscribe(() => {
            this.updates
              .activateUpdate()
              .then(() => document.location.reload());
          });
      });

    }
  }
}
