import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { interval, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import postscribe from "postscribe";
import { environment } from "@env/environment";

@Component({
  selector: "app-freestar-loader",
  templateUrl: "./freestar-loader.component.html",
  styleUrls: ["./freestar-loader.component.css"],
})
export class FreestarLoaderComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  constructor(public authService: AuthenticationService) {}

  ngOnInit(): void {
    this.authService.user.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if(!user.loaded){
        return; //loading
      }
      let head = document.head.querySelector("#freestar-head");
      let recovered = document.head.querySelector("#recover");
      if (user && user.adsfree == "true") {
        window["freestar"] &&
        window["freestar"].deleteAdSlots &&
        window["freestar"].deleteAdSlots();
        document.querySelector("#playoffpredictors_adhesion")?.remove();
        document.querySelector("#playoffpredictors_pushdown")?.remove();
        document.querySelectorAll("iframe").forEach(frame => frame.remove());
        document.querySelector("#pubfig")?.remove();
        document.querySelector('[data-owner="freestar"]')?.remove()

        window.addEventListener("primisPlayerInit", (listener:any)=>{
          document.querySelector('#primisPlayerContainerDiv')?.remove();

          window["freestar"].disableRefresh = true;
          setTimeout(()=>{
            window["freestar"] &&
            window["freestar"].deleteVideo &&
            window["freestar"].deleteVideo();
          },2500)
          //window["freestar"] = null;
        })
        head?.remove();
        recovered?.remove();
      } else {
        if (!head && user.loaded) {
          postscribe(
            document.head,
            `
            <script data-cfasync="false" type="text/javascript" async="true" id="recover" src="https://static.playoffpredictors.com/site/r.js"></script>
        <script data-cfasync="false" type="text/javascript" async="true" id="freestar-head">
        var freestar = window['freestar'] || {};
        freestar.hitTime = Date.now();
        freestar.queue = freestar.queue || [];
        freestar.config = freestar.config || {};
        freestar.config.enabled_slots = [];
        freestar.initCallback = function () {
          (freestar.config.enabled_slots.length === 0)
          ? freestar.initCallbackCalled = false
          : freestar.newAdSlots(freestar.config.enabled_slots) }
        freestar.debug = ${
          window.location.search.indexOf("fsdebug") === -1 ? false : true
        }
        !function (a, b) {
          var c = b.getElementsByTagName("script")[0], d = b.createElement("script"), e = "https://a.pub.network/playoffpredictors-com";
        e += ${
          window.location.search.indexOf("fsdebug") === -1 ? false : true
        } ? "/qa/pubfig.min.js" : "/pubfig.min.js", d.async = !0,
        d.src = e,
        c.parentNode.insertBefore(d, c),
        d.id='pubfig' }(window, document);
        </script>
        `
          );
        }
      }
    });
  }
}
