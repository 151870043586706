<ng-container *ngIf="hasPools">
<button mat-raised-button color="primary" [matMenuTriggerFor]="menu" *ngIf="league.features.allowsPickLocks">
  <span> Submit Week to Pool</span>
  <mat-icon>arrow_right</mat-icon>
</button>
<button mat-raised-button color="primary" (click)="tabSwapper.next('leaderboard')" *ngIf="tabSwapper" style="margin-left:10px;">
  <span> View Pools</span>
  <mat-icon>preview</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngIf="!authService.isLoggedIn">
    <button mat-menu-item (click)="showLoginModal()">
        <mat-icon> login </mat-icon>
        Log in to Submit
      </button>
    </ng-container>
  <ng-container *ngIf="authService.isLoggedIn">
    <button
      mat-menu-item
      *ngFor="let item of pools"
      (click)="lockUserPicks(item.groupGuid)"
    >
    <ng-container [ngSwitch]="item.privacyLevel">
      <mat-icon *ngSwitchCase="1">public</mat-icon>
      <mat-icon *ngSwitchCase="2">lock</mat-icon>
      <mat-icon *ngSwitchCase="3">close</mat-icon>
    </ng-container>
      <span> {{ item.groupName }}</span>
    </button>
  </ng-container>
</mat-menu>
</ng-container>
