import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home/home.component";
import { SuccessComponent } from "./success/success.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatModule } from "app/material/mat.module";
import { BillingRoutingModule } from "./billing.routing";

@NgModule({
    imports: [
        BillingRoutingModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatModule,
    ],
    declarations: [HomeComponent, SuccessComponent],
    exports: []
})
export class BillingModule {}
