import { BracketUploaderService } from '@services/bracket-uploader/bracket-uploader.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { BracketUpload } from '@models';
import { Subject } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SeoService } from '@services/seo/seo.service';

@Component({
  selector: 'app-bracket-upload',
  templateUrl: './bracket-upload.component.html',
  styleUrls: ['./bracket-upload.component.css']
})
export class BracketUploadComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  bracket: BracketUpload;
  constructor(private route: ActivatedRoute, 
    private bracketService: BracketUploaderService, 
    private meta: Meta,
    private seoService: SeoService,
    @Inject(DOCUMENT)private dom) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => params.get('bracketId')),
        switchMap((id: string) => {
          return this.bracketService.getBracket(id);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(bracket => {
        this.bracket = bracket;

        const title = `Post-Season Prediction for the ${bracket.leagueTitle}`;
        const imagePath = `https://static.playoffpredictors.com/uploads/${bracket.imageFileName}.png`;
        this.seoService.updateTitle(`${title} - NFL Bracket - Playoff Predictors`);
        this.seoService.updateDescription(`A user-submitted bracket prediction on Playoff Predictors for ${bracket.leagueTitle}`)
        this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.meta.addTag({ name: 'twitter:site', content: '@RayLehnhoff' });
        this.meta.addTag({ name: 'twitter:creator', content: '@RayLehnhoff' });
        this.meta.addTag({ name: 'twitter:title', content: title });
        this.meta.addTag({ name: 'twitter:description', content: title });
        this.meta.addTag({ name: 'twitter:image', content:  imagePath});
        this.meta.addTag({ name: 'og:image', content: imagePath });
        this.meta.addTag({ name: 'og:type', content: 'article' });
        this.meta.addTag({ name: 'og:image:width', content: '600' });
        this.meta.addTag({ name: 'og:image:height', content: '315' });
        this.meta.addTag({ name: 'og:description', content: title });
        this.meta.addTag({ name: 'og:title', content: title });
        this.updatePageImage(imagePath);
      });
      
  }
  updatePageImage(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='image_src']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','image_src')
    element.setAttribute('href',url)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
