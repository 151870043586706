
<p>Select a team to see their aggregated predicted performance based on our user's selections since the week prior to the Current Year NFL Kickoff<em>*</em></p>
<mat-form-field appearance="fill">
  <mat-label>Team</mat-label>
  <mat-select (selectionChange)="teamSelected($event)" [value]="teamName$.value">
    <mat-option *ngFor="let team of teams" [value]="team.value">
      <app-team-logo [sportType]="1" [teamName]="team.value"></app-team-logo> {{ team.viewValue }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="watermarked">
  <div class="chart" *ngIf="chartOptions">
    <highcharts-chart
    [Highcharts] = "highcharts"
    [options] = "chartOptions"
    style = "width: 100%; height: 600px; display: block;">
 </highcharts-chart>
  </div>
  <p>Updates may be delayed for up to 6 hours.</p>
</div>
<p><em>*</em>selections are non-randomized, anonymous. Some selections submitted by users may be excluded.</p>
