import { DialogComponent, DialogData } from './../dialog/dialog.component';
import { AuthenticationService } from './../../../services';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register-template',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss']
})
export class RegisterTemplateComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  destroy$ = new Subject<boolean>();
  constructor(private authenticationService: AuthenticationService, public dialog: MatDialog) { }
  username = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]);
  error = '';

  getErrorMessage() {
    return this.username.hasError('required') ? 'You must enter a value' : this.username.hasError('email') ? 'Not a valid email' : '';
  }

  ngOnInit() { }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    // stop here if form is invalid
    if (!(this.username.invalid && this.password.invalid)) {
      this.authenticationService.register(this.username.value, this.password.value).subscribe(
        {
          next: (data) => {
            this.dialog.open(DialogComponent, {
              width: '500px',
              data: {
                title: 'Your account has been created',
                message: 'You will need to confirm your account before accessing advanced features of this site. Please check your email.',
                status: 200,
                id: data.id,
                navigateRoute: `/account/confirm/${this.authenticationService.userValue.id}`
              }
            });
          },
          error: errorRes => {
            const res = { title: 'Oops. We ran into a problem.', message: '', status: errorRes.status } as DialogData;
            if (errorRes.error[0].code == 'DuplicateUserName') {
              res.title = "Username in user";
              res.message = errorRes.error[0].description;
              res.routerLink = '/account/user/login';
              res.linkText = "Try logging in?"
            }

            this.dialog.open(DialogComponent, {
              width: '500px',
              data: res
            });
          }
        }
      );
    }
  }
}
