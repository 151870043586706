import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { Subject } from "rxjs";
import { take} from "rxjs/operators";
import { SetNameErrorComponent } from "../set-name-error/set-name-error.component";

@Component({
  selector: "app-set-name",
  templateUrl: "./set-name.component.html",
  styleUrls: ["./set-name.component.css"],
})
export class SetNameComponent implements OnInit, OnDestroy {
  authName: string;
  displayName = new FormControl("", [
    Validators.required,
    Validators.maxLength(50),
  ]);
  destroy$ = new Subject<boolean>();

  constructor(
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.authName = this.authService.userValue.en;
    this.displayName.setValue(this.authName)
  }
  onCancel() {
    this.displayName.reset();
    this.displayName.setValue(this.authName);
  }
  onSubmit() {
    if (this.displayName.valid) {
      this.authService
        .updateExternalName(this.displayName.value)
        .pipe(take(1))
        .subscribe({
          next: (v) => {
          },
          error: (v) => {
            this.dialog.open(SetNameErrorComponent, { width: "250px" });
          },
        });
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
