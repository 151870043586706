import { Component } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.scss']
})
export class UserComponent {
  navLinks = [{ path: 'login', label: 'Login' }, { path: 'register', label: 'Register' }];
  constructor() {}
}
