import { UserConfig, CommonGamesMethodology, UndefinedDenominatorBehavior } from '@models';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UserSettingsService implements OnDestroy {
  userConfig: UserConfig;
  destroy$ = new Subject<boolean>();
  constructor() {
    this.userConfig = new UserConfig();

    this.assertValid();
    this.userConfig.hasChangedEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      
      if(this.userConfig.nightmodeEnabled){
        document.body.classList.add('darkMode');
      }
      else{
        document.body.classList.remove('darkMode')
      }
      this.saveStorage();
    });

    if (!localStorage.getItem('UserConfig')) {
      this.saveStorage();
    }

    if(this.userConfig.nightmodeEnabled){
      document.body.classList.add('darkMode');
    }
  }
  assertValid() {
    // They've never had their config loaded
    if (!this.userConfig) {
      this.userConfig = new UserConfig();
    }

    // Maps all the values of the user's to the default
    this.mergeRecursive(this.userConfig, JSON.parse(localStorage.getItem('UserConfig')));
    if(this.userConfig.nfl.enableScoring === false){
      this.userConfig.nfl.liveUpdatesEnabled = false;
      this.userConfig.nfl.updateGameOutcomesWithScoreUpdate = false;
    }
    if(this.userConfig["_liveUpdatesEnabled"]){
      delete this.userConfig["_liveUpdatesEnabled"];
    }
    if(this.userConfig["_updateGameOutcomesWithScoreUpdate"]){
      delete this.userConfig["_updateGameOutcomesWithScoreUpdate"];
      this.saveStorage();
    }
  }

  saveStorage() {
    localStorage.setItem(
      'UserConfig',
      JSON.stringify(this.userConfig, (key, value) => {
        if (key === 'hasChangedEvent') {
          return '';
        }
        return value;
      })
    );
  }

  mergeRecursive(obj1, obj2) {
    const skipKeys = ['hasChangedEvent'];
    // tslint:disable-next-line:forin
    for (const p in obj2) {
      if (skipKeys.indexOf(p) !== -1) {
        continue;
      }
      try {
        // Property in destination object set; update its value.
        if (obj2[p].constructor === Object) {
          obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);
        } else {
          if (typeof obj1[p] === 'function') {
            obj1[p](obj2[p]);
          } else {
            obj1[p] = obj2[p];
          }
        }
      } catch (e) {
        // Property in destination object not set; create it and set its value.
        obj1[p] = obj2[p];
      }
    }

    return obj1;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
