<h1 mat-dialog-title>Add Submission to Pool</h1>
<mat-dialog-content #dialog style="height:250px;width:400px;">
  <form (ngSubmit)="onSubmit()" style="display: flex; flex-direction: column" [formGroup]="submissionGroup">
    <mat-form-field appearance="fill">
      <mat-label>Submission Name</mat-label>
      <input
        matInput
        formControlName="submissionName"
        required
        type="text"
        [placeholder]="'Submission Name'"
      />

      <mat-error *ngIf="fc.submissionName.invalid">
        <span *ngIf="fc.submissionName.hasError('required')">
          Field is required
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Bracket Save String</mat-label>
      <input
        matInput
        type="text"
        placeholder="1222111112211"
        formControlName="url"
        maxlength="13"
      />
      <mat-error *ngIf="fc.url.invalid">
        <ng-container *ngIf="fc.url.errors.playoffUrl">
          Value is not a valid
          <a target="_blank" href="https://static.playoffpredictors.com/site/valid-playoff-string.png">save value</a>. Must be 1 or 2s only
        </ng-container>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Super Bowl Away Score</mat-label>
      <input
        matInput
        type="number"
        placeholder="28"
        formControlName="awayScore"
      />
      <mat-error *ngIf="fc.awayScore.invalid">
          Value is not a valid
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Super Bowl Home Score</mat-label>
      <input
        matInput
        type="number"
        placeholder="3"
        formControlName="homeScore"
      />
      <mat-error *ngIf="fc.homeScore.invalid">
          Value is not a valid
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>SB Total Score</mat-label>
      <input
        readonly
        matInput
        type="number"
        placeholder="53"
        formControlName="totalScore"
      />
      <mat-error *ngIf="fc.totalScore.invalid">
        <p *ngIf="fc.totalScore.hasError('required')">
          Field is required
        </p>
      </mat-error>
    </mat-form-field>
  </form>
  {{ error }}
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    [disabled]="submissionGroup.invalid || loading"
    (click)="onSubmit()"
    color="accent"
  >
    {{ loading ? "saving..." : "Save" }}
  </button>
  <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
