import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { BracketService } from '../../services/bracket/bracket.service';
import { LeagueService } from '../../services/league/league.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BracketUploaderService } from '@services/bracket-uploader/bracket-uploader.service';
import { UrlSerializerService } from '@services/url-serializer/url-serializer.service';
import { League, PlayoffGame } from '@models';
import { PlayoffsService } from '../../services/playoffs/playoffs.service';

@Component({
  selector: 'app-bracket',
  templateUrl: './bracket.component.html',
  styleUrls: ['./bracket.component.css']
})
export class BracketComponent implements AfterViewInit, OnDestroy,OnInit {
  private destroy$ = new Subject<boolean>();
  hasBracket$ = new Subject<boolean>();
  hasBracket = false;
  hasUpload: boolean;
  bracketLink: string;
  leagueName = '';
  private leagueGuid: string;

  @ViewChild('bracket', { static: true }) canvas: ElementRef;
  constructor(private bracketService: BracketService, private leagueService: LeagueService,
    private bracketUploaderService: BracketUploaderService,
    private urlSerializationService: UrlSerializerService,
    private playoffsService: PlayoffsService) {

  }

  uploadBracket() {
    this.bracketUploaderService
      .uploadBracket(this.canvas.nativeElement.firstElementChild.toDataURL('image/png', 1.0), this.leagueGuid, this.urlSerializationService.saveString, window.origin)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        bracketLink => {
          this.bracketLink = bracketLink.link;
          this.hasUpload = true;
        },
        errorRes => {}
      );
  }

  ngAfterViewInit() {

    this.leagueGuid = this.leagueService.league$.value.leagueGuid;

  }

  ngOnInit(){
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: League) => {
        this.leagueName = res.leagueName;
      })

    this.hasBracket$.pipe(takeUntil(this.destroy$))
    .subscribe(hasBracket => {
      this.hasBracket= hasBracket;
    })

    this.bracketService.reset$.pipe(takeUntil(this.destroy$)).subscribe(reset => {
      this.hasUpload = false;
      this.hasBracket$.next(false);
      try {
        this.canvas.nativeElement.firstElementChild.remove();
      } catch (ex) {
        // swallow
      }
    });
    this.leagueService.standingsSet$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.hasBracket$.next(this.bracketService.generateBracket(this.canvas));
    });
    this.playoffsService.playoffGameSelected$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if(this.playoffsService.isFullBracketPicked){
        this.bracketService.generateBracketFromPlayoffService(this.canvas);
      }
    });

    this.playoffsService.updateUrl$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if(this.playoffsService.isFullBracketPicked){
        this.bracketService.generateBracketFromPlayoffService(this.canvas);
      }else{
        this.bracketService.reset();
      }
    });
    this.playoffsService.playoffs$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if(this.playoffsService.isFullBracketPicked){
        this.bracketService.generateBracketFromPlayoffService(this.canvas);
      }else{
        this.bracketService.reset();
      }
    });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
