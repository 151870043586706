<h1 mat-dialog-title>
  Bulk Add Users</h1>
<mat-dialog-content #dialog>
  <p>Paste a comma or space-separated list of email addresses to invite. The Member Name will be set to be the same as the Email, but it can be changed later</p>
  <div [formGroup]="form">
    <div class="editor-email" (click)="focusEmailInput()">
      <span class="email-item" *ngFor="let email of emails">
      {{email}}
      
      <span class="remove-email" (click)="removeEmail(email)">×</span>
    </span>
		<input
        [disabled]="seatsUsed >= seatsAllowed"
        placeholder=""
        #emailInput type="text"
        class="input-email"
        (keyup)="onKeyUp($event)"
        formControlName="email">
    </div>
  </div>
  <p>Separate emails with a comma (,) or enter a space</p>
  <mat-error *ngIf="error">
    {{error}}
  </mat-error>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    [disabled]="form.invalid || loading"
    (click)="onSubmit()"
    color="accent"
  >
    {{ loading ? "saving..." : "Save" }}
  </button>
  <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
