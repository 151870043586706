import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { debounce, map, takeUntil } from "rxjs/operators";
import { ChartDataService } from "../services/chart-data.service";
import * as Highcharts from "highcharts";

@Component({
  selector: "app-pulse-daily",
  templateUrl: "./pulse-daily.component.html",
  styleUrls: ["./pulse-daily.component.css"],
})
export class PulseDailyComponent implements OnInit {
  chart: any;
  leagueGuid = "ECC1459D-3649-43B4-97A7-5C253ADEE207";
  teamName$ = new BehaviorSubject<string>("Chiefs");

  minDate;
  maxDate;
  range = new FormGroup({
    start: new FormControl("", [Validators.required]),
    end: new FormControl("", [Validators.required]),
    teamName: new FormControl("", [Validators.required]),
  });
  public teams = [
    { value: "49ers", viewValue: "49ers" },
    { value: "Bears", viewValue: "Bears" },
    { value: "Bengals", viewValue: "Bengals" },
    { value: "Bills", viewValue: "Bills" },
    { value: "Broncos", viewValue: "Broncos" },
    { value: "Browns", viewValue: "Browns" },
    { value: "Buccaneers", viewValue: "Buccaneers" },
    { value: "Cardinals", viewValue: "Cardinals" },
    { value: "Chargers", viewValue: "Chargers" },
    { value: "Chiefs", viewValue: "Chiefs" },
    { value: "Colts", viewValue: "Colts" },
    { value: "Commanders", viewValue: "Commanders" },
    { value: "Cowboys", viewValue: "Cowboys" },
    { value: "Dolphins", viewValue: "Dolphins" },
    { value: "Eagles", viewValue: "Eagles" },
    { value: "Falcons", viewValue: "Falcons" },
    { value: "Giants", viewValue: "Giants" },
    { value: "Jaguars", viewValue: "Jaguars" },
    { value: "Jets", viewValue: "Jets" },
    { value: "Lions", viewValue: "Lions" },
    { value: "Packers", viewValue: "Packers" },
    { value: "Panthers", viewValue: "Panthers" },
    { value: "Patriots", viewValue: "Patriots" },
    { value: "Raiders", viewValue: "Raiders" },
    { value: "Rams", viewValue: "Rams" },
    { value: "Ravens", viewValue: "Ravens" },
    { value: "Saints", viewValue: "Saints" },
    { value: "Seahawks", viewValue: "Seahawks" },
    { value: "Steelers", viewValue: "Steelers" },
    { value: "Texans", viewValue: "Texans" },
    { value: "Titans", viewValue: "Titans" },
    { value: "Vikings", viewValue: "Vikings" },
  ];
  destroy$ = new Subject<boolean>();


  constructor(
    private chartService: ChartDataService
  ) {
    let max = new Date();
    this.minDate = new Date("2023-05-11");
    max.setHours(-24);
    this.maxDate = max;
  }
  highcharts = Highcharts;
  chartOptions = null;

  ngOnInit(): void {
      this.range.valueChanges.pipe(debounce(() => interval(1000)), takeUntil(this.destroy$), map(p => p)).subscribe((f) => {
        if (this.range.valid) {
          this.chartService
            .getDailyChartData(
              this.leagueGuid,
              this.range.get("teamName").value,
              this.range.get("start").value,
              this.range.get("end").value
            )
            .subscribe((input: any) => {
              input.chart.type = "areaspline";
              input.plotOptions = {
                areaspline: {
                  fillOpacity: 0.5,
                },
              };
              input.credits.text =  "v2.playoffpredictors.com/pulse/daily";
              input.credits.href = "https://v2.playoffpredictors.com/pulse/daily";
              input.caption.color = "black";
              input.xAxis.labels = {rotation: 45 }
              this.chartOptions = input;
            });
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  teamSelected(event: MatSelectChange) {
    this.teamName$.next(event.value);
    (<any>window).ga("send", "event", "pulse-daily-team-selected", event.value);
  }
}
