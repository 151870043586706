import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { FullGroupDetailsResponse, LeagueDropdownResponse, PoolDetails } from "@models";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PlayoffPoolService, PoolService } from "@services/index";

@Component({
  selector: "app-edit-pool",
  templateUrl: "./edit-pool.component.html",
  styleUrls: ["./edit-pool.component.css"],
})
export class EditPoolModalComponent implements OnInit {

  @Input('poolGuid')
  selectedPool: BehaviorSubject<FullGroupDetailsResponse>;

  selectedPoolGroup: PoolDetails
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";
  destroy$ = new Subject<boolean>();
  _routerSubscription;
  constructor(
    private router: Router,
    private poolService: PoolService,
    private snackBar: MatSnackBar
  ) {
    //this.selectedGroupGuid = data.selectedPool.groupGuid;
    // this.editPoolForm.setValue({
    //   groupName: data.selectedPool.groupName,
    //   baseMultiplier: data.selectedPool.baseMultiplier,
    //   wildcardPoints: data.selectedPool.wildcardPoints,
    //   divisionPoints: data.selectedPool.divisionPoints,
    //   championshipPoints: data.selectedPool.championshipPoints,
    //   superBowlPoints: data.selectedPool.superBowlPoints
    // });
  }
  editPoolForm = new FormGroup({
    groupName: new FormControl("", [Validators.required]),
    baseMultiplier: new FormControl("1", [Validators.required]),
    wildcardPoints: new FormControl("1", [Validators.required]),
    divisionPoints: new FormControl("2", [Validators.required]),
    championshipPoints: new FormControl("4", [Validators.required]),
    superBowlPoints: new FormControl("8", [Validators.required]),
})

  get fc(){
    return this.editPoolForm.controls;
  }
  ngOnInit() {
    this.selectedPool.pipe(takeUntil(this.destroy$)).subscribe(pool => {
      this.poolService.getPoolDetails(pool.groupGuid).subscribe(res => {
        this.selectedPoolGroup = res;
        this.editPoolForm.setValue({
          groupName: this.selectedPoolGroup.groupName,
          baseMultiplier: this.selectedPoolGroup.baseMultiplier,
          wildcardPoints: this.selectedPoolGroup.wildcardPoints,
          divisionPoints: this.selectedPoolGroup.divisionPoints,
          championshipPoints: this.selectedPoolGroup.championshipPoints,
          superBowlPoints: this.selectedPoolGroup.superBowlPoints
        });
      })
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  onCancel(){
    this.editPoolForm.reset({
      groupName: this.selectedPoolGroup.groupName,
      baseMultiplier: this.selectedPoolGroup.baseMultiplier,
      wildcardPoints: this.selectedPoolGroup.wildcardPoints,
      divisionPoints: this.selectedPoolGroup.divisionPoints,
      championshipPoints: this.selectedPoolGroup.championshipPoints,
      superBowlPoints: this.selectedPoolGroup.superBowlPoints
    });
  }
  @HostListener("window:keyup.Enter", ["$event"])
  onSubmit() {
   
    // stop here if form is invalid
    if (this.editPoolForm.valid) {
      this.submitted = true;
      this.loading = true;
      this.poolService
        .editPool({
          groupName: this.fc.groupName.value,
          groupId: this.selectedPoolGroup.groupId,
          baseMultiplier: this.fc.baseMultiplier.value,
          championshipPoints: this.fc.championshipPoints.value,
          divisionPoints: this.fc.divisionPoints.value,
          superBowlPoints: this.fc.superBowlPoints.value,
          wildcardPoints: this.fc.wildcardPoints.value,
        })
        .subscribe(
          () => {
            this.poolService.refreshMyPools();
            this.snackBar.open("Pool Updated!", "dismiss", {
              duration: 2000,
            });
            this.loading = false;
          },
          () => {
            this.error = "An error occurred";
            this.loading = false;
          }
        );
    }
  }
}
