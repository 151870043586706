<mat-card>
  <mat-card-header>
    <mat-card-title>Confirm Your Account</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      <strong>Having trouble getting the email or otherwise confirming?</strong>
      Send an email to
      <a
        href="mailto:verify-me@playoffpredictors.com?subject=Verify Me&Body=Hit Send"
        >verify-me@playoffpredictors.com</a
      >
      from the email address you've signed up with to be auto-verified. This
      email doesn't need to contain a body.
    </p>
    <p>
      Please note that the manual verify process may take a few minutes to complete.
    </p>
    <form (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input
          matInput
          placeholder="Code"
          type="text"
          [formControl]="code"
          required
        />
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      [disabled]="code.invalid"
      (click)="onSubmit()"
      color="accent"
    >
      Confirm Account
    </button>
  </mat-card-actions>
</mat-card>
