import { LeagueService } from '../../services/league/league.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Team, Game, SelectionType, UserConfig, League, UpdateReason } from '@models';
import { WeekService } from '../../services/week/week.service';
import { UserSettingsService } from '@services/user-settings/user-settings.service';
import { LeagueSettingsService } from '../../services/league-settings/league-settings.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GameMapService } from '../../services/game-map/game-map.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { environment } from '@env/environment';

@Component({
  selector: 'app-picker-channel',
  templateUrl: './picker-channel.component.html',
  styleUrls: ['./picker-channel.component.scss']
})
export class PickerChannelComponent implements OnInit, OnDestroy {
  public environment = environment;
  destroy$ = new Subject<boolean>();
  league: League;
  @Input()
  games: Array<Game>;

  @Input()
  channelName = '';

  userConfig: UserConfig;
  get week() {
    return this.weekService.week;
  }
  regex = new RegExp(/[_]+/gi);
  public replaceFunc = (str) => {
    return str.replace(this.regex, ' ').replace('STATUS ', '');
  }
  constructor(public leagueService: LeagueService, private weekService: WeekService,
    private userSettings: UserSettingsService, public leagueSettings: LeagueSettingsService,
    private gameMapService: GameMapService, private snackBar: MatSnackBar) { }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit() {
    this.userConfig = this.userSettings.userConfig;
    this.leagueService.league$.pipe(takeUntil(this.destroy$)).subscribe(league => this.league = league);
    this.gameMapService.ScoreUpdates.pipe(takeUntil(this.destroy$)).subscribe(liveUpdate => {
      let game = this.gameMapService.getGame(parseInt(liveUpdate.id, 10));
      if(!this.userConfig.nfl.liveUpdatesEnabled){
        return;
      }
      if (game.gameGuid.toUpperCase() != liveUpdate.gid.toUpperCase()) {
        return;
      }
      var isUpdated = false;
      if (game.scores.h != liveUpdate.h || game.scores.a != liveUpdate.a || game.scores.status != liveUpdate.s) {
        isUpdated = true;
      }
      if (isUpdated) {
        game.scores.h = liveUpdate.h;
        game.scores.a = liveUpdate.a;
        game.scores.status = liveUpdate.s;
        this.scoreChange(game, true);
        let config = new MatSnackBarConfig();
        config.duration = 2000;
        this.snackBar.open(`Live update published for ${game.away.teamName}@${game.home.teamName}`, "dismiss", config)
      }
    })
  }

  pickTeam(game: Game, teamPicked: Team) {
    game.updateReason = UpdateReason.UserPick;
    this.leagueService.pickTeam(game, teamPicked);
  }

  pickTie(game: Game) {
    game.updateReason = UpdateReason.UserPick;
    this.leagueService.pickTie(game);
  }

  scoreChange(game: Game, isLiveUpdate:boolean) {
    game.scoresIsDirty = true;

    if(isLiveUpdate && !this.userSettings.userConfig.nfl.updateGameOutcomesWithScoreUpdate){
      if(game.scores?.status?.toUpperCase() != 'F'){
        game.updateReason = UpdateReason.ScoreUpdate;
        return;
      }
    }

    if (game.scores.h != null && game.scores.a != null && (game.scores.a > 0 || game.scores.h > 0)) {
      if (game.scores.h > game.scores.a) {
        if (game.selectionType !== SelectionType.homeWin) {
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.pickTeam(game, game.home);
        }else{
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.gamePicked$.next(game);
        }
      } else if (game.scores.h < game.scores.a) {
        if (game.selectionType !== SelectionType.awayWin) {
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.pickTeam(game, game.away);
        }else{
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.gamePicked$.next(game);
        }
      } else if (game.scores.h === game.scores.a) {
        if (game.selectionType !== SelectionType.tie) {
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.pickTie(game);
        }else{
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.gamePicked$.next(game);
        }
      }
    }
  }
  void() { }
}
