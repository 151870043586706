<h2>Web Accessibility Statement</h2>
<h3>General</h3>
<p>
Playoff Predictors cares about all of our users and is committed to facilitating and improving the accessibility and usability of its Website, www.playoffpredictors.com (the "Website"), 
ensuring that our Website services and content are accessible to persons with disabilities including, but not limited to users of screen reader technology. Playoff Predictors has implemented standard technology and frameworks that will enable and maintain accessibility in-line with the Web Content Accessibility Guidelines (WCAG), 
which also attempts to bring the Website into conformance with the Americans with Disabilities Act of 1990.
</p>
<h3>Disclaimer</h3>
<p>
Please be aware that our efforts to maintain accessibility and usability are ongoing. While we strive to make the Website as accessible as possible some issues may be encountered by different assistive technology as the range of assistive technology is wide and varied. We appreciate your understanding.
</p>
<h3>Contact Us</h3>
<p>
If, at any time, you have specific questions or concerns about the accessibility of any particular web page on the Website, then please contact us by e-mail at help@playoffpredictors.com. 
If you do encounter an accessibility issue, then please be sure to specify the web page and nature of the issue, and we will make all reasonable efforts to make that page or the information contained therein accessible for you. 
</p>
<p>
Thanks for visiting.
</p>
