import {  
    ErrorHandler,  
    Injector,  
    Injectable  
} from '@angular/core';  
import { MonitoringService } from '@services/monitoring/monitoring.service';
@Injectable()  
export class ApplicationInsightsErrorHandler implements ErrorHandler {  
    constructor(private injector: Injector) {}  
    handleError(error: any): void {  
        this.injector.get<MonitoringService>(MonitoringService).logException(error);  
        console.warn(error);  
    }  
}  