<ng-container *ngIf="channelName.indexOf('vs') !== -1">
  
<h3 class="text-center">
    <img width="32" src="https://static.playoffpredictors.com/nfl/{{channelName.split(' vs ')[0]}}.svg"> vs <img width="32" src="https://static.playoffpredictors.com/nfl/{{channelName.split(' vs ')[1]}}.svg">
</h3>
</ng-container>
<ng-container *ngIf="channelName.indexOf('vs') == -1">
  
  <h3 class="text-center">
    <img width="32" src="https://static.playoffpredictors.com/nfl/{{channelName}}.svg" title="{{channelName}}"> {{channelName}}
  </h3>
</ng-container>

<table mat-table [dataSource]="games">
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.scores?.status?.toUpperCase() != 'NC' && element.scores?.status?.toUpperCase() != 'F' && element.scores?.status != 'PREGAME' && element.scores?.status != null ">
        <p matTooltip="In Progress" [matTooltipTouchGestures]="'on'"><mat-icon>info</mat-icon></p>
      </ng-container>
      <ng-container *ngIf="element.scores?.status?.toUpperCase() == 'NC' || element.selectionMade == 4">
        <p matTooltip="No Contest" [matTooltipTouchGestures]="'on'"><mat-icon>cancel</mat-icon></p>
      </ng-container>
      <ng-container *ngIf="!environment.production">
        {{ element.gameScheduleNum }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="away">
    <th mat-header-cell *matHeaderCellDef>Away</th>
    <td mat-cell *matCellDef="let element">
      <button [attr.data-gid]="element.gameScheduleNum" title="{{ element.away.regionName }} {{ element.away.teamName }}" mat-raised-button color="secondary" (click)="pickTeam(element, element.away)" [ngClass]="element.away.teamAbbreviation">
        <img width="32" height="32" title="{{ element.away.regionName }} {{ element.away.teamName }}" src="https://static.playoffpredictors.com/nfl/{{ element.away.teamLogo || element.away.teamAbbreviation }}.svg" title="element.away.teamName" />
        <sup><span *ngIf="(week > this.leagueSettings.numRegularSeasonWeeks)">{{ element.away.conferenceRank }}</span></sup>
        <!--<span fxHide.lt-xl>
          {{element.away.teamName}}
        </span>-->
      </button>
      <div style="display:flex;">
        <mat-icon *ngIf="element.awayOffBye" matTooltip="coming off bye">night_shelter</mat-icon>
        <mat-icon *ngIf="element.awayOffThurs" class="indicator-mini-bye" matTooltip="coming off mini bye">night_shelter</mat-icon>
        <input *ngIf="userConfig.nfl.enableScoring && league.features.allowsScores && week <= this.leagueSettings.numRegularSeasonWeeks" matInput type="number" [(ngModel)]="element.scores.a" (ngModelChange)="scoreChange(element, false)" style="width:50px" placeholder="10" />
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="tie">
    <th mat-header-cell *matHeaderCellDef style="text-align:center;">
      <span *ngIf="week <= this.leagueSettings.numRegularSeasonWeeks">TIE</span> 
      <span *ngIf="!(week <= this.leagueSettings.numRegularSeasonWeeks)">&nbsp;</span>
    </th>
    <td mat-cell *matCellDef="let element"><button *ngIf="week <= this.leagueSettings.numRegularSeasonWeeks" mat-button color="secondary" (click)="pickTie(element)" [ngClass]="element.selectionType == 3 ? 'TIE' : ''">=</button> <span *ngIf="!(week < this.leagueSettings.numRegularSeasonWeeks)">&nbsp;</span></td>
  </ng-container>
  <ng-container matColumnDef="home">
    <th mat-header-cell *matHeaderCellDef>Home</th>
    <td mat-cell *matCellDef="let element">
      <button title="{{ element.home.regionName }} {{ element.home.teamName }}" mat-raised-button color="secondary" (click)="pickTeam(element, element.home)" [ngClass]="element.home.teamAbbreviation">
        <img width="32" height="32" title="{{ element.home.regionName }} {{ element.home.teamName }}" src="https://static.playoffpredictors.com/nfl/{{ element.home.teamLogo || element.home.teamAbbreviation }}.svg" title="element.home.teamName" />
        <sup>
          <span *ngIf="(week > this.leagueSettings.numRegularSeasonWeeks)">{{ element.home.conferenceRank }}</span>
        </sup>
      </button>
      <div style="display:flex;">
        <mat-icon *ngIf="element.homeOffBye" matTooltip="coming off bye">night_shelter</mat-icon>
        <mat-icon *ngIf="element.homeOffThurs" class="indicator-mini-bye" matTooltip="coming off mini bye">night_shelter</mat-icon>
        <input *ngIf="userConfig.nfl.enableScoring && league.features.allowsScores && week <= this.leagueSettings.numRegularSeasonWeeks" matInput type="number" [(ngModel)]="element.scores.h" (ngModelChange)="scoreChange(element, false)" style="width:50px" placeholder="10" />
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="info">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element?.flag">
        <img width="32" style="margin:5px 0 5px 20px" src="https://static.playoffpredictors.com/flags/{{ element.flag }}.svg" matTooltip="international game - {{ element.flag }}" alt="international game {{ element.flag }}" /><br/>
      </ng-container>
      <span style="margin:5px 0 5px 20px" matTooltip="{{ element.gameDate | date: 'long' }}">
        {{ element.gameDate | date: 'E' }}
      </span>

    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="[ 'status', 'away', 'tie', 'home', 'info']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['status', 'away', 'tie', 'home', 'info']" class="{{ row.teamSelected }}"></tr>
</table>
