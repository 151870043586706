<h4>Legend</h4>
<table>
  <tbody>
    <tr>
      <td>
        <mat-icon>night_shelter</mat-icon>
      </td>
      <td>The team is coming off of a BYE &mdash; they didn't play the week prior</td>
    </tr>
    <tr>
      <td>
        <mat-icon class="indicator-mini-bye">night_shelter</mat-icon>
      </td>
      <td>
        The team is coming off of a mini-bye &mdash; they played Thursday the week
        prior
      </td>
    </tr>
    <tr>
        <td><img width="32" src="https://static.playoffpredictors.com/flags/uk.svg" /></td>
        <td>International game played in the United Kingdom</td>
    </tr>
    <tr>
        <td><img width="32" src="https://static.playoffpredictors.com/flags/ger.svg" /></td>
        <td>International game played in Germany</td>
    </tr>
  </tbody>
</table>
