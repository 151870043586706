import { NotFoundRoutingModule } from './not-found.routing';
import { NotFoundComponent } from './not-found/not-found.component';
import { MatModule } from './../material/mat.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesModule } from '@services/services.module';

@NgModule({
  providers: [],
  imports: [MatModule, CommonModule, NotFoundRoutingModule, ServicesModule, SharedModule, FormsModule, ReactiveFormsModule],
  declarations: [NotFoundComponent],
  exports: []
})
export class NotFoundModule {}
