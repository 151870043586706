import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil, map } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  year = new Date().getFullYear();
  private destroy$ = new Subject<boolean>();
  constructor() {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
