import { LinkService } from "@services/link-service/link.service";
import { UserSettingsService } from "@services/user-settings/user-settings.service";
import { HttpClientModule } from "@angular/common/http";
import { JwtInterceptor } from "@services/jwt.interceptor";
import { ErrorInterceptor } from "@services/error.interceptor";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SeoService } from "@services/seo/seo.service";
import { UpdateService, OnlineService, AuthenticationService, AlertService, UrlSerializerService, SubscriptionService, PoolService } from "@services/index";
import { MonitoringService } from "@services/monitoring/monitoring.service";
import { PlayoffPoolService } from "@services/playoff-pool/playoff-pool.service";
import { PlaysheetService } from "./playsheet/playsheet.service";
@NgModule({
  providers: [
    AuthenticationService,
    ErrorInterceptor,
    JwtInterceptor,
    AlertService,
    UrlSerializerService,
    UserSettingsService,
    LinkService,
    SubscriptionService,
    SeoService,
    UpdateService,
    OnlineService,
    MonitoringService,
    PlayoffPoolService,
    PoolService,
    PlaysheetService
  ],
  imports: [CommonModule, HttpClientModule],
  declarations: [],
})
export class ServicesModule {}
