import { Team } from './team.model';
import { SelectionType } from './SelectionType';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ScoreRecord } from './league-response.model';
import { UpdateReason } from './update-reason.model';

export class Game {
  away: Team;
  awayId: number;
  gameDate: Date;
  gameDateOffset?: any;
  gameGuid: string;
  gameKey: string;
  gameScheduleNum?: number;
  gameTypeID?: any;
  home: Team;
  homeId: number;
  leagueGuid: string;
  startTimeTBD?: any;
  timezone?: any;
  week: number;
  teamSelected: string;
  selectionType: SelectionType;
  gameClass: string;
  scoresIsDirty = false;
  scores: ScoreRecord;
  info: string;
  homeOffBye:boolean;
  awayOffBye:boolean;
  awayOffThurs:boolean;
  homeOffThurs:boolean;
  updateReason: UpdateReason;
  flag: string;
  get sortOrder() {
    let ret = 1;

    if (this.gameKey && this.gameDate) {
      const date = new Date(this.gameDate);
      const dayOfWeek = date.getDay();
      const sort = date.getTime();
      // Mon
      if (dayOfWeek === 1) {
        return sort * 10000;
      } else if (dayOfWeek === 4) {
        return sort / 100000;
      } else if (dayOfWeek === 6) {
        return sort;
      }
      return sort;
    }

    if (this.gameKey) {
      return this.gameKey;
    }

    if (this.gameDate) {
      const dayOfWeek = this.gameDate.getDay();
      const sort = this.gameDate.getTime();
      // Mon
      if (dayOfWeek === 1) {
        return sort * 10000;
      } else if (dayOfWeek === 4) {
        return sort / 100000;
      } else if (dayOfWeek === 6) {
        return sort;
      }
      return sort;
    }

    switch (this.gameTypeID) {
      case 1: // sunday night
        ret = 7;
        break;
      case 2: // saturday
        ret = 9;
        break;
      case 3: // Mon
        ret = 1;
        break;
      case 4: // Thurs
        ret = 10;
        break;
      case 5: // MX
        ret = 6;
        break;
      case 6: // LN
        ret = 6;
        break;
      default:
        ret = 8;
        break;
    }
    return ret;
  }
  get selectionMade() {
    return this.selectionType;
  }

  get teamThatWon() {
    if (
      this.selectionMade &&
      this.selectionType !== SelectionType.none &&
      this.selectionType !== SelectionType.tie
    ) {
      switch (this.selectionType) {
        case SelectionType.awayWin: {
          return this.away;
        }
        case SelectionType.homeWin: {
          return this.home;
        }
        default:
          return null;
      }
    }
  }

  get teamThatLost() {
    if (
      this.selectionMade &&
      this.selectionType !== SelectionType.none &&
      this.selectionType !== SelectionType.tie
    ) {
      switch (this.selectionType) {
        case SelectionType.awayWin: {
          return this.home;
        }
        case SelectionType.homeWin: {
          return this.away;
        }
        default:
          return null;
      }
    }
  }
  constructor(data) {
    Object.assign(this, data);
    this.away = new Team(this.away);
    this.home = new Team(this.home);
    if (this.gameDate) {
      this.gameDate = zonedTimeToUtc(this.gameDate, this.timezone || 'America/New_York');
    }
    this.scores = new ScoreRecord();
  }
  bothTeamNamesInGame(team1: string, team2: string){
    return (this.away.teamAbbreviation == team1 || this.away.teamAbbreviation == team2) 
      && (this.home.teamAbbreviation == team1 || this.home.teamAbbreviation == team2);

  }
}
