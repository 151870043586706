<h1 mat-dialog-title>Add Member</h1>
<mat-dialog-content #dialog *ngIf="fc != null">
  <form (ngSubmit)="onSubmit()" style="display: flex; flex-direction: column" [formGroup]="addMemberForm">
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input
        matInput
        formControlName="email"
        required
        type="text"
        [placeholder]="'user@domain.com'"
      />

      <mat-error *ngIf="fc.email.invalid">
        <span *ngIf="fc.email.hasError('required')">
          Email is required
        </span>
        <span *ngIf="fc.email.hasError('email')">
            Value must be an email address
          </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Member Name</mat-label>
      <input
        matInput
        formControlName="memberName"
        required
        type="text"
        [placeholder]="'Luke Skywalker'"
      />
      <mat-error *ngIf="fc.memberName.invalid">
        <span *ngIf="fc.memberName.hasError('required')">
          Member name is required
        </span>
      </mat-error>
    </mat-form-field>
  </form>
  {{ error }}
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    [disabled]="fc && addMemberForm.invalid || loading"
    (click)="onSubmit()"
    color="accent"
  >
    {{ loading ? "saving..." : "Save" }}
  </button>
  <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
