<ng-container *ngIf="!authService.isLoggedIn">
  <p>Please <button mat-button mat-flat-button (click)="loginClick()">login</button> to see your picks</p>
</ng-container>
<ng-container *ngIf="!selectedPool && authService.isLoggedIn">
  <p>Please select a pool from the dropdown to see your picks</p>
</ng-container>
<ng-container *ngIf="authService.isLoggedIn && selectedPool">
  <p><strong>Season Total Points: {{ seasonTotal }}</strong></p>
  <div class="button-row">
    <button mat-button mat-raised-button (click)="prevWeek()" [disabled]="selectedWeek == 1"><mat-icon>arrow_left</mat-icon></button>
    <mat-form-field appearance="fill">
      <mat-label>Week</mat-label>
      <mat-select [(value)]="selectedWeek" (selectionChange)="selectWeek($event)">
        <mat-option *ngFor="let option of weeks" [value]="option.value">{{
          option.value
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button mat-raised-button (click)="nextWeek()" [disabled]="selectedWeek == weekService.weeks.length"><mat-icon>arrow_right</mat-icon></button>
</div>
<mat-form-field appearance="fill" *ngIf="!(isPublicPool.value) && poolMembers.length">
  <mat-label>Viewing As</mat-label>
  <mat-select [(value)]="selectedMember" (selectionChange)="selectMember($event)">
    <mat-option *ngFor="let member of poolMembers" [value]="member">{{member.memberName}}</mat-option>
  </mat-select>
</mat-form-field>
<p *ngIf="isViewingOtherUser">
  You are viewing another user's picks. You will only see another user's picks if the game is finished.
</p>
<p>
  Pool administrators can see all of your picks, even if the game has not started, but cannot modify them.
</p>
<p *ngIf="!isViewingOtherUser">
    Clicking a team name will allow you to change your pick up until the game
    starts. You may not select ties.
  </p>
  <table class="mat-table min-width-50">
    <tr class="mat-header-row">
      <th class="mat-header-cell">Away</th>
      <th class="mat-header-cell">Home</th>
      <th class="mat-header-cell">Win/Loss</th>
    </tr>
    <ng-container *ngFor="let game of selectedWeeksPicks">
      <tr class="{{ game.teamThatWonAbbreviation }} mat-row">
        <td class="mat-cell">
          <button
            mat-raised-button
            [disabled]="!game.canUpdate"
            color="secondary"
            (click)="changePick(game, game.awayId, game.awayAbbreviation)"
            [class]="game.awayAbbreviation"
            [class.cyan-outline]="game.userRight == null && !game.canUpdate && game.teamPicked == game.awayId"
            [class.green-outline]="
              game.userRight && game.teamThatWon == game.awayId
            "
            [class.red-outline]="
              !game.userRight && game.teamThatWon == game.homeId
            "
            [class.tie-outline]="!game.userRight && game.actualOutcome == 3"
          >
            <img
              width="32"
              height="32"
              src="https://static.playoffpredictors.com/nfl/{{
                game.awayLogo || game.awayAbbreviation
              }}.svg"
            />
          </button>
        </td>
        <td class="mat-cell">
          <button
            mat-raised-button
            [disabled]="!game.canUpdate"
            color="secondary"
            (click)="changePick(game, game.homeId, game.homeAbbreviation)"
            [class]="game.homeAbbreviation"
            [class.cyan-outline]="game.userRight == null && !game.canUpdate && game.teamPicked == game.homeId"
            [class.green-outline]="
              game.userRight && game.teamThatWon == game.homeId
            "
            [class.red-outline]="
              !game.userRight && game.teamThatWon == game.awayId
            "
            [class.tie-outline]="!game.userRight && game.actualOutcome == 3"
          >
          <img
              width="32"
              height="32"
              src="https://static.playoffpredictors.com/nfl/{{
                game.homeLogo ||
                game.homeAbbreviation
              }}.svg"
            />
          </button>
        </td>
        <td class="mat-cell">
          <ng-container *ngIf="game.userRight != null">
            <span [class.good]="game.userRight" [class.bad]="!game.userRight && game.actualOutcome != 3" [class.tie-outcome]="!game.userRight && game.actualOutcome == 3">
              {{ game.userRight ? "&#x2713;" : "&#x2718;" }} <span *ngIf="!game.userRight && game.actualOutcome == 3">TIE</span>
            </span>
          </ng-container>
          <ng-container *ngIf="game.userRight == null"> - </ng-container>
        </td>
      </tr>
    </ng-container>
    <tfoot>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">Week's Score (right - wrong)</td>
        <td class="mat-cell">{{ weekTotalRight }}-{{ weekTotalWrong }}</td>
      </tr>
    </tfoot>
  </table>
</ng-container>
