<h2>Playoff Predictors Roadmap</h2>
<p>Revised Jan 21, 2023</p>
<p>This roadmap doesn’t represent any guarantees or promises. It serves as a rough outline to what we want to do in the future and highlights the current short-term priorities.</p>
<h3>Active Development</h3>
<ul>
    <li>NFL Playoff Pool Manager (coming 2023-2024 Season)</li>
</ul>
<h3>Up Next</h3>
<ul>
    <li>CFB to V2</li>
</ul>
<h3>Some Level of Development</h3>
<ul>
    <li>Subscription Services</li>
    <li>NCAA College Football Championship Games</li>
    <li>NCAA College Football Conference-specific Tiebreakers</li>
    <li>NCAA March Madness</li>
    <li>NCAA College Basketball</li>
</ul>
<h3>Planned, but not prioritized/began</h3>
<ul>
    <li>Euro Soccer</li>
    <li>MLS</li>
</ul>
<h3>Likely Future Subscriber-Only Features</h3>
<ul>
    <li>March Madness Bracket Builder</li>
    <li>Better features for NFL Custom Leagues, such as changing logos/divisions/number of games</li>
</ul>
<h3>Do not plan to do</h3>
<ul>
    <li>Draft/lottery systems for NBA, MLB</li>
    <li>Other college sports</li>
</ul>
