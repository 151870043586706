import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PulseHomeComponent } from "./pulse-home/home.component";
import { PulseDailyComponent } from "./pulse-daily/pulse-daily.component";
import { PulseWeeklyComponent } from "./pulse-weekly/pulse-weekly.component";
import { PulseComponent } from "./pulse/pulse.component";
import { PowerRankComponent } from "./powerrank/powerrank.component";

const routes: Routes = [
  {
    path: "",
    component: PulseHomeComponent,

    children: [
      {
        path: "daily",
        component: PulseDailyComponent,
        data:{
          title: "Playoff Predictors Daily Pulse",
          description: "See how a team performed on Playoff Predictors on a daily basis"
        }
      },
      {
        path: "weekly",
        component: PulseWeeklyComponent,
        data:{
          title: "Playoff Predictors Daily Pulse",
          description: "See how a team performed on Playoff Predictors on a weekly basis"
        }
      },
      {
        path: "powerrank",
        component: PowerRankComponent,
        data:{
          title: "Playoff Predictors NFL Power Rank",
          description: "Shows how NFL Teams Power Ranks change over time on Playoff Predictors"
        }
      },
      {
        path:"home",
        redirectTo:"weekly"
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PulseRoutingModule {}
