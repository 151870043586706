<mat-card class="playoff-game" [ngClass]="playoffGame?.teamSelected?.team?.teamAbbreviation">
  <mat-card-content>

  <ng-container *ngIf="playoffGame.home">
    <!--
    {{ playoffGame.key }}
    {{ playoffGame.saveIndex }}
    -->
    <div>
    <button title="{{ playoffGame.home.team.regionName }} {{ playoffGame.home.team.teamName }}" mat-raised-button color="secondary" [ngClass]="playoffGame.home.team.teamAbbreviation" (click)="pickTeam(playoffGame.home)">
      <img
        title="{{ playoffGame.home.team.regionName }}
            {{ playoffGame.home.team.teamName }}"
        src="https://static.playoffpredictors.com/nfl/{{ playoffGame.home.team.teamLogo || playoffGame.home.team.teamAbbreviation }}.svg"
        title="{{playoffGame.home.team.teamName}}"
      />
      <p>
        <span fxHide fxShow.gt-md> {{ playoffGame.home.team.teamName }} </span> 
        <span fxHide fxShow fxHide.gt-md> {{ playoffGame.home.team.teamAbbreviation }} </span> 
        <sup>{{ playoffGame.home.seed }}</sup>
      </p>
    </button>
    <input *ngIf="enableScoring" type="number" [(ngModel)]="homeScore" (ngModelChange)="scoreUpdate()" />
  </div>
  </ng-container>
  <ng-container *ngIf="!playoffGame.home"> <button mat-raised-button color="secondary">
    <img src="https://static.playoffpredictors.com/site/pp-color.svg">
    <p>
      {{ homePlaceholderText }}
    </p></button> </ng-container>
    <ng-container *ngIf="playoffGame.away">
      <div>
      <button title="{{ playoffGame.away.team.regionName }} {{ playoffGame.away.team.teamName }}" mat-raised-button color="secondary" [ngClass]="playoffGame.away.team.teamAbbreviation" (click)="pickTeam(playoffGame.away)">
        <img
          title="{{ playoffGame.away.team.regionName }}
            {{ playoffGame.away.team.teamName }}"
          src="https://static.playoffpredictors.com/nfl/{{ playoffGame.away.team.teamLogo || playoffGame.away.team.teamAbbreviation }}.svg"
          title="{{playoffGame.away.team.teamName}}"
        />
        

          <p>
          <span fxHide fxShow.gt-md> {{ playoffGame.away.team.teamName }} </span> 
          <span fxHide fxShow fxHide.gt-md> {{ playoffGame.away.team.teamAbbreviation }} </span> 
          <sup>{{ playoffGame.away.seed }}</sup>
          </p>
      </button>
      <input *ngIf="enableScoring" type="number" [(ngModel)]="awayScore" (ngModelChange)="scoreUpdate()" />
    </div>
    </ng-container>
    <ng-container *ngIf="!playoffGame.away"> <button mat-raised-button color="secondary">
      <img src="https://static.playoffpredictors.com/site/pp-color.svg">
      <p>
        {{ awayPlaceholderText }}
      </p>
      &nbsp;</button> </ng-container>
</mat-card-content>
</mat-card>
