import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';
@Injectable()
export class PaywallGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      if (this.authService.userValue) {
        var isAdsFree = this.authService.isAdsFree;
        if (!isAdsFree) {
          return this.router.navigate(['/home/subscribersOnly']);
        } else if (isAdsFree) {
          // logged in and is a subscriber so return true
          return true;
        }
      }

      // not logged in so redirect to login page with the return url
      return this.router.navigate(['/account/user/login'], {
        queryParams: { returnUrl: state.url }
      });
    } catch (ex) {
      return this.router.navigate(['/account/user/login'], {
        queryParams: { returnUrl: state.url }
      });
    }
  }
}
