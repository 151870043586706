import { MatModule } from "../material/mat.module";
import { PctFormatComponent } from "./templates/pct-format/pct-format.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule, BREAKPOINT } from "@angular/flex-layout";
import { TweetComponent } from "./tweet/tweet.component";
import { FreestarComponent } from "./templates/freestar/freestar.component";
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from "@angular/forms";
import { lzstringValidator } from "./validators/lzstring-validator.directive";
import { TeamLogoComponent } from "./templates/team-logo/team-logo.component";
import { GaEventDirective } from "./ga-event/ga-event.directive";
import { AlertComponent } from "./alert/alert.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { YesNoDialogComponent } from "./templates/yes-no-dialog/yes-no-dialog.component";
import { WatchlistComponent } from "./templates/watchlist/watchlist.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SaveWatchlistModalComponent } from "./templates/save-watchlist-modal/save-watchlist-modal.component";
import { playoffUrlValidator } from "./validators/playoff-url-validator.directive";
import { PlaysheetPostComponent } from './templates/playsheet-post/playsheet-post/playsheet-post.component';

const PRINT_BREAKPOINTS = [
  {
    alias: "xs.print",
    suffix: "XsPrint",
    mediaQuery: "print and (max-width: 297px)",
    overlapping: false,
  },
];
@NgModule({
  imports: [
    CommonModule,
    MatModule,
    FlexLayoutModule,
    FontAwesomeModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    PctFormatComponent,
    TweetComponent,
    FreestarComponent,
    TeamLogoComponent,
    GaEventDirective,
    AlertComponent,
    YesNoDialogComponent,
    WatchlistComponent,
    SaveWatchlistModalComponent,
    PlaysheetPostComponent,
  ],
  exports: [
    PctFormatComponent,
    TweetComponent,
    FreestarComponent,
    TeamLogoComponent,
    GaEventDirective,
    AlertComponent,
    YesNoDialogComponent,
    WatchlistComponent,
    PlaysheetPostComponent
  ],
  providers: [
    { provide: BREAKPOINT, useValue: PRINT_BREAKPOINTS, multi: true },
    { provide: NG_VALIDATORS, useExisting: lzstringValidator, multi: true },,
    { provide: NG_VALIDATORS, useExisting: playoffUrlValidator, multi: true },
  ],
})
export class SharedModule {}
