import {
  League,
  Team,
  Game,
  SelectionType,
  LeagueResponse,
  RegisterPickRequest,
  UpdateReason,
} from "@models";
import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "@env/environment";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import {
  take,
  map,
  takeUntil,
  bufferTime,
  first,
  distinct,
} from "rxjs/operators";
import * as Enumerable from "linq";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { UserSettingsService } from "@services/user-settings/user-settings.service";

@Injectable()
export class LeagueService implements OnDestroy {
  league$ = new BehaviorSubject<League>(null);
  standingsSet$ = new BehaviorSubject<boolean>(false);
  selectedTeam$ = new Subject<Team>();
  destroy$ = new Subject<boolean>();
  gamePicked$ = new Subject<Game>();
  randomizedGamePicked$ = new Subject<Game>();
  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private userSettingsService: UserSettingsService
  ) {}
  private readonly keySubject = new Subject<RegisterPickRequest>();
  private readonly request$: Observable<void> = this.keySubject
    .asObservable()
    .pipe(
      distinct((p) => p.gameIdentifier),
      bufferTime(1000),
      map((items) => this.postRegisterPick(items))
    );
  private postRegisterPick(requests: RegisterPickRequest[]) {
    if(!environment.production){
      return;
    }
    this.httpClient
      .post(
        "https://api.playoffpredictors.com/LeagueEngine/RegisterPicks",
        requests
      )
      .subscribe();
  }
  RegisterPick(request: RegisterPickRequest) {
    const obs = new Observable<void>((observer) => {
      this.request$.subscribe(observer);
      this.keySubject.next(request);
    });
    return obs.pipe(first()).subscribe();
  }

  LoadInitialLeague(leagueGuid: string, swBypass: boolean, userId?: string): Observable<League> {
    const user = this.authService.userValue;
    if (leagueGuid.indexOf("?") !== -1) {
      leagueGuid = leagueGuid.split("?")[0];
    }
    let url = `${environment.leagueApiUrl}leagues/1/${leagueGuid}/`;
    if (userId) {
      if (userId.indexOf("?") !== -1) {
        userId = userId.split("?")[0];
      }
      url += `${userId}/`;
    } else if (user) {
      if (user.id) {
        url += `${user.id}/`;
      }
    } else {
      url += "official/";
    }
    let headers = {};
    if(swBypass){
      headers = { headers: {
        'ngsw-bypass':'true'
      }}
    }
    this.httpClient
      .get<LeagueResponse>(url, headers)
      .pipe(
        take(1),
        map((res: LeagueResponse) => {
          const teams = res.teams.map((team) => new Team(team));
          const confs = Enumerable.from(teams)
            .groupBy((p) => p.conference)
            .toArray();
          const dataDict: any = {};
          dataDict[confs[0].key()] = confs[0].getSource();
          dataDict[confs[1].key()] = confs[1].getSource();
          const league = new League(
            res.league,
            teams,
            dataDict,
            confs[0].key(),
            confs[1].key(),
            res.scores,
            res.playoffTeams
          );
          league.sportId = res.sportId;
          league.leagueGuid = leagueGuid;
          this.league$.next(league);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    return this.league$;
  }

  saveScores(leagueGuid: string, scores: any) {
    return this.httpClient
      .post(
        //todo
        `${environment.leagueApiUrl}scores/1/${leagueGuid}`,
        scores
      )
      .pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  pickTeam(game: Game, teamPicked: Team) {
    if (game.selectionType !== 0 && game.teamThatWon === teamPicked) {
      this.pickNone(game);
      game.selectionType = SelectionType.none;
      game.home.gameRecords.notify("none", game, game.home);
      game.away.gameRecords.notify("none", game, game.away);
    } else {
      game.teamSelected = teamPicked.teamAbbreviation;
      if (game.home === teamPicked) {
        game.selectionType = SelectionType.homeWin;
        game.home.gameRecords.notify("win", game, game.away);
      } else if (game.away === teamPicked) {
        game.selectionType = SelectionType.awayWin;
        game.away.gameRecords.notify("win", game, game.home);
      } else {
        this.pickTie(game);
      }
    }
    if(game.updateReason == UpdateReason.ScheduleLoad){
      return;
    }
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'teampicked',
      'league': this.league$.value.leagueName,
      'team': teamPicked.teamName
    });
    if (game.updateReason == UpdateReason.Randomized) {
      this.randomizedGamePicked$.next(game);
    } else {
      this.gamePicked$.next(game);
    }
  }

  pickTie(game: Game) {
    if (game.selectionType === SelectionType.tie) {
      game.home.gameRecords.notify("none", game, game.home);
      game.away.gameRecords.notify("none", game, game.away);
      this.pickNone(game);
    } else {
      game.away.gameRecords.notify("Tie", game, game.home);
      game.selectionType = SelectionType.tie;
      game.teamSelected = "TIE";
    }
    if(game.updateReason == UpdateReason.ScheduleLoad){
      return;
    }
    if (game.updateReason == UpdateReason.Randomized) {
      this.randomizedGamePicked$.next(game);
    } else {
      this.gamePicked$.next(game);
    }
  }

  pickNone(game: Game) {
    game.selectionType = SelectionType.none;
    game.teamSelected = "NONE";
    
    game.home.gameRecords.notify("none", game, game.home);
    game.away.gameRecords.notify("none", game, game.away);
    
    this.gamePicked$.next(game);
  }

  pickNoContest(game: Game){
    game.selectionType = SelectionType.noContest;
    game.teamSelected = "NONE";
    this.gamePicked$.next(game);
  }
}
