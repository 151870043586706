import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginTemplateComponent } from './templates/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user/user.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '@services/guards/auth.guard';
import { RegisterTemplateComponent } from './templates/register/register.component';
import { RecoverComponent } from './recover/recover.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
const routes: Routes = [
  {
    path: 'account',
    component: HomeComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: 'user',
            component: UserComponent,
            children: [
              {
                path: 'login',
                component: LoginTemplateComponent
              },
              {
                path: 'register',
                component: RegisterTemplateComponent
              }
            ]
          },
          {
            path: 'recover',
            component: RecoverComponent
          },
          {
            path: 'confirm/:id',
            component: ConfirmComponent
          },
          {
            path: 'confirm/:id/:code',
            component: ConfirmComponent
          },
          {
            path: 'reset-password/:id/:code',
            component: ResetPasswordComponent
          },
          {
            path: 'settings',
            component: SettingsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'subscriptions',
            component: SubscriptionsComponent,
            canActivate: [AuthGuard]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
