import { FootballComponent } from "./football/football.component";
import { PlayoffPictureComponent } from "./nfl/playoff-picture/playoff-picture.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NflComponent } from "./nfl/nfl/nfl.component";
import { PaywallGuard } from "@services/guards/paywall.guard";
import { BracketUploadComponent } from "./nfl/bracket-upload/bracket-upload.component";
import { ScorerComponent } from "./nfl/scorer/scorer.component";
import { PlayoffPoolComponent } from "./nfl/playoff-pool/playoff-pool.component";
import { PoolsComponent } from "./nfl/pools/pools.component";
import { environment } from "@env/environment";

const routes: Routes = [
  {
    path: "football",
    component: FootballComponent,
    children: [
      {
        path: "",
        children: [
          {
            path: "nfl/playoffpicture/:leagueGuid",
            component: PlayoffPictureComponent,
          },

          {
            path: 'nfl/pools/:leagueGuid/:poolGuid',
            component: PoolsComponent,
          },

          {
            path: 'nfl/pools/:leagueGuid',
            component: PoolsComponent,
          },
          {
            path: "nfl/pools",
            pathMatch: "full",
            redirectTo: `nfl/pools/${environment.currentNflGuid}`,
          },

          {
            path: `nfl`,
            children: [
              {
                path: "playoff-pool/:groupId",
                component: PlayoffPoolComponent,
                //canActivate: [BetaGuard],
              },
              {
                path: "scorer",
                component: ScorerComponent,
              },
              {
                path: "bracket/:bracketId",
                component: BracketUploadComponent,
              },
              {
                path: ":leagueGuid/:userId",
                component: NflComponent,
              },
              {
                path: ":leagueGuid",
                component: NflComponent,
              },
              {
                path: ":leagueGuid/:userId/:leaderboard",
                component: NflComponent,
              },
              {
                path: "",
                pathMatch: "full",
                redirectTo: `${environment.currentNflGuid}`,
              },
            ],
          },
        ],
      },
      {
        path: "**",
        redirectTo: "/not-found",
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FootballRoutingModule {}
