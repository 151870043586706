import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-email-preferences',
  templateUrl: './email-preferences.component.html',
  styleUrls: ['./email-preferences.component.css']
})
export class EmailPreferencesComponent implements OnInit {
  optIn = false;
  destroy$ = new Subject<boolean>();
  loading = true;
  constructor(public authService: AuthenticationService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.authService.getMe().subscribe(me => {
      this.optIn = me.emailListSubscribed;
      this.loading=false;
    })

   
  }
  optInChange(event: MatCheckboxChange){
    this.authService.updateEmailPreference(event.checked).subscribe(r=>{
      this.snackBar.open("Your settings have been updated", "dismiss", {
        duration: 2000
      })
    })
  }
}
