import { PlayoffGame, PlayoffTeam, League, SelectionType } from '@models';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { takeUntil, take, map } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import * as Enumerable from 'linq';
@Injectable({
  providedIn: 'root'
})
export class PlayoffsService implements OnDestroy {
  destroy$ = new Subject<boolean>();
  playoffs$ = new BehaviorSubject<League>(null);
  afcTeams: PlayoffTeam[];
  nfcTeams: PlayoffTeam[];
  league: League;
  playoffGames:{[gameKey:string]:PlayoffGame} = {};
  playoffGameSelected$: Subject<PlayoffGame>;
  updateUrl$ = new Subject<string>();
  enableScoring = false;
get isFullBracketPicked(){
  try{
  return this.playoffGames['afc-wc-1'].isTeamSelected() &&
        this.playoffGames['afc-wc-2'].isTeamSelected() &&
        this.playoffGames['afc-wc-3'].isTeamSelected() &&
        this.playoffGames['nfc-wc-1'].isTeamSelected() &&
        this.playoffGames['nfc-wc-2'].isTeamSelected() &&
        this.playoffGames['nfc-wc-3'].isTeamSelected() &&
        this.playoffGames['afc-dv-1'].isTeamSelected() &&
        this.playoffGames['afc-dv-2'].isTeamSelected() &&
        this.playoffGames['nfc-dv-1'].isTeamSelected() &&
        this.playoffGames['nfc-dv-2'].isTeamSelected() &&
        this.playoffGames['afc-cg'].isTeamSelected() &&
        this.playoffGames['nfc-cg'].isTeamSelected() &&
        this.playoffGames['sb'] .isTeamSelected();
  }catch (ex){
    return false;
  }
}
  get afcWcWinners() {
    let temp = [];
    const winner1 = this.playoffGames['afc-wc-1'] && this.playoffGames['afc-wc-1'].teamSelected;
    const winner2 = this.playoffGames['afc-wc-2'] && this.playoffGames['afc-wc-2'].teamSelected;
    const winner3 = this.playoffGames['afc-wc-3'] && this.playoffGames['afc-wc-3'].teamSelected;
    if (winner1) {
      temp.push(winner1);
    } else {
      temp.push(null);
    }

    if (winner2) {
      temp.push(winner2);
    } else {
      temp.push(null);
    }
    if(winner3){
      temp.push(winner3);
    }else{
      temp.push(null)
    }
    return Enumerable.from(temp)
      .orderByDescending(p => {
        if (p) {
          return p.seed;
        }
      })
      .toArray();
  }
  get afcDvWinners() {
    const temp = [];
    const winner1 = this.playoffGames['afc-dv-1'] && this.playoffGames['afc-dv-1'].teamSelected;
    const winner2 = this.playoffGames['afc-dv-2'] && this.playoffGames['afc-dv-2'].teamSelected;
    if (winner1) {
      temp.push(winner1);
    } else {
      temp.push(null);
    }

    if (winner2) {
      temp.push(winner2);
    } else {
      temp.push(null);
    }
    return Enumerable.from(temp)
      .orderByDescending(p => {
        if (p) {
          return p.seed;
        }
      })
      .toArray();
  }
  get nfcWcWinners() {
    let temp = [];
    const winner1 = this.playoffGames['nfc-wc-1'] && this.playoffGames['nfc-wc-1'].teamSelected;
    const winner2 = this.playoffGames['nfc-wc-2'] && this.playoffGames['nfc-wc-2'].teamSelected;
    const winner3 = this.playoffGames['nfc-wc-3'] && this.playoffGames['nfc-wc-3'].teamSelected;
    if (winner1) {
      temp.push(winner1);
    } else {
      temp.push(null);
    }

    if (winner2) {
      temp.push(winner2);
    } else {
      temp.push(null);
    }
    if (winner3) {
      temp.push(winner3);
    } else {
      temp.push(null);
    }
    return Enumerable.from(temp)
      .orderByDescending(p => {
        if (p) {
          return p.seed;
        }
      })
      .toArray();
  }
  get nfcDvWinners() {
    const temp = [];
    const winner1 = this.playoffGames['nfc-dv-1'] && this.playoffGames['nfc-dv-1'].teamSelected;
    const winner2 = this.playoffGames['nfc-dv-2'] && this.playoffGames['nfc-dv-2'].teamSelected;
    if (winner1) {
      temp.push(winner1);
    } else {
      temp.push(null);
    }

    if (winner2) {
      temp.push(winner2);
    } else {
      temp.push(null);
    }
    return Enumerable.from(temp)
      .orderByDescending(p => {
        if (p) {
          return p.seed;
        }
      })
      .toArray();
  }
  get cgWinners() {
    const temp = [];
    const winner1 = this.playoffGames['afc-cg'] && this.playoffGames['afc-cg'].teamSelected;
    const winner2 = this.playoffGames['nfc-cg'] && this.playoffGames['nfc-cg'].teamSelected;
    if (winner1) {
      temp.push(winner1);
    } else {
      temp.push(null);
    }

    if (winner2) {
      temp.push(winner2);
    } else {
      temp.push(null);
    }
    const isAfcHome = this.league.superbowlYear % 2 === 0;
    if (isAfcHome) {
      return [winner1, winner2];
    } else {
      return [winner2, winner1];
    }
  }
  constructor() {
    this.playoffGameSelected$ = new Subject<PlayoffGame>();
    this.updateUrl$ = new Subject<string>();
    this.playoffGameSelected$.pipe(takeUntil(this.destroy$)).subscribe((res: PlayoffGame) => {
      if (res.conference === 'AFC') {
        if (res.round === 'Wc') {
          this.playoffGames['afc-cg'].home = null;
          this.playoffGames['afc-cg'].away = null;
          this.playoffGames['afc-cg'].teamSelected = null;
          this.playoffGames['afc-dv-1'].teamSelected = null;
          this.playoffGames['afc-dv-2'].teamSelected = null;
          this.playoffGames['sb'].teamSelected = null;

          this.playoffGames['afc-dv-1'].away = null;
          this.playoffGames['afc-dv-2'].home = null;
          this.playoffGames['afc-dv-2'].away = null;
        }
        if (res.round === 'Dv') {
          this.playoffGames['afc-cg'].home = null;
          this.playoffGames['afc-cg'].away = null;
          this.playoffGames['afc-cg'].teamSelected = null;
          this.playoffGames['sb'].teamSelected = null;
        }
        //dv1 has the 1 seed
        const dv1 = this.playoffGames['afc-dv-1'];
        //worst plays 1 seed
        if (this.afcWcWinners[0]) {
          dv1.away = this.afcWcWinners[0];
        }

        const dv2 = this.playoffGames['afc-dv-2'];
        if (this.afcWcWinners[2]) {
          dv2.home = this.afcWcWinners[2];
        }

        if(this.afcWcWinners[1]){
          dv2.away = this.afcWcWinners[1];
        }
        const cg = this.playoffGames['afc-cg'];
        if (this.afcDvWinners[0]) {
          cg.away = this.afcDvWinners[0];
        } else {
          cg.away = null;
        }

        if (this.afcDvWinners[1]) {
          cg.home = this.afcDvWinners[1];
        } else {
          cg.home = null;
        }
        const sb = this.playoffGames['sb'];
        sb.away = this.cgWinners[1];
        sb.home = this.cgWinners[0];
      } else if (res.conference === 'NFC') {
        if (res.round === 'Wc') {
          this.playoffGames['nfc-cg'].home = null;
          this.playoffGames['nfc-cg'].away = null;
          this.playoffGames['nfc-cg'].teamSelected = null;
          this.playoffGames['nfc-dv-1'].teamSelected = null;
          this.playoffGames['nfc-dv-2'].teamSelected = null;
          this.playoffGames['sb'].teamSelected = null;

          this.playoffGames['nfc-dv-1'].away = null;
          this.playoffGames['nfc-dv-2'].home = null;
          this.playoffGames['nfc-dv-2'].away = null;
        }
        if (res.round === 'Dv') {
          this.playoffGames['nfc-cg'].home = null;
          this.playoffGames['nfc-cg'].away = null;
          this.playoffGames['nfc-cg'].teamSelected = null;
          this.playoffGames['sb'].teamSelected = null;
        }
        //dv1 has the 1 seed
        const dv1 = this.playoffGames['nfc-dv-1'];
        //worst plays 1 seed
        if (this.nfcWcWinners[0]) {
          dv1.away = this.nfcWcWinners[0];
        }

        const dv2 = this.playoffGames['nfc-dv-2'];
        if (this.nfcWcWinners[2]) {
          dv2.home = this.nfcWcWinners[2];
        }

        if(this.nfcWcWinners[1]){
          dv2.away = this.nfcWcWinners[1];
        }
        const cg = this.playoffGames['nfc-cg'];
        if (this.nfcDvWinners[0]) {
          cg.away = this.nfcDvWinners[0];
        }

        if (this.nfcDvWinners[1]) {
          cg.home = this.nfcDvWinners[1];
        }
        const sb = this.playoffGames['sb'];
        sb.away = this.cgWinners[1];
        sb.home = this.cgWinners[0];
      }
      this.updateUrl();
    });
  }

  getPlayoffs(league: League) {
    this.league = league;
    this.afcTeams = league.playoffTeams.filter(team => team.team?.conference === 'AFC').sort((a, b) => a.seed - b.seed);
    this.nfcTeams = league.playoffTeams.filter(team => team.team?.conference === 'NFC').sort((a, b) => a.seed - b.seed);
    this.playoffGames['afc-wc-1'] = new PlayoffGame(this.afcTeams[5], this.afcTeams[2], 'AFC', 'Wc', 0, 'afc-wc-1');
    this.playoffGames['afc-wc-2'] = new PlayoffGame(this.afcTeams[4], this.afcTeams[3], 'AFC', 'Wc', 1, 'afc-wc-2');
    this.playoffGames['afc-wc-3'] = new PlayoffGame(this.afcTeams[6], this.afcTeams[1], 'AFC', 'Wc', 2, 'afc-wc-3');

    this.playoffGames['nfc-wc-1'] = new PlayoffGame(this.nfcTeams[5], this.nfcTeams[2], 'NFC', 'Wc', 3, 'nfc-wc-1');
    this.playoffGames['nfc-wc-2'] = new PlayoffGame(this.nfcTeams[4], this.nfcTeams[3], 'NFC', 'Wc', 4, 'nfc-wc-2');
    this.playoffGames['nfc-wc-3'] = new PlayoffGame(this.nfcTeams[6], this.nfcTeams[1], 'NFC', 'Wc', 5, 'nfc-wc-3');

    this.playoffGames['afc-dv-1'] = new PlayoffGame(null, this.afcTeams[0], 'AFC', 'Dv', 6, 'afc-dv-1');
    this.playoffGames['afc-dv-2'] = new PlayoffGame(null, null, 'AFC', 'Dv', 7, 'afc-dv-2');
    this.playoffGames['nfc-dv-1'] = new PlayoffGame(null, this.nfcTeams[0], 'NFC', 'Dv', 8, 'nfc-dv-1');
    this.playoffGames['nfc-dv-2'] = new PlayoffGame(null, null, 'NFC', 'Dv', 9, 'nfc-dv-2');

    this.playoffGames['afc-cg'] = new PlayoffGame(null, null, 'AFC', 'CG', 10, 'afc-cg');
    this.playoffGames['nfc-cg'] = new PlayoffGame(null, null, 'NFC', 'CG', 11, 'nfc-cg');

    this.playoffGames['sb'] = new PlayoffGame(null, null, '', 'SB', 12, 'sb');
    this.playoffs$.next(this.league);
  }
  updateUrl(){
    var retStr = [];
    const sb = this.playoffGames['sb'];

    for (var gameIdx in this.playoffGames) {
        const game:PlayoffGame = this.playoffGames[gameIdx];
        if (game.away && game.home && game.teamSelected) {
            if (game.away.team.teamAbbreviation == game.teamSelected.team.teamAbbreviation) {
                retStr[game.saveIndex] = SelectionType.awayWin;
            } else {
                retStr[game.saveIndex] = SelectionType.homeWin;
            }
        } else {
            retStr[game.saveIndex] = 0;
        }
    }
    var returnString = retStr.join('');
    let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?L=${returnString}`;
    if (sb && sb.homeScore != null) {
        newurl += `&sbhomescore=${sb.homeScore}`;
    }
    if (sb && sb.awayScore != null) {
        newurl += `&sbawayscore=${sb.awayScore}`;
    }
    if(this.enableScoring){
      newurl += `&es=${this.enableScoring}`;
    }
    window.history.pushState({ path: newurl }, "", newurl);
    this.updateUrl$.next(newurl);
  }
  gameOutcomePicked(playoffGame: PlayoffGame): any {
    this.playoffGameSelected$.next(playoffGame);
  }
  unselectGame(playoffGame: PlayoffGame) {
    this.playoffGameSelected$.next(playoffGame);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  refresh(){
    for(var idx in this.playoffGames){
      const game:PlayoffGame = this.playoffGames[idx];
      game.teamSelected = null;
      if(game.round != 'Wc'){
        if(game.round == 'Dv' && game.home?.seed != 1){
          game.home = null;
          game.away = null;
        }
      }
      this.unselectGame(game);
    }
    if(this.league?.playoffString){
      this.load(this.league.playoffString);
    }else{
      this.updateUrl();
    }
  }

  clear(){
    for(var idx in this.playoffGames){
      const game:PlayoffGame = this.playoffGames[idx];
      game.teamSelected = null;
      if(game.round != 'Wc'){
        if(game.round == 'Dv' && game.home?.seed != 1){
          game.home = null;
          game.away = null;
        }
      }
      this.unselectGame(game);
    }
    this.updateUrl();
  }

  load(saveUrl: string){
    if(!saveUrl){
      return;
    }
    let gameMap = {};
    Object.keys(this.playoffGames).map(g => {
      const game:PlayoffGame = this.playoffGames[g];
      gameMap[game.saveIndex] = game;
    })
    if(Object.keys(this.playoffGames).length == 0){
      return;
    }
    const outcomes = saveUrl.split('').map(o => parseInt(o, 10));
    for(let i = 0; i < 6; i++){
      switch(outcomes[i] as SelectionType){
        case SelectionType.awayWin:
          gameMap[i].teamSelected = gameMap[i].away;
          break;
          case SelectionType.homeWin:
            gameMap[i].teamSelected = gameMap[i].home;
          break;
          default:
            break;
        }
        this.gameOutcomePicked(gameMap[i]);
      }

      for(let i = 6; i < 10; i++){
        switch(outcomes[i] as SelectionType){
          case SelectionType.awayWin:
            gameMap[i].teamSelected = gameMap[i].away;
            break;
            case SelectionType.homeWin:
              gameMap[i].teamSelected = gameMap[i].home;
            break;
            default:
              break;
          }
          this.gameOutcomePicked(gameMap[i]);
      }

      for(let i = 10; i < outcomes.length; i++){
        switch(outcomes[i] as SelectionType){
          case SelectionType.awayWin:
            gameMap[i].teamSelected = gameMap[i].away;
            break;
            case SelectionType.homeWin:
              gameMap[i].teamSelected = gameMap[i].home;
            break;
            default:
              break;
          }
          this.gameOutcomePicked(gameMap[i]);
      }
  }
}
