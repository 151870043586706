<mat-card>    
  <form (ngSubmit)="onSubmit()">

  <mat-card-header> <mat-card-title>Recover Account</mat-card-title> </mat-card-header>
  <mat-card-content>
    <p>We will send you an email with a link that will allow you to recover your account</p>
      <mat-form-field>
        <input matInput placeholder="Email" [formControl]="email" required type="text" />
        <mat-error *ngIf="email.invalid"> <span *ngIf="email.hasError('required')"> email is required </span> </mat-error>
      </mat-form-field>

  </mat-card-content>
  <mat-card-actions> <button type="submit" mat-raised-button [disabled]="email.invalid" (click)="onSubmit()" color="accent">Recover</button> </mat-card-actions>
</form>
</mat-card>
