import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent implements OnInit {

  @Input("alertType")
  public alertType: 'warning' | 'success' | 'info' | 'danger';
  constructor() { }

  ngOnInit(): void {
  }

}
