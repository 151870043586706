const apiRootUrl = "https://apim.playoffpredictors.com/";
export const environment = {
  production: true,
  leagueApiUrl: apiRootUrl + "LeagueEngine/",
  apimUrl: apiRootUrl,
  siteUrl: "https://playoffpredictors.com/",
  staticJsonUrl: "https://static.playoffpredictors.com/site/json/",
  paymentEngineUrl: apiRootUrl + "PaymentEngine/",
  resultingEngineUrl: apiRootUrl + "ResultingEngine/",
  authEngineUrl: apiRootUrl + "AuthEngine/",
  updateEngineUrl: apiRootUrl + "UpdateEngine/",
  ChartEngineUrl: apiRootUrl + "ChartEngine/",
  autoLogIn: false,
  adminUser: null,
  adminPw: null,
  headerId: null,
  currentNflGuid: 'ECC1459D-3649-43B4-97A7-5C253ADEE207',
  payments:{
    poolSub: "price_1NeTCcCAObTcIrJkVUxrLTyY",
    adsFreeId: "adblock-absolution",
  },
  appInsights: {
    enabled: false,
    instrumentationKey: "c2678ae7-9a25-4ec1-8689-472c53cebcd0",
  },
  enableServiceWorker:false,
  pwaInterval: 60000 //1 mins
}
