import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { Watchlist } from "@models";
import { UrlSerializerService } from "@services/url-serializer/url-serializer.service";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { WatchlistService } from "@services/watchlist/watchlist.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PlayoffPoolService } from "@services/index";
import { playoffUrlValidator } from "app/shared/validators/playoff-url-validator.directive";

@Component({
  selector: "app-add-submission-modal",
  templateUrl: "./add-submission-modal.component.html",
  styleUrls: ["./add-submission-modal.component.css"],
})
export class AddSubmissionModalComponent implements OnInit {
  @Input('groupGuid')
  groupGuid: string;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";
  destroy$ = new Subject<boolean>();
  _routerSubscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddSubmissionModalComponent>,
    private dialog: MatDialog,
    private router: Router,
    private poolService: PlayoffPoolService,
    private snackBar: MatSnackBar
  ) {
    this.groupGuid = this.data.groupGuid;
  }
  submissionGroup = new FormGroup({
    totalScore: new FormControl("", [Validators.required]),
    submissionName: new FormControl("", [Validators.required]),
    url: new FormControl("", [Validators.required, playoffUrlValidator()]),
    awayScore: new FormControl("", [Validators.required]),
    homeScore: new FormControl("", [Validators.required])
  });
  public get fc(){
    return this.submissionGroup.controls;
  }
  ngOnInit() {
    // reset login status
    this._routerSubscription = this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
      this.fc.awayScore.valueChanges.subscribe((val)=>{
        this.fc.totalScore.setValue(Number(val) + Number(this.fc.homeScore.value));
      });
      this.fc.homeScore.valueChanges.subscribe((val) => {
        this.fc.totalScore.setValue(Number(val) + Number(this.fc.awayScore.value));
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  @HostListener("window:keyup.Enter", ["$event"])
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (this.submissionGroup.valid) {
      this.loading = true;
      this.poolService.addSubmission({
        submissionName: this.fc.submissionName.value,
        submissionUrl: this.fc.url.value,
        groupGuid: this.groupGuid,
        awayScore: this.fc.awayScore.value,
        homeScore: this.fc.homeScore.value
      }).subscribe(() => {
        this.poolService.getSubmissionsForGroup(this.groupGuid);
        this.snackBar.open("Submission Saved", "dismiss", {
          duration: 2000
        })
        this.dialogRef.close();
      },()=>{
        this.error = "An error occurred"
        this.loading = false;
      })
    }
  }
  onCancel(){
    this.dialogRef.close();
  }
}
