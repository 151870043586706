import { Component, OnInit, Input } from "@angular/core";
import { League, PoolDropdownResponse } from "@models";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LeagueService } from "../../../services/league/league.service";
import { AuthenticationService, PoolService } from "@services/index";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { LoginDialogComponent } from "app/account/templates/login-dialog/login-dialog.component";
import { environment } from "@env/environment";

@Component({
  selector: "app-resulting",
  templateUrl: "./resulting.component.html",
  styleUrls: ["./resulting.component.css"],
})
export class ResultingComponent implements OnInit {
  public league: League;

  @Input("initialPoolGuid")
  initialPoolGuid?: string = '';
  selectedPool = new BehaviorSubject<PoolDropdownResponse>(null);
  pools: PoolDropdownResponse[] = [];
  destroy$ = new Subject<boolean>();
  promptLogin = false;
  isLoading = true;
  constructor(
    private leagueService: LeagueService,
    public poolService: PoolService,
    public authService: AuthenticationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.poolService.dropdowns$
      .pipe(takeUntil(this.destroy$))
      .subscribe((p) => {
        this.pools = p;
        if (this.pools.length) {
          if(this.authService.isLoggedIn && this.initialPoolGuid){
            this.selectPool(this.pools.find(p => p.groupGuid.toUpperCase() == this.initialPoolGuid.toUpperCase()));
          }else if(!this.authService.isLoggedIn && this.initialPoolGuid){
            this.promptLogin = true;
          }else{
            this.selectedPool.next(this.pools[0]);
          }
          this.isLoading = false;
        }
      }, (err)=>{
          this.isLoading = false;
      });
    this.authService.user.pipe(takeUntil(this.destroy$)).subscribe(u => {
      if(u.loaded){
        this.promptLogin = !this.authService.isLoggedIn && !!this.initialPoolGuid
      }
    })
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((league) => {
        this.league = league;
        if (!this.authService.userValue.loaded || !this.authService.isLoggedIn) {
          this.poolService.getSubmittableGroupsAnonymous(
            this.league?.leagueGuid || environment.currentNflGuid
          );
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  selectPool(pool: PoolDropdownResponse) {
    if(window.location.href.indexOf('pools') !== -1){
      window.history.replaceState({}, null, `/football/nfl/pools/${this.league?.leagueGuid || ''}/${pool?.groupGuid || ''}`);
    }
    this.selectedPool.next(pool);
  }
  loginClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.promptLogin = false;
    });
  }
}
