import { Component, OnInit} from "@angular/core";

@Component({
  selector: "app-pulse-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class PulseHomeComponent implements OnInit {
  ngOnInit(): void {
  }

}