import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PulseRoutingModule } from "./pulse.routing";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ServicesModule } from "@services/services.module";
import { MatModule } from "app/material/mat.module";
import { SharedModule } from "app/shared/shared.module";
import { PulseHomeComponent } from './pulse-home/home.component';
import { PulseComponent } from './pulse/pulse.component';
import { PulseDailyComponent } from './pulse-daily/pulse-daily.component';
import { PulseWeeklyComponent } from './pulse-weekly/pulse-weekly.component';
import { PowerRankComponent } from './powerrank/powerrank.component';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [PulseHomeComponent, PulseComponent, PulseDailyComponent, PulseWeeklyComponent, PowerRankComponent],
  imports: [
    MatModule,
    ServicesModule,
    SharedModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    CommonModule,
    PulseRoutingModule,
    HighchartsChartModule
  ],
})
export class PulseModule {}
