import { AppModule } from 'app/app.module';
import { MatModule } from './../material/mat.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { HomeRoutingModule } from './home.routing';
import { ServicesModule } from '@services/services.module';
import { SharedModule } from 'app/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubscribersOnlyComponent } from './subscribers-only/subscribers-only.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RoadMapComponent } from './road-map/road-map.component';
import { HateAdsComponent } from './hate-ads/hate-ads.component';
import { LoginDialogComponent } from 'app/account/templates/login-dialog/login-dialog.component';
import { AccessibilityStatementComponent } from './accessibility-statement/accessibility-statement.component';

@NgModule({
    imports: [CommonModule, HomeRoutingModule, MatModule, ServicesModule, SharedModule, FlexLayoutModule, FormsModule, ReactiveFormsModule],
    declarations: [HomeComponent, FaqComponent, SubscribersOnlyComponent, HomePageComponent, PrivacyPolicyComponent, ContactUsComponent, RoadMapComponent, HateAdsComponent, AccessibilityStatementComponent]
})
export class HomeModule {}
