import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import {
  AuthenticationService,
  UrlSerializerService,
  WatchlistService,
} from "@services/index";
import { LoginDialogComponent } from "app/account/templates/login-dialog/login-dialog.component";
import { SportType, Watchlist, WatchlistEdit } from "@models";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { YesNoDialogComponent } from "../yes-no-dialog/yes-no-dialog.component";

@Component({
  selector: "app-watchlist",
  templateUrl: "./watchlist.component.html",
  styleUrls: ["./watchlist.component.css"],
})
export class WatchlistComponent implements OnInit, OnDestroy {
  @Input("sportId")
  sportId: string;
  @ViewChild("table") table: MatTable<Watchlist>;
  destroy$ = new Subject<boolean>();
  isLoggedIn = false;
  watchlists: WatchlistEdit[] = [];
  constructor(
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private watchlistService: WatchlistService,
    public urlService: UrlSerializerService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.user.pipe(takeUntil(this.destroy$)).subscribe((p) => {
      if (p.loaded) {
        if (p.id !== undefined) {
          this.isLoggedIn = true;
          this.watchlistService
            .getWatchlistsForUser(parseInt(this.sportId, 10))
            .subscribe();
        }
      } else {
        this.isLoggedIn = false;
      }
    });

    this.watchlistService.watchlists
      .pipe(takeUntil(this.destroy$))
      .subscribe((p) => {
        this.watchlists = p.map((p) => {
          let editItem = <WatchlistEdit>p;
          editItem.isEdit = false;
          editItem.isSaving = false;
          editItem.viewScenarioLabel = `View scenario with name "${editItem.watchlistName}"`;
          editItem.editScenarioLabel = `Edit scenario with name "${editItem.watchlistName}"`;
          editItem.deleteScenarioLabel = `Delete scenario with name "${editItem.watchlistName}"`;
          editItem.originalDescription = p.description;
          editItem.originalName = p.watchlistName;
          return editItem;
        });
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  showLoginModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog
      .open(LoginDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {});
  }
  reOrder: string = null;
  onListDrop(event: CdkDragDrop<Watchlist[]>) {
    if (this.isInvalidDragEvent) {
      this.isInvalidDragEvent = false;
      return;
    }
    const prevIndex = this.watchlists.findIndex((d) => d === event.item.data);
    if (prevIndex !== event.currentIndex) {
      moveItemInArray(this.watchlists, prevIndex, event.currentIndex);
      let newOrder = this.watchlists.map((p, idx) => {
        return {
          id: p.watchlistGuid,
          idx: idx,
        };
      });
      var hash = btoa(JSON.stringify(newOrder));
      if (this.reOrder !== hash) {
        this.table.renderRows();
        this.reOrder = hash;
        this.watchlistService.updateSortOrder(
          newOrder,
          parseInt(this.sportId, 10)
        ).subscribe(success=>{
          this.snackBar.open("Scenario reordered", "dismiss", {
            duration: 2000,
          });
        });
      }
    }
  }
  isInvalidDragEvent: boolean = false;
  onInvalidDragEventMouseDown() {
    this.isInvalidDragEvent = true;
  }
  dragStarted(event) {
    if (this.isInvalidDragEvent) {
      document.dispatchEvent(new Event("mouseup"));
    }
  }
  editRow(element: WatchlistEdit) {
    element.isEdit = !element.isEdit;
  }
  cancelEdit(element: WatchlistEdit){
    element.isEdit = !element.isEdit;
    element.watchlistName = element.originalName;
    element.description = element.originalDescription;
  }
  saveRow(element: WatchlistEdit) {
    element.isSaving = true;
    this.watchlistService
      .updateWatchlist(<Watchlist>element, parseInt(this.sportId, 10))
      .subscribe((result: Watchlist) => {
        element.isSaving = false;
        element.isEdit = false;
        element.originalName = result.watchlistName;
        element.originalDescription = result.description;
        this.snackBar.open("Scenario has been saved", "dismiss", {
          duration: 2000,
        });
      });
  }
  viewScenario(element: WatchlistEdit) {
    if (element.version == 2) {
      let url = "https://v2.playoffpredictors.com/";
      switch (<SportType>parseInt(this.sportId, 10)) {
        case SportType.Nfl:
          url += `football/nfl/${element.leagueGuid}/official/?L=${element.saveString}`;
          window.open(url, "_BLANK");
          break;
      }
    } else {
      let url = "https://playoffpredictors.com/";
      switch (<SportType>parseInt(this.sportId, 10)) {
        case SportType.Nfl:
          url += `/football/CustomLeague/SchedulePicker/${element.leagueGuid}?L=${element.saveString}`;
          break;
        case SportType.Mlb:
          url += `/baseball/CustomLeague/SchedulePicker/${element.leagueGuid}?L=${element.saveString}`;
          break;
        case SportType.Nba:
          url += `/basketball/CustomLeague/SchedulePicker/${element.leagueGuid}?L=${element.saveString}`;
          break;
      }
      window.open(url, "_blank");
    }
  }
  deleteScenario(element: WatchlistEdit) {
    this.dialog
      .open(YesNoDialogComponent, {
        width: "500px",
        data: {
          title: "Confirm Delete",
          message: "Are you sure you want to delete this scenario? This cannot be undone.",
          yesLinkText: "Yes, delete",
          noLinkText: "Cancel",
        },
      })
      .afterClosed()
      .subscribe((yesNo) => {
        if (yesNo) {
          let sportId = parseInt(this.sportId, 10);
          this.watchlistService
            .deleteWatchlist(sportId, element.watchlistGuid)
            .subscribe((r) => {
              this.watchlistService.getWatchlistsForUser(sportId).subscribe(
                (p) => {
                  this.snackBar.open("Scenario has been deleted", "dismiss", {
                    duration: 2000,
                  });
                },
                (f) => {
                  console.warn(f);
                }
              );
            });
        }
      });
  }
}
