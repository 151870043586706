import { LeagueService } from '../../services/league/league.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Game, League, SelectionType, Team, UpdateReason, UserConfig } from '@models';
import { UserSettingsService } from '@services/user-settings/user-settings.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LeagueSettingsService } from '../../services/league-settings/league-settings.service';

@Component({
  selector: 'app-team-schedule',
  templateUrl: './team-schedule.component.html',
  styleUrls: ['./team-schedule.component.scss']
})
export class TeamScheduleComponent implements OnInit, OnDestroy {
  league: League;
  destroy$ = new Subject<boolean>();

  userConfig: UserConfig;
  @Input()
  games: Array<Game>;

  @Output()
  teamPicked: EventEmitter<Game> = new EventEmitter<Game>();

  constructor(private leagueService: LeagueService,
    private userSettings: UserSettingsService, public leagueSettings: LeagueSettingsService) {}

  ngOnInit() {

    this.userConfig = this.userSettings.userConfig;
    this.leagueService.league$.pipe(takeUntil(this.destroy$)).subscribe(league => this.league = league);
  }

  pickTeam(game: Game, teamPicked: Team) {
    this.leagueService.pickTeam(game, teamPicked);

    this.teamPicked.emit(game);
  }

  pickTie(game: Game) {
    this.leagueService.pickTie(game);
    this.teamPicked.emit(game);
  }
  scoreChange(game: Game) {
    game.scoresIsDirty = true;

    if(!this.userSettings.userConfig.nfl.updateGameOutcomesWithScoreUpdate){
      if(game.scores?.status?.toUpperCase() != 'F'){
        game.updateReason = UpdateReason.ScoreUpdate;
        return;
      }      
    }

    if (game.scores.h != null && game.scores.a != null && (game.scores.a > 0 || game.scores.h > 0)) {
      if (game.scores.h > game.scores.a) {
        if (game.selectionType !== SelectionType.homeWin) {
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.pickTeam(game, game.home);
        }else{
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.gamePicked$.next(game);
        }
      } else if (game.scores.h < game.scores.a) {
        if (game.selectionType !== SelectionType.awayWin) {
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.pickTeam(game, game.away);
        }else{
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.gamePicked$.next(game);
        }
      } else if (game.scores.h === game.scores.a) {
        if (game.selectionType !== SelectionType.tie) {
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.pickTie(game);
        }else{
          game.updateReason = UpdateReason.ScoreUpdate;
          this.leagueService.gamePicked$.next(game);
        }
      }
    }
  }
  void() {}
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
