<table mat-table [dataSource]="games">
  <ng-container matColumnDef="idx">
    <th mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
  </ng-container>
  <ng-container matColumnDef="away">
    <th mat-header-cell *matHeaderCellDef>Away</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container *ngIf="element != null">
        <button mat-button mat-raised-button (click)="pickTeam(element, element.away)" [ngClass]="element.away.teamAbbreviation">
          <img src="https://static.playoffpredictors.com/nfl/{{element.away.teamLogo || element.away.teamAbbreviation}}.svg" title="{{element.away.teamName}}" alt="{{element.away.teamName}}" width="24px" />
        </button>
        <div style="display:flex;">
          <mat-icon *ngIf="element.awayOffBye" matTooltip="coming off bye">night_shelter</mat-icon>
          <mat-icon *ngIf="element.awayOffThurs" class="indicator-mini-bye" matTooltip="coming off mini bye">night_shelter</mat-icon>
          <input *ngIf="userConfig.nfl.enableScoring && league.features.allowsScores && ((i+1) <= this.leagueSettings.numRegularSeasonWeeks)" matInput type="number" [(ngModel)]="element.scores.a" (ngModelChange)="scoreChange(element)" style="width:50px" placeholder="10" />
        </div>
      </ng-container>
      <ng-container *ngIf="!element"> <div class="bye-cell">BYE</div> </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="tie">
    <th mat-header-cell *matHeaderCellDef>=</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!element"> </ng-container>
      <ng-container *ngIf="element != null"> <button mat-button color="secondary" (click)="pickTie(element)" [ngClass]="element.selectionType == 3 ? 'TIE' : ''">=</button></ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="home">
    <th mat-header-cell *matHeaderCellDef>Home</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container *ngIf="element != null">
        <button mat-raised-button color="secondary" (click)="pickTeam(element, element.home)" [ngClass]="element.home.teamAbbreviation">
          <img src="https://static.playoffpredictors.com/nfl/{{element.home.teamLogo || element.home.teamAbbreviation}}.svg" title="{{element.home.teamName}}" alt="{{element.home.teamName}}" width="24px" />
        </button>
        <div style="display:flex;">
          <mat-icon *ngIf="element.homeOffThurs" class="indicator-mini-bye" matTooltip="coming off mini bye">night_shelter</mat-icon>
          <mat-icon *ngIf="element.homeOffBye" matTooltip="coming off bye">night_shelter</mat-icon>
          <input *ngIf="userConfig.nfl.enableScoring && league.features.allowsScores && ((i+1) <= this.leagueSettings.numRegularSeasonWeeks)" matInput type="number" [(ngModel)]="element.scores.h" (ngModelChange)="scoreChange(element)" style="width:50px" placeholder="10" />
        </div>
      </ng-container>
      <ng-container *ngIf="!element"> <div class="bye-cell">BYE</div> </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element?.flag">
        <img width="32" src="https://static.playoffpredictors.com/flags/{{ element.flag }}.svg" matTooltip="international game - {{ element.flag }}" /><br/>
      </ng-container>
      <ng-container *ngIf="element != null">
        <span matTooltip="{{ element.gameDate | date: 'long' }}">
          {{ element.gameDate | date: 'E' }}
        </span>
      </ng-container>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="['idx', 'away', 'tie', 'home', 'date']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['idx', 'away', 'tie', 'home', 'date']; let i = index" class="{{ row?.teamSelected }}"></tr>
</table>
