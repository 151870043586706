<mat-card style="margin-top: 1em">
  <mat-card-title [innerHTML]="post.title.rendered"></mat-card-title>
  <mat-card-subtitle
    >by {{ post.author_info.display_name }} on
    {{ post.date_gmt | date }}</mat-card-subtitle
  >
  <mat-card-content>
    <div class="flex row-wrap">
      <a [href]="post.link" class="article-image article-image-link column">
        <img
          [srcset]="
            'https://playsheet.playoffpredictors.com' +
            post.featured_image_urls.medium[0] +
            ' 300w,https://playsheet.playoffpredictors.com' +
            post.featured_image_urls.medium_large[0] +
            ' 768w, https://playsheet.playoffpredictors.com' +
            post.featured_image_urls.large[0] +
            ' 1000w'
          "
          sizes="(max-width: 300px) 100vw, 300px"
        />
      </a>
      <ng-container *ngIf="post.excerpt?.rendered">
        <article class="flex">
          <div [innerHtml]="post.excerpt.rendered"></div>
          <a class="row" style="width:100%" [href]="post.link">[Read More]</a>
        </article>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
