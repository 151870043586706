import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { BehaviorSubject, Subject } from "rxjs";
import { ChartDataService } from "../services/chart-data.service";
import * as Highcharts from "highcharts";
import { takeUntil, map } from "rxjs/operators";
import { environment } from "@env/environment";

@Component({
  selector: "app-pulse-weekly",
  templateUrl: "./pulse-weekly.component.html",
  styleUrls: ["./pulse-weekly.component.css"],
})
export class PulseWeeklyComponent implements OnInit, OnDestroy {
  chart: any;
  leagueGuid = environment.currentNflGuid;
  teamName$ = new BehaviorSubject<string>("");
  public teams = [
    { value: "49ers", viewValue: "49ers" },
    { value: "Bears", viewValue: "Bears" },
    { value: "Bengals", viewValue: "Bengals" },
    { value: "Bills", viewValue: "Bills" },
    { value: "Broncos", viewValue: "Broncos" },
    { value: "Browns", viewValue: "Browns" },
    { value: "Buccaneers", viewValue: "Buccaneers" },
    { value: "Cardinals", viewValue: "Cardinals" },
    { value: "Chargers", viewValue: "Chargers" },
    { value: "Chiefs", viewValue: "Chiefs" },
    { value: "Colts", viewValue: "Colts" },
    { value: "Commanders", viewValue: "Commanders" },
    { value: "Cowboys", viewValue: "Cowboys" },
    { value: "Dolphins", viewValue: "Dolphins" },
    { value: "Eagles", viewValue: "Eagles" },
    { value: "Falcons", viewValue: "Falcons" },
    { value: "Giants", viewValue: "Giants" },
    { value: "Jaguars", viewValue: "Jaguars" },
    { value: "Jets", viewValue: "Jets" },
    { value: "Lions", viewValue: "Lions" },
    { value: "Packers", viewValue: "Packers" },
    { value: "Panthers", viewValue: "Panthers" },
    { value: "Patriots", viewValue: "Patriots" },
    { value: "Raiders", viewValue: "Raiders" },
    { value: "Rams", viewValue: "Rams" },
    { value: "Ravens", viewValue: "Ravens" },
    { value: "Saints", viewValue: "Saints" },
    { value: "Seahawks", viewValue: "Seahawks" },
    { value: "Steelers", viewValue: "Steelers" },
    { value: "Texans", viewValue: "Texans" },
    { value: "Titans", viewValue: "Titans" },
    { value: "Vikings", viewValue: "Vikings" },
  ];
  highcharts = Highcharts;
  chartOptions = null;
  destroy$ = new Subject<boolean>();

  constructor(private chartService: ChartDataService) {}
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.teamName$.pipe(takeUntil(this.destroy$), map(p => p)).subscribe((teamName) => {
      if(!teamName){return;}
      this.chartService
        .getWeeklyChartData(this.leagueGuid, teamName)
        .subscribe((input: any) => {
          input.chart.type = "areaspline";
          input.plotOptions = {
            areaspline: {
              fillOpacity: 0.5,
            },
          };
          input.credits.text =  "v2.playoffpredictors.com/pulse/weekly";
          input.credits.href = "https://v2.playoffpredictors.com/pulse/weekly";
          input.caption.color = "black";
          input.xAxis.labels = {rotation: 45 }
          this.chartOptions = input;
        });
    });
  }
  teamSelected(event: MatSelectChange) {
    this.teamName$.next(event.value);
    (<any>window).ga(
      "send",
      "event",
      "pulse-weekly-team-selected",
      event.value
    );
  }
}
