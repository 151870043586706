<mat-card *ngIf="!loading">
  <mat-card-header>
    <mat-card-title>Email List Preferences</mat-card-title>
  </mat-card-header>
  <mat-card-content>
      <ng-container *ngIf="authService.userValue.confirmed">
       <mat-checkbox [(ngModel)]="optIn" (change)="optInChange($event)">Opt-in to emails</mat-checkbox>
       <p>We send fewer than 5 emails a <strong>year</strong> to our users, usually when new things are released or major events happen. Opt-out any time.</p>
      </ng-container>
      <ng-container *ngIf="!authService.userValue.confirmed">
        <p>
          You must confirm your account before being able to join the mailing
          list.
        </p>
    </ng-container>
  </mat-card-content>
</mat-card>
