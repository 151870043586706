import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { LeagueDropdownResponse } from "@models";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PoolService } from "@services/index";

@Component({
  selector: "app-bulk-invite-user-modal",
  templateUrl: "./bulk-invite-user-modal.component.html",
  styleUrls: ["./bulk-invite-user-modal.component.scss"],
})
export class BulkInviteUserModalComponent implements OnInit, OnDestroy {
  seatLimitReachedError = "Seat limit reached. Please purchase a subscription to add more users.";
  error: string = undefined;
  @ViewChild("emailInput") emailInputRef: ElementRef;
  groupGuid: string;
  seatsAllowed: number;
  seatsUsed: number;
  loading = false;
  submitted = false;
  destroy$ = new Subject<boolean>();
  emails: string[] = [];
  form: FormGroup;
  emailValidator: FormControl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BulkInviteUserModalComponent>,
    private router: Router,
    private poolService: PoolService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.groupGuid = data.groupGuid;
    this.seatsAllowed = this.data.seatsAllowed;
    this.seatsUsed = this.data.seatsUsed;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [undefined],
    });
    this.emailValidator = new FormControl("", [
      Validators.email,
      Validators.required,
    ]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.form.controls.email.value;
    if (event.code === "Backspace" && !inputValue) {
      this.removeEmail();
      return;
    } else {
      if (event.code === "Comma" || event.code === "Space") {
        let split = [];
        if(inputValue.indexOf(',') >= 0){
          split = inputValue.split(',');
        }else{
          split = inputValue.split(' ')
        }
        split.forEach((email) => {
          email = email.trim();
          if(!email){
            return;
          }
          this.emailValidator.setValue(email);
          if (this.emailValidator.valid) {
            this.addEmail(email.trim());
            this.form.controls.email.setValue("");
            this.emailValidator.reset();
            if(this.error != this.seatLimitReachedError)
            this.error = '';
          } else {
            this.error = "value is not an email";
          }
        });
      }
    }
  }

  focusEmailInput(): void {
    this.emailInputRef.nativeElement.focus();
  }
  removeEmail(email?: string): void {
    if (!!email) {
      this.emails = this.emails.filter((p) => p != email);
    } else {
      this.emails.splice(-1);
    }
  }
  addEmail(email: string): void {
    if (email[email.length - 1] === "," || email[email.length - 1] === " ") {
      email = email.slice(0, -1);
    }
    if (email.length > 0 && !this.emails.find((p) => p == email)) {
      if (this.seatsUsed < this.seatsAllowed) {
        this.emails.push(email);
        this.seatsUsed++;
      } else {
        this.error = this.seatLimitReachedError;
      }
    }
  }
  @HostListener('window:keyup.Enter', ['$event'])
  onSubmit() {
    //stop here if form is invalid
    if (this.form.valid && this.emails.length > 0) {
      this.submitted = true;
      this.loading = true;
      this.poolService
        .addBulkMembersToPool(this.groupGuid, this.emails)
        .subscribe(
          () => {
            //this.poolService.getMyPools(this.leagueGuid);
            this.snackBar.open("Members Invited!", "dismiss", {
              duration: 2000,
            });
            this.dialogRef.close();
          },
          () => {
            this.error = "An error occurred";
            this.loading = false;
          }
        );
    }
  }
  onCancel() {
    this.dialogRef.close();
  }
}
