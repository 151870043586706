import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { CreatePoolRequest, EditPoolRequest, LeagueDropdownResponse, PlayoffSubmission, PoolDetails } from "@models";
import { BehaviorSubject, Subject } from "rxjs";
import {
  take,
  map,
  takeUntil,
} from "rxjs/operators";
import { PoolSubmissionEdit } from "@models";

@Injectable({
  providedIn: "root",
})
export class PlayoffPoolService {
  destroy$ = new Subject<boolean>();
  pools$ = new BehaviorSubject<Array<PoolDetails>>([]);
  submissions$ = new BehaviorSubject<Array<PlayoffSubmission>>([]);
  constructor(
    private httpClient: HttpClient
  ) {}

//unused; for playoff pools
  getSubmissionsForGroup(groupGuid: string){
    let url = `${environment.resultingEngineUrl}pools/${groupGuid}/submissions`;

    this.httpClient
      .get<Array<PlayoffSubmission>>(url, { headers: { 'add-jwt': 'true'}})
      .pipe(
        take(1),
        map((res: Array<PlayoffSubmission>) => {

          this.submissions$.next(res);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
//unused; for playoff pools
  postScoreSubmissions(groupGuid: string){
    let url = `${environment.resultingEngineUrl}pools/${groupGuid}/score-submissions`;

    this.httpClient
      .post(url, {}, { headers: { 'add-jwt': 'true'}})
      .pipe(
        take(1),
        map(() => {
          this.getSubmissionsForGroup(groupGuid)
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  //unused; for playoff pools
  addSubmission(submission: PoolSubmissionEdit){
    let url = `${environment.resultingEngineUrl}pools/${submission.groupGuid}/submit`;
    return this.httpClient.post(url, submission, { headers: { 'add-jwt': 'true'}})
  }
}
