import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Game, League, PoolDropdownResponse, SelectionType, SubmitPicksToPoolRequest, UserPickResult } from '@models';
import { AuthenticationService, PoolService } from '@services/index';
import { ResultingService } from '@services/resulting/resulting.service';
import { SetNameDialogComponent } from 'app/account/set-name-dialog/set-name-dialog.component';
import { LoginDialogComponent } from 'app/account/templates/login-dialog/login-dialog.component';
import { LeagueService } from 'app/football/nfl/services/league/league.service';
import { WeekService } from 'app/football/nfl/services/week/week.service';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
@Component({
  selector: 'app-pool',
  templateUrl: './pool.component.html',
  styleUrls: ['./pool.component.css']
})
export class PoolComponent implements OnInit, OnDestroy {
  hasPools = false;
  pools: PoolDropdownResponse[] = [];
  destroy$ = new Subject<boolean>();
  league: League;

  @Input("tabSwapper")
  tabSwapper?: Subject<string>;
  constructor(private poolService: PoolService, public authService: AuthenticationService, private leagueService: LeagueService,
    private dialog: MatDialog, private weekService: WeekService, private snackBar: MatSnackBar
    ) { }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.league = this.leagueService.league$.value;
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((league) => (this.league = league));

    // this.authService.user.pipe(takeUntil(this.destroy$)).subscribe(_ => {
    //   if(_.id){
    //     this.poolService.getSubmittableGroups(this.leagueService.league$.value.leagueGuid);
    //   }
    // });
    this.poolService.dropdowns$
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        this.hasPools = !!v.length;
        this.pools = v;
      })
  }
  showLoginModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginDialogComponent, dialogConfig);
  }
  lockUserPicks(groupGuid: string): void {
    if (this.authService.userValue.en.length == 0) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.closeOnNavigation = true;

      const dialogRef = this.dialog.open(SetNameDialogComponent, dialogConfig);
    } else {
      if (this.league.features.allowsPickLocks) {
        // 1. Build request object
        const userPicks: SubmitPicksToPoolRequest = new SubmitPicksToPoolRequest();
        userPicks.groupGuid = groupGuid;
        userPicks.week = this.weekService.week;
        let games = [].concat.apply([], this.weekService.activeGames);
        for (const game of games.filter(
          (p) =>
            p.selectionMade &&
            (p.selectionType == SelectionType.homeWin ||
              p.selectionType == SelectionType.awayWin)
        )) {
          let newPick = new UserPickResult();
          newPick.gameGuid = game.gameGuid;
          newPick.outcome = game.selectionType;
          if (game.selectionType == SelectionType.homeWin) {
            newPick.teamPicked = game.homeId;
          } else if (game.selectionType == SelectionType.awayWin) {
            newPick.teamPicked = game.awayId;
          }
          userPicks.results.push(newPick);
        }
        // 2. Send to service
        this.poolService.submitPicksToPool(groupGuid, userPicks).subscribe(
          (response) => {
            this.snackBar.open(
              `${response.count} picks have been locked. Only picks for games that have not started yet will be locked.`,
              "dismiss", {
                duration: 2000
              }
            );
          },
          (error) => {
            this.snackBar.open("Please Log in to lock picks.", "dismiss");
            // Potentially bring up login modal?
            // Bring up modal => inject login module/component
          }
        );
      }
    }
  }

}
