import { AbstractControl, ValidatorFn } from "@angular/forms";
import { LZString } from "@helpers/lz-string";

export function lzstringValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    var lzstring = LZString.lz.decompressFromEncodedURIComponent(control.value);

    if (lzstring == "" || lzstring == null) {
      return { lzstring: { value: true } };
    }

    for (var char of lzstring) {
      if (!(char == "0" || char == "1" || char == "2")) {
        return { lzstring: { value: true } };
      }
    }
    return null;
  };
}
