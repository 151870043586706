import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, NavigationStart } from '@angular/router';
import { Validators, FormControl } from '@angular/forms';
import { first, takeUntil, filter } from 'rxjs/operators';
import { AuthenticationService } from '../../../services';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginErrorComponent } from '../login-error/login-error.component';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  destroy$ = new Subject<boolean>();
  _routerSubscription;
  constructor(private authenticationService: AuthenticationService, private dialogRef: MatDialogRef<LoginDialogComponent>, private dialog: MatDialog, private router: Router) {

  }
  username = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]);
  getErrorMessage() {
    return this.username.hasError('required') ? 'You must enter a value' : this.username.hasError('email') ? 'Not a valid email' : '';
  }
  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    this._routerSubscription = this.router.events
    .pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      filter(() => !!this.dialogRef)
    )
    .subscribe(() => {
      this.dialogRef.close();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  @HostListener('window:keyup.Enter', ['$event'])
  onSubmit() {
    this.submitted = true;
    this.loading=true;
    // stop here if form is invalid
    if (!(this.username.invalid && this.password.invalid)) {
      this.loading = true;
      this.authenticationService
        .login(this.username.value, this.password.value)
        .pipe(
          first(),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: () => {
            const ret = {};
            this.dialogRef.close();
          },
          error: error => {
            console.log('Error occurred!!');
            this.dialog.open(LoginErrorComponent, { width: '250px' });
            this.loading = false;
          }
        });
    }
  }

}
