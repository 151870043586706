export enum RankMethod {
  unknown = -1,
  allPct = 0,
  confPct = 1,
  divPct = 2,
  sos = 3,
  sov = 4,
  divTopAllPct = 5,
  divTopConfPct = 6,
  divTopDivPct = 7,
  divTopSos = 8,
  divTopSov = 9,
  divTopHth = 10,
  hth = 11,
  cg = 12,
  coinFlip = 13,
  commonPct = 14,
  commonGames = 15,
  divisionWinner = 16,
  divisionWinnerMultiWay = 17,
  hthFullBreakMultiWay = 18,
  hthPartialBreakMultiWay = 19,
  divPctFullBreakMultiWay = 20,
  confPctFullBreakMultiWay = 21,
  hthTwoWay = 22,
  twoWayConfPCT = 23,
  twoWayDivPCT = 24,
  divisionWinnerCoinFlip = 25,
  nonDivisionWinner = 26,
  divTopCommonPct = 27,
  divPctPartialBreakMultiWay = 28,
  confPointDifferential = 29,
  divTopConfPointDifferential = 30,
  pointDifferential = 31,
  divTopPointDifferential = 32,
  combinedRankingConference = 33,
  combinedRankingLeague = 34,
  bestNetPointsAllGames = 35,
  bestNetPointsConferenceGames = 36
}
