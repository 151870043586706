import { AbstractControl, ValidatorFn } from "@angular/forms";

export function playoffUrlValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    
    if (control.value == "" || control.value == null || control.value.length != '0000000000000'.length) {
      return { playoffUrl: { value: true } };
    }

    for (var char of control.value) {
      if (!(char == "1" || char == "2")) {
        return { playoffUrl: { value: true } };
      }
    }
    return null;
  };
}
