import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { User, UserResponse } from '@models';
import { OnlineService } from '@services/index';

@Injectable()
export class AuthenticationService implements OnDestroy {
  destroy$ = new Subject<boolean>();
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public jwtSubject: BehaviorSubject<string>;
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  constructor(private http: HttpClient, private onlineService: OnlineService) {
    this.userSubject = new BehaviorSubject<User>({
      loaded:false
    });
    this.jwtSubject = new BehaviorSubject<string>(null);
    this.user = this.userSubject.asObservable();
  }
  public get isLoggedIn(): boolean {
    return this.userSubject.value.loaded == true && this.userSubject.value.id != null;
  }
  public get isAdmin(): boolean {
    return this.isLoggedIn && this.userSubject.value.admin === 'true';
  }
  public get isBeta():boolean{
    return this.isLoggedIn && this.userSubject.value.beta === 'true'
  }
  public get isAdsFree() {
    return this.isLoggedIn && this.userSubject.value.adsfree === 'true';
  }
  public get userValue(): User {
      return this.userSubject.value;
  }
  public get passwordChangeRequired(){
    return this.isLoggedIn && this.userSubject.value.cr === 'true';
  }
  login(username: string, password: string) {
    return this.http.post<any>(`${environment.authEngineUrl}sso/login`, { username, password }, { withCredentials: true }).pipe(
      map((res: any) => {
        this.jwtSubject.next(res.authToken);
        const user = JSON.parse(atob(res.authToken.split('.')[1]));
        user.loaded = true;
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            'event': 'login',
            'userId': user.id
          });
        return user;
      })
    );
  }
  refreshToken() {
    if(document.cookie.indexOf('refreshExists=true') !== -1 || !this.onlineService.isOnline$.value){
      this.http.post<any>(`${environment.authEngineUrl}sso/refresh-token`, {}, { withCredentials: true })
        .pipe(map((res) => {
          const user:User = JSON.parse(atob(res.authToken.split('.')[1]));
          user.loaded = true;
          this.jwtSubject.next(res.authToken);
          this.userSubject.next(user);
          this.startRefreshTokenTimer();
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({
            'event': 'login',
            'userId': user.id
          });
          return user;
        })).subscribe(()=>{});
        return of(true)
    }
    this.userSubject.next({
      loaded:true
    })
    return of(false);
  }
  private refreshTokenTimeout;
  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
  private startRefreshTokenTimer() {
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(this.userValue.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (120 * 1000);
    clearTimeout(this.refreshTokenTimeout);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }
  confirmAccount(id: string, code: string) {
    return this.http.post<any>(`${environment.authEngineUrl}account/confirm`, { id, code }).pipe(
      map((res: any) => {
        const user = JSON.parse(atob(res.authToken.split('.')[1]));
        user.loaded = true;
        this.jwtSubject.next(res.authToken);
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }),
      takeUntil(this.destroy$)
    );
  }
  resetPassword(id: string, code: string, password: string) {
    return this.http.post<any>(`${environment.authEngineUrl}account/reset-password`, { id, code, password }).pipe(
      map((res: any) => {
        const user = JSON.parse(atob(res.authToken.split('.')[1]));
        user.loaded = true;
        this.jwtSubject.next(res.authToken);
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }),
      takeUntil(this.destroy$)
    );
  }
  resendConfirmationToken(id: string) {
    return this.http.post<any>(`${environment.authEngineUrl}account/resend-confirmation-token`, { id }).pipe(
      map((user: any) => { }),
      takeUntil(this.destroy$)
    );
  }
  changePassword(newPassword: string, oldPassword: string) {
    return this.http.post<any>(`${environment.authEngineUrl}account/change-password`, { newPassword, oldPassword }, { withCredentials: true }).pipe(
      map((user: any) => { }),
      takeUntil(this.destroy$)
    );
  }
  recover(email: string) {
    sessionStorage.setItem('email', email);
    return this.http.post<any>(`${environment.authEngineUrl}account/forgot-password`, { email }).pipe(
      map((user: any) => { }),
      takeUntil(this.destroy$)
    );
  }

  register(email: string, password: string) {
    return this.http.post<any>(`${environment.authEngineUrl}account/register`, { email, password })
    .pipe(
      map((res: any) => {
        const user = JSON.parse(atob(res.authToken.split('.')[1]));
        user.loaded = true;
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }),
      takeUntil(this.destroy$)
    );
  }

  logout() {
    return this.http.post<any>(`${environment.authEngineUrl}sso/log-out`, {}, { withCredentials: true })
      .pipe(map((res) => {
        this.stopRefreshTokenTimer();
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
          'event': 'logout'
        });
        this.userSubject.next({
          loaded:true
        });
      }));
  }

  updateExternalName(newName: string){
    return this.http.post<any>(`${environment.authEngineUrl}account/update-external-name`,{
      externalName: newName
    })
    .pipe(map((res)=>{
      this.userValue.en = newName;
      this.userValue.loaded = true;
      this.userSubject.next(this.userValue);
      this.refreshToken().subscribe();
    }));
  }

  getMe(){
    return this.http.get<UserResponse>(`${environment.authEngineUrl}account/me`);
  }

  updateEmailPreference(optIn: boolean){
      return this.http.post<any>(`${environment.authEngineUrl}account/update-email-preferences`,{
        optIn: optIn
      })
  }
}
