import { ResendConfirmationComponent } from './../templates/resend-confirmation/resend-confirmation.component';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { FormControl, Validators } from '@angular/forms';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DialogComponent } from '../templates/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  code = new FormControl('', [Validators.required]);
  id: string;
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService, private router: Router, public dialog: MatDialog) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.id = params.get('id');

      this.code.setValue(params.get('code'));
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (!(this.code.invalid)) {
      this.authenticationService
        .confirmAccount(this.id, this.code.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          user => {
            this.router.navigate([`/`]);
          },
          errorRes => {
            const res = { title: 'Oops. We ran into a problem.', message: '', status: errorRes.status };
            if (errorRes.error[0].code === 'InvalidToken') {
              res.message = 'Your confirmation token is invalid. On the next tab we will allow you to resend this token.';
            }

            this.dialog.open(ResendConfirmationComponent, {
              width: '500px',
              data: {id: this.id}
            });
          }
        );
    }
  }
}
