import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { Watchlist } from "@models";
import { UrlSerializerService } from "@services/url-serializer/url-serializer.service";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { WatchlistService } from "@services/watchlist/watchlist.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-save-watchlist-modal",
  templateUrl: "./save-watchlist-modal.component.html",
  styleUrls: ["./save-watchlist-modal.component.css"],
})
export class SaveWatchlistModalComponent implements OnInit {
  @Input('leagueGuid')
  leagueGuid: string;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";
  destroy$ = new Subject<boolean>();
  _routerSubscription;
  watchlist: Watchlist;
  saveString: string;
  sportId: number;
  constructor(
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SaveWatchlistModalComponent>,
    private dialog: MatDialog,
    private router: Router,
    private urlHelperService: UrlSerializerService,
    private watchlistService: WatchlistService,
    private snackBar: MatSnackBar
  ) {
    this.saveString = this.urlHelperService.saveString;
    this.leagueGuid = this.data.leagueGuid;
    this.sportId = this.data.sportId;
    this.watchlist = {
      watchlistName:'',
      description:'',
      leagueGuid: this.leagueGuid,
      version: 2,
      sortOrder: -1,
      saveString: this.urlHelperService.saveString,
      createdOn: null,
      watchlistGuid:null
    }
  }
  watchlistName = new FormControl("", [Validators.required]);
  description = new FormControl("", [
    Validators.required,
    Validators.maxLength(150),
  ]);
  getErrorMessage() {
    return this.watchlistName.hasError("required")
      ? "You must enter a value"
      : "";
  }
  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    this._routerSubscription = this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  @HostListener("window:keyup.Enter", ["$event"])
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (!(this.watchlistName.invalid)) {
      this.loading = true;
      this.watchlistService.updateWatchlist({
        createdOn: new Date(),
        description: this.description.value,
        leagueGuid: this.leagueGuid,
        saveString: this.saveString,
        sortOrder: -1,
        version: 2,
        watchlistGuid: null,
        watchlistName: this.watchlistName.value
      }, this.sportId).subscribe(() => {
        this.watchlistService.getWatchlistsForUser(this.sportId).subscribe(wl => wl);
        this.snackBar.open("Your scenario has been saved", "dismiss", {
          duration: 2000
        })
        this.dialogRef.close();
      },()=>{
        this.error = "An error occurred on saving"
        this.loading = false;
      })
    }
  }
  onCancel(){
    this.dialogRef.close();
  }
}
