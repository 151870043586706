import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { LeagueDropdownResponse } from "@models";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PoolService } from "@services/index";

@Component({
  selector: "app-create-pool-modal",
  templateUrl: "./create-pool-modal.component.html",
  styleUrls: ["./create-pool-modal.component.css"],
})
export class CreatePoolModalComponent implements OnInit {

  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";
  destroy$ = new Subject<boolean>();
  _routerSubscription;
  leagueGuid: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreatePoolModalComponent>,
    private dialog: MatDialog,
    private router: Router,
    private poolService: PoolService,
    private snackBar: MatSnackBar
  ) {
    this.leagueGuid = data.leagueGuid;
  }
  createPoolForm = new FormGroup({
    groupName: new FormControl("", [Validators.required]),
    baseMultiplier: new FormControl("1", [Validators.required]),
    wildcardPoints: new FormControl("1", [Validators.required]),
    divisionPoints: new FormControl("2", [Validators.required]),
    championshipPoints: new FormControl("4", [Validators.required]),
    superBowlPoints: new FormControl("8", [Validators.required]),
})

  get fc(){
    return this.createPoolForm.controls;
  }
  ngOnInit() {
    // reset login status
    this._routerSubscription = this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  @HostListener("window:keyup.Enter", ["$event"])
  onSubmit() {
   
    // stop here if form is invalid
    if (this.createPoolForm.valid) {
      this.submitted = true;
      this.loading = true;
      this.poolService
        .createPool({
          groupName: this.fc.groupName.value,
          leagueGuid: this.leagueGuid,
          baseMultiplier: this.fc.baseMultiplier.value,
          championshipPoints: this.fc.championshipPoints.value,
          divisionPoints: this.fc.divisionPoints.value,
          superBowlPoints: this.fc.superBowlPoints.value,
          wildcardPoints: this.fc.wildcardPoints.value,
        })
        .subscribe(
          () => {
            this.poolService.getMyPools(this.leagueGuid);
            this.snackBar.open("Pool Created!", "dismiss", {
              duration: 2000,
            });
            this.dialogRef.close();
          },
          () => {
            this.error = "An error occurred";
            this.loading = false;
          }
        );
    }
  }
  onCancel() {
    this.dialogRef.close();
  }
}
