import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { GameRecord } from '@models';

@Component({
  selector: 'app-pct-format',
  templateUrl: './pct-format.component.html',
  styleUrls: ['./pct-format.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PctFormatComponent implements OnInit {
  @Input()
  gameRecord: GameRecord;
  constructor() {}

  ngOnInit() {}
}
