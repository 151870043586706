import { AccountModule } from "./account/account.module";
import { AppRoutingModule } from "./app.routing";
import { BrowserModule, Title } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { MatModule } from "./material/mat.module";
import { JwtInterceptor } from "@services/jwt.interceptor";
import { ErrorInterceptor } from "@services/error.interceptor";
import { AdminModule } from "./admin/admin.module";
import { HomeModule } from "./home/home.module";
import { TopNavbarComponent } from "./top-navbar/top-navbar.component";
import { FootballModule } from "./football/football.module";
import { AdminGuard } from "@services/guards/admin.guard";
import { AuthGuard } from "@services/guards/auth.guard";
import { PaywallGuard } from "@services/guards/paywall.guard";
import { FooterComponent } from "./footer/footer.component";
import { NotFoundModule } from "./NotFound/not-found.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { appInitializer } from "@helpers/app.initializer";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { SharedModule } from "./shared/shared.module";
import { FreestarLoaderComponent } from "./shared/freestar-loader/freestar-loader.component";
import { BillingModule } from "./billing/billing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { PulseModule } from "./pulse/pulse.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { QuicklinkModule } from "ngx-quicklink";
import { ApplicationInsightsErrorHandler } from "@services/error-handler/ApplicationInsightsErrorHandler.handler";
import { BetaGuard } from "./services/guards/beta.guard";
@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    FooterComponent,
    FreestarLoaderComponent,
  ],
  imports: [
    QuicklinkModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    MatModule,
    ReactiveFormsModule,
    HttpClientModule,
    FootballModule,
    HomeModule,
    AdminModule,
    AccountModule,
    AppRoutingModule,
    NotFoundModule,
    FlexLayoutModule,
    SharedModule,
    BillingModule,
    PulseModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService],
    },
    { provide: LOCALE_ID, useValue: "en-US" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: "googleTagManagerId", useValue: "GTM-M42N699" },
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
    AuthGuard,
    AdminGuard,
    PaywallGuard,
    BetaGuard,
    Title,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
