import { DialogComponent } from './../templates/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  destroy$ = new Subject<boolean>();
  newPassword = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]);
  oldPassword = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]);

  constructor(private authenticationService: AuthenticationService, public dialog: MatDialog, private router: Router) {}

  ngOnInit() {}

  onSubmit() {
    // stop here if form is invalid
    if (!(this.newPassword.invalid && this.oldPassword.invalid)) {
      this.authenticationService
        .changePassword(this.newPassword.value, this.oldPassword.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(user => {
          this.authenticationService.refreshToken().subscribe(() => {
            this.router.navigate([`/`]);
          });
        }, errorRes => {
          const res = { title: 'Oops. We ran into a problem.', message: '', status: errorRes.status };
          if (errorRes.error[0].code === 'PasswordMismatch') {
            res.message = 'Incorrect Password. Please try again.';
          }

          this.dialog.open(DialogComponent, {
            width: '500px',
            data: res
          });
        });
    }
  }
}
