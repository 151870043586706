import { Team } from "./team.model";
import { Game } from "./game.model";
import { TeamMapType } from "./team-map-type.model";
import {
  NumberDict,
  PlayoffTeamResponse,
  ScoreDict,
  ScoreRecord,
} from "./league-response.model";
import { LeagueFeatures } from "./league-features.model";
import { PlayoffTeam } from "./playoff-team.model";
import { DraftTradeResponse } from "./draft-trade-response.model";

export class League {
  scores: ScoreDict;
  createdOn: string;
  currentWeekString: string;
  games: Array<Game>;
  isCustomLeague: boolean;
  leagueGuid: string;
  leagueName: string;
  sortOrder: number;
  sportId: number;
  superbowlYear: number;
  teamOverrides: any[];
  userId: string;
  teams: Array<Team>;
  conf1Name: string;
  conf2Name: string;
  dataDict: TeamMapType;
  features: LeagueFeatures;
  teamsInWeek: NumberDict = {};
  playoffTeams: PlayoffTeam[];
  playoffString: string;
  draftTrades: DraftTradeResponse[];
  isErrored: boolean = false;
  constructor(
    data: League,
    teamArray: Array<Team>,
    dataDict: TeamMapType,
    conf1: string,
    conf2: string,
    scores: ScoreDict,
    playoffTeams: Array<PlayoffTeamResponse>
  ) {
    Object.assign(this, data);
    this.conf1Name = conf1;
    this.conf2Name = conf2;
    this.teams = teamArray;
    this.scores = scores;
    this.games = this.games.map((g) => {
      const game = new Game(g);
      if (!this.teamsInWeek[game.week]) {
        this.teamsInWeek[game.week] = [game.homeId, game.awayId];
      } else {
        this.teamsInWeek[game.week].push(game.homeId, game.awayId);
      }
      this.setTeams(game, teamArray, scores);
      game.leagueGuid = this.leagueGuid;
      return game;
    });
    
    this.playoffTeams = playoffTeams.map((pT) => {
      let team = this.teams.find((p) => p.teamId == pT.teamId);
      let playoffteam = new PlayoffTeam();
      playoffteam.team = team;
      playoffteam.seed = pT.seed;
      return playoffteam;
    });

    for (var team of this.teams) {
      for (let week in this.teamsInWeek) {
        if (this.teamsInWeek[week].indexOf(team.teamId) == -1) {
          team.byeWeek = parseInt(week, 10);
        }
        if (team.byeWeek) {
          continue;
        }
      }
    }
    this.playoffString = data.playoffString;
    this.features = data.features;
    this.dataDict = dataDict;
    this.setSchedules(teamArray);
  }

  setTeams(game: Game, teamArray: Array<Team>, scores: ScoreDict) {
    let awayTeam = teamArray.find((p) => p.teamId === game.awayId);
    let homeTeam = teamArray.find((p) => p.teamId === game.homeId);
    game.scores = scores[game.gameScheduleNum] || new ScoreRecord();
    game.away = awayTeam;
    game.home = homeTeam;
  }

  setSchedules(teams: Array<Team>) {
    try {
      if(!this.games.length){
        this.isErrored = true;
        for(const team of teams){
          team.schedule = [];
        }
        return;
      }
      for (let game of this.games) {
        if(game.gameDate.getDay() == 4){
          //need to mark the home/away teams for the following week that they had a mini bye
          let awayOffMiniBye = this.games.filter(p => p.week == game.week+1 && ((p.awayId == game.homeId) || (p.awayId == game.awayId)));
          let homeOffMiniBye = this.games.filter(p => p.week == game.week+1 && ((p.homeId == game.homeId) || (p.homeId == game.awayId)));
          for(let away of awayOffMiniBye){
            away.awayOffThurs = true;
          }
          for(let home of homeOffMiniBye){
            home.homeOffThurs = true;
          }
        }
        if (game.week == 1) {
          continue;
        }
        if (this.teamsInWeek[game.week - 1].length == 32) {
          continue;
        } else {
          let teamsInLastWeek = this.teamsInWeek[game.week - 1]
          if (teamsInLastWeek.indexOf(game.homeId) == -1) {
            game.homeOffBye = true;
          }
          if (teamsInLastWeek.indexOf(game.awayId) == -1) {
            game.awayOffBye = true;
          }

        }
      }
      for (const team of teams) {
        team.schedule = this.games.filter(
          (p) =>
            p.home.teamAbbreviation === team.teamAbbreviation ||
            p.away.teamAbbreviation === team.teamAbbreviation
        );
      }
    } catch (ex) {
      this.isErrored = true;
      // this error can throw if the schedule is not fully loaded
      console.warn(ex);
    }
  }
}
