import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import {
  AdminPoolMemberResponse,
  CreatePoolRequest,
  GroupMembersByAdmin,
  PlayoffSubmission,
  PoolDropdownResponse,
  RemoveMemberFromPoolResponse,
  SubmitPicksToPoolRequest,
  UserPickResponse,
  PoolDetails,
  EditPoolRequest,
  AddMemberToPoolRequest,
  AssignSubscriptionResponse,
  AvailableSubscriptionsResponse,
  FullGroupDetailsResponse,
  UpdateMemberOfPoolRequest,
  UpdateMemberOfPoolResponse,
  PoolMemberParticipantResponse
} from "@models";
import { BehaviorSubject, interval, timer } from "rxjs";
import { map, take, takeUntil, debounce } from "rxjs/operators";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class PoolService {
  dropdowns$ = new BehaviorSubject<Array<PoolDropdownResponse>>([]);
  myPools$ = new BehaviorSubject<FullGroupDetailsResponse[]>([]);
  mySubscriptions$ = new BehaviorSubject<AvailableSubscriptionsResponse[]>([]);
  public isError = false;
  private lastLeagueGuid: string;
  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar
  ) {
    this.authService.user.subscribe((_) => {
      if (!this.lastLeagueGuid) {
        return;
      }
      if (this.authService.isLoggedIn) {
        this.getSubmittableGroups(this.lastLeagueGuid);
      } else {
        this.getSubmittableGroupsAnonymous(this.lastLeagueGuid);
      }
    });
  }
  refreshMyPools() {
    this.getMyPools(this.lastLeagueGuid);
    this.getAvailableSubscriptions(this.lastLeagueGuid);
  }
  getSubmittableGroups(leagueGuid: string) {
    let url = `${environment.resultingEngineUrl}pools/${leagueGuid}`;
    this.lastLeagueGuid = leagueGuid;
    this.httpClient
      .get<Array<PoolDropdownResponse>>(url, { headers: { "add-jwt": "true" } })
      .pipe(
        map((res: Array<PoolDropdownResponse>) => {
          this.dropdowns$.next(res);
        })
      )
      .subscribe(()=>{}, () =>{
        this.isError = true;
        this.snackBar.open("An error occurred when fetching the data. Please try again later.", "dismiss")
        this.dropdowns$.error({})
      });
  }
  getSubmittableGroupsAnonymous(leagueGuid: string) {
    let url = `${environment.resultingEngineUrl}pools/anonymous/${leagueGuid}`;
    this.lastLeagueGuid = leagueGuid;
    this.httpClient
      .get<Array<PoolDropdownResponse>>(url)
      .pipe(
        map((res: Array<PoolDropdownResponse>) => {
          this.dropdowns$.next(res);
        })
      ).subscribe(()=>{},()=>{
        this.isError = true;
        this.snackBar.open("An error occurred when fetching the data. Please try again later.", "dismiss")
      });

  }
  submitPicksToPool(groupId: string, userPicks: SubmitPicksToPoolRequest) {
    return this.httpClient
      .post<any>(
        `${environment.resultingEngineUrl}pools/${groupId}/save-picks`,
        userPicks
      )
      .pipe(
        map((r) => {
          return r;
        })
      );
  }
  getGroupMembersByParticipant(poolGuid: string) {
    return this.httpClient
      .get<PoolMemberParticipantResponse[]>(
        `${environment.resultingEngineUrl}poolMembers/${poolGuid}/members/`
      )
      .pipe(map((r) => r), debounce(() => interval(100)));
  }
  getGroupMembersByAdmin(poolGuid: string) {
    return this.httpClient
      .get<AdminPoolMemberResponse>(
        `${environment.resultingEngineUrl}poolMembers/${poolGuid}/membersAdmin/`
      )
      .pipe(map((r) => r), debounce(() => interval(100)));
  }
  getMyPools(leagueGuid: string) {
    this.httpClient
      .get<FullGroupDetailsResponse[]>(
        `${environment.resultingEngineUrl}pools/${leagueGuid}/owned/`
      )
      .pipe(
        debounce(() => interval(500)),
        map((r) => r)
      )
      .subscribe((p) => this.myPools$.next(p));
    return this.myPools$;
  }
  getOtherUserPicks(poolGuid: string, userGuid: string) {
    return this.httpClient
      .get<UserPickResponse[]>(
        `${environment.resultingEngineUrl}pools/${poolGuid}/picks/${userGuid}`
      )
      .pipe(
        map((r) => r)
      );
  }
  getUserPicks(poolGuid: string) {
    return this.httpClient
      .get<UserPickResponse[]>(
        `${environment.resultingEngineUrl}pools/${poolGuid}/picks/`
      )
      .pipe(
        map((r) => r)
      );
  }
  createPool(request: CreatePoolRequest) {
    let url = `${environment.resultingEngineUrl}pools`;
    return this.httpClient.post(url, request, {
      headers: { "add-jwt": "true" },
    });
  }

  getPoolDetails(poolGuid: string) {
    return this.httpClient.get<PoolDetails>(
      `${environment.resultingEngineUrl}pools/details/${poolGuid}`
    );
  }

  editPool(request: EditPoolRequest) {
    let url = `${environment.resultingEngineUrl}pools`;
    return this.httpClient.put(url, request, {
      headers: { "add-jwt": "true" },
    });
  }
  updateMemberOfPool(groupGuid: string, request: UpdateMemberOfPoolRequest) {
    return this.httpClient.put<UpdateMemberOfPoolResponse>(
      `${environment.resultingEngineUrl}poolMembers/${groupGuid}/`, request,
      { headers: { "add-jwt": "true" } }
    );
  }
  addMemberToPool(groupGuid: string, request: AddMemberToPoolRequest) {
    return this.httpClient.post(
      `${environment.resultingEngineUrl}poolMembers/${groupGuid}/`, request,
      { headers: { "add-jwt": "true" } }
    );
  }
  addBulkMembersToPool(groupGuid: string, members: string[]){
    return this.httpClient.post(
      `${environment.resultingEngineUrl}poolMembers/bulk/${groupGuid}/`, members,
      { headers: { "add-jwt": "true" } }
    );
  }
  removeMemberFromPool(groupMember: GroupMembersByAdmin) {
    return this.httpClient.delete<RemoveMemberFromPoolResponse>(
      `${environment.resultingEngineUrl}poolMembers/${groupMember.groupId}/${groupMember.groupMemberId}`,
      {
        headers: { "add-jwt": "true" },
      }
    );
  }

  getAvailableSubscriptions(leagueGuid: string){
    this.httpClient.get<Array<AvailableSubscriptionsResponse>>(`${environment.resultingEngineUrl}pools/subscriptions/${leagueGuid}`,
    {
      headers: { "add-jwt": "true" },
    })
    .pipe(
      debounce(() => interval(500)),
      map((r) => r)
    )
    .subscribe((p) => this.mySubscriptions$.next(p));

    return this.mySubscriptions$;
  }
  assignSubscriptionToPool(subscription: AvailableSubscriptionsResponse, groupGuid: string) {
    return this.httpClient.post<AssignSubscriptionResponse>(`${environment.resultingEngineUrl}pools/subscriptions/assign`,{
      subscriptionExternalId: subscription.subscriptionExternalId,
      groupGuid: groupGuid
    },{
      headers: { "add-jwt": "true" },
    })
  }
}
