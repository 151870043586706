
<article>

<ng-container *ngIf="!isGroupSelected">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Playoff Pools</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="authService.isLoggedIn && authService.isBeta">
        <button mat-raised-button color="primary" (click)="openCreatePoolModal()">Create Pool</button>
        <ng-container *ngIf="pools.length">
          <table mat-table [dataSource]="pools" class="min-width-50">
            <ng-container matColumnDef="groupName">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">{{ element.groupName }}</td>
            </ng-container>
            <ng-container matColumnDef="leagueName">
              <th mat-header-cell *matHeaderCellDef>Season</th>
              <td mat-cell *matCellDef="let element">{{ element.leagueName }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button (click)="selectGroup(element.groupGuid)">
                  <mat-icon>preview</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="['groupName', 'leagueName', 'actions']"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: ['groupName', 'leagueName', 'actions']"
            ></tr>
          </table>
        </ng-container>
        <ng-container *ngIf="pools.length == 0">
          <p>You aren't an administrator of any pools</p>
        </ng-container>
      </ng-container>
    </mat-card-content>
  </mat-card>
</ng-container>
<ng-container *ngIf="isGroupSelected">
  <mat-card>
    <mat-card-header>
      <mat-card-title> {{ selectedGroup.groupName }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-expansion-panel>
        <mat-expansion-panel-header>Pool Information </mat-expansion-panel-header>
        <ul>
          <li>
            <strong>Base Win Value:</strong> {{ selectedGroup.baseMultiplier }} (which
            is multiplied by the round value)
          </li>
          <li>
            <strong>Wild Card Multiplier:</strong> {{ selectedGroup.wildcardPoints }}
          </li>
          <li>
            <strong>Divisional Round Multiplier:</strong>
            {{ selectedGroup.divisionPoints }}
          </li>
          <li>
            <strong>Championship Round Multiplier:</strong>
            {{ selectedGroup.championshipPoints }}
          </li>
          <li>
            <strong>Super Bowl Multiplier:</strong>
            {{ selectedGroup.superBowlPoints }}
          </li>
          <li><strong>Super Bowl Home Team:</strong>
          {{ selectedGroup.isAfcHome ? 'AFC' : 'NFC' }}</li>
        </ul>
    
      </mat-expansion-panel>
    
        
  <h3>Submissions</h3>
  <div class="button-row">
    <button mat-raised-button color="primary" (click)="updateScores(selectedGroup.groupGuid)" [disabled]="submissions.length == 0">Update Results</button>
    <button mat-raised-button color="accent" (click)="addSubmission()">Add Submission</button>
    <button mat-raised-button color="warn" (click)="editPool()">Edit Pool</button>
  </div>
    <ng-container *ngIf="submissions.length">
      <table mat-table [dataSource]="submissions" class="min-width-50">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
  
        <ng-container matColumnDef="wildcardPoints">
          <th mat-header-cell *matHeaderCellDef>WC</th>
          <td mat-cell *matCellDef="let element">{{ element.wildcardPoints }}</td>
        </ng-container>
        <ng-container matColumnDef="divisionPoints">
          <th mat-header-cell *matHeaderCellDef>DV</th>
          <td mat-cell *matCellDef="let element">{{ element.divisionPoints }}</td>
        </ng-container>
        <ng-container matColumnDef="championshipPoints">
          <th mat-header-cell *matHeaderCellDef>CG</th>
          <td mat-cell *matCellDef="let element">{{ element.championshipPoints }}</td>
        </ng-container>
        <ng-container matColumnDef="superBowlPoints">
          <th mat-header-cell *matHeaderCellDef>SB</th>
          <td mat-cell *matCellDef="let element">{{ element.superBowlPoints }}</td>
        </ng-container>
        <ng-container matColumnDef="totalScore">
          <th mat-header-cell *matHeaderCellDef>Total Score</th>
          <td mat-cell *matCellDef="let element">{{ element.totalScore }}</td>
        </ng-container>
        <ng-container matColumnDef="championshipTotalScore">
          <th mat-header-cell *matHeaderCellDef matTooltip="{{selectedGroup.isAfcHome ? 'AFC is Home' : 'NFC is Home'}}">Tiebreaker (a-h)*</th>
          <td mat-cell *matCellDef="let element">{{ element.championshipTotalScore }} ({{ element.awayScore }}-{{ element.homeScore }})</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a mat-raised-button href="{{ '/football/nfl/playoffpicture/' + selectedGroup.leagueGuid +'/?L=' + element.url + '&sbhomescore=' + element.homeScore + '&sbawayscore='+element.awayScore +'&es=true'}}" target="_blank">
              <mat-icon>preview</mat-icon>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastUpdatedOnUtc">
          <th mat-header-cell *matHeaderCellDef>Updated On</th>
          <td mat-cell *matCellDef="let element">{{ element.lastUpdatedOnUtc | date:'short':'':'en-US' }}</td>
        </ng-container>
        <ng-container matColumnDef="submittedOnUtc">
          <th mat-header-cell *matHeaderCellDef>Submitted On</th>
          <td mat-cell *matCellDef="let element">{{ element.submittedOnUtc | date:'short':'':'en-US' }}</td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="['name', 'totalScore', 'wildcardPoints', 'divisionPoints', 'championshipPoints','superBowlPoints', 'actions', 'championshipTotalScore','submittedOnUtc', 'lastUpdatedOnUtc']"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['name', 'totalScore', 'wildcardPoints', 'divisionPoints', 'championshipPoints','superBowlPoints', 'actions', 'championshipTotalScore', 'submittedOnUtc', 'lastUpdatedOnUtc']"
        ></tr>
      </table>
    </ng-container>
  
    <ng-container *ngIf="submissions.length == 0">
      <p>No submissions yet</p>
    </ng-container>
    <button mat-raised-button color="accent" (click)="selectGroup(null)">
      Back
    </button>
      </mat-card-content
    >
  </mat-card>
  
</ng-container>

</article>