import { take, map, takeUntil } from "rxjs/operators";
import { Link } from "@models";
import { Subject, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class LinkService implements OnDestroy {
  links$ = new BehaviorSubject<Link[]>(null);
  constructor(private httpClient: HttpClient) {
    this.getLinks();
  }
  private destroy$ = new Subject<boolean>();
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  getLinks() {
    let url = `${environment.staticJsonUrl}links`;
    if (!environment.production) {
      url += "-test";
    }
    this.httpClient
      .get<Link[]>(`${url}.json`, { headers: { "add-jwt": "false" } })
      .pipe(
        take(1),
        map((res) => {
          this.links$.next(res);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    return this.links$;
  }
}
