import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { LeagueService } from "../services/league/league.service";
import { AuthenticationService, PoolService, SeoService } from "@services/index";
import { Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { ActivatedRoute, ParamMap, Route } from "@angular/router";

@Component({
  selector: "app-pools",
  templateUrl: "./pools.component.html",
  styleUrls: ["./pools.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PoolsComponent implements OnInit, OnDestroy {
  hasPools: boolean = false;
  destroy$ = new Subject<boolean>();
  leagueGuid: string;
  initialPoolGuid: string;
  constructor(
    private poolService: PoolService,
    private leagueService: LeagueService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private seoService: SeoService
  ) {}
  ngOnDestroy(): void {
    this.seoService.updateTitle(`Playoff Predictors`);
    this.destroy$.next(true);
  }

  ngOnInit() {
    this.leagueService.league$.pipe(takeUntil(this.destroy$)).subscribe((league) => {
      if(league){
        this.seoService.updateTitle(`Pick'Em Pools - ${ league.leagueName } - Playoff Predictors`);
        this.seoService.updateDescription(`Create custom pick'em pools for the entire ${ league.leagueName } regular season, and the playoffs`)
      }
    })
    this.poolService.dropdowns$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ddl) => {
        this.hasPools = !!ddl.length;
      });
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return {
            leagueGuid: params.get("leagueGuid"),
            poolGuid: params.get("poolGuid")
          };
        }),
        switchMap((params) => {
          if (this.authenticationService.isLoggedIn) {
            this.poolService.getSubmittableGroups(params.leagueGuid);
          } else {
            this.poolService.getSubmittableGroupsAnonymous(params.leagueGuid);
          }
          this.initialPoolGuid = params.poolGuid;
          this.leagueGuid = params.leagueGuid;
          return this.leagueService.LoadInitialLeague(
            params.leagueGuid,
            false,
            null
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {});
  }
}
