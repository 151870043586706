<mat-tab-group [selectedIndex]="0">
  <mat-tab>
    <ng-template mat-tab-label> <img src="https://static.playoffpredictors.com/nfl/AFC.svg" width="32" height="32"/> {{ league.conf1Name }} </ng-template>
    <app-conference-scores [conference]="league.conf1Name"> </app-conference-scores>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label> <img src="https://static.playoffpredictors.com/nfl/NFC.svg" width="32" height="32"/> {{ league.conf2Name }} </ng-template>
    <app-conference-scores [conference]="league.conf2Name"></app-conference-scores>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label> <img src="https://static.playoffpredictors.com/nfl/AFC.svg" width="32" height="32"/> Combined <img src="https://static.playoffpredictors.com/nfl/NFC.svg" width="32" height="32"/> </ng-template>
    <app-conference-scores [conference]="'All'"></app-conference-scores>
  </mat-tab>
</mat-tab-group>
