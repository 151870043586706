import { ResendConfirmationComponent } from './templates/resend-confirmation/resend-confirmation.component';
import { LoginTemplateComponent } from './templates/login/login.component';
import { MatModule } from './../material/mat.module';
import { AccountRoutingModule } from './account.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user/user.component';
import { HomeComponent } from './home/home.component';
import { RegisterTemplateComponent } from './templates/register/register.component';
import { RecoverComponent } from './recover/recover.component';
import { DialogComponent } from './templates/dialog/dialog.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginErrorComponent } from './templates/login-error/login-error.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { LoginDialogComponent } from './templates/login-dialog/login-dialog.component';
import { SetNameDialogComponent } from './set-name-dialog/set-name-dialog.component';
import { SetNameErrorComponent } from './set-name-error/set-name-error.component';
import { SetNameComponent } from './set-name/set-name.component';
import { EmailPreferencesComponent } from './email-preferences/email-preferences.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AccountRoutingModule,
        MatModule,
    ],
    exports: [ReactiveFormsModule, FormsModule],
    declarations: [
        UserComponent,
        HomeComponent,
        RegisterTemplateComponent,
        LoginTemplateComponent,
        RecoverComponent,
        DialogComponent,
        ConfirmComponent,
        ResetPasswordComponent,
        SettingsComponent,
        LoginErrorComponent,
        ResendConfirmationComponent,
        SubscriptionsComponent,
        LoginDialogComponent,
        SetNameDialogComponent,
        SetNameErrorComponent,
        SetNameComponent,
        EmailPreferencesComponent,
    ]
})
export class AccountModule {}
