import { AdminGuard } from '@services/guards/admin.guard';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';
import { paths } from './app-paths';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { environment } from '@env/environment';

const routes = [
  {
    path: 'home',
    loadChildren: () => import('app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'account',
    loadChildren: () => import('app/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('app/billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'football',
    loadChildren: () => import('app/football/football.module').then(m => m.FootballModule)
  },
  {
    path:'pulse',
    loadChildren: () => import('app/pulse/pulse.module').then(m => m.PulseModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  }
];
 
const extraOptions:ExtraOptions = {
  relativeLinkResolution: 'legacy',
  preloadingStrategy: QuicklinkStrategy
};
@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
