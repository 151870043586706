import { PlayoffsService } from './../../services/playoffs/playoffs.service';
import { PlayoffTeam, PlayoffGame } from '@models';
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-playoff-game',
  templateUrl: './playoff-game.component.html',
  styleUrls: ['./playoff-game.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class PlayoffGameComponent implements OnInit {
  @Input()
  playoffGame: PlayoffGame;
  @Input()
  homePlaceholderText: string;
  @Input()
  awayPlaceholderText: string;
  @Input()
  enableScoring: boolean;
  @Input()
  awayScore: number;
  @Input()
  homeScore:number;
  @Output()
  scoreUpdated = new EventEmitter<PlayoffGame>();
  constructor(private playoffService: PlayoffsService) {}

  ngOnInit() {}

  pickTeam(playoffTeam: PlayoffTeam) {
    if(this.playoffGame.teamSelected == playoffTeam){
      this.playoffGame.teamSelected = null;
      this.playoffService.unselectGame(this.playoffGame);
    }
    else{
      this.playoffGame.teamSelected = playoffTeam;
      this.playoffService.gameOutcomePicked(this.playoffGame);
    }
  }

  scoreUpdate(){
    this.playoffGame.homeScore = this.homeScore;
    this.playoffGame.awayScore = this.awayScore;
    if(this.playoffGame.homeScore > this.playoffGame.awayScore){
      this.playoffGame.teamSelected = this.playoffGame.home;
    }
    if(this.playoffGame.homeScore < this.playoffGame.awayScore){
      this.playoffGame.teamSelected = this.playoffGame.away;
    }
    this.scoreUpdated.emit(this.playoffGame);
  }
}
