import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  title: string;
  message: string;
  status: number;
  email: string;
  navigateRoute: string;
  routerLink: string;
  linkText: string;
  noLinkText:string;
  yesLinkText:string;
}
@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.css']
})
export class YesNoDialogComponent {
  constructor(private router: Router, public dialogRef: MatDialogRef<YesNoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onYesClick(): void {
    this.dialogRef.close(true);
  }
  onNoClick():void{
    this.dialogRef.close(false);
  }
}
