<ng-container *ngIf="isLoading">
  <mat-progress-spinner [mode]="'indeterminate'"> </mat-progress-spinner>
  <p>Loading... Just a moment</p>
</ng-container>
<ng-container *ngIf="poolService.isError">
  <p>
    An error occurred when attempting to load the data. This is likely a problem
    on Playoff Predictors' side. If this keeps happening, please
    <a href="mailto:help@playoffpredictors.com">Contact Us</a> for assistance
  </p>
</ng-container>
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="!poolService.isError">
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
      <ng-container *ngIf="selectedPool.value?.groupGuid == undefined">
        <span> Select Pool</span>
      </ng-container>
      <ng-container *ngIf="selectedPool.value?.groupGuid">
        <span> {{ selectedPool.value?.groupName }}</span>
      </ng-container>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container>
        <button
          mat-menu-item
          *ngFor="let item of pools"
          (click)="selectPool(item)"
        >
          <ng-container [ngSwitch]="item.privacyLevel">
            <mat-icon *ngSwitchCase="1">public</mat-icon>
            <mat-icon *ngSwitchCase="2">lock</mat-icon>
            <mat-icon *ngSwitchCase="3">close</mat-icon>
          </ng-container>
          <span> {{ item.groupName }}</span>
        </button>
      </ng-container>
    </mat-menu>
    <ng-container *ngIf="promptLogin">
      <div>
        You aren't currently logged in, but are trying to view a pool. Want to
        <button mat-button mat-flat-button (click)="loginClick()">login</button>?
      </div>
    </ng-container>
    <ng-container *ngIf="selectedPool.value?.groupGuid">
      <mat-tab-group>
        <mat-tab label="My Picks">
          <ng-template matTabContent>
            <app-user-picks [selectedPool]="selectedPool"></app-user-picks>
          </ng-template>
        </mat-tab>
        <mat-tab label="Leaderboard">
          <app-leader-board [selectedPool]="selectedPool"></app-leader-board>
        </mat-tab>
        <mat-tab label="Pool Manager">
          <ng-template matTabContent>
            <app-my-pools></app-my-pools>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </ng-container>
</ng-container>
