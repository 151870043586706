<ng-container *ngIf="bracket">
  <app-freestar [adDimensions]="'__320x100 __970x250 __300x250'" [adName]="'playoffpredictors_leaderboard_atf_NFL'"></app-freestar>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h2>Post-season prediction for the {{ bracket.leagueTitle }}</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <span class="date">Originally uploaded on {{ bracket.uploadedOn | date: 'shortDate' }}</span>
      </mat-card-subtitle>
    </mat-card-header>
    <img src="https://static.playoffpredictors.com/uploads/{{ bracket.imageFileName }}.png" alt="Post-season prediction for the {{ bracket.leagueTitle }}" />

    <mat-card-actions>
      <a mat-button mat-raised-button color="accent" [routerLink]="['/football/nfl/' + bracket.leagueGuid+'/official']" [queryParams]="{ L: bracket.saveHash }">See the full prediction</a>
    </mat-card-actions>
  </mat-card>
</ng-container>
