<mat-card>
  <mat-card-header>
    <mat-card-title>Set Display Name</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input
          matInput
          placeholder="External Name"
          [formControl]="displayName"
          required
          type="text"
        />
        <mat-error *ngIf="displayName.invalid">
          <span *ngIf="displayName.hasError('required')">
            field is required
          </span>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-button
      mat-raised-button
      [disabled]="displayName.invalid"
      (click)="onSubmit()"
      color="accent"
    >
      Save
    </button>
    <button mat-button (click)="onCancel()">Cancel</button>
  </mat-card-actions>
</mat-card>
