import {
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  NavigationStart,
  ActivatedRoute,
} from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { SeoService, AuthenticationService, UpdateService, UserSettingsService } from "@services/index";
import { filter, map, mergeMap } from "rxjs/operators";
//import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MonitoringService } from "@services/monitoring/monitoring.service";
import { environment } from "@env/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  loading = true;
  modalVersion = false;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    private updateService: UpdateService,
    private monitoringService: MonitoringService
  ) {
    if(!environment.production && !this.authService.isLoggedIn && environment.autoLogIn && environment.adminUser && environment.adminPw){
      this.authService.login(environment.adminUser, environment.adminPw).subscribe()
    }
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        case event instanceof NavigationEnd: {
          (<any>window).gtag('config', 'G-79R8114BRH',
            {'anonymize_ip': true},
            {
              'page_path':
              (event as NavigationEnd).urlAfterRedirects
            });
          this.loading = false;
          if (authService.isAdsFree) {
            window["freestar"] &&
              window["freestar"].deleteAdSlots &&
              window["freestar"].deleteAdSlots();
          }

          break;
        }
        default:
          break;
      }
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event) => {
      this.seoService.updateTitle(event["title"]);
      this.seoService.updateOgUrl(event["ogUrl"]);
      //Updating Description tag dynamically with title
      this.seoService.updateDescription(
        `${event["title"]} - ${event["description"]}`
      );
 });
  }
  public ngOnInit(): void {

  }

}
