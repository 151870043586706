import { LeagueSettingsService } from './../../services/league-settings/league-settings.service';
import { League, Team } from '@models';
import { LeagueService } from './../../services/league/league.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { debounce, debounceTime, takeUntil, throttleTime } from 'rxjs/operators';
import * as Enumerable from 'linq';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UserSettingsService } from '@services/user-settings/user-settings.service';

@Component({
  selector: 'app-division-standings',
  templateUrl: './division-standings.component.html',
  styleUrls: ['./division-standings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DivisionStandingsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  @Input()
  conference: string;

  numPlayoffTeams: number = this.leagueSettings.numPlayoffTeamsPerConference;
  dataSource: MatTableDataSource<Team>;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  divisionTeams: any[];
  constructor(private leagueService: LeagueService, private leagueSettings: LeagueSettingsService, private userSettings: UserSettingsService) { }
  sortRank = {
    "North": 1,
    "East": 2,
    "South": 3,
    "West": 4
  }
  ngOnInit() {
    this.leagueService.league$.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe((res: League) => {
      let teams = [];
      if(this.userSettings.userConfig.nfl.reorderDivisionStandings){
        teams = Enumerable.from(this.leagueService.league$.value.dataDict[this.conference])
          .orderBy(p => p.conferenceRank)
          .groupBy(p => p.division)
          .toArray();
      }else{
        teams = Enumerable.from(this.leagueService.league$.value.dataDict[this.conference])
        .orderBy(p => this.sortRank[p.division])
        .thenBy(p => p.conferenceRank)
        .groupBy(p => p.division)
        .toArray();
      }
      this.divisionTeams = [...teams]
    });
    this.leagueService.standingsSet$.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe((set) => {
      let teams = [];
      if(this.userSettings.userConfig.nfl.reorderDivisionStandings){
        teams = Enumerable.from(this.leagueService.league$.value.dataDict[this.conference])
          .orderBy(p => p.conferenceRank)
          .groupBy(p => p.division)
          .toArray();
      }else{
        teams = Enumerable.from(this.leagueService.league$.value.dataDict[this.conference])
        .orderBy(p => this.sortRank[p.division])
        .thenBy(p => p.conferenceRank)
        .groupBy(p => p.division)
        .toArray();
      }
      this.divisionTeams = [...teams]
    });
  }
  selectTeam(team) {
    this.leagueService.selectedTeam$.next(team);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
