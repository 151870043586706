import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

if(environment.enableServiceWorker){
  platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/ngsw-worker.js');
    }
  }).catch(err => console.log(err));
}else{
  platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations)=>{
        for(let registration of registrations){
          registration.unregister();
        }
      })
    }
  }).catch(err => console.log(err));
}

