import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { Subject } from "rxjs";
import { first, take, takeUntil } from "rxjs/operators";
import { SetNameErrorComponent } from "../set-name-error/set-name-error.component";

@Component({
  selector: "app-set-name-dialog",
  templateUrl: "./set-name-dialog.component.html",
  styleUrls: ["./set-name-dialog.component.css"],
})
export class SetNameDialogComponent implements OnInit, OnDestroy {
  displayName = new FormControl("", [
    Validators.required,
    Validators.maxLength(50),
  ]);
  destroy$ = new Subject<boolean>();

  constructor(
    private dialogRef: MatDialogRef<SetNameDialogComponent>,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy() {
    this.dialogRef.close(true);
    this.destroy$.next(true);
  }
  ngOnInit(): void {}
  onCancel() {
    this.dialogRef.close(true);
    this.destroy$.next(true);
  }
  onSubmit() {
    if (this.displayName.valid) {
      this.authService
        .updateExternalName(this.displayName.value)
        .pipe(first(), takeUntil(this.destroy$))
        .subscribe({
          next: (v) => {
            this.authService.refreshToken().pipe(take(1)).subscribe(()=>{})
            this.dialogRef.close(true);
          },
          error: (v) => {
            console.log("Error occurred!!");
            this.dialog.open(SetNameErrorComponent, { width: "250px" });
          },
        });
    }
  }
}
