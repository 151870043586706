import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AuthenticationService } from "..";

@Injectable()
export class MonitoringService {
  appInsights: ApplicationInsights;
  constructor(private authService: AuthenticationService) {
    if (environment.production && environment.appInsights.enabled) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.instrumentationKey,
          samplingPercentage: 20,
          disableCorrelationHeaders: false,
          disableXhr: false,
          correlationHeaderDomains: [
            "*.playoffpredictors.com",
            "playoffpredictors.com",
          ],
          enableAutoRouteTracking: true, // option to log all route changes
        },
      });

      this.appInsights.loadAppInsights();
      authService.user.subscribe((user) => {
        if (user) {
          this.appInsights.setAuthenticatedUserContext(user.sub, user.id);
        }
      });
    }
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    if (environment.production && environment.appInsights.enabled) {
      this.appInsights.trackPageView({
        name: name,
        uri: url,
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (environment.production && environment.appInsights.enabled) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    if (environment.production && environment.appInsights.enabled) {
      this.appInsights.trackMetric(
        { name: name, average: average },
        properties
      );
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (environment.production && environment.appInsights.enabled) {
      if (
        exception.stack?.indexOf("main.js") ||
        exception["url"]?.indexOf("apim.playoffpredictors.com")
      ) {
        this.appInsights.trackException({
          exception: exception,
          severityLevel: severityLevel,
        });
      }
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (environment.production && environment.appInsights.enabled) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }
}
