import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { User } from '@models';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements OnDestroy{
  destroy$ = new Subject<boolean>();
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public jwtSubject: BehaviorSubject<string>;
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.jwtSubject = new BehaviorSubject<string>(null);
    this.user = this.userSubject.asObservable();
  }

  portalLink() {
    return this.http.get<any>(`${environment.paymentEngineUrl}account/portal`)
    .pipe(
      map((res: any) => {
        return res;
      }),
      takeUntil(this.destroy$)
    );
  }
  purchasePoolItem(seats: number, leagueGuid: string, poolGuid: string) {
    let priceId = environment.payments.poolSub;
    return this.http.post<any>(`${environment.paymentEngineUrl}pools/payments/checkout/${priceId}/${seats}/${leagueGuid}/${poolGuid}`, {})
    .pipe(
      map((res) => {
        return res;
      }),
      takeUntil(this.destroy$)
    );
  }
  purchaseItem(priceId: string, mode: string) {
    return this.http.post<any>(`${environment.paymentEngineUrl}payments/checkout/${priceId}/${mode}`, {})
    .pipe(
      map((res) => {
        return res;
      }),
      takeUntil(this.destroy$)
    );
  }

  success(sessionId: string){
    return this.http.get<any>(`${environment.paymentEngineUrl}payments/success/${sessionId}`)
    .pipe(
      map((res: any) => {
        return res;
      }),
      takeUntil(this.destroy$)
    );
  }

  getSubscriptions(){
    return this.http.get<any>(`${environment.paymentEngineUrl}payments/subscriptions`)
    .pipe(
      map((res: any) => {
        return res;
      }),
      takeUntil(this.destroy$)
    );
  }
}
