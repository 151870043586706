<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-raised-button focused color="primary" (click)="onYesClick()">
    {{ data.yesLinkText }}
  </button>
  <button mat-raised-button (click)="onNoClick()">
    {{ data.noLinkText }}
  </button>
</div>
