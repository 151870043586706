<h2>What is Playoff Predictors?</h2>
<p>PlayoffPredictors is the premiere sports tiebreaking website on the Internet, and allows our users to select every game of several sports leagues, and see accurate seeds for their selections.</p>
<p>
  Allowing our users to select every game of a season and seeding teams <em>correctly</em>. There are other tiebreaking systems out there, but many of them have severe deficiencies with their systems -- we quickly correct any errors, and have earned
  a reputation for being the most accurate.
</p>
<h2>Wait, so you don't project winners yourself?</h2>
<p><strong>No!</strong> we allow our users to see the outcome of <em>their</em> projections. We leave the game probability outcome to the experts like 538.</p>
<h2>What sports do you support?</h2>
<p>We support many different sports with varying levels of support. See their sub-sections below for more information</p>
<h3>NFL</h3>
<p>
  The NFL is the most supported sport on this website, and consists of the most complicated tiebreaking machanisms in professional sports, but don't take our word for it -- have a glimpse at the
  <a href="https://www.nfl.com/standings/tiebreakingprocedures">NFL Tiebreaking Procedures</a>
</p>
<p>
  What differentiates us from our competitors on this picker is that we correctly break all game-level ties. This includes Head-to-Head, Common Games, Strength of Schedule, and Strength of Victory. Our major competitors do not break ties after Common
  Games.
</p>
<p>
  We also generate Draft Orders for the NFL based on out tiebreaking engine -- this allows us to correctly seed teams in the post-season on all qualifying criteria from an Algorithmic approach, whereas competitors typically do this by hand which is
  error prone.
</p>
<h3>NBA</h3>
<p></p>
<h3>MLB</h3>
<h2></h2>
