import { AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-freestar',
  templateUrl: './freestar.component.html',
  styleUrls: ['./freestar.component.scss']
})
export class FreestarComponent implements OnInit, AfterViewInit, OnDestroy{
  isAdsFree = false;
  @Input()
  adDimensions: string;
  @Input()
  adName: string;
  @Input()
  renderCloseButton = false;
  isClosed = true;
  constructor(public authService: AuthenticationService) { }
  destroy$ = new Subject<boolean>();
  ngOnInit(): void {
    this.authService.user.pipe(takeUntil(this.destroy$), map((user)=>{
      if(!user.loaded){//loading
        return;
      }
      if (user && user.loaded && user.adsfree == 'true') {
        this.isAdsFree = true;
        // user is ads free and shouldn't be getting any
        window['freestar'] && window['freestar'].deleteAdSlots && window['freestar'].deleteAdSlots()
        document.querySelector(`#${this.adName}`)?.remove();
        document.querySelector('#primisPlayerContainerDiv')?.remove();
      }else{
        this.isAdsFree = false;
        this.isClosed = false;
        window["freestar"].config.enabled_slots.push({ placementName: this.adName, slotId: this.adName });
      }
    }));
  }

  ngAfterViewInit() {
    if (window["freestar"] && window["freestar"].config && this.authService.userValue?.adsfree != 'true') {
      window["freestar"].config.enabled_slots.push({ placementName: this.adName, slotId: this.adName });
    }
  }

  ngOnDestroy(){
    this.destroy$.next(true);
  }
  closeAd(){
    window["freestar"].deletePushdown(this.adName);
    this.isClosed = true;
  }
}
