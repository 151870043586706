import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
  AddMemberToPoolRequest,
  GroupMembersByAdmin,
  League,
  PoolDropdownResponse,
  SeatsForPool,
  UserPickResponse,
} from "@models";
import {
  AuthenticationService,
  PoolService,
  SubscriptionService,
} from "@services/index";
import { LeagueService } from "app/football/nfl/services/league/league.service";
import { FullGroupDetailsResponse } from "app/models/export/pools/full-group-details-response.model";
import { BehaviorSubject, Subject, interval } from "rxjs";
import { debounce, take, takeUntil } from "rxjs/operators";
import { CreatePoolModalComponent } from "../create-pool-modal/create-pool-modal.component";
import { YesNoDialogComponent } from "app/shared/templates/yes-no-dialog/yes-no-dialog.component";
import { InviteUserModalComponent } from "../invite-user-modal/invite-user-modal.component";
import { LoginDialogComponent } from "app/account/templates/login-dialog/login-dialog.component";
import { AvailableSubscriptionsResponse } from "app/models/export/pools/available-subscriptions-response.model";
import { BulkInviteUserModalComponent } from "../bulk-invite-user-modal/bulk-invite-user-modal.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-my-pools",
  templateUrl: "./my-pools.component.html",
  styleUrls: ["./my-pools.component.css"],
})
export class MyPoolsComponent implements OnInit, OnDestroy {
  Object = Object;
  destroy$ = new Subject<boolean>();
  pools: FullGroupDetailsResponse[] = [];
  availableSubscriptions: AvailableSubscriptionsResponse[] = [];
  selectedPool$ = new BehaviorSubject<FullGroupDetailsResponse>(undefined);
  selectedPool: FullGroupDetailsResponse = null;
  registerForm: FormGroup;
  seats = new FormControl("", [Validators.required, Validators.min(10)]);
  memberName = new FormControl("", [Validators.required]);
  groupName = new FormControl("", [Validators.required]);
  league: League;
  isEditing = false;
  public seatsForPool: SeatsForPool;
  matDataSource: MatTableDataSource<GroupMembersByAdmin>;
  refreshPoolMembers = new Subject<boolean>();
  selectedGroupMember: GroupMembersByAdmin = null;
  hasFreePools = false;
  constructor(
    private poolsService: PoolService,
    private leagueService: LeagueService,
    public authService: AuthenticationService,
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private snackBar: MatSnackBar
  ) {}
  get f() {
    return this.registerForm.controls;
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  ngOnInit(): void {
    this.refreshPoolMembers
      .pipe(
        takeUntil(this.destroy$),
        debounce(() => interval(100))
      )
      .subscribe(() => {
        if (this.selectedPool$.value) {
          this.poolsService
            .getGroupMembersByAdmin(this.selectedPool$.value.groupGuid)
            .pipe(take(1))
            .subscribe((poolInfoResponse) => {
              this.matDataSource = new MatTableDataSource(
                poolInfoResponse.groupMembers.map((gm) => {
                  gm.isEditMode = false;
                  return gm;
                })
              );
              this.seatsForPool = poolInfoResponse.seatsForPool;
              if (
                poolInfoResponse.seatsForPool.seatsAllowed == 8 &&
                !this.selectedPool$.value.isFreePool
              ) {
                this.poolsService.refreshMyPools();
              }
            });
        }
      });
    this.selectedPool$.pipe(takeUntil(this.destroy$)).subscribe((group) => {
      if (group !== undefined) {
        this.groupName.setValue(group.groupName);
        this.refreshPoolMembers.next(true);
      }
    });
    this.leagueService.league$.pipe(takeUntil(this.destroy$)).subscribe((l) => {
      this.league = l;
      this.poolsService.getMyPools(l.leagueGuid);
      this.poolsService.getAvailableSubscriptions(l.leagueGuid);
    });
    this.poolsService.myPools$.pipe(takeUntil(this.destroy$)).subscribe((p) => {
      this.pools = p;
      if (this.pools.length == 1) {
        this.selectedPool$.next(this.pools[0]);
      }
      this.hasFreePools = this.pools.filter((p) => p.isFreePool).length >= 1;
      if (this.selectedPool$.value) {
        let updatedPool = this.pools.find(
          (p) => p.groupGuid == this.selectedPool$.value.groupGuid
        );
        this.selectedPool$.next(updatedPool);
      }
    });
    this.poolsService.mySubscriptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((p) => {
        this.availableSubscriptions = p;
      });
  }
  purchaseSubscription() {
    this.subscriptionService
      .purchasePoolItem(this.seats.value, this.league.leagueGuid, this.selectedPool$.value.groupGuid)
      .subscribe((res) => {
        window.open(res.url, "_blank");
      });
  }
  selectPool(pool: FullGroupDetailsResponse) {
    this.selectedPool$.next(pool);
  }
  openInviteUserModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      mode: "single",
      leagueGuid: this.league.leagueGuid,
      groupGuid: this.selectedPool$.value.groupGuid,
    };
    this.dialog
      .open(InviteUserModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.refreshPoolMembers.next(true);
      });
  }
  openBulkInviteUserModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      mode: "bulk",
      leagueGuid: this.league.leagueGuid,
      groupGuid: this.selectedPool$.value.groupGuid,
      seatsAllowed: this.seatsForPool.seatsAllowed,
      seatsUsed: this.seatsForPool.seatsUsed,
    };
    this.dialog
      .open(BulkInviteUserModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.refreshPoolMembers.next(true);
      });
  }
  openCreatePoolModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = { leagueGuid: this.league.leagueGuid };
    this.dialog
      .open(CreatePoolModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {});
  }
  editUser(groupMember: GroupMembersByAdmin) {
    this.isEditing = true;
    groupMember.isEditMode = true;
    this.memberName.setValue(groupMember.memberName);
  }

  updateMemberName(groupMember: GroupMembersByAdmin) {
    if(this.memberName.value === groupMember.memberName){
      groupMember.isEditMode = false;
      this.isEditing = false;
      this.memberName.reset();
      return;
    }
    if (this.memberName.valid) {
      this.poolsService
        .updateMemberOfPool(this.selectedPool$.value.groupGuid, {
          groupMemberId: groupMember.groupMemberId,
          memberName: this.memberName.value,
        })
        .pipe(take(1))
        .subscribe((res) => {
          this.poolsService.refreshMyPools();
          this.isEditing = false;
          this.snackBar.open('User Updated', 'Okay',{
            duration: 800
          })
        });
    }
  }
  cancelUpdate(groupMember: GroupMembersByAdmin) {
    groupMember.isEditMode = false;
    this.memberName.reset();
    this.isEditing = false;
  }
  promptDeleteUser(groupMember: GroupMembersByAdmin) {
    this.dialog
      .open(YesNoDialogComponent, {
        width: "500px",
        data: {
          title: "Confirm Delete",
          message: `Are you sure you want to remove this person from your pool? This will delete all of their selections, and cannot be undone.`,
          yesLinkText: "Yes I'm Sure",
          noLinkText: "Cancel",
        },
      })
      .afterClosed()
      .subscribe((yesNo) => {
        if (yesNo) {
          this.poolsService.removeMemberFromPool(groupMember).subscribe(
            (good) => {
              this.selectPool(this.selectedPool$.value);
            },
            (bad) => {}
          );
        }
      });
  }
  loginClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {});
  }
  assignSubscription(subscription: AvailableSubscriptionsResponse) {
    this.poolsService
      .assignSubscriptionToPool(
        subscription,
        this.selectedPool$.value.groupGuid
      )
      .subscribe(
        (res) => {
          this.poolsService.refreshMyPools();
        },
        (fail) => {}
      );
  }
}
