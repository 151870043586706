import {
  ScoreDict,
  ScoreRecord,
} from "./../../../../models/export/league-response.model";
import { Game, ScoreLiveUpdate, League } from "@models";
import { Injectable } from "@angular/core";
import { UserSettingsService } from "@services/user-settings/user-settings.service";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { LeagueService } from "../league/league.service";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { WeekService } from "../week/week.service";

@Injectable({
  providedIn: "root",
})
export class GameMapService {
  private ws: WebSocket;
  private league: League;
  errors = 0;
  private gameMap: Game[] = [];
  private scoreMap: ScoreDict = {};
  ScoreUpdates = new Subject<ScoreLiveUpdate>();
  private WsConnection = new Subject<string>();

  constructor(
    private userSettings: UserSettingsService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private leagueService: LeagueService,
    private authService: AuthenticationService,
    private weekService: WeekService
  ) {
    this.leagueService.league$.subscribe((l) => {
      this.league = l;
      if (!this.league) {
        return;
      }
      //TODO: Remove This
      let isCurrentLeague = this.league.leagueGuid == environment.currentNflGuid;

      if (
        isCurrentLeague || (
        userSettings.userConfig.nfl.liveUpdatesEnabled &&
        this.league.features.liveUpdatesEnabled)
      ) {
        this.refreshConnection(this.league.leagueGuid, this.league.sportId);
      }
      userSettings.userConfig.hasChangedEvent.subscribe((updates) => {
        if (
          userSettings.userConfig.nfl.liveUpdatesEnabled &&
          this.league.features.liveUpdatesEnabled
        ) {
          this.refreshConnection(this.league.leagueGuid, this.league.sportId);
        }

        if (
          !userSettings.userConfig.nfl.liveUpdatesEnabled ||
          !userSettings.userConfig.nfl.enableScoring
        ) {
          this.ws?.close();
        }
      });

      this.WsConnection.subscribe((conn) => {
        if (this.ws?.readyState === WebSocket.OPEN) {
          return;
        }
        this.ws = new WebSocket(conn);
        window["ws"] = this.ws;
        this.ws.onopen = () => {
          if (this.errors == 0) {
            // this.snackBar.open("Live update module initialized", "dismiss", {
            //   duration: 2000,
            // });
            this.ws.onerror = (err) => {
              console.error(err);
              if (this.errors++ < 3) {
                this.refreshConnection(
                  this.league.leagueGuid,
                  this.league.sportId
                );
              }
            };
            this.ws.onmessage = (event: MessageEvent) => {
              let eventData = <ScoreLiveUpdate>JSON.parse(event.data);

              switch (eventData.typ) {
                case "ScoreUpdatedEvent":
                  if (
                    eventData.lg.toUpperCase() ==
                    this.league.leagueGuid.toUpperCase()
                  ) {
                    if(this.userSettings.userConfig.nfl.enableScoring){
                      this.ScoreUpdates.next(eventData);
                    }
                  }
                  break;
                case "ScheduleLoadedEvent":
                  if (this.league.leagueGuid.toUpperCase() == eventData.lg.toUpperCase() && this.league.isErrored) {
                    // split the refresh time across a 30s interval to prevent the site getting hugged to death
                    setTimeout(() => {
                      this.weekService.currentStandings();
                    }, ~~(Math.random() * 30000));
                  }
                  break;
                default:
                  break;
              }
            };
          }
        };
      });
    });
    this.userSettings.userConfig.hasChangedEvent.subscribe((changed) => {
      if (
        !userSettings.userConfig.nfl.enableScoring ||
        !this.league.features.liveUpdatesEnabled
      ) {
        for (let game of this.gameMap) {
          if (!game) {
            continue;
          }
          if (!game.scores) {
            game.scores = new ScoreRecord();
          }
          if (game.scores && (game.scores.a || game.scores.h)) {
            continue;
          }
        }
      }
    });
  }

  resetGame(gameNum: number) {
    delete this.gameMap[gameNum];
  }
  get afcWcGames() {
    return this.returnGameRange(272, 274);
  }

  get afcDvGames() {
    return this.returnGameRange(278, 279);
  }
  get afcCgGame() {
    const game = this.gameMap[282];
    if (game) {
      return game;
    }
    return null;
  }
  get nfcWcGames() {
    return this.returnGameRange(275, 277);
  }

  get nfcDvGames() {
    return this.returnGameRange(280, 281);
  }

  get nfcCgGame() {
    const game = this.gameMap[283];
    if (game) {
      return game;
    }
    return null;
  }

  get sbGame() {
    const game = this.gameMap[284];
    if (game) {
      return game;
    }
    return null;
  }
  private returnGameRange(start: number, end: number) {
    let ret = [];
    for (let i = start; i <= end; i++) {
      ret.push(this.gameMap[i]);
    }
    return ret.filter((p) => p != null);
  }
  private returnGames(game1: number, game2: number) {
    if (this.gameMap[game1] && this.gameMap[game2]) {
      return [this.gameMap[game1], this.gameMap[game2]];
    }
    return [];
  }

  public getGame(gameNum: number) {
    const game = this.gameMap[gameNum];
    if (game) {
      return game;
    }
    return null;
  }

  public setGame(gameNum: number, game: Game) {
    if (!this.userSettings.userConfig.nfl.enableScoring) {
      game.scores = new ScoreRecord();
      game.scores.a = null;
      game.scores.h = null;
      game.scores.status = null;
    }
    this.gameMap[gameNum] = game;
  }

  public getScore(gameNum: string): ScoreRecord {
    if (this.userSettings.userConfig.nfl.enableScoring) {
      return this.scoreMap[gameNum];
    }
    return null;
  }

  public gamesExist(...gameNums: number[]) {
    let exist = true;
    for (const game of gameNums) {
      exist = exist && !!this.gameMap[game];
    }
    return exist;
  }
  public init(numGames: number) {
    this.gameMap = [];
    for (let i = 0; i < numGames; i++) {
      this.gameMap.push(null);
    }
  }

  private refreshConnection(leagueGuid: string, sportId: number) {
    if (!this.league.features.liveUpdatesEnabled) {
      return;
    }
    let data = {
      leagueGuid,
      sportId,
    };
    this.http
      .post<any>(`${environment.updateEngineUrl}GetConnection`, data, {
        observe: "response",
      })
      .subscribe((conn: any) => {
        if (conn.status == 200) {
          this.WsConnection.next(conn.body.url);
        }
      });
  }
}
