import { UrlSerializerService } from "./../../../../services/url-serializer/url-serializer.service";
import { GameMapService } from "./../game-map/game-map.service";
import { Injectable } from "@angular/core";
import { Game, GameMapType, Team, League } from "@models";
import { Helpers } from "@helpers/helpers";
import { BracketService } from "../bracket/bracket.service";
import { LeagueSettingsService } from "../league-settings/league-settings.service";
@Injectable()
export class PostSeasonService {
  constructor(
    private gameMapService: GameMapService,
    private bracketService: BracketService,
    private urlSerializer: UrlSerializerService,
    private leagueSettings: LeagueSettingsService
  ) {}

  reset(league: League) {
    const resetGames = league.games.filter(
      (p) => p.week > this.leagueSettings.numRegularSeasonWeeks
    );
    for (const game of resetGames) {
      this.gameMapService.resetGame(game.gameScheduleNum);
      this.urlSerializer.setSerializationArrayItem(
        game.gameScheduleNum,
        0,
        false
      );
    }
    league.games = league.games.filter(
      (p) => p.week <= this.leagueSettings.numRegularSeasonWeeks
    );
    this.bracketService.reset();
  }
  seedWildcardGames(
    topTeams1: Team[],
    topTeams2: Team[],
    league: League,
    gameSerializationArray: number[]
  ) {
    // Wipe out current post-season games
    league.games = league.games.filter(
      (p) => p.week <= this.leagueSettings.numRegularSeasonWeeks
    );
    this.seedConf1Wc(topTeams1, league, gameSerializationArray);
    this.seedConf2Wc(topTeams2, league, gameSerializationArray);
  }

  seedConf1Wc(
    topTeams1: Team[],
    league: League,
    gameSerializationArray: number[]
  ): any {
    const wc1 = new Game({
      home: topTeams1[3],
      away: topTeams1[4],
      week: this.leagueSettings.numRegularSeasonWeeks + 1,
      gameScheduleNum: this.leagueSettings.numRegularSeasonGames,
      gameKey: this.leagueSettings.numRegularSeasonGames,
      homeScore: null,
      awayScore: null,
      leagueGuid: league.leagueGuid,
    });
    const wc2 = new Game({
      home: topTeams1[2],
      away: topTeams1[5],
      week: this.leagueSettings.numRegularSeasonWeeks + 1,
      gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 1,
      gameKey: this.leagueSettings.numRegularSeasonGames + 1,
      homeScore: null,
      awayScore: null,
      leagueGuid: league.leagueGuid,
    });

    const wc3 = new Game({
      home: topTeams1[1],
      away: topTeams1[6],
      week: this.leagueSettings.numRegularSeasonWeeks + 1,
      gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 2,
      gameKey: this.leagueSettings.numRegularSeasonGames + 2,
      homeScore: null,
      awayScore: null,
      leagueGuid: league.leagueGuid,
    });

    this.gameMapService.setGame(wc1.gameScheduleNum, wc1);
    this.gameMapService.setGame(wc2.gameScheduleNum, wc2);
    this.gameMapService.setGame(wc3.gameScheduleNum, wc3);
    gameSerializationArray[wc1.gameScheduleNum] = 0;
    gameSerializationArray[wc2.gameScheduleNum] = 0;
    gameSerializationArray[wc3.gameScheduleNum] = 0;
    league.games.push(wc1);
    league.games.push(wc2);
    league.games.push(wc3);
  }
  seedConf2Wc(
    topTeams2: Team[],
    league: League,
    gameSerializationArray: number[]
  ): any {
    const wc1 = new Game({
      home: topTeams2[3],
      away: topTeams2[4],
      week: this.leagueSettings.numRegularSeasonWeeks + 1,
      gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 3,
      gameKey: this.leagueSettings.numRegularSeasonGames + 3,
      homeScore: null,
      awayScore: null,
      leagueGuid: league.leagueGuid,
    });
    const wc2 = new Game({
      home: topTeams2[2],
      away: topTeams2[5],
      week: this.leagueSettings.numRegularSeasonWeeks + 1,
      gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 4,
      gameKey: this.leagueSettings.numRegularSeasonGames + 4,
      homeScore: null,
      awayScore: null,
      leagueGuid: league.leagueGuid,
    });

    const wc3 = new Game({
      home: topTeams2[1],
      away: topTeams2[6],
      week: this.leagueSettings.numRegularSeasonWeeks + 1,
      gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 5,
      gameKey: this.leagueSettings.numRegularSeasonGames + 5,
      homeScore: null,
      awayScore: null,
      leagueGuid: league.leagueGuid,
    });

    this.gameMapService.setGame(wc1.gameScheduleNum, wc1);
    this.gameMapService.setGame(wc2.gameScheduleNum, wc2);
    this.gameMapService.setGame(wc3.gameScheduleNum, wc3);
    gameSerializationArray[wc1.gameScheduleNum] = 0;
    gameSerializationArray[wc2.gameScheduleNum] = 0;
    gameSerializationArray[wc3.gameScheduleNum] = 0;

    league.games.push(wc1);
    league.games.push(wc2);
    league.games.push(wc3);
  }
  seedDivisionGames(
    topTeams1: Team[],
    topTeams2: Team[],
    league: League,
    gameSerializationArray: number[]
  ) {
    league.games = league.games.filter(
      (p) => p.week < this.leagueSettings.numRegularSeasonWeeks + 2
    );
    // afc
    if (
      this.gameMapService.getGame(this.leagueSettings.numRegularSeasonGames)
        ?.selectionMade &&
      this.gameMapService.getGame(this.leagueSettings.numRegularSeasonGames + 1)
        ?.selectionMade &&
      this.gameMapService.getGame(this.leagueSettings.numRegularSeasonGames + 2)
        ?.selectionMade
    ) {
      const dvTeams = [
        this.gameMapService.getGame(this.leagueSettings.numRegularSeasonGames)
          ?.teamThatWon,
        this.gameMapService.getGame(
          this.leagueSettings.numRegularSeasonGames + 1
        )?.teamThatWon,
        topTeams1[0],
        this.gameMapService.getGame(
          this.leagueSettings.numRegularSeasonGames + 2
        )?.teamThatWon,
      ].sort(Helpers.conferenceRankSort);

      const dv1 = new Game({
        home: dvTeams[1],
        away: dvTeams[2],
        week: this.leagueSettings.numRegularSeasonWeeks + 2,
        gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 6,
        gameKey: this.leagueSettings.numRegularSeasonGames + 6,
        homeScore: null,
        awayScore: null,
        leagueGuid: league.leagueGuid,
      });
      const dv2 = new Game({
        home: topTeams1[0],
        away: dvTeams[3],
        week: this.leagueSettings.numRegularSeasonWeeks + 2,
        gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 7,
        gameKey: this.leagueSettings.numRegularSeasonGames + 7,
        homeScore: null,
        awayScore: null,
        leagueGuid: league.leagueGuid,
      });

      this.gameMapService.setGame(dv1.gameScheduleNum, dv1);
      gameSerializationArray[dv1.gameScheduleNum] = 0;

      this.gameMapService.setGame(dv2.gameScheduleNum, dv2);
      gameSerializationArray[dv2.gameScheduleNum] = 0;
      league.games.push(dv1);
      league.games.push(dv2);
    }
    // nfc
    if (
      this.gameMapService.getGame(this.leagueSettings.numRegularSeasonGames + 3)
        ?.selectionMade &&
      this.gameMapService.getGame(this.leagueSettings.numRegularSeasonGames + 4)
        ?.selectionMade &&
      this.gameMapService.getGame(this.leagueSettings.numRegularSeasonGames + 5)
        ?.selectionMade
    ) {
      //nfc dv
      const dvTeams = [
        this.gameMapService.getGame(
          this.leagueSettings.numRegularSeasonGames + 3
        )?.teamThatWon,
        this.gameMapService.getGame(
          this.leagueSettings.numRegularSeasonGames + 4
        )?.teamThatWon,
        topTeams2[0],
        this.gameMapService.getGame(
          this.leagueSettings.numRegularSeasonGames + 5
        )?.teamThatWon,
      ].sort(Helpers.conferenceRankSort);
      const dv1 = new Game({
        home: dvTeams[1],
        away: dvTeams[2],
        week: this.leagueSettings.numRegularSeasonWeeks + 2,
        gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 8,
        gameKey: this.leagueSettings.numRegularSeasonGames + 8,
        homeScore: null,
        awayScore: null,
        leagueGuid: league.leagueGuid,
      });
      const dv2 = new Game({
        home: topTeams2[0],
        away: dvTeams[3],
        week: this.leagueSettings.numRegularSeasonWeeks + 2,
        gameScheduleNum: this.leagueSettings.numRegularSeasonGames + 9,
        gameKey: this.leagueSettings.numRegularSeasonGames + 9,
        homeScore: null,
        awayScore: null,
        leagueGuid: league.leagueGuid,
      });

      this.gameMapService.setGame(dv1.gameScheduleNum, dv1);
      gameSerializationArray[dv1.gameScheduleNum] = 0;

      this.gameMapService.setGame(dv2.gameScheduleNum, dv2);
      gameSerializationArray[dv2.gameScheduleNum] = 0;
      league.games.push(dv1);
      league.games.push(dv2);
    }
  }
  seedConferenceChampionshipGames(
    league: League,
    gameSerializationArray: number[]
  ) {
    league.games = league.games.filter(
      (p) => p.week < this.leagueSettings.numRegularSeasonWeeks + 3
    );
    let afcStart = 278;
    if (
      this.gameMapService.gamesExist(afcStart, afcStart + 1) &&
      this.gameMapService.getGame(afcStart)?.selectionMade &&
      this.gameMapService.getGame(afcStart + 1)?.selectionMade
    ) {
      const cgTeams = [
        this.gameMapService.getGame(afcStart)?.teamThatWon,
        this.gameMapService.getGame(afcStart + 1)?.teamThatWon,
      ].sort(Helpers.conferenceRankSort);
      const cg = new Game({
        home: cgTeams[0],
        away: cgTeams[1],
        week: this.leagueSettings.numRegularSeasonWeeks + 3,
        gameScheduleNum: afcStart + 4,
        gameKey: afcStart + 4,
        homeScore: null,
        awayScore: null,
        leagueGuid: league.leagueGuid,
      });

      this.gameMapService.setGame(cg.gameScheduleNum, cg);
      gameSerializationArray[cg.gameScheduleNum] = 0;
      league.games.push(cg);
    }
    let nfcStart = 280;
    if (
      this.gameMapService.gamesExist(nfcStart, nfcStart + 1) &&
      this.gameMapService.getGame(nfcStart)?.selectionMade &&
      this.gameMapService.getGame(nfcStart + 1)?.selectionMade
    ) {
      const cgTeams = [
        this.gameMapService.getGame(nfcStart)?.teamThatWon,
        this.gameMapService.getGame(nfcStart + 1)?.teamThatWon,
      ].sort(Helpers.conferenceRankSort);
      const cg = new Game({
        home: cgTeams[0],
        away: cgTeams[1],
        week: this.leagueSettings.numRegularSeasonWeeks + 3,
        gameScheduleNum: nfcStart + 3,
        gameKey: nfcStart + 3,
        homeScore: null,
        awayScore: null,
        leagueGuid: league.leagueGuid,
      });

      this.gameMapService.setGame(cg.gameScheduleNum, cg);
      gameSerializationArray[cg.gameScheduleNum] = 0;
      league.games.push(cg);
    }
  }
  seedSuperbowl(league: League, gameSerializationArray: number[]) {
    league.games = league.games.filter(
      (p) => p.week < this.leagueSettings.numRegularSeasonWeeks + 4
    );
    let start = 282;
    if (
      this.gameMapService.gamesExist(start, start + 1) &&
      this.gameMapService.getGame(start)?.selectionMade &&
      this.gameMapService.getGame(start + 1)?.selectionMade
    ) {
      const sbOpts: any = {
        week: this.leagueSettings.numRegularSeasonWeeks + 4,
        gameScheduleNum: start + 2,
      };
      // AFC SB
      if (league.superbowlYear % 2 === 0) {
        sbOpts.home = this.gameMapService.getGame(start).teamThatWon;
        sbOpts.away = this.gameMapService.getGame(start + 1).teamThatWon;
      } else {
        // NFC SB
        sbOpts.away = this.gameMapService.getGame(start).teamThatWon;
        sbOpts.home = this.gameMapService.getGame(start + 1).teamThatWon;
      }
      const sb = new Game(sbOpts);
      sb.leagueGuid = league.leagueGuid;
      sb.gameKey = sb.gameScheduleNum.toString();
      this.gameMapService.setGame(sb.gameScheduleNum, sb);
      gameSerializationArray[sb.gameScheduleNum] = 0;
      league.games.push(sb);
    }
  }
}
