import { CommonGamesMethodology } from './CommonGamesMethodology';
import { StrengthOfScheduleMethodology } from './StrengthOfScheduleMethodology';
import { UndefinedDenominatorBehavior } from './UndefinedDenominatorBehavior';
import { interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';

export abstract class SchedulePickerConfig {
  abstract get divToggle(): boolean;
  abstract set divToggle(val: boolean);
  abstract get stickyColumns();
  abstract set stickyColumns(val: boolean);
}

export class SportConfig extends SchedulePickerConfig {
  hasChangedEvent: Subject<boolean>;
  constructor(emitter: Subject<boolean>) {
    super();
    this.hasChangedEvent = emitter;
  }
  private _divToggle = true;
  private _stickyColumns = false;
  get divToggle() {
    return this._divToggle;
  }
  set divToggle(val) {
    this._divToggle = val;
    this.hasChangedEvent.next(true);
  }

  get stickyColumns() {
    return this._stickyColumns;
  }
  set stickyColumns(val) {
    this._stickyColumns = val;
    this.hasChangedEvent.next(true);
  }
}
export class NBAConfig extends SportConfig {}
export class OWLConfig extends SportConfig {}
export class MLBConfig extends SportConfig {}

export class NFLConfig extends SportConfig {
  hasChangedEvent: Subject<boolean>;
  private _sosMethodology = StrengthOfScheduleMethodology.GamesMustBePlayed;
  get sosMethodology(){
    return this._sosMethodology;
  }
  set sosMethodology(val){
    this._sosMethodology = val;
    this.hasChangedEvent.next(true);
  }
  private _reorderDivisionStandings = true;
  get reorderDivisionStandings(){
    return this._reorderDivisionStandings;
  }
  set reorderDivisionStandings(val){
    this._reorderDivisionStandings = val;
    this.hasChangedEvent.next(true);
  }
  private _liveUpdatesEnabled = false;
  get liveUpdatesEnabled() {
    return this._liveUpdatesEnabled;
  }
  set liveUpdatesEnabled(val) {
    this._liveUpdatesEnabled = val;
    this.hasChangedEvent.next(true);
  }

  private _updateGameOutcomesWithScoreUpdate = false;
  get updateGameOutcomesWithScoreUpdate(){
    return this._updateGameOutcomesWithScoreUpdate;
  }
  set updateGameOutcomesWithScoreUpdate(val){
    this._updateGameOutcomesWithScoreUpdate = val;
    this.hasChangedEvent.next(true);
  }

  private _commonGamesMethodology = CommonGamesMethodology.requireGamesBePlayed;
  get commonGamesMethodology() {
    return this._commonGamesMethodology;
  }
  set commonGamesMethodology(val) {
    this._commonGamesMethodology = val;
    this.hasChangedEvent.next(true);
  }
  private _treatNonPlayoffTeamsLikeWildcards = true;
  get treatNonPlayoffTeamsLikeWildcards() {
    return this._treatNonPlayoffTeamsLikeWildcards;
  }
  set treatNonPlayoffTeamsLikeWildcards(val) {
    this._treatNonPlayoffTeamsLikeWildcards = val;
    this.hasChangedEvent.next(true);
  }

  private _enableScoring = true;
  get enableScoring(){
    return this._enableScoring;
  }
  set enableScoring(val){
    this._enableScoring = val;
    this.hasChangedEvent.next(true);
  }

  constructor(emitter: Subject<boolean>) {
    super(emitter);
    this.hasChangedEvent = emitter;
    this.hasChangedEvent.pipe(debounce(()=>interval(200))).subscribe((e)=>{
      if(this.enableScoring === false && this.liveUpdatesEnabled === true){
        this.liveUpdatesEnabled = false;
      }
      if(this.enableScoring === false && this.updateGameOutcomesWithScoreUpdate === true){
        this.updateGameOutcomesWithScoreUpdate = false;
      }
    })
  }
}
export class MathConfig {
  hasChangedEvent: Subject<boolean>;
  constructor(emitter: Subject<boolean>) {
    this.hasChangedEvent = emitter;
  }
  private _undefinedDenominatorBehavior = UndefinedDenominatorBehavior.coerceToNegative;
  get undefinedDenominatorBehavior() {
    return this._undefinedDenominatorBehavior;
  }
  set undefinedDenominatorBehavior(val) {
    this._undefinedDenominatorBehavior = val;
    this.hasChangedEvent.next(true);
  }
}

export class UserConfig {
  private _nightmodeEnabled = false;
  get nightmodeEnabled() {
    return this._nightmodeEnabled;
  }
  set nightmodeEnabled(val) {
    this._nightmodeEnabled = val;
    this.hasChangedEvent.next(true);
  }

  nba: SportConfig;
  nfl: NFLConfig;
  mlb: SportConfig;
  owl: SportConfig;
  math: MathConfig;
  hasChangedEvent: Subject<boolean> = new Subject<boolean>();
  constructor() {
    this.nba = new SportConfig(this.hasChangedEvent);
    this.nfl = new NFLConfig(this.hasChangedEvent);
    this.mlb = new SportConfig(this.hasChangedEvent);
    this.owl = new SportConfig(this.hasChangedEvent);
    this.math = new MathConfig(this.hasChangedEvent);
    // nightmodeEnabled: false,
    //   mlb: {
    //     divToggle: true,
    //     HideStatus: false,
    //     stickyColumns: false
    //   },
    //   nba: {
    //     divToggle: true,
    //     HideStatus: false,
    //     stickyColumns: false
    //   },
    //   owl: {
    //     divToggle: true,
    //     HideStatus: false,
    //     stickyColumns: false
    //   },
    //   nfl: {
    //     divToggle: true,
    //     commonGamesMethodology: commonGamesMethodology.requireGamesBePlayed,
    //     treatNonPlayoffTeamsLikeWildcards: true,
    //     HideStatus: false,
    //     stickyColumns: false
    //   },
    //   math: {
    //     undefinedDenominatorBehavior:
    //       undefinedDenominatorBehavior.coerceToNegative
    //   }
  }
}
