import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { SubscriptionService } from '@services/subscription/subscription.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginDialogComponent } from 'app/account/templates/login-dialog/login-dialog.component';

@Component({
  selector: 'app-hate-ads',
  templateUrl: './hate-ads.component.html',
  styleUrls: ['./hate-ads.component.css']
})
export class HateAdsComponent implements OnInit {
  constructor(public authService: AuthenticationService, private subscriptionService: SubscriptionService,   
    private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngOnSubmit(form: NgForm){
    let priceId = `${!environment.production ? 'test-' : ''}${form.value["priceId"]}`
    this.subscriptionService.purchaseItem(priceId, form.value["mode"])
      .subscribe(res => {
        window.location.href = res.url;
      });
  }

  showLoginModal(){
    const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginDialogComponent, dialogConfig)
  }

}
