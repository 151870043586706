import { LeagueSettingsService } from './../../services/league-settings/league-settings.service';
import { LeagueService } from './../../services/league/league.service';
import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Team } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-conference-standings',
  templateUrl: './conference-standings.component.html',
  styleUrls: ['./conference-standings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConferenceStandingsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  @Input()
  conference: string;

  numPlayoffTeams: number = this.leagueSettings.numPlayoffTeamsPerConference;
  dataSource: MatTableDataSource<Team>;

  sortedTeams: Team[];
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  constructor(private leagueService: LeagueService, private leagueSettings: LeagueSettingsService) { }
  ngOnInit() {
    this.leagueService.league$.pipe(takeUntil(this.destroy$)).subscribe((league) => {
      this.dataSource = new MatTableDataSource(this.sortedTeams)
      const sorted = league.dataDict[this.conference].sort((a, b) => a.conferenceRank - b.conferenceRank)
      this.dataSource = new MatTableDataSource([...sorted])
    })
    this.leagueService.standingsSet$.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(() => {
      const sorted = this.leagueService.league$.value.dataDict[this.conference].sort((a, b) => a.conferenceRank - b.conferenceRank)

      this.dataSource = new MatTableDataSource([...sorted])
    });
    
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  selectTeam(team) {
    this.leagueService.selectedTeam$.next(team);
  }
}
