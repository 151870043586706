import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { LeagueDropdownResponse } from "@models";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PoolService } from "@services/index";

@Component({
  selector: "app-invite-user-modal",
  templateUrl: "./invite-user-modal.component.html",
  styleUrls: ["./invite-user-modal.component.css"],
})
export class InviteUserModalComponent implements OnInit, OnDestroy {
  error = "";
  addMemberForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    memberName: new FormControl("", [Validators.required]),
  });
  get fc() {
    return this.addMemberForm.controls;
  }
  groupGuid: string;
  loading = false;
  submitted = false;
  destroy$ = new Subject<boolean>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InviteUserModalComponent>,
    private router: Router,
    private poolService: PoolService,
    private snackBar: MatSnackBar
  ) {
    this.groupGuid = data.groupGuid;
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  onSubmit() {
    // stop here if form is invalid
    if (this.addMemberForm.valid) {
      this.submitted = true;
      this.loading = true;
      this.poolService
        .addMemberToPool(this.groupGuid, {
          email: this.fc.email.value,
          memberName: this.fc.memberName.value,
        })
        .subscribe(
          () => {
            //this.poolService.getMyPools(this.leagueGuid);
            this.snackBar.open("Member Invited!", "dismiss", {
              duration: 2000,
            });
            this.dialogRef.close();
          },
          (errorResponse) => {
            switch (errorResponse.error.message) {
              case "You are already in your own pool":
                {
                  this.snackBar.open(errorResponse.error.message, "dismiss", {
                    duration: 2000,
                  });

                  this.dialogRef.close();
                }
                break;
              default:
                this.error = "An error occurred";
                this.loading = false;
            }
          }
        );
    }
  }
  onCancel() {
    this.dialogRef.close();
  }
}
