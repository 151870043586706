import { WeekService } from './nfl/services/week/week.service';
import { PlayoffsService } from './nfl/services/playoffs/playoffs.service';
import { ConferenceScoresComponent } from './nfl/templates/conference-scores/conference-scores.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TiebreakerService } from './nfl/services/tiebreaker/tiebreaker.service';
import { SortByConferenceRankPipe } from '@helpers/sortByConferenceRank.pipe';
import { FootballRoutingModule } from './football.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NflComponent } from './nfl/nfl/nfl.component';
import { ServicesModule } from '@services/services.module';
import { MatModule } from '../material/mat.module';
import { PickerChannelComponent } from './nfl/templates/picker-channel/picker-channel.component';
import { SelectedTeamComponent } from './nfl/templates/selected-team/selected-team.component';
import { ConferenceStandingsComponent } from './nfl/templates/conference-standings/conference-standings.component';
import { ControlBarComponent } from './nfl/templates/control-bar/control-bar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TeamScheduleComponent } from './nfl/templates/team-schedule/team-schedule.component';
import { ScoresComponent } from './nfl/templates/scores/scores.component';
import { SettingsComponent } from './nfl/templates/settings/settings.component';
import { DivisionStandingsComponent } from './nfl/templates/division-standings/division-standings.component';
import { PostSeasonService } from './nfl/services/post-season/post-season.service';
import { PlayoffPictureComponent } from './nfl/playoff-picture/playoff-picture.component';
import { PlayoffGameComponent } from './nfl/templates/playoff-game/playoff-game.component';
import { LeagueService } from './nfl/services/league/league.service';
import { FootballComponent } from './football/football.component';
import { BracketComponent } from './nfl/templates/bracket/bracket.component';
import { BracketUploadComponent } from './nfl/bracket-upload/bracket-upload.component';
import { ScheduleControlBarComponent } from './nfl/templates/schedule-control-bar/schedule-control-bar.component';
import { DialogComponent } from './nfl/templates/dialog/dialog.component';
import { ScorerComponent } from './nfl/scorer/scorer.component';
import { LeaderBoardComponent } from './nfl/templates/pools/leader-board/leader-board.component';
import { UserPicksComponent } from './nfl/templates/pools/user-picks/user-picks.component';
import { ResultingComponent } from './nfl/templates/pools/resulting/resulting.component';
import { LeagueStylesComponent } from './nfl/templates/league-styles/league-styles.component';
import { ByesComponent } from './nfl/templates/byes/byes.component';
import { OrdinalPipe } from '@helpers/ordinal.pipe';
import { DraftOrderComponent } from './nfl/templates/draft-order/draft-order.component';
import { PlayoffPoolComponent } from './nfl/playoff-pool/playoff-pool.component';
import { AddSubmissionModalComponent } from './nfl/add-submission-modal/add-submission-modal.component';
import { CreatePoolModalComponent } from './nfl/templates/pools/create-pool-modal/create-pool-modal.component';
import { EditPoolModalComponent } from './nfl/templates/pools/edit-pool/edit-pool.component';
import { LegendComponent } from './nfl/templates/legend/legend.component';
import { PoolComponent } from './nfl/templates/pools/pool/pool.component';
import { MyPoolsComponent } from './nfl/templates/pools/my-pools/my-pools.component';
import { InviteUserModalComponent } from './nfl/templates/pools/invite-user-modal/invite-user-modal.component';
import { PoolsComponent } from './nfl/pools/pools.component';
import { PlaysheetPostsComponent } from './nfl/templates/playsheet-posts/playsheet-posts.component';
import { BulkInviteUserModalComponent } from './nfl/templates/pools/bulk-invite-user-modal/bulk-invite-user-modal.component';
@NgModule({
  providers: [TiebreakerService, PostSeasonService, PlayoffsService, LeagueService, WeekService],
  imports: [MatModule, CommonModule, FootballRoutingModule, ServicesModule, SharedModule, FlexLayoutModule, FormsModule, ReactiveFormsModule],
  declarations: [
    NflComponent,
    OrdinalPipe,
    SortByConferenceRankPipe,
    PickerChannelComponent,
    SelectedTeamComponent,
    ConferenceStandingsComponent,
    ControlBarComponent,
    TeamScheduleComponent,
    ScoresComponent,
    ConferenceScoresComponent,
    SettingsComponent,
    DivisionStandingsComponent,
    PlayoffPictureComponent,
    PlayoffGameComponent,
    FootballComponent,
    BracketComponent,
    BracketUploadComponent,
    ScheduleControlBarComponent,
    DialogComponent,
    ScorerComponent,
    LeaderBoardComponent,
    UserPicksComponent,
    ResultingComponent,
    LeagueStylesComponent,
    ByesComponent,
    DraftOrderComponent,
    PlayoffPoolComponent,
    AddSubmissionModalComponent,
    CreatePoolModalComponent,
    EditPoolModalComponent,
    LegendComponent,
    PoolComponent,
    MyPoolsComponent,
    InviteUserModalComponent,
    BulkInviteUserModalComponent,
    PoolsComponent,
    PlaysheetPostsComponent
  ],
  exports: [SortByConferenceRankPipe]
})
export class FootballModule {}
