import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SetNameDialogComponent } from '../set-name-dialog/set-name-dialog.component';

@Component({
  selector: 'app-set-name-error',
  templateUrl: './set-name-error.component.html',
  styleUrls: ['./set-name-error.component.css']
})
export class SetNameErrorComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SetNameDialogComponent>) { }

  ngOnInit(): void {
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

}
