import { PlayoffsService } from './../services/playoffs/playoffs.service';
import { Component, OnInit, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { League, PlayoffGame } from '@models';
import { LeagueService } from '../services/league/league.service';
import { SeoService } from '@services/seo/seo.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';
@Component({
  selector: 'app-playoff-picture',
  templateUrl: './playoff-picture.component.html',
  styleUrls: ['./playoff-picture.component.scss']
})
export class PlayoffPictureComponent implements OnInit, OnDestroy {
  saveUrl: string;
  shareUrl: string;
  enableScoring = false;
  destroy$ = new Subject<boolean>();
  playoffGames = {};
  sbHomeScore: number;
  sbAwayScore: number;
  @ViewChild("copyTooltip")copyTooltip: MatTooltip;
  public league: League;
  constructor(private route: ActivatedRoute, private playoffsService: PlayoffsService, private leagueService: LeagueService,
    private seoService: SeoService, private clipboard: Clipboard) {}

  ngOnInit() {
    this.route.paramMap
    .pipe(
      map((params: ParamMap) => {
        return {
          leagueGuid: params.get("leagueGuid")
        };
      }),
      switchMap((params) => {
        return this.leagueService.LoadInitialLeague(
          params.leagueGuid,
          true,
          'official'
        );
      }),
      takeUntil(this.destroy$)
    )
    .subscribe(() => {});
    this.leagueService.league$.subscribe(l => {
      if (!l) {
        return;
      }
      this.seoService.updateDescription(
        `NFL Playoff Picture - Pick and fill out every NFL Playoff Game on the Playoff Predictors Playoff Bracket for the ${l.leagueName} NFL Season`
      );
      this.seoService.updateTitle(`${l.leagueName} - Playoff Picture - Fillable Playoff Bracket - Playoff Predictors`)
      this.playoffsService.getPlayoffs(l);
    })

    this.playoffsService.playoffs$.pipe(takeUntil(this.destroy$)).subscribe((res: League) => {
      this.league = this.playoffsService.league;
      this.playoffGames = this.playoffsService.playoffGames;

      const sb = this.playoffGames['sb'];
      if(sb){
      sb.homeScore = this.sbHomeScore;
      sb.awayScore = this.sbAwayScore;
      }
      if (this.saveUrl) {
        this.load(this.saveUrl);
      }else{
        if(res != null){
          this.load(this.league.playoffString);
        }
        else{
          this.playoffsService.updateUrl();
        }
      }
    });
    this.route.queryParamMap
      .pipe(
        map((params: ParamMap) => {
          let mapped = {
            saveUrl: params.get("L"),
            enableScoring: !!params.get("es"),
            sbAwayScore: parseInt(params.get('sbawayscore') || '0', 10),
            sbHomeScore: parseInt(params.get('sbhomescore') || '0', 10)
          };


          this.saveUrl = mapped.saveUrl || '0000000000000';
          this.enableScoring = mapped.enableScoring;
          this.sbAwayScore = mapped.sbAwayScore;
          this.sbHomeScore = mapped.sbHomeScore;
          this.load(this.saveUrl);
          return mapped;
        }),
        takeUntil(this.destroy$)
      ).subscribe(()=>{});
    this.playoffsService.updateUrl$.pipe(takeUntil(this.destroy$))
    .subscribe(url => {
      this.shareUrl = url;
    })
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  load(saveUrl: string){
    this.playoffsService.load(saveUrl);
  }
  refresh(){
    this.playoffsService.refresh();
  }
  clear(){
    this.playoffsService.clear();
  }
  copyToClipboard(){
    const copied = this.clipboard.copy(this.shareUrl);
    if(copied){
      this.copyTooltip.disabled = false;
      this.copyTooltip.show();
      setTimeout(()=>{
        this.copyTooltip.disabled = true;
      }, 500);
    }
   }
  toggleScoring(){
    this.enableScoring = !this.enableScoring;
    this.playoffsService.enableScoring = this.enableScoring;
    this.playoffsService.updateUrl();
  }

  sbScoreUpdated(playoffGame: PlayoffGame){
    this.playoffsService.updateUrl();
  }
}
