<app-alert [alertType]="'info'">
    <p><strong>Heads up!</strong> This feature is slated to become a premium feature in the future, available to <a [routerLink]="['/home/hate-ads']">Premium Tier</a> subscribers only, but feel free to use it until that happens.</p>
</app-alert>
<p>Select a team to see their aggregated predicted performance based on our user's selections since the 2021-2022 NFL Schedule Release<em>*</em></p>
<div class="flex-row column-sm" [formGroup]="range">
<mat-form-field appearance="fill">
  <mat-label>Team</mat-label>
  <mat-select (selectionChange)="teamSelected($event)" formControlName="teamName">
    <mat-option *ngFor="let team of teams" [value]="team.value">
      <app-team-logo [sportType]="1" [teamName]="team.value"></app-team-logo> {{ team.viewValue }} 
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate" (click)="picker.open()">
      <input matStartDate placeholder="Start date" formControlName="start">
      <input matEndDate placeholder="End date" formControlName="end">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker [touchUi]="true"></mat-date-range-picker>
  </mat-form-field>
</div>

  <div class="watermarked">
    <div class="chart" *ngIf="chartOptions">
      <highcharts-chart
      [Highcharts] = "highcharts" 
      [options] = "chartOptions" 
      style = "width: 100%; height: 600px; display: block;">
   </highcharts-chart>
  <p>Daily updates may be delayed for up to 24 hours.</p>
</div>
<p><em>*</em>selections are non-randomized, anonymous. Some selections submitted by users may be excluded.</p>