import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Watchlist } from 'app/models/export/watchlist.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WatchlistService {
  deleteWatchlist(sportId: number, watchlistGuid: string) {
    return this.http.delete(`${environment.leagueApiUrl}user/watchlist/${sportId}/${watchlistGuid}`, {withCredentials: true})
  }
  public watchlists: BehaviorSubject<Watchlist[]> = new BehaviorSubject<Watchlist[]>([]);
  constructor(private http: HttpClient) { }
  updateWatchlist(watchlist: Watchlist, sportId: number) {
    return this.http.post(`${environment.leagueApiUrl}user/watchlist/${sportId}`, watchlist, {withCredentials: true})
  }
  updateSortOrder(newOrder: { id: string; idx: number; }[], sportId: number) {
    return this.http.put(`${environment.leagueApiUrl}user/watchlist/${sportId}`, newOrder, {withCredentials: true});
  }
  getWatchlistsForUser(sportId: number) {
    return this.http.get<Watchlist[]>(`${environment.leagueApiUrl}user/watchlists/${sportId}`, { withCredentials: true }).pipe(
      map(res => {
        this.watchlists.next(res)
      })
    )
  }
}
