import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormControl } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../../../services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginErrorComponent } from '../login-error/login-error.component';

@Component({
  selector: 'app-login-template',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginTemplateComponent implements OnInit, OnDestroy {
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  destroy$ = new Subject<boolean>();
  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private dialog: MatDialog) {}
  username = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]);
  getErrorMessage() {
    return this.username.hasError('required') ? 'You must enter a value' : this.username.hasError('email') ? 'Not a valid email' : '';
  }
  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.authenticationService.user.pipe(takeUntil(this.destroy$)).subscribe(u => {
      if(u.loaded && u.id != null){
        this.router.navigate([this.returnUrl.replace(/%2f/gi, '/')])
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  onSubmit() {
    this.submitted = true;
    this.loading=true;
    // stop here if form is invalid
    if (!(this.username.invalid && this.password.invalid)) {
      this.loading = true;
      this.authenticationService
        .login(this.username.value, this.password.value)
        .pipe(
          first(),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: () => {
            const ret = {};
            let baseUri = this.returnUrl;
            if (this.returnUrl.indexOf('?') !== -1) {
              const split = this.returnUrl.split('?');
              const token = split[1].split('=');
              ret[token[0]] = token[1];
              baseUri = split[0];
            }

            this.router.navigate([baseUri], ret);
          },
          error: error => {
            this.dialog.open(LoginErrorComponent, { width: '250px' });
            this.loading = false;
          }
        });
    }
  }
}
