<ng-container *ngFor="let division of divisionTeams">
  <table mat-table [dataSource]="division.getSource()" matSort>
    <ng-container matColumnDef="rank">
      <th mat-header-cell *matHeaderCellDef style="width: 4em">{{ division.key()[0] }}#</th>
      <td mat-cell *matCellDef="let element">
        <span [class.in-post-season]="element.conferenceRank <= numPlayoffTeams" [class.out-post-season]="element.conferenceRank > numPlayoffTeams" 
          matTooltip="{{ element.RankMethodText }}"> {{ element.conferenceRankText }} </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="team">
      <th mat-header-cell *matHeaderCellDef class="team-name">Team</th>
      <td mat-cell *matCellDef="let element" title="{{ element.regionName }} {{ element.teamName }}">
        <img src="https://static.playoffpredictors.com/nfl/{{ element.teamLogo || element.teamAbbreviation }}.svg" width="32" height="32"/> <span class="team-name" fxHide.lt-xl> {{ element.teamName }} </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="overallRec">
      <th mat-header-cell *matHeaderCellDef>Rec</th>
      <td mat-cell *matCellDef="let element"><app-pct-format [gameRecord]="element.gameRecords.allRecord"></app-pct-format></td
    ></ng-container>

    <ng-container matColumnDef="divRec">
      <th mat-header-cell *matHeaderCellDef>Div</th>
      <td mat-cell *matCellDef="let element"><app-pct-format [gameRecord]="element.gameRecords.divisionRecord"></app-pct-format></td
    ></ng-container>

    <tr mat-header-row *matHeaderRowDef="['rank', 'team', 'overallRec', 'divRec']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['rank', 'team', 'overallRec', 'divRec']" (click)="selectTeam(row)" class="interactable" [class.faded]="row.conferenceRank > numPlayoffTeams"></tr>
  </table>
</ng-container>
