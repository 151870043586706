<footer>
  <div class="flex-fluid">
    <div class="footer-block">
      <h3>Information</h3>
      <ul>
        <li><a [routerLink]="['/home/road-map']">Roadmap</a></li>
        <li><a [routerLink]="['/home/contact-us']">Contact Us</a></li>
        <li><a [routerLink]="['/home/faq']">FAQ</a></li>
        <li><a href="mailto:help@playoffpredictors.com">Need Help?</a></li>
        <li><a [routerLink]="['/home/privacy-policy']">Privacy Policy</a></li>
        <li><a [routerLink]="['/home/accessibility-statement']">Accessibility Statement</a></li>
      </ul>
    </div>
    <div class="footer-block">
      <h3>Community</h3>
      <ul class="social-links">
        <li>
          <a href="https://discord.gg/uFr4xt768Y"> <img alt="discord" title="discord" src="https://static.playoffpredictors.com/site/discord.svg" width="32" height="32"/> <span>Discord</span></a>
        </li>
        <li>
          <a href="https://twitter.com/RayLehnhoff/"> <img alt="twitter" title="twitter" src="https://static.playoffpredictors.com/site/twitter.svg" width="32" height="32"/> <span>@RayLehnhoff</span></a>
        </li>
        <li>
          <a [routerLink]="['/account/settings']"> <img alt="email" title="email" src="https://static.playoffpredictors.com/site/email.svg" width="32" height="32"/> <span>Mailing List</span></a>
        </li>
      </ul>
    </div>
    <div class="footer-block">
      <h3>Become a subscriber!</h3>
      <p class="text-left">Benefits include:</p>
      <ol>
        <li>Supporting the site you love</li>
        <li>
          Not seeing ads for the duration of your subscription; or
          <ol>
            <li>Gaining absolution for using an adblocker</li>
          </ol>
        </li>
        <li>Early access to beta and alpha site features</li>
        <li>Access to subscriber-only features</li>
      </ol>
    </div>
  </div>
  <p>© {{ year }} - PlayoffPredictors.com</p>
</footer>
