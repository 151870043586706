<mat-card>
  <form (submit)="onSubmit()" action="#">
  <mat-card-header> <mat-card-title>Login</mat-card-title> </mat-card-header>
  <mat-card-content>
      <mat-form-field>
        <input matInput placeholder="Email" [formControl]="username" required type="text" />
        <mat-error *ngIf="username.invalid"> <span *ngIf="username.hasError('required')"> Username is required </span> <span *ngIf="username.hasError('email')"> Username must be an email. </span> </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Password" type="password" [formControl]="password" required autocomplete="current-password" />
        <mat-error> <span *ngIf="password.hasError('required')"> Password is required </span> </mat-error>
      </mat-form-field>
  </mat-card-content>
  <mat-card-actions> <button type="submit" mat-raised-button [disabled]="username.invalid || password.invalid || loading" (keyup.enter)="onSubmit()" (click)="onSubmit()" color="accent">{{ loading ? 'logging you in...' : 'Login' }}</button> 
    <mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>
  </mat-card-actions>
  <mat-card-footer> <button mat-button [routerLink]="'/account/recover'">Forgot your password?</button> </mat-card-footer>
  </form>
</mat-card>
