import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin.routing';
import { HomeComponent } from './home/home.component';
import { MatModule } from '../material/mat.module';

@NgModule({
  providers: [],
  imports: [CommonModule, AdminRoutingModule, MatModule],
  declarations: [HomeComponent]
})
export class AdminModule {}
