import { Component, OnInit } from '@angular/core';
import { WeekService } from '../../services/week/week.service';

@Component({
  selector: 'app-byes',
  templateUrl: './byes.component.html',
  styleUrls: ['./byes.component.css']
})
export class ByesComponent implements OnInit {

  constructor(public weekService: WeekService) { }

  ngOnInit(): void {
  }

}
