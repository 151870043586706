<h2>Settings</h2>

<app-set-name></app-set-name>
<app-email-preferences></app-email-preferences>
<mat-card>
  <mat-card-header> <mat-card-title>Change Password</mat-card-title> </mat-card-header>
  <mat-card-content>
    <form (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input matInput placeholder="Old Password" type="password" [formControl]="oldPassword" required />
        <mat-error>
          <p *ngIf="oldPassword.hasError('required')">Password is required</p>
          <p *ngIf="oldPassword.hasError('minlength')">A minimum length of 8 characters is required</p>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        ><input matInput placeholder="New Password" type="password" [formControl]="newPassword" required />
        <mat-error>
          <p *ngIf="newPassword.hasError('required')">Password is required</p>
          <p *ngIf="newPassword.hasError('minlength')">A minimum length of 8 characters is required</p>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions> <button mat-raised-button [disabled]="oldPassword.invalid || newPassword.invalid" (click)="onSubmit()" color="accent">Reset</button> </mat-card-actions>
</mat-card>
