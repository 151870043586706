<ng-container *ngIf="!authService.isLoggedIn">
  <p>
    Please
    <button mat-button mat-flat-button (click)="loginClick()">login</button> to
    manage pools
  </p>
</ng-container>
<ng-container *ngIf="!league.features.allowsPoolCreation">
  <span>This season is closed for pool creation or modification</span>
</ng-container>
<ng-container *ngIf="pools.length == 0 && authService.isLoggedIn && league.features.allowsPoolCreation">
  You don't have any pools. Want to
  <button mat-raised-button color="primary" (click)="openCreatePoolModal()">
    Create One For Free</button
  >?
</ng-container>

<ng-container *ngIf="pools.length && authService.isLoggedIn && league.features.allowsPoolCreation">
  <div class="button-row">
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
      <ng-container *ngIf="selectedPool$.value?.groupGuid == undefined">
        <span> Select Pool</span>
      </ng-container>
      <ng-container *ngIf="selectedPool$?.value?.groupGuid">
        <span> {{ selectedPool$?.value?.groupName }}</span>
      </ng-container>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!-- <ng-container *ngIf="!authService.isLoggedIn">
          <button mat-menu-item (click)="showLoginModal()">
              <mat-icon> login </mat-icon>
              Log in to Submit
            </button>
          </ng-container> -->
      <ng-container>
        <button
          mat-menu-item
          *ngFor="let item of pools"
          (click)="selectPool(item)"
        >
          <ng-container [ngSwitch]="item.privacyLevel">
            <mat-icon *ngSwitchCase="1">public</mat-icon>
            <mat-icon *ngSwitchCase="2">lock</mat-icon>
            <mat-icon *ngSwitchCase="3">close</mat-icon>
          </ng-container>
          <span> {{ item.groupName }}</span>
        </button>
      </ng-container>
    </mat-menu>
    <button
      mat-raised-button
      color="primary"
      (click)="openCreatePoolModal()"
      [disabled]="hasFreePools"
    >
      <ng-container *ngIf="hasFreePools"> Free Limit Reached </ng-container>
      <ng-container *ngIf="!hasFreePools"> Create Free Pool </ng-container>
    </button>
    <ng-container *ngIf="hasFreePools">
      <p style="color: red">
        Free Pool limit reached. You may only have one free pool per season.
      </p>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="selectedPool$?.value != null">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>What is this?</mat-expansion-panel-header>
      <p>
        This module allows you to create your own Pick'Em Pool within Playoff
        Predictors. Each pool lasts for one season, and allows users to pick
        every game of the regular season, as well as the post-season.

        <!--Post
        Season submissions will be available when the playoff seeds are
        finalized.-->
      </p>
      <p>
        During the regular season, pool participants are awarded 1 point for
        getting a pick correct. No points are awarded for ties.
      </p>
      <!--
      <p>
        During the post-season, the pool's configuration determines points
        awarded.
      </p>
      -->
      <p>A full announcement and FAQ can be read <a href="https://playsheet.playoffpredictors.com/2023/09/04/announcements/introducing-playoff-predictors-nfl-pick-em-pools" target="_blank">here</a></p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header> Edit Pool </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-edit-pool [poolGuid]="selectedPool$"></app-edit-pool>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        Seat Usage {{ seatsForPool?.seatsUsed }} of
        {{ seatsForPool?.seatsAllowed }}
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container *ngIf="selectedPool$.value.isFreePool">
          <p>
            Run a larger pool? Purchase a subscription for a one-time fee of
            $0.85/participant for the entire season.
          </p>

          <p>
            You may create a single pool of up to 8 participants for free each
            season. If you run larger pools, you may purchase additional seats
            for the low cost of $0.85/participant. You must purchase at least 10
            seats to create a paid pool.
          </p>
          <p>
            When you purchase a seat, the seats are <strong>not</strong> added
            to your free seats. Purchasing 10 seats allow a pool of up to 10
            people.
          </p>
          <p>A full FAQ can be read <a href="https://playsheet.playoffpredictors.com/2023/09/04/announcements/introducing-playoff-predictors-nfl-pick-em-pools" target="_blank">here</a></p>
          <form>
            <mat-form-field appearance="fill">
              <mat-label>Seats</mat-label>
              <input
                type="number"
                placeholder="Seats"
                matInput
                [formControl]="seats"
              />
              <mat-error *ngIf="seats.invalid">
                <p *ngIf="seats.hasError('required')">Field is required</p>
                <p *ngIf="seats.hasError('min')">
                  Minimum 10 seats required for purchase
                </p>
              </mat-error>
            </mat-form-field>
            <div>
              <p>Clicking purchase will open a new window to Stripe</p>
              <p *ngIf="seats?.value >= 10">
                Price: ${{ (seats.value * 0.85).toFixed(2) }}
              </p>
              <button
                mat-button
                mat-raised-button
                (click)="purchaseSubscription()"
                color="primary"
                [disabled]="seats.invalid"
              >
                Purchase
              </button>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="!selectedPool$.value.isFreePool">
          <p>
            Subscription applied. Need more seats? Contact
            <a href="mailto:help@playoffpredictors.com">Support</a> for
            assistance
          </p>
        </ng-container>
        <ng-container
          *ngIf="
            availableSubscriptions.length > 0 && selectedPool$.value.isFreePool
          "
        >
          <h3>Available Subscriptions</h3>
          <ul>
            <li
              *ngFor="let sub of availableSubscriptions"
              style="margin: 1.5em 0"
            >
              {{ sub.seats }} seats, purchased on {{ sub.purchasedOn | date }}
              <button
                (click)="assignSubscription(sub)"
                color="accent"
                mat-raised-button
              >
                Assign to This Pool
              </button>
            </li>
          </ul>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
  <h3>Pool Members</h3>
  <ng-container>
    <div class="button-row">
      <button
        mat-raised-button
        color="secondary"
        [disabled]="seatsForPool?.seatsUsed == seatsForPool?.seatsAllowed"
        (click)="openInviteUserModal()"
      >
        <ng-container
          *ngIf="seatsForPool?.seatsUsed == seatsForPool?.seatsAllowed"
        >
          Maximum Seats Used
        </ng-container>
        <ng-container
          *ngIf="seatsForPool?.seatsUsed < seatsForPool?.seatsAllowed"
        >
          Invite
        </ng-container>
      </button>
      <button
        mat-raised-button
        color="secondary"
        [disabled]="seatsForPool?.seatsUsed == seatsForPool?.seatsAllowed"
        (click)="openBulkInviteUserModal()"
      >
        <ng-container
          *ngIf="seatsForPool?.seatsUsed == seatsForPool?.seatsAllowed"
        >
          Maximum Seats Used
        </ng-container>
        <ng-container
          *ngIf="seatsForPool?.seatsUsed < seatsForPool?.seatsAllowed"
        >
          Bulk Invite
        </ng-container>
      </button>
    </div>
  </ng-container>
  <table mat-table [dataSource]="matDataSource">
    <ng-container matColumnDef="memberName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.isEditMode">
          <mat-form-field appearance="fill">
            <mat-label>Member Name</mat-label>
            <input
              type="text"
              matInput
              placeholder="name"
              [value]="element.memberName"
              [formControl]="memberName"
            />
            <mat-error *ngIf="memberName.invalid">
              Value is not a valid
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="!element.isEditMode">
          {{ element.memberName }}
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>
    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="button-row">
          <ng-container
            *ngIf="element.groupMemberId != -1 && element.isEditMode == true"
          >
            <button
              mat-raised-button
              color="primary"
              (click)="updateMemberName(element)"
            >
              <mat-icon>checkmark</mat-icon>
            </button>
            <button
              mat-raised-button
              color="secondary"
              (click)="cancelUpdate(element)"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
          <ng-container
            *ngIf="element.groupMemberId != -1 && element.isEditMode == false"
          >
            <button
              [disabled]="isEditing"
              mat-raised-button
              color="primary"
              (click)="editUser(element)"
            >
              <mat-icon>edit</mat-icon>
            </button>

            <button
              mat-raised-button
              color="warn"
              (click)="promptDeleteUser(element)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
        </div>
      </td>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="['memberName', 'email', 'options']"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['memberName', 'email', 'options']"
    ></tr>
  </table>
</ng-container>
