import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { SuccessComponent } from "./success/success.component";
const routes: Routes = [
  {
    path: "billing",
    component: HomeComponent,
    children: [
      {
        path: "",
        children: [
          {
            path: "success",
            component: SuccessComponent,
            children:[
              {
                path: ":session_id",
                component: SuccessComponent,
              },
            ]
          }
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BillingRoutingModule {}
