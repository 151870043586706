import { Subject } from 'rxjs';
import { Team } from './../../../../models/export/team.model';
import { Game, PlayoffGame } from '@models';
import { Helpers } from '@helpers/helpers';
import { GameMapService } from './../game-map/game-map.service';
import { Injectable, ElementRef } from '@angular/core';
import * as Enumerable from 'linq';
import { PlayoffsService } from '../playoffs/playoffs.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BracketService {
  constructor(private gameMapService: GameMapService, private playoffsService: PlayoffsService) {

  }

  private playoffTeams = {
    '4': {},
    '3': {},
    '2': {},
    '1': {},
    '0': {}
  };
  private bracketLength: number;
  private bracketHeight = 45;
  private iterations = 4;
  private useByeWeek = true;
  private forkCount = 0;
  private debugMode = false;
  private bracketScale = 1.5;
  private height = 750;
  private width = 700;
  public reset$ = new Subject<boolean>();
  reset() {
    this.reset$.next(true);
  }
  private orderGames(games: Game[]) {
    return Enumerable.from(games)
      .orderByDescending((p: Game) => (p.teamThatWon && p.teamThatWon.conferenceRank ? p.teamThatWon.conferenceRank : 100))
      .toArray();
  }
  public generateBracketFromPlayoffService(canvas: ElementRef) {
    if (
      canvas &&
      this.playoffsService.isFullBracketPicked
    ) {
      this.forkCount = 0;
      this.playoffsService.playoffGames['sb'].teamSelected.team.conferenceRank = this.playoffsService.playoffGames['sb'].teamSelected.seed;
      this.playoffTeams['0']['0'] = [this.playoffsService.playoffGames['sb'].teamSelected.team]


      var sbTeams = [this.playoffsService.playoffGames['sb'].teamSelected.team, this.playoffsService.playoffGames['sb'].teamThatLost.team]
      this.playoffTeams['4']['0'] = [sbTeams.find(p => p.conference == 'AFC'), sbTeams.find(p => p.conference == 'NFC')]; // sb

      this.playoffTeams['3']['1'] = [this.playoffsService.playoffGames['afc-cg'].teamSelected.team, this.playoffsService.playoffGames['afc-cg'].teamThatLost.team]; // afccg
      this.playoffTeams['3']['8'] = [this.playoffsService.playoffGames['nfc-cg'].teamSelected.team, this.playoffsService.playoffGames['nfc-cg'].teamThatLost.team]; // nfccg

      this.playoffTeams['2']['9'] = [this.playoffsService.playoffGames['nfc-dv-1'].teamSelected.team, this.playoffsService.playoffGames['nfc-dv-1'].teamThatLost.team];
      this.playoffTeams['2']['12'] = [this.playoffsService.playoffGames['nfc-dv-2'].teamSelected.team, this.playoffsService.playoffGames['nfc-dv-2'].teamThatLost.team];

      this.playoffTeams['2']['2'] = [this.playoffsService.playoffGames['afc-dv-1'].teamSelected.team, this.playoffsService.playoffGames['afc-dv-1'].teamThatLost.team];
      this.playoffTeams['2']['5'] = [this.playoffsService.playoffGames['afc-dv-2'].teamSelected.team, this.playoffsService.playoffGames['afc-dv-2'].teamThatLost.team];

      // afc wc
      this.playoffTeams['1']['4'] = [this.playoffsService.playoffGames['afc-wc-2'].teamSelected.team, this.playoffsService.playoffGames['afc-wc-2'].teamThatLost.team];
      this.playoffTeams['1']['7'] = [this.playoffsService.playoffGames['afc-wc-3'].teamSelected.team, this.playoffsService.playoffGames['afc-wc-3'].teamThatLost.team];
      this.playoffTeams['1']['6'] = [this.playoffsService.playoffGames['afc-wc-1'].teamSelected.team, this.playoffsService.playoffGames['afc-wc-1'].teamThatLost.team];

      // nfc wc
      this.playoffTeams['1']['11'] = [this.playoffsService.playoffGames['nfc-wc-1'].teamSelected.team, this.playoffsService.playoffGames['nfc-wc-1'].teamThatLost.team];
      this.playoffTeams['1']['14'] = [this.playoffsService.playoffGames['nfc-wc-2'].teamSelected.team, this.playoffsService.playoffGames['nfc-wc-2'].teamThatLost.team];
      this.playoffTeams['1']['13'] = [this.playoffsService.playoffGames['nfc-wc-3'].teamSelected.team, this.playoffsService.playoffGames['nfc-wc-3'].teamThatLost.team];
      // this.playoffTeams['1']['11'] = [nfcwcordered[0].home, nfcwcordered[0].away]; // 6@3
      // this.playoffTeams['1']['14'] = [nfcwcordered[1].home, nfcwcordered[1].away]; // 5@4
      // this.playoffTeams['1']['13'] = [nfcwcordered[2].home, nfcwcordered[2].away]; // 7@2

      this.renderBrackets(canvas);
      return true;
    } else {
      if (canvas.nativeElement.firstElementChild) {
        canvas.nativeElement.firstElementChild.remove();
      }
      return false;
    }
  }
  public generateBracket(canvas: ElementRef) {
    if (
      canvas &&
      this.gameMapService.afcWcGames.length === 3 &&
      this.gameMapService.nfcWcGames.length === 3 &&
      this.gameMapService.afcDvGames.length === 2 &&
      this.gameMapService.nfcDvGames.length === 2 &&
      this.gameMapService.afcCgGame &&
      this.gameMapService.nfcCgGame &&
      this.gameMapService.sbGame &&
      this.gameMapService.sbGame.selectionMade &&
      [...this.gameMapService.afcWcGames, ...this.gameMapService.nfcWcGames, ...this.gameMapService.afcDvGames, ...this.gameMapService.nfcDvGames, this.gameMapService.afcCgGame, this.gameMapService.nfcCgGame, this.gameMapService.sbGame].every(p => p.selectionMade)

    ) {
      this.forkCount = 0;
      const afcwcordered: any[] = this.orderGames(this.gameMapService.afcWcGames);
      const nfcwcordered: any[] = this.orderGames(this.gameMapService.nfcWcGames);
      this.playoffTeams['0']['0'] = [this.gameMapService.sbGame.teamThatWon]; // winner
      this.playoffTeams['4']['0'] = [this.gameMapService.afcCgGame.teamThatWon, this.gameMapService.nfcCgGame.teamThatWon]; // sb
      this.playoffTeams['3']['1'] = [this.gameMapService.afcDvGames[1].teamThatWon, this.gameMapService.afcDvGames[0].teamThatWon]; // afccg
      this.playoffTeams['3']['8'] = [this.gameMapService.nfcDvGames[1].teamThatWon, this.gameMapService.nfcDvGames[0].teamThatWon]; // nfccg

      this.playoffTeams['2']['9'] = [this.gameMapService.nfcDvGames[1].home, this.gameMapService.nfcDvGames[1].away]; // nfcdv2
      this.playoffTeams['2']['12'] = [this.gameMapService.nfcDvGames[0].home, this.gameMapService.nfcDvGames[0].away]; // nfcdv1

      this.playoffTeams['2']['2'] = [this.gameMapService.afcDvGames[1].home, this.gameMapService.afcDvGames[1].away]; // afcdv2
      this.playoffTeams['2']['5'] = [this.gameMapService.afcDvGames[0].home, this.gameMapService.afcDvGames[0].away]; // afcdv1

      // afc wc
      this.playoffTeams['1']['4'] = [afcwcordered[0].home, afcwcordered[0].away]; // 6@3
      this.playoffTeams['1']['7'] = [afcwcordered[1].home, afcwcordered[1].away]; // 5@4
      this.playoffTeams['1']['6'] = [afcwcordered[2].home, afcwcordered[2].away]; //7@2

      // nfc wc
      this.playoffTeams['1']['11'] = [nfcwcordered[0].home, nfcwcordered[0].away]; // 6@3
      this.playoffTeams['1']['14'] = [nfcwcordered[1].home, nfcwcordered[1].away]; // 5@4
      this.playoffTeams['1']['13'] = [nfcwcordered[2].home, nfcwcordered[2].away]; // 7@2

      this.renderBrackets(canvas);
      return true;
    } else {
      if (canvas.nativeElement.firstElementChild) {
        canvas.nativeElement.firstElementChild.remove();
      }
      return false;
    }
  }

  private renderBrackets(canvasRef: ElementRef) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    try {
      canvas.width = canvasRef.nativeElement.parentElement.parentElement.scrollWidth || this.width;
    } catch (ex) {
      canvas.width = this.width;
    }
    canvas.height = this.height;
    const ctx = canvas.getContext('2d');
    if(ctx.canvas.width == 0){
      return;
    }
    ctx.translate(0.5, 0.5);
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'black';
    ctx.font = '14px helvetica ';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    const x = canvas.width,
    y = canvas.height / 2 + 8;

    this.bracketLength = Math.floor(canvas.width / (this.iterations + 1));
    if(this.debugMode){
      let longestName = '';
      let longestNameLength = 0;
      for(let iter in this.playoffTeams){
        for(let grp in this.playoffTeams[iter]){
          for(let team of this.playoffTeams[iter][grp]){
            let width = ctx.measureText(team.teamName).width + 50;
            if(width > longestNameLength){
              longestNameLength = width;
              longestName = team.teamName;
            }
          }
        }
      }
      console.log('x: "%s"', x);
      console.log('total Bracket Length: "%s"', this.bracketLength * 5);
      console.log('longest name on brackets: "%s" size:"%s"', longestName, longestNameLength);
      console.log('longest name wins out width: %s', longestNameLength * 5)
    }
    this.drawWinner(ctx, x, y);
    this.fork(ctx, x - this.bracketLength, y, this.iterations, this.bracketHeight);
    this.waterMark(ctx, canvas.width, canvas.height);
    if (canvasRef.nativeElement.firstElementChild) {
      canvasRef.nativeElement.firstElementChild.replaceWith(canvas);
    } else {
      canvasRef.nativeElement.append(canvas);
    }
  }

  private drawWinner(ctx: CanvasRenderingContext2D, x, y) {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - this.bracketLength, y);
    this.writeTeam(ctx, x - this.bracketLength, y - 5, 0, 0, 0);
    ctx.stroke();
    ctx.closePath();
  }

  private fork(ctx: CanvasRenderingContext2D, x: number, y: number, iteration: number, height: number) {
    const localCount = this.forkCount;
    this.forkCount++;
    const topOffset = y - height * iteration;
    if(this.debugMode){
      ctx.fillText(iteration + '-' + localCount, x, topOffset-25);

      console.log("x: %s, bracketLength: %s, math: %s", x, this.bracketLength, x-this.bracketLength);
    }
    const canWriteNextBracket = x - this.bracketLength >= this.bracketLength;

    if ((iteration === 1 && localCount==3 || localCount==10 && this.useByeWeek) || x < 0 || topOffset < 0 || iteration < 0) {
      return;
    }
    // top prong
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, topOffset);
    ctx.lineTo(x - this.bracketLength, topOffset);
    ctx.stroke();
    this.writeTeam(ctx, x - this.bracketLength, topOffset - 5, iteration, localCount, 0);
    ctx.closePath();
    if (canWriteNextBracket) {
      this.fork(ctx, x - this.bracketLength, topOffset, iteration - 1, height / this.bracketScale);
    }else if(this.debugMode){
      console.log("Cannot create bracket for %s-%s", iteration, localCount)
    }
    // bottom prong
    const bottomOffset = y + height * iteration;

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, bottomOffset);
    ctx.lineTo(x - this.bracketLength, bottomOffset);
    ctx.stroke();
    this.writeTeam(ctx, x - this.bracketLength, bottomOffset + 22, iteration, localCount, 1);
    ctx.closePath();
    if (canWriteNextBracket) {
      this.fork(ctx, x - this.bracketLength, bottomOffset, iteration - 1, height / this.bracketScale);
    }else if(this.debugMode){
      ctx.fillText(iteration-1 + '-' + localCount, x-this.bracketLength+25, bottomOffset);

      console.log("Cannot create bracket for %s-%s", iteration, localCount)
    }
  }

  private writeTeam(ctx, x, y, iteration, localCount, idx) {
    try {
      x += 5;
      const team: Team = this.playoffTeams[iteration][localCount][idx];
      if(!team){
        return;
      }
      const originalFont = ctx.font;
      const teamWidth = ctx.measureText(team.teamName).width;

      if (teamWidth + 50 < this.bracketLength) {
        ctx.fillText(team.teamName + ' ', x + 32, y);
        ctx.fillText(team.conferenceRank, x + teamWidth + 32, y - 5);
      } else {
        ctx.fillText(team.conferenceRank, x + 32, y - 5);
      }
      ctx.font = '10px helvetica';

      ctx.font = originalFont;
      const logo = new Image();
      logo.src = '/assets/nfl/' + (team.teamLogo || team.teamAbbreviation) + '.svg';
      logo.width = 32;
      logo.height = 32;
      logo.onload = () => {
        ctx.drawImage(logo, x, y - 25, logo.width, logo.height);
      };
    } catch (ex) {
      if (this.debugMode) {
        ctx.fillText(iteration + '-' + localCount, x, y);
      }
    }
  }

  private waterMark(/** @type {CanvasRenderingContext2D} */ ctx, x, y) {
    this.measureAndWrite(ctx, x - 5, y - 26, 'Created by Playoff Predictors');
    this.measureAndWrite(ctx, x - 5, y - 12, 'https://PlayoffPredictors.com/');
    const logo = new Image();
    logo.src = '/assets/pp-color.svg';
    logo.width = 48;
    logo.height = 48;
    logo.onload = () => {
      ctx.drawImage(logo, x - ctx.measureText('https://PlayoffPredictors.com/').width - 48, y - 48, logo.width, logo.height);
    };
  }

  private measureAndWrite(/** @type {CanvasRenderingContext2D} */ ctx, x, y, text) {
    const measurements = ctx.measureText(text);
    ctx.fillText(text, x - measurements.width, y);
  }
}
