import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { AuthenticationService } from "./authentication/authentication.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   */
  constructor(private authService: AuthenticationService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers: HttpHeaders;
    if(!environment.production){
      headers = new HttpHeaders({
        "x-playoffpredictors-id": environment.headerId
      })
      request = request.clone({headers});
    }
    if (request.headers.get("add-jwt") == "false") {
      return next.handle(request);
    }

    // add authorization header with jwt token if available
    if (this.authService.jwtSubject.value) {
      if (request.url.indexOf("localhost") !== -1 && this.authService.isLoggedIn) {
        headers = new HttpHeaders({
          "x-playoffpredictors-email": this.authService.userValue.sub,
          "x-playoffpredictors-userId": this.authService.userValue.id,
          "x-playoffpredictors-adsfree":
            this.authService.isAdsFree.toString(),
          "x-playoffpredictors-beta": this.authService.isBeta.toString(),
          "x-playoffpredictors-en": this.authService.userValue.en,
          "x-playoffpredictors-admin": this.authService.isAdmin.toString(),
          "x-playoffpredictors-confirmed":
            this.authService.userValue.confirmed,
          "x-playoffpredictors-id": environment.headerId
        })
        request = request.clone({headers});
      } else {
        let headers = new HttpHeaders({
          Authorization: `Bearer ${this.authService.jwtSubject.value}`
        });
        request = request.clone({headers});
      }
    }

    return next.handle(request);
  }
}
