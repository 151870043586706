import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  GroupPrivacyLevel,
  League,
  PoolDropdownResponse,
  PoolMemberParticipantResponse,
  SelectionType,
  SubmitPicksToPoolRequest,
  UserPickResponse,
} from "@models";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { ResultingService } from "@services/resulting/resulting.service";
import { LoginDialogComponent } from "app/account/templates/login-dialog/login-dialog.component";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { debounce, map, takeUntil, filter } from "rxjs/operators";
import { LeagueSettingsService } from "../../../services/league-settings/league-settings.service";
import { LeagueService } from "../../../services/league/league.service";
import { WeekService } from "../../../services/week/week.service";
import { PoolService } from "@services/pools/pool.service";
import { SetNameDialogComponent } from "app/account/set-name-dialog/set-name-dialog.component";

@Component({
  selector: "app-user-picks",
  templateUrl: "./user-picks.component.html",
  styleUrls: ["./user-picks.component.css"],
})
export class UserPicksComponent implements OnInit, OnDestroy {
  @Input("selectedPool")
  selectedPool$ = new BehaviorSubject(null);
  selectedPool: PoolDropdownResponse = null;
  selectedWeek: number;
  selectedWeeksPicks: UserPickResponse[];
  userPicks = [];
  weeks = [];
  destroy$ = new Subject<boolean>();
  league: League;
  weekTotalRight: number = 0;
  seasonTotal: number;
  isPublicPool = new BehaviorSubject(false);
  poolMembers = [];
  selectedMember: PoolMemberParticipantResponse;
  selectedMemberName: string;
  isViewingOtherUser = false;
  weekTotalWrong: number = 0;
  constructor(
    public weekService: WeekService,
    private leagueService: LeagueService,
    private snackBar: MatSnackBar,
    public authService: AuthenticationService,
    private dialog: MatDialog,
    private leagueSettings: LeagueSettingsService,
    private poolService: PoolService
  ) {}
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  ngOnInit(): void {
    this.selectedWeek = 1;

    this.isPublicPool.pipe(takeUntil(this.destroy$)).subscribe((isPublic)=>{
      if(!isPublic && this.selectedPool?.groupGuid){
        this.poolService.getGroupMembersByParticipant(this.selectedPool.groupGuid).subscribe(pm => {
          this.poolMembers = pm;
          this.selectedMember = this.poolMembers.find(p => p.userGuid == this.authService.userValue.id);
          this.selectedMemberName = this.selectedMember?.memberName;
        })
      }
    })
    this.selectedPool$
    .pipe(takeUntil(this.destroy$), filter(i=>{
      return this.selectedPool?.groupGuid != i.groupGuid
    }))
    .subscribe((selectedPool: PoolDropdownResponse) => {
      if(!selectedPool || !this.authService.isLoggedIn){
        return;
      }
      this.selectedPool = selectedPool;
      this.isPublicPool.next(selectedPool.privacyLevel === GroupPrivacyLevel.Unrestricted);
      this.poolService
        .getUserPicks(selectedPool.groupGuid)
        .pipe(takeUntil(this.destroy$))
        .subscribe((up) => {
          this.userPicks = up.map((pick) => this.userPickMap(pick));
          this.seasonTotal = up.filter(
            (p) => p.teamThatWon == p.teamPicked
          ).length;
          this.weeks = this.weekService.weeks.map((g) => {
            return { value: g.key(), viewValue: g.key() };
          });
          if (
            this.weekService.week > this.leagueSettings.numRegularSeasonWeeks
          ) {
            this.selectedWeek = this.leagueSettings.numRegularSeasonWeeks;
          } else {
            this.selectedWeek = this.weekService.week;
          }
          this.setSelectedWeekPicks();
        });
    });
    this.leagueService.league$
      .pipe(
        map((r) => r),
        takeUntil(this.destroy$)
      )
      .subscribe((l) => {
        this.league = l;
      });
  }
  private userPickMap(pick: UserPickResponse) {
    if(!this.league){
      return;
    }
    let away = this.league.teams.find((p) => p.teamId == pick.awayId);
    let home = this.league.teams.find((p) => p.teamId == pick.homeId);
    if (pick.actualOutcome == SelectionType.homeWin) {
      pick.teamThatWon = pick.homeId;
      pick.teamThatWonAbbreviation = pick.homeAbbreviation;
    } else if (pick.actualOutcome == SelectionType.awayWin) {
      pick.teamThatWon = pick.awayId;
      pick.teamThatWonAbbreviation = pick.awayAbbreviation;
    } else if (pick.actualOutcome == SelectionType.tie) {
      pick.teamThatWon = -1;
    }
    pick.awayLogo = away.teamLogo || away.teamAbbreviation;
    pick.homeLogo = home.teamLogo || home.teamAbbreviation;

    if (pick.teamThatWon) {
      pick.userRight = pick.teamThatWon == pick.teamPicked;
    }
    if(!this.league.features.allowsPickLocks){
      pick.canUpdate = false;
    }
    return pick;
  }
  selectMember(event: MatSelectChange){
    this.selectedMember = event.value;
    this.selectedMemberName = this.selectedMember?.memberName;
    this.isViewingOtherUser = this.authService.userValue?.id !== this.selectedMember.userGuid;
    this.changeViewingAs();
  }
  changeViewingAs(){
    this.poolService.getOtherUserPicks(this.selectedPool.groupGuid, this.selectedMember.userGuid).subscribe((up)=>{
      this.userPicks = up.map((pick) => this.userPickMap(pick));
          this.seasonTotal = up.filter(
            (p) => p.teamThatWon == p.teamPicked
          ).length;
          this.weeks = this.weekService.weeks.map((g) => {
            return { value: g.key(), viewValue: g.key() };
          });
          if (
            this.weekService.week > this.leagueSettings.numRegularSeasonWeeks
          ) {
            this.selectedWeek = this.leagueSettings.numRegularSeasonWeeks;
          } else {
            this.selectedWeek = this.weekService.week;
          }
          this.setSelectedWeekPicks();
        });
  }
  selectWeek(event: MatSelectChange) {
    this.selectedWeek = event.value;
    this.setSelectedWeekPicks();
  }
  private setSelectedWeekPicks() {
    this.selectedWeeksPicks = this.userPicks
      .filter((g) => g.week == this.selectedWeek)
      .map((g: UserPickResponse) => {
        let ret = g;
        if (!g.actualOutcome) {
          if (ret.teamPicked) {
            ret.teamThatWonAbbreviation =
              ret.teamPicked == ret.homeId
                ? ret.homeAbbreviation
                : ret.awayAbbreviation;
          }
          return ret;
        }
        if (ret.teamThatWon) {
          ret.userRight = ret.teamThatWon == ret.teamPicked;
        }
        return ret;
      })
      .sort((a, b) =>
        a.actualOutcome != null ? -1 : a.gameScheduleNum - b.gameScheduleNum
      );
    this.weekTotalRight = this.selectedWeeksPicks.filter((p) => p.userRight).length;
    console.log(this.selectedWeeksPicks)
    this.weekTotalWrong = this.selectedWeeksPicks.filter((p) => p.actualOutcome != null && !p.userRight).length;
  }

  changePick(game: UserPickResponse, teamId, teamAbbreviation) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    if (this.authService.userValue.en.length == 0) {
      const dialogRef = this.dialog.open(SetNameDialogComponent, dialogConfig);
      dialogRef
        .afterClosed()
        .pipe(debounce(() => interval(250)))
        .subscribe(() => {
          this.changePick(game, teamId, teamAbbreviation);
        });
    } else {
      let currentWon = game.teamThatWonAbbreviation;
      game.teamThatWonAbbreviation = teamAbbreviation;
      let selection = SelectionType.none;
      if (game.awayId == teamId) {
        selection = SelectionType.awayWin;
      } else if (game.homeId == teamId) {
        selection = SelectionType.homeWin;
      }
      let pickRequest: SubmitPicksToPoolRequest = {
        groupGuid: this.selectedPool.groupGuid,
        week: this.selectedWeek,
        results: [
          { gameGuid: game.gameGuid, outcome: selection, teamPicked: teamId },
        ],
      };
      this.poolService.submitPicksToPool(this.selectedPool.groupGuid, pickRequest).subscribe(
        (res) => {
          if (res.count > 0) {
            this.snackBar.open(`Your pick has been updated`, "dismiss", {
              duration: 2000
            });
          } else {
            game.teamThatWonAbbreviation = currentWon;
            this.snackBar.open(
              "Your pick could not be updated. Has the game already started?",
              "dismiss"
            );
          }
        },
        (err) => {
          game.teamThatWonAbbreviation = currentWon;
          this.snackBar.open(
            "Your pick could not be updated because an error occurred.",
            "dismiss"
          );
        }
      );
    }
  }
  loginClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      // this.poolService.getUserPicks(this.selectedPool.groupGuid).subscribe((up) => {
      //   this.userPicks = up.map((pick) => this.userPickMap(pick));
      //   this.setSelectedWeekPicks();
      //   this.weeks = this.weekService.weeks.map((g) => {
      //     return { value: g.key(), viewValue: g.key() };
      //   });
      // });
    });
  }

  prevWeek() {
    if (this.selectedWeek > 1) {
      this.selectedWeek--;
      this.setSelectedWeekPicks();
    }
  }
  nextWeek() {
    if (this.selectedWeek < this.weekService.weeks.length) {
      this.selectedWeek++;
      this.setSelectedWeekPicks();
    }
  }
}
