import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import * as Highcharts from "highcharts";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ChartDataService } from "../services/chart-data.service";
import { environment } from "@env/environment";
@Component({
  selector: "app-powerrank",
  templateUrl: "./powerrank.component.html",
  styleUrls: ["./powerrank.component.css"],
})
export class PowerRankComponent implements OnInit, OnDestroy {
  leagueGuid = environment.currentNflGuid;
  highcharts = Highcharts;
  conferences = ["All", "AFC", "NFC"]
  selectedConference: string;
  chartOptions:any = {

    title: 'Power Rank',
    series: []
  };
  public forceUpdate = false;
  private originalChartOptions;
  confName$ = new Subject<string>();

  public teams = [
    { name: "49ers", conference: "NFC" },
    { name: "Bears", conference: "NFC" },
    { name: "Bengals", conference: "AFC" },
    { name: "Bills", conference: "AFC" },
    { name: "Broncos", conference: "AFC" },
    { name: "Browns", conference: "AFC" },
    { name: "Buccaneers", conference: "NFC" },
    { name: "Cardinals", conference: "NFC" },
    { name: "Chargers", conference: "AFC" },
    { name: "Chiefs", conference: "AFC" },
    { name: "Colts", conference: "AFC" },
    { name: "Commanders", conference: "NFC"},
    { name: "Cowboys", conference: "NFC" },
    { name: "Dolphins", conference: "AFC" },
    { name: "Eagles", conference: "NFC" },
    { name: "Falcons", conference: "NFC" },
    { name: "Giants", conference: "NFC" },
    { name: "Jaguars", conference: "AFC" },
    { name: "Jets", conference: "AFC" },
    { name: "Lions", conference: "NFC" },
    { name: "Packers", conference: "NFC" },
    { name: "Panthers", conference: "NFC" },
    { name: "Patriots", conference: "AFC" },
    { name: "Raiders", conference: "AFC" },
    { name: "Rams", conference: "NFC" },
    { name: "Ravens", conference: "AFC" },
    { name: "Saints", conference: "NFC" },
    { name: "Seahawks", conference: "NFC" },
    { name: "Steelers", conference: "AFC" },
    { name: "Texans", conference: "AFC" },
    { name: "Titans", conference: "AFC" },
    { name: "Vikings", conference: "NFC" },
  ];
  validTeams = [];
  destroy$ = new Subject<boolean>();
  loaded = false;
  chartRef: Highcharts.Chart;
  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
      this.chartRef = chart;
    };
  constructor(private chartService: ChartDataService) { }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.chartService.getPowerRank(this.leagueGuid).subscribe((input: any) => {
      this.chartOptions = {...input};
      this.chartOptions.chart.type = "line";
      this.chartOptions.credits.text = "v2.playoffpredictors.com/pulse/powerrank";
      this.chartOptions.credits.href = "https://v2.playoffpredictors.com/pulse/powerrank";
      this.chartOptions.caption.color = "black";
      this.chartOptions.xAxis.labels = { rotation: 45 };
      this.chartOptions.yAxis.reversed = true;
      this.chartOptions.yAxis.tickPositions = [1,2,3,4,5,8,14,20,25,32];
      this.chartOptions.yAxis.title = {
        enabled: true,
        text: "Rank" };
        this.forceUpdate = true;
      this.originalChartOptions = JSON.stringify(this.chartOptions);
      this.loaded = true;
    });
    this.confName$.pipe(takeUntil(this.destroy$)).subscribe(conf => {
      let originalOptions = JSON.parse(this.originalChartOptions);
      if(conf == "All"){
        this.chartOptions.series = originalOptions.series;
        while(this.chartRef.series.length){
          this.chartRef.series[0].remove();
        }
        for(let item of originalOptions.series){
          this.chartRef.addSeries(item, false);
        }
      }else{
        this.validTeams = this.teams.filter(p => p.conference == conf).map(p => p.name);
        let newSeries = originalOptions.series.filter(p => {
          return this.validTeams.indexOf(p.name) !== -1}).splice(0);
        this.chartOptions.series = [];

        while(this.chartRef.series.length){
          this.chartRef.series[0].remove();
        }
        for(let item of newSeries){
          this.chartRef.addSeries(item, false);
        }
        this.chartOptions.series = [...newSeries];
        this.forceUpdate = true;
      }
      this.chartOptions.credits.text = "Updated"
      this.forceUpdate = true;
      this.loaded = true;
    })
  }

  conferenceSelected(event: MatSelectChange) {
    this.confName$.next(event.value);
  }
}
