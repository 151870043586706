<h1 mat-dialog-title>Save Scenario</h1>
<mat-dialog-content #dialog>
  <form (ngSubmit)="onSubmit()" style="display: flex; flex-direction: column">
    <mat-form-field appearance="fill">
      <input
        matInput
        [formControl]="watchlistName"
        required
        type="text"
        [placeholder]="'Scenario Name'"
      />

      <mat-error *ngIf="watchlistName.invalid">
        <span *ngIf="watchlistName.hasError('required')">
          Scenario Name is required
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <textarea
        matInput
        placeholder="Description"
        [formControl]="description"
      ></textarea>
      <mat-error *ngIf="description.invalid">
        <span *ngIf="description.hasError('required')">
          Description is required
        </span>
      </mat-error>
    </mat-form-field>
  </form>
  {{ error }}
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    [disabled]="watchlistName.invalid || description.invalid || loading"
    (click)="onSubmit()"
    color="accent"
  >
    {{ loading ? "saving..." : "Save" }}
  </button>
  <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
