<button mat-button [matMenuTriggerFor]="menu" aria-label="Weekly Actions">
  Weekly Actions <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="randomizeWeek()">
    <mat-icon> shuffle </mat-icon>
    <span> Randomize (all) </span>
  </button>
  <button mat-menu-item (click)="randomizeWeekUnpickedGames()">
    <mat-icon> shuffle </mat-icon>
    <span> Randomize (unpicked) </span>
  </button>
  <button mat-menu-item (click)="bestRecordWins()">
    <mat-icon> shuffle </mat-icon>
    <span> Best Record Wins (unpicked) </span>
  </button>
  <button mat-menu-item (click)="resetWeek()">
    <mat-icon> restart_alt </mat-icon>
    <span> Reset Week </span>
  </button>
</mat-menu>
