import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "..";

@Injectable({
  providedIn: "root",
})
export class BetaGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      
    if (this.authService.userValue) {
      if (
        this.authService.userValue &&
        this.authService.userValue.beta === "true"
      ) {
        return true;
      }
      // logged in so return true
      return false;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/account/user/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
