<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button fxHide fxShow.lt-md mat-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1>
      <a [routerLink]="['/']"
        ><img
          src="https://static.playoffpredictors.com/site/pp-color.svg"
          width="48"
        />
        <span fxShow fxHide.lt-sm color="primary" class="pp-top-text"
          >Playoff</span
        >
        <span fxShow fxHide.lt-sm color="secondary">Predictors</span>
      </a>
    </h1>
    <ng-container *ngIf="!isSmallScreen">
      <ng-container *ngFor="let link of links">
        <a mat-button [matMenuTriggerFor]="menu"
          ><mat-icon *ngIf="link.icon" title="{{ link.text }}">{{
            link.icon
          }}</mat-icon>
          <span *ngIf="!link.icon">
            {{ link.text }}
          </span>
          <mat-icon *ngIf="!link.icon">arrow_drop_down</mat-icon>
        </a>
        <!-- "Football" -->

        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let linkGroup of link.children">
            <ng-container *ngIf="linkGroup.children?.length">
              <button mat-menu-item [matMenuTriggerFor]="subMenu">
                {{ linkGroup.text }}
              </button>
              <!-- "NFL" -->
              <mat-menu #subMenu="matMenu">
                <ng-container *ngFor="let subSubGroup of linkGroup.children">
                  <ng-container *ngIf="subSubGroup.children == null">
                    <ng-container *ngIf="subSubGroup.external">
                      <a mat-menu-item [href]="subSubGroup.url">
                        {{ subSubGroup.text }}
                      </a>
                    </ng-container>
                    <ng-container *ngIf="!subSubGroup.external">
                      <a
                        mat-menu-item
                        [routerLink]="getLink(subSubGroup.url)"
                        [queryParams]="getParamMap(subSubGroup.url)"
                      >
                        {{ subSubGroup.text }}
                      </a>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="subSubGroup.children != null">
                    <!-- "2020 Season" -->
                    <button mat-menu-item [matMenuTriggerFor]="subSubMenu">
                      {{ subSubGroup.text }}
                    </button>
                    <!-- 2020 season-->
                    <mat-menu #subSubMenu="matMenu">
                      <ng-container
                        *ngFor="let bottomGroup of subSubGroup.children"
                      >
                        <ng-container *ngIf="bottomGroup.external">
                          <a mat-menu-item [href]="bottomGroup.url">
                            {{ bottomGroup.text }}
                          </a>
                        </ng-container>
                        <ng-container *ngIf="!bottomGroup.external">
                          <a
                            mat-menu-item
                            [routerLink]="getLink(bottomGroup.url)"
                            [queryParams]="getParamMap(bottomGroup.url)"
                          >
                            {{ bottomGroup.text }}
                          </a>
                        </ng-container>
                      </ng-container>
                    </mat-menu>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </ng-container>

            <!-- <ng-container *ngIf="!linkGroup.children?.length && linkGroup.url">
              <a *ngIf="!linkGroup.isExternal" mat-menu-item [routerLink]="linkGroup.url">{{ linkGroup.text }}</a>
              <a *ngIf="linkGroup.isExternal" mat-menu-item href="{{linkGroup.url}}">{{ linkGroup.text }}</a>
            </ng-container> -->
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
    <a
      fxShow
      fxHide.lt-md
      mat-button
      href="https://playsheet.playoffpredictors.com/"
    >
      PlaySheet
    </a>
    <!-- <a fxShow fxHide.lt-md mat-button [routerLink]="['/pulse/weekly']">
      Pulse
    </a> -->
    <div class="flex-spacer"></div>
    <a
      mat-button
      [routerLink]="['/account/subscriptions']"
      *ngIf="authService.isAdsFree && !isSmallScreen"
    >
      <mat-icon>favorite</mat-icon>
    </a>
    <a
      fxShow
      fxHide.lt-md
      mat-button
      [routerLink]="['/home/hate-ads']"
      *ngIf="!authService.isAdsFree && !isSmallScreen"
    >
      Hate Ads?
    </a>
    <button
      mat-button
      *ngIf="shareService.canShare"
      (click)="shareService.sharePage()"
    >
      <mat-icon>share</mat-icon>
    </button>
    <button mat-button (click)="toggleNightmode()">
      <mat-icon>dark_mode</mat-icon>
    </button>

    <a
      mat-button
      *ngIf="isOnline && authService.isLoggedIn"
      [matMenuTriggerFor]="identityMenu"
    >
      <mat-icon>account_box</mat-icon>
      <mat-icon>arrow_drop_down</mat-icon>
    </a>
    <a
      mat-button
      *ngIf="isOnline && !authService.isLoggedIn"
      [matMenuTriggerFor]="identityMenu"
    >
      <mat-icon>login</mat-icon>
      <mat-icon>arrow_drop_down</mat-icon>
    </a>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #identityMenu="matMenu">
  <span
    mat-menu-item
    routerLink="/account/settings"
    *ngIf="authService.isLoggedIn"
    ><mat-icon>settings</mat-icon>{{ authService?.userValue.sub }}</span
  >
  <a
    mat-menu-item
    routerLink="/account/user/register"
    *ngIf="!authService.isLoggedIn"
  >
    Register
  </a>
  <a
    mat-menu-item
    routerLink="/account/user/login"
    *ngIf="!authService.isLoggedIn"
  >
    Login
  </a>
  <a
    mat-menu-item
    routerLink="/account/subscriptions"
    *ngIf="authService.isLoggedIn"
    ><mat-icon>attach_money</mat-icon> Subscriptions
  </a>
  <a mat-menu-item (click)="logOut()" *ngIf="authService.isLoggedIn"
    ><mat-icon>exit_to_app</mat-icon> Logout
  </a>
</mat-menu>
<!--Sidenav-->
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" [(opened)]="opened">
    <ul>
      <ng-container *ngFor="let link of links">
        <li>
          <a mat-button [matMenuTriggerFor]="menu"
            >{{ link.text }} <mat-icon>arrow_drop_down</mat-icon>
          </a>
          <!-- "Football" -->

          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let linkGroup of link.children">
              <ng-container *ngIf="linkGroup.children?.length">
                <button mat-menu-item [matMenuTriggerFor]="subMenu">
                  {{ linkGroup.text }}
                </button>
                <!-- "NFL" -->
                <mat-menu #subMenu="matMenu">
                  <ng-container *ngFor="let subSubGroup of linkGroup.children">
                    <ng-container *ngIf="subSubGroup.children == null">
                      <ng-container *ngIf="subSubGroup.external">
                        <a mat-menu-item [href]="subSubGroup.url">
                          {{ subSubGroup.text }}
                        </a>
                      </ng-container>
                      <ng-container *ngIf="!subSubGroup.external">
                        <a
                          mat-menu-item
                          [routerLink]="getLink(subSubGroup.url)"
                          [queryParams]="getParamMap(subSubGroup.url)"
                        >
                          {{ subSubGroup.text }}
                        </a>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="subSubGroup.children != null">
                      <!-- "2020 Season" -->
                      <button mat-menu-item [matMenuTriggerFor]="subSubMenu">
                        {{ subSubGroup.text }}
                      </button>
                      <!-- 2020 season-->
                      <mat-menu #subSubMenu="matMenu">
                        <ng-container
                          *ngFor="let bottomGroup of subSubGroup.children"
                        >
                          <ng-container *ngIf="bottomGroup.external">
                            <a mat-menu-item [href]="bottomGroup.url">
                              {{ bottomGroup.text }}
                            </a>
                          </ng-container>
                          <ng-container *ngIf="!bottomGroup.external">
                            <a
                              mat-menu-item
                              [routerLink]="getLink(bottomGroup.url)"
                              [queryParams]="getParamMap(bottomGroup.url)"
                            >
                              {{ bottomGroup.text }}
                            </a>
                          </ng-container>
                        </ng-container>
                      </mat-menu>
                    </ng-container>
                  </ng-container>
                </mat-menu>
              </ng-container>
              <!--
              <ng-container *ngIf="!linkGroup.children?.length && linkGroup.url">
                <a *ngIf="!linkGroup.isExternal" mat-menu-item [routerLink]="linkGroup.url">{{ linkGroup.text }}</a>
                <a *ngIf="linkGroup.isExternal" mat-menu-item href="{{linkGroup.url}}">{{ linkGroup.text }}</a>
              </ng-container> -->
            </ng-container>
          </mat-menu>
        </li>
      </ng-container>
      <a mat-button href="https://playsheet.playoffpredictors.com/">
        PlaySheet
      </a>
      <!-- <li><a mat-button [routerLink]="['/pulse/weekly']"> Pulse </a></li> -->
      <li>
        <a
          mat-button
          [routerLink]="['/account/subscriptions']"
          *ngIf="authService.isAdsFree"
        >
          Manage Subscription
        </a>
        <a
          mat-button
          [routerLink]="['/home/hate-ads']"
          *ngIf="!authService.isAdsFree"
        >
          Hate Ads?
        </a>
      </li>
    </ul>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="site-wrapper">
      <ng-container *ngIf="authService.passwordChangeRequired">
        <app-alert [alertType]="'warning'">
          You must change your password. Please visit your
          <a class="alert-link" [routerLink]="['/account/settings']"
            >settings page</a
          >
          and change it
        </app-alert>
      </ng-container>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
