import { map, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { BracketUpload } from '@models';

@Injectable({
  providedIn: 'root'
})
export class BracketUploaderService implements OnDestroy {
  constructor(private http: HttpClient) {}
  private destroy$ = new Subject<boolean>();
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  uploadBracket(base64: string, leagueGuid: string, saveString: string, origin: string) {
    return this.http.post<any>(`${environment.leagueApiUrl}brackets/upload-bracket`, { base64, leagueGuid, saveString, origin }).pipe(
      map((link: any) => {
        return link;
      }),
      takeUntil(this.destroy$)
    );
  }

  getBracket(bracketId: string) {
    return this.http.get<BracketUpload>(`${environment.leagueApiUrl}brackets/view-bracket/${bracketId}`).pipe(
      map((bracket: BracketUpload) => {
        return bracket;
      }),
      takeUntil(this.destroy$)
    );
  }
}
