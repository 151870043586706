import { LeagueSettingsService } from "./../services/league-settings/league-settings.service";
import { GameMapService } from "./../services/game-map/game-map.service";
import {
  UserConfig,
  SelectionType,
  League,
  Team,
  Game,
  ScoreRecord,
  ScoreDict,
  RegisterPickRequest,
  UpdateReason,
} from "@models";
import { UserSettingsService } from "@services/user-settings/user-settings.service";
import { Subject, BehaviorSubject, interval } from "rxjs";
import { switchMap, map, takeUntil, take, debounce } from "rxjs/operators";
import { ControlBarComponent } from "./../templates/control-bar/control-bar.component";
import { TiebreakerService } from "../services/tiebreaker/tiebreaker.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
} from "@angular/core";
import { LeagueService } from "../services/league/league.service";
import { PostSeasonService } from "../services/post-season/post-season.service";
import { WeekService } from "../services/week/week.service";
import { Helpers } from "@helpers/helpers";
import { UrlSerializerService } from "@services/url-serializer/url-serializer.service";
import { MatTabGroup } from "@angular/material/tabs";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { MatDialog } from "@angular/material/dialog";
import { SeoService } from "@services/seo/seo.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { PoolService } from "@services/index";
import { environment } from "@env/environment";

@Component({
  selector: "app-nfl",
  templateUrl: "./nfl.component.html",
  styleUrls: ["./nfl.component.scss"],
})
export class NflComponent implements OnInit, OnDestroy {
  currentNflGuid = environment.currentNflGuid;
  tabSwapper = new Subject<string>();
  league: League;
  teams: Array<Team>;
  destroy$ = new Subject<boolean>();
  selectedTeam: Team | null;
  saveUrl: string;
  selectedWeek: number = -1;
  selectedTeamAbbrv: string;
  leagueGuid: string;
  activeGames: Array<Array<Game>> = [];
  @ViewChild(ControlBarComponent, { static: false })
  controlBar: ControlBarComponent;
  @ViewChild("tabGroup", { static: false })
  matTabGroup: MatTabGroup;
  userConfig: UserConfig;
  gamesEmitter = new BehaviorSubject<boolean>(null);
  conf1Top: Team[];
  conf2Top: Team[];
  showContent = false;
  fragment: string;
  private blockSave = false;
  leaderboardSelected = false;
  localStorage;
  hasPools = false;
  bottomStandingsVisible = false;
  constructor(
    private route: ActivatedRoute,
    private leagueService: LeagueService,
    private tiebreakerService: TiebreakerService,
    private urlService: UrlSerializerService,
    public userSettingsService: UserSettingsService,
    private postSeasonService: PostSeasonService,
    public weekService: WeekService,
    private gameMapService: GameMapService,
    private leagueSettings: LeagueSettingsService,
    public authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private seoService: SeoService,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private poolService: PoolService
  ) {
    window["gameMapService"] = gameMapService;
    window["leagueService"] = leagueService;
    window["nflComponent"] = this;
    this.localStorage = localStorage;
    this.breakpointObserver.observe(["(max-width: 1044px)"]).subscribe((result: BreakpointState) =>{
      if(result.matches){
        this.bottomStandingsVisible = true;
      }else{
        this.bottomStandingsVisible = false;
      }
    })
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    try {
      const oldUrl = this.saveUrl.replace(/\%20/gi, "+").replace(/ /gi, "+");
      if(!event.state?.path){
        return;
      }
      this.saveUrl = event.state.path
        .split('?')[1]
        .split("=")[1]
        .replace(/\%20/gi, "+");

      if (oldUrl === this.saveUrl) {
        return;
      }
      this.blockSave = true;
      this.resetGames();
      this.load(this.saveUrl, {});
      this.blockSave = false;
    } catch (ex) {
      if(!environment.production){
        console.warn(ex);
      }
    }
  }
  ngOnInit() {
    this.poolService.dropdowns$.pipe(takeUntil(this.destroy$)).subscribe(ddl => {
      this.hasPools = !!ddl.length;
    })
    this.userConfig = this.userSettingsService.userConfig;
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return {
            leagueGuid: params.get("leagueGuid"),
            userId: params.get("userId"),
            leaderboard: params.get("leaderboard"),
          };
        }),
        switchMap((params) => {
          if(this.authenticationService.isLoggedIn){
            this.poolService.getSubmittableGroups(params.leagueGuid)
          }else{
            this.poolService.getSubmittableGroupsAnonymous(params.leagueGuid);
          }
          if (params.leaderboard) {
            this.leaderboardSelected = true;
          } else {
            this.leaderboardSelected = false;
          }
          this.leagueGuid = params.leagueGuid;
          return this.leagueService.LoadInitialLeague(
            params.leagueGuid,
            false,
            params.userId
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {

      });

    this.weekService.getCurrentStandings
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.saveUrl = null;
        this.leagueService
          .LoadInitialLeague(
            this.league.leagueGuid,
            res,
            this.league.features.isOfficial ? "official" : this.league.userId
          )
          .pipe(take(1))
          .subscribe((res) => {
            this.load(res.currentWeekString.replace(/\%20/gi, "+"), res.scores);
            this.urlService.updateUrl();
          });
      });

    this.leagueService.league$
      .pipe(takeUntil(this.destroy$), debounce(() => interval(200)))
      .subscribe((res: League) => {
        if (!res) {
          return;
        }
        this.league = res;
        this.seoService.updateTitle(
          `${this.league.leagueName} - NFL - Playoff Predictors`
        );
        this.seoService.updateDescription(
          `NFL Playoff Predictor (NFL Season Picker) lets you pick every game of the ${this.league.leagueName} NFL Season via a season Schedule, and will show you the seeds for the AFC and NFC`
        );
        this.urlService.initializeSerializationArray(
          this.leagueSettings.numTotalGames
        );

        this.gameMapService.init(this.leagueSettings.numTotalGames);
        if (this.league.features.leagueVersion === 2) {
          res.games.map((game: Game) => {
            this.gameMapService.setGame(game.gameScheduleNum, game);
          });
        }

        if (this.saveUrl) {
          this.load(this.saveUrl, res.scores);
        } else {
          this.saveUrl = this.league.currentWeekString;
          this.load(this.league.currentWeekString, res.scores);
          this.urlService.updateUrl();
        }
        if(this.selectedWeek !== -1){
          this.weekService.setWeek(this.selectedWeek);
          this.selectedWeek = -1;
        }
        setTimeout(()=>{
          if (this.leaderboardSelected) {
            if (this.matTabGroup && this.matTabGroup._tabs) {
              this.matTabGroup.selectedIndex = this.matTabGroup._tabs.length - 3;
            } else {
              setTimeout(() => {
                this.matTabGroup.selectedIndex =
                  this.matTabGroup._tabs.length - 3;
              }, 500);
            }
          }

          if (this.matTabGroup && this.matTabGroup._tabs) {
            if(this.selectedTeamAbbrv){
              let found = this.league.teams.find(p=>p.teamAbbreviation == this.selectedTeamAbbrv.toUpperCase());
              if(found){
                this.teamSelected(found);
              }
            }
          }
        },500)
      });
    this.leagueService.randomizedGamePicked$
      .pipe(
        debounce(() => interval(200)),
        takeUntil(this.destroy$)
      )
      .subscribe((game) => {
        this.urlService.setSerializationArrayItem(
          game.gameScheduleNum,
          game.selectionType,
          this.blockSave
        );
        this.updateStandings(game.week, 'randomizedGamePicked');

        game.updateReason = UpdateReason.Cleared;
      });

    this.leagueService.gamePicked$
      .pipe(
        debounce(() => interval(200)),
        takeUntil(this.destroy$)
      )
      .subscribe((game) => {
        this.urlService.setSerializationArrayItem(
          game.gameScheduleNum,
          game.selectionType,
          this.blockSave
        );
        if(game.week == this.weekService.week || !!this.selectedTeam){
          this.updateStandings(game.week, 'gamePicked');
        }
        if (
          !this.blockSave &&
          game.selectionMade != SelectionType.none &&
          game.updateReason == UpdateReason.UserPick
        ) {
          let registerPick: RegisterPickRequest;
          if (game.selectionMade == SelectionType.tie) {
            registerPick = {
              gameIdentifier: game.gameGuid || game.gameScheduleNum.toString(),
              leagueGuid: game.leagueGuid,
              teamPicked: "tie",
              weekNum: game.week,
              gameNum: game.gameScheduleNum,
              isPostSeason: !game.gameGuid,
              opponent:null
            };
          } else {
            registerPick = {
              gameIdentifier: game.gameGuid || game.gameScheduleNum.toString(),
              leagueGuid: game.leagueGuid,
              teamPicked: game.teamThatWon.teamName,
              weekNum: game.week,
              gameNum: game.gameScheduleNum,
              opponent: game.teamThatLost.teamName,
              isPostSeason: !game.gameGuid,
            };
          }
          this.leagueService.RegisterPick(registerPick);
        }
        game.updateReason = UpdateReason.Cleared;
      });
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      const savestr = params.get("L");
      if (savestr) {
        this.saveUrl = savestr.replace(/\%20/gi, "+");
        this.urlService.lastUpdate = this.saveUrl;
      } else {
        this.saveUrl = window.localStorage[this.leagueGuid] || (this.league && this.league.currentWeekString) || "";
      }
      const weekSelected = params.get('week');
      if(weekSelected && Number(weekSelected)){
        this.selectedWeek = Number(weekSelected);
      }
      const selectedTeam = params.get('team');
      if(selectedTeam){
        this.selectedTeamAbbrv = selectedTeam.toUpperCase();
      }
      const disableScoring = params.get('disableScoring') === 'true';
      if(disableScoring && this.userSettingsService.userConfig.nfl.enableScoring){
        this.userSettingsService.userConfig.nfl.enableScoring = false;
        this.snackBar.open('Warning: Scoring has been disabled from a URL override. Re-enable it in settings if you didn\'t want this to happen.',
        'okay')
      }
      this.tabSwapper.pipe(takeUntil(this.destroy$)).subscribe((tab)=>{
        this.switchTab(tab);
      })
    });

    this.leagueService.selectedTeam$
      .pipe(takeUntil(this.destroy$))
      .subscribe((team: Team) => {
        this.matTabGroup.selectedIndex = 0;
        this.selectedTeam = team;
        setTimeout(() => {
          this.matTabGroup.selectedIndex = 1;
        }, 20);
      });

    this.weekService.resetGames.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.resetGames();
    });
    let weekCache = this.weekService.week;
    this.weekService.currentWeekGames
      .pipe(takeUntil(this.destroy$))
      .subscribe((games) => {
        if(this.weekService.week !== weekCache)
        {
          this.switchTab('schedule')
          weekCache = this.weekService.week;
        }
        this.setGames(games);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.leagueService.league$.next(null);
    this.urlService.updateUrl();
  }

  load(saveUrl: string, scores: ScoreDict) {
    this.switchTab('schedule');
    if (!saveUrl) {
      return;
    }
    saveUrl = saveUrl.replace(/\%20/gi, "+");
    this.saveUrl = saveUrl;
    this.blockSave = true;
    const urlString = Helpers.decompress(saveUrl);
    if (this.league.features.leagueVersion === 2) {
      this.loadV2(urlString, scores);
    } else {
      this.loadV1(urlString);
    }
  }

  loadV1(saveUrl: string) {
    let position = 0;
    let offset = 0;
    this.blockSave = true;
    let biggestWeek = 1;
    for (const team of this.league.teams) {
      team.clear();
    }
    // tslint:disable-next-line:forin
    for (const weekInd in this.weekService.weeks) {
      const gameBucket = [[], [], []];
      this.weekService
        .v1GameSort(this.weekService.weeks[weekInd].getSource())
        .map((game) => {
          if (
            game.home.conference === game.away.conference &&
            game.away.conference === "AFC"
          ) {
            gameBucket[0].push(game);
          } else if (
            game.home.conference === game.away.conference &&
            game.away.conference === "NFC"
          ) {
            gameBucket[2].push(game);
          } else {
            gameBucket[1].push(game);
          }
        });
      for (const conferenceGames of gameBucket) {
        for (const game of conferenceGames) {
          const selection: SelectionType = parseInt(saveUrl[position], 10);
          game.updateReason = UpdateReason.ScheduleLoad;
          if (game && !game.gameScheduleNum) {
            if (position - offset < this.leagueSettings.numRegularSeasonGames) {
              game.gameScheduleNum = position - offset;
              this.gameMapService.setGame(game.gameScheduleNum, game);
            }
          } else {
            offset++;
          }

          this.urlService.setSerializationArrayItem(
            position,
            selection,
            this.blockSave
          );
          switch (selection) {
            case SelectionType.awayWin:
              if (biggestWeek < game.week) {
                biggestWeek = game.week;
              }
              if (
                game.gameScheduleNum <=
                this.leagueSettings.numRegularSeasonGames
              ) {
                game.away.wins.push(game);
                game.home.losses.push(game);
              }
              this.leagueService.pickTeam(game, game.away);
              break;
            case SelectionType.homeWin:
              if (biggestWeek < game.week) {
                biggestWeek = game.week;
              }

              if (
                game.gameScheduleNum <=
                this.leagueSettings.numRegularSeasonGames
              ) {
                game.away.losses.push(game);
                game.home.wins.push(game);
              }
              this.leagueService.pickTeam(game, game.home);

              break;
            case SelectionType.none:
              this.leagueService.pickNone(game);
              break;
            case SelectionType.noContest:
              this.leagueService.pickNoContest(game);
              break;
            case SelectionType.tie:
              if (biggestWeek < game.week) {
                biggestWeek = game.week;
              }
              if (
                game.gameScheduleNum <=
                this.leagueSettings.numRegularSeasonGames
              ) {
                game.away.ties.push(game);
                game.home.ties.push(game);
              }
              this.leagueService.pickTie(game);

              break;
          }

          position++;
        }
      }
    }
    if (position === this.leagueSettings.numRegularSeasonGames) {
      this.weekService.week = this.leagueSettings.numRegularSeasonWeeks;
      this.updateStandings(this.weekService.week, 'seed wildcard v1');
    }
    for (
      position = this.leagueSettings.numRegularSeasonGames;
      position < saveUrl.length;
      position++
    ) {
      const selection: SelectionType = parseInt(saveUrl[position], 10);

      if (position > this.leagueSettings.numRegularSeasonGames) {
        if (position <= 260) {
          this.postSeasonService.seedDivisionGames(
            this.conf1Top,
            this.conf2Top,
            this.league,
            this.urlService.gameSerializationArray
          );
        } else if (position <= 264) {
          this.postSeasonService.seedConferenceChampionshipGames(
            this.league,
            this.urlService.gameSerializationArray
          );
        } else if (position <= 266) {
          this.postSeasonService.seedSuperbowl(
            this.league,
            this.urlService.gameSerializationArray
          );
        }
      }

      const game = this.gameMapService.getGame(position);
      game.updateReason = UpdateReason.ScheduleLoad;
      this.urlService.setSerializationArrayItem(
        position,
        selection,
        this.blockSave
      );
      switch (selection) {
        case SelectionType.awayWin:
          if (biggestWeek < game.week) {
            biggestWeek = game.week;
          }
          if (
            game.gameScheduleNum <= this.leagueSettings.numRegularSeasonGames
          ) {
            game.away.wins.push(game);
            game.home.losses.push(game);
          }
          this.leagueService.pickTeam(game, game.away);
          break;
        case SelectionType.homeWin:
          if (biggestWeek < game.week) {
            biggestWeek = game.week;
          }

          if (
            game.gameScheduleNum <= this.leagueSettings.numRegularSeasonGames
          ) {
            game.away.losses.push(game);
            game.home.wins.push(game);
          }
          this.leagueService.pickTeam(game, game.home);

          break;
        case SelectionType.none:
          this.leagueService.pickNone(game);
          break;
        case SelectionType.noContest:
          this.leagueService.pickNoContest(game);
          break;
        case SelectionType.tie:
          if (biggestWeek < game.week) {
            biggestWeek = game.week;
          }
          if (
            game.gameScheduleNum <= this.leagueSettings.numRegularSeasonGames
          ) {
            game.away.ties.push(game);
            game.home.ties.push(game);
          }
          this.leagueService.pickTie(game);

          break;
      }
    }
    this.blockSave = false;
    this.weekService.week = biggestWeek;
    this.setGames(this.weekService.activeGames);
    this.leagueService.standingsSet$.next(true);
  }
  loadV2(saveUrl: string, scores: ScoreDict) {
    this.blockSave = true;
    let biggestWeek = 1;

    for (const team of this.league.teams) {
      team.clear();
    }
    let posMap = {};

    posMap["dv"] = this.leagueSettings.numRegularSeasonGames + 6;
    posMap["cg"] = this.leagueSettings.numRegularSeasonGames + 6 + 4;
    posMap["sb"] = this.leagueSettings.numRegularSeasonGames + 6 + 4 + 2;

    let updatePositions:number[] = [posMap["dv"], posMap["cg"], posMap["sb"]];
    for (let position = 0; position < saveUrl.length; position++) {
      if(position == this.leagueSettings.numRegularSeasonGames){
        this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks);
        this.updateStandings(this.weekService.week, 'seed wildcard v2');
      }
      else if(updatePositions.some(p => p == position)){
        if(position == posMap["dv"]){
          this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks + 1);
        }
        else if(position == posMap["cg"]){
          this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks + 2);
        }
        else if(position == posMap["sb"]){
          this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks + 3);
        }
        this.updatePostSeason();
      }
      const game = this.gameMapService.getGame(position);
      if (!game) {
        continue;
      }
      game.updateReason = UpdateReason.ScheduleLoad;
      let selection: SelectionType = parseInt(saveUrl[position], 10);
      if (!game.scores) {
        game.scores = new ScoreRecord();
      }
      if (
        game.scores.h != null &&
        game.scores.a != null &&
        (game.scores.a > 0 || game.scores.h > 0) &&
        this.userSettingsService.userConfig.nfl.enableScoring
      ) {
        if (this.userSettingsService.userConfig.nfl.updateGameOutcomesWithScoreUpdate) {
          if (game.scores.h > game.scores.a) {
            selection = SelectionType.homeWin;
          } else if (game.scores.h < game.scores.a) {
            selection = SelectionType.awayWin;
          } else if (game.scores.h === game.scores.a) {
            selection = SelectionType.tie;
          }
        } else {
          if (game.scores.status == "F") {
            if (game.scores.h > game.scores.a) {
              selection = SelectionType.homeWin;
            } else if (game.scores.h < game.scores.a) {
              selection = SelectionType.awayWin;
            } else if (game.scores.h === game.scores.a) {
              selection = SelectionType.tie;
            }
          }
          else if(game.scores.status == "NC"){
            selection = SelectionType.noContest;
          }
        }
      }
      this.urlService.setSerializationArrayItem(
        position,
        selection,
        this.blockSave
      );
      switch (selection) {
        case SelectionType.awayWin:
          if (biggestWeek < game.week) {
            biggestWeek = game.week;
          }
          if (
            game.gameScheduleNum <= this.leagueSettings.numRegularSeasonGames
          ) {
            game.away.wins.push(game);
            game.home.losses.push(game);
          }
          this.leagueService.pickTeam(game, game.away);
          break;
        case SelectionType.homeWin:
          if (biggestWeek < game.week) {
            biggestWeek = game.week;
          }

          if (
            game.gameScheduleNum <= this.leagueSettings.numRegularSeasonGames
          ) {
            game.away.losses.push(game);
            game.home.wins.push(game);
          }
          this.leagueService.pickTeam(game, game.home);

          break;
        case SelectionType.none:
          this.leagueService.pickNone(game);
          break;
        case SelectionType.noContest:
          this.leagueService.pickNoContest(game);
          break;
        case SelectionType.tie:
          if (biggestWeek < game.week) {
            biggestWeek = game.week;
          }
          if (
            game.gameScheduleNum <= this.leagueSettings.numRegularSeasonGames
          ) {
            game.away.ties.push(game);
            game.home.ties.push(game);
          }
          this.leagueService.pickTie(game);

          break;
      }
    }
    this.blockSave = false;
    this.weekService.setWeek(biggestWeek);
    setTimeout(()=>{
      this.urlService.updateUrl();
      this.updateStandings(biggestWeek,'');
      this.leagueService.standingsSet$.next(true);
    }
    ,200);
  }
  static counter = 0;
  updateStandings(week: number, updateReason) {
    //console.log('%s %s %s %s', updateReason, ++NflComponent.counter, week, this.weekService.week);
    if (week <= this.leagueSettings.numRegularSeasonWeeks) {
      this.tiebreakerService.updateStandings(
        this.league.teams,
        this.league.conf1Name,
        this.league.conf2Name
      );
      this.conf1Top = this.league.teams
        .filter(
          (p) =>
            p.conference === this.league.conf1Name &&
            p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
        )
        .sort(Helpers.conferenceRankSort);
      this.conf2Top = this.league.teams
        .filter(
          (p) =>
            p.conference === this.league.conf2Name &&
            p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
        )
        .sort(Helpers.conferenceRankSort);
      this.postSeasonService.seedWildcardGames(
        this.conf1Top,
        this.conf2Top,
        this.league,
        this.urlService.gameSerializationArray
      );
    } else {
        this.updatePostSeason();
    }
    if (!this.blockSave) {
      this.updateUrl();
      this.gamesEmitter.next(true);
    }

    this.tiebreakerService.generateDraftOrder(this.league.teams);
    this.leagueService.standingsSet$.next(true);
  }

  updatePostSeason() {
    switch (this.weekService.week) {
      case this.leagueSettings.numRegularSeasonWeeks + 1: {
        this.postSeasonService.seedDivisionGames(
          this.conf1Top,
          this.conf2Top,
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case this.leagueSettings.numRegularSeasonWeeks + 2: {
        this.postSeasonService.seedConferenceChampionshipGames(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case this.leagueSettings.numRegularSeasonWeeks + 3: {
        this.postSeasonService.seedSuperbowl(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  updateUrl() {
    if (!this.blockSave) {
      this.urlService.updateUrl();
    }
  }

  teamSelected(team) {
    this.leagueService.selectedTeam$.next(team);
  }

  setGames(games) {
    this.activeGames = games;
    this.gamesEmitter.next(true);
  }

  resetGames() {
    for (const team of this.league.teams) {
      team.clear();
    }

    this.urlService.initializeSerializationArray(
      this.leagueSettings.numTotalGames
    );
    for (
      let position = 0;
      position < this.leagueSettings.numTotalGames;
      position++
    ) {
      const game = this.gameMapService.getGame(position);
      if (game != null) {
        if (
          !this.userConfig.nfl.enableScoring ||
          !this.league.features.allowsScores
        ) {
          this.leagueService.pickNone(game);
        } else {
          this.leagueService.pickNone(game);
          game.scores = game.scores || new ScoreRecord();
          game.scores.a = null;
          game.scores.h = null;
          game.scores.status = null;
        }
      } else {
        break;
      }
    }
    this.weekService.week = 1;
    this.weekService.currentWeekGames.next(this.weekService.activeGames);
    this.updateUrl();
  }

  settingsChanged() {
    this.updateStandings(this.weekService.week, 'settings changed');
  }

  switchTab(tabName:string){
    if(!this.matTabGroup || !this.matTabGroup._allTabs){
      return;
    }
    let tabToSelect = -1;
    this.matTabGroup._allTabs.find((mt, idx) => {
      if(mt.textLabel == tabName){
        tabToSelect = idx;
        return true;
      }
      return false;
    });
    this.matTabGroup.selectedIndex = tabToSelect;
  }
  setLocalStorage(key: string){
    localStorage[key] = true;
  }
}
