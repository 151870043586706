import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pulse',
  templateUrl: './pulse.component.html',
  styleUrls: ['./pulse.component.css']
})
export class PulseComponent implements OnInit {
  navLinks = [{ path: 'powerrank', label:'Power Rank'},{ path: 'weekly', label: 'Weekly' }, { path: 'daily', label: 'Daily' }];

  constructor() { }

  ngOnInit(): void {
  }

}
