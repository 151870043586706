<p>
  Power Ranks are defined as the pick differential between all teams - the
  higher the differential, the higher the rank. We're showing data for the most
  recent 4 weeks of picks.
</p>
<mat-form-field class="full-width">
  <mat-label>Conference</mat-label>
  <mat-select (selectionChange)="conferenceSelected($event)" required>
    <mat-option *ngFor="let conference of conferences" [value]="conference">
      {{ conference }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="chart" *ngIf="loaded">
  <highcharts-chart
  [Highcharts]="highcharts"
  [options]="chartOptions"
  [callbackFunction]="chartCallback"
  [(update)]="forceUpdate"
    style="width: 100%; height: 900px; display: block"
  >
  </highcharts-chart>
</div>
