<mat-sidenav-container>
  <mat-sidenav opened mode="side">
    <h3>Table of Contents</h3>
    <ul>
      <li>
        <a class="nav-link" (click)="navigateTo('what-we-collect')">What We Collect</a>
        <ul>
          <li><a class="nav-link" (click)="navigateTo('information-you-provide-to-us')">Information You Provide to Us</a></li>
          <li><a class="nav-link" (click)="navigateTo('information-we-collect-automatically')">Information We Collect Automatically</a></li>
        </ul>
      </li>
      <li><a class="nav-link" (click)="navigateTo('how-we-use-information-about-you')">How We Use Information About You</a></li>
      <li><a class="nav-link" (click)="navigateTo('how-information-about-you-is-shared')">How Information About You Is Shared</a></li>
      <li>
        <a class="nav-link" (click)="navigateTo('ads-and-analytics-partners')">Ads and Analytics Partners</a>
        <ul>
          <li><a class="nav-link" (click)="navigateTo('advertisers-and-ad-networks')">Advertisers and Ad Networks</a></li>
          <li><a class="nav-link" (click)="navigateTo('analytics-partners')">Analytics Partners</a></li>
        </ul>
      </li>
      <li>
        <a class="nav-link" (click)="navigateTo('your-choices')">Your Choices</a>
        <ul>
          <li><a class="nav-link" (click)="navigateTo('deleting-your-account')">Deleting Your Account</a></li>
          <li><a class="nav-link" (click)="navigateTo('controlling-the-user-of-cookies')">Controlling the Use of Cookies</a></li>
          <li><a class="nav-link" (click)="navigateTo('controlling-advertising-and-analytics')">Controlling Advertising and Analytics</a></li>
          <li><a class="nav-link" (click)="navigateTo('do-not-track')">Do Not Track</a></li>
          <li><a class="nav-link" (click)="navigateTo('controlling-promotional-communications')">Controlling Promotional Communications</a></li>
        </ul>
      </li>
      <li>
        <a class="nav-link" (click)="navigateTo('other-information')">Other Information</a>
        <ul>
          <li><a class="nav-link" (click)="navigateTo('information-security')">Information Security</a></li>
          <li><a class="nav-link" (click)="navigateTo('additional-information-for-eea-users')">Additional Information for EEA Users</a></li>
          <li><a class="nav-link" (click)="navigateTo('children')">Children</a></li>
          <li><a class="nav-link" (click)="navigateTo('changes-to-this-policy')">Changes to This Policy</a></li>
        </ul>
      </li>
      <li><a class="nav-link" [routerLink]="['/home/contact-us']">Contact Us</a></li>
    </ul>
  </mat-sidenav>
  <div class="privacy-policy-wrapper">
    <h2 id="privacy-policy">PRIVACY POLICY</h2>
    <strong>Effective May 27, 2018. Last Revised October 14, 2021</strong>
    <p>
      We want you to understand how and why Playoff Predictors, LLC. ("PlayoffPredictors",” “we” or “us”) collects, uses, and shares information about you when you use our websites, mobile apps, widgets, and other online products and services
      (collectively, the "Services") or when you otherwise interact with us or receive a communication from us. This Privacy Policy applies to all of our Services.
    </p>
    <h2 id="what-we-collect">What We Collect</h2>
    <h3 id="information-you-provide-to-us">Information You Provide to Us</h3>
    <p>We collect information you provide to us directly when you use the Services. This includes:</p>
    <p>
      <em>Account information</em>. To create an account, you must provide an email address and password. Your email address is not public, and it doesn’t have to be related to your real name. We do not store user preferences (such as schedule
      settings) on our servers, and instead store them locally in your browser. We do store whether your account has been confirmed.
    </p>
    <p><em>Content you submit</em>. We collect the content you submit to the Services. This includes your finalized picks for all schedules.</p>
    <p>
      <em>Actions you take</em>. We collect information about the actions you take when using the Services. This includes your interactions with content, like toggling schedule settings, toggling site settings (such as night mode), and any actions
      you take on our schedule pickers. The information we collect on these actions is anonymous, and cannot be traced back to any specific user.
    </p>
    <p>
      <em>Transactional information</em>. If you are a Subscriber of ours, we will collect certain information from you, including your name, address, email address, and information about the product or service you are purchasing. Payments are
      processed by third-party payment processors (Stripe), so please refer to the applicable processor’s terms and privacy policy for more information about how payment information is processed and stored.
    </p>
    <p><em>Email List</em>. If you subscribe to our email list, your Privacy is covered by MailChimp's privacy policy. We will collect your name and email address in order to communicate with you in the manner that you have subscribed to.</p>
    <p>
      <em>Discord</em>. If you are a member of our Discord server, your Privacy is covered by Discord's privacy policy. We do not connect your Discord username with your PlayoffPredictors user account unless to provide customer support, and if you
      explicitly gave us this information.
    </p>
    <p>
      <em>Other information</em>. You may choose to provide other information directly to us. For example, we may collect information when you fill out a form, participate in PlayoffPredictors-sponsored activities or promotions, request customer
      support or otherwise communicate with us.
    </p>
    <h3 id="information-we-collect-automatically">Information We Collect Automatically</h3>
    <p>When you access or use our Services, we may also automatically collect information about you. This includes:</p>
    <p>
      <em>Log and usage data</em>. We may log information when you access and use the Services. This may include your IP address, user-agent string, browser type, operating system, referral URLs, device information (e.g., device IDs), pages visited,
      links clicked, the requested URL, and hardware settings.
    </p>
    <p>
      <em>Information collected from cookies and similar technologies</em>. We may receive information from cookies, which are pieces of data your browser stores and sends back to us when making requests, and similar technologies. We use this
      information to improve your experience, understand user activity, personalize content and advertisements, and improve the quality of our Services. For more information on how you can disable cookies, please see “Your Choices” below.
    </p>
    <p><em>Location information</em>. Except from Google Analytics, which has its own privacy policy, we do not track location information.</p>
    <h2 id="how-we-use-information-about-you">How We Use Information About You</h2>
    <p>We use information about you to:</p>
    <ul style="list-style:circle">
      <li>Provide, maintain, and improve the Services;</li>
      <li>Research and develop new services;</li>
      <li>Provide customer service;</li>
      <li>If you subscribe to our MailChimp Email List, we may communicate with you on the subjects that you have subscribed to (for information about how to opt out of these communications, see "Your Choices" below);</li>
      <li>Monitor and analyze trends, usage, and activities in connection with our Services; and</li>
      <li>Personalize the Services and provide advertisements, content and features that match user profiles or interests. (for information about how to manage the types of advertisements you experience on our Services, see “Your Choices” below)</li>
    </ul>
    <h2 id="how-information-about-you-is-shared">How Information About You Is Shared</h2>
    <p>When you use the Services, certain information may be shared with other users and the public. For example:</p>
    <ul style="list-style:circle">
      <li>When we send you an email the email is sent through SendGrid, and your privacy on that service is covered by their privacy policy.</li>
      <li>
        When you submit content (such as create a Custom League) to the Services, any visitors to and users of our Services will be able to see that content, but not the username associated with the content. PlayoffPredictors allows other websites to
        embed public PlayoffPredictors content via our embed tools. PlayoffPredictors also allows third parties to access public PlayoffPredictors content via the PlayoffPredictors API and via other similar technologies.
      </li>
      <li>
        When you communicate on our Discord server, messages you send will be public to other users of the server, and they will be able to see your username and date and time that you sent the message. Please refer to Discord's Privacy Policy on how
        they use and store your data.
      </li>
      <li>
        We offer social sharing features that let you share content or actions you take on our Services with other media. Your use of these features enables the sharing of certain information with your friends or the public, depending on the settings
        you establish with the third party that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of
        the third parties that provide these social sharing features (e.g., Reddit, Discord, MailChimp).
      </li>
    </ul>
    <p>Otherwise, we do not share, sell, or give away your personal information to third parties unless one of the following circumstances applies:</p>
    <ul style="list-style:circle">
      <li>
        <em>To enforce our policies and rights</em>. We may share information if we believe your actions are inconsistent with our user agreements, rules, or other PlayoffPredictors policies, or to protect the rights, property, and safety of
        ourselves and others.
      </li>
      <li>
        <em>Aggregated or de-identified information</em>. We may share information about you that has been aggregated or anonymized such that it cannot reasonably be used to identify you. For example, we may show the total number of times a page has
        been viewed without identifying who the visitors were.
      </li>
    </ul>
    <h2 id="ads-and-analytics-partners">Ads and Analytics Partners</h2>
    <h3 id="advertisers-and-ad-networks">Advertisers and Ad Networks</h3>
    <p>
      We may partner with third-party advertisers, ad networks, and analytics providers to deliver advertising and content targeted to your interests and to better understand your use of the Services. These third parties may collect information sent by your computer, browser, or mobile device in response to a request for content, such as unique identifiers, your IP address, or other information about your computer or device.
    </p>
    <p>
        When you use our website, we share information that we may collect from you, such as
        your email (in hashed form), IP address or information about your browser or operating
        system, with our partner/service provider, LiveRamp Inc and its group companies
        (‘LiveRamp’). LiveRamp may use our first party cookie on your browser to match your
        shared information to their marketing databases in order to provide back a pseudonymous
        privacy-centric identifier for our use in real time bidding in digital advertising. These third
        parties may in turn link further demographic or interest-based information to your
        browser. To opt out of this use, please head here (<a href="https://liveramp.com/opt_out/">https://liveramp.com/opt_out/</a>).
    </p>
    <h3 id="analytics-partners">Analytics Partners</h3>
    <p>We use analytics partners (such as Google Analytics) to help analyze usage and traffic for our Services. As an example, we may use analytics partners to analyze and measure, in the aggregate, the number of unique visitors to our Services.</p>
    <h2 id="your-choices">Your Choices</h2>
    <p>As a PlayoffPredictors user, you have choices about how to protect and limit the collection, use, and disclosure of information about you.</p>
    <h3 id="deleting-your-account">Deleting Your Account</h3>
    <p>
      You may delete your account at any time by contacting <a href="mailto:dpo@PlayoffPredictors.com">dpo@PlayoffPredictors.com</a> from the account that you want deleted. When your account is deleted, we will remove any custom leagues, games, and
      information we have about you. We will respond to deletion requests within three business days.
    </p>
    <h3 id="controlling-the-user-of-cookies">Controlling the Use of Cookies</h3>
    <p>
      Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject first- and third-party cookies. Please note that if you choose to remove or reject cookies, this could affect
      the availability and functionality of our Services.
    </p>
    <h3 id="controlling-advertising-and-analytics">Controlling Advertising and Analytics</h3>
    <p>
      Some analytics providers we partner with may provide specific opt-out mechanisms and we may provide, as needed and as available, additional tools and third-party services that allow you to better understand cookies and how you can opt-out. For
      example, you may manage the use and collection of certain information by Google Analytics <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
    </p>
    <p>
      For advertising, we partner exclusively with Google Analytics, and information on their privacy policy can be found <a href="https://policies.google.com/technologies/partner-sites">here</a>. Please refer to this policy to control ad
      personalization and data privacy.
    </p>
    <h3 id="do-not-track">Do Not Track</h3>
    <p>
      Most modern web browsers give you the option to send a Do Not Track signal to the websites you visit, indicating that you do not wish to be tracked. However, there is no accepted standard for how a website should respond to this signal, and we
      do not take any action in response to this signal. Instead, in addition to publicly available third-party tools, we offer you the choices described in this policy to manage the collection and use of information about you.
    </p>
    <h3 id="controlling-promotional-communications">Controlling Promotional Communications</h3>
    <p>
      You may opt out of receiving some or all categories of promotional communications from us by following the instructions in those communications. If you opt out of promotional communications, we may still send you non-promotional communications,
      such as information about your account or your use of the Services.
    </p>
    <h2 id="other-information">Other Information</h2>
    <h3 id="information-security">Information Security</h3>
    <p>
      We take measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction. For example, we use HTTPS while information is being transmitted. We do not store passwords in
      plain text, and follow best security practices for storing information.
    </p>
    <h3 id="additional-information-for-eea-users">Additional Information for EEA Users</h3>
    <p>
      Users in the European Economic Area have the right to request access to, rectification of, or erasure of their personal data; to data portability in certain circumstances; to request restriction of processing; to object to processing; and to
      withdraw consent for processing where they have previously provided consent. These rights can be exercised using the information provided under “Your Choices” above or using the contact information provided at the end of this document. EEA
      users also have the right to lodge a complaint with their local supervisory authority.
    </p>
    <p>
      As required by applicable law, we collect and process information about individuals in the EEA only where we have legal bases for doing so. Our legal bases depend on the Services you use and how you use them. We process your information on the
      following legal bases:
    </p>
    <ul>
      <li>You have consented for us to do so for a specific purpose;</li>
      <li>We need to process the information to provide you the Services, including to operate the Services, provide customer support and personalized features and to protect the safety and security of the Services;</li>
      <li>
        It satisfies a legitimate interest (which is not overridden by your data protection interests), such as preventing fraud, ensuring network and information security, enforcing our rules and policies, protecting our legal rights and interests,
        research and development, and marketing and promoting the Services;
      </li>
      <li>We need to process your information to comply with our legal obligations.</li>
    </ul>
    <h3 id="children">Children</h3>
    <p>
      Children under the age of 13 are not allowed to create an account or otherwise use the Services. Additionally, if you are in the EEA, you must be over the age required by the laws of your country to create an account or otherwise use the
      Services, or we need to have obtained verifiable consent from your parent or legal guardian.
    </p>
    <h3 id="changes-to-this-policy">Changes to This Policy</h3>
    <p>
      We may change this Privacy Policy from time to time. If we do, we will let you know by revising the date at the top of the policy. If we make a change to this policy that, in our sole discretion, is material, we will provide you with additional
      notice (such as adding sending you an email). We encourage you to review the Privacy Policy whenever you access or use our Services or otherwise interact with us to stay informed about our information practices and the ways you can help protect
      your privacy. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.
    </p>
    <h2 id="contact-us">Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please visit our <a href="https://playoffpredictors.com/Home/ContactUs">Contact Us</a> page.</p>
  </div>
</mat-sidenav-container>
