<div class="button-row">
  <button
    mat-raised-button
    color="secondary"
    [disabled]="weekService.week <= 1"
    (click)="decrementWeek()"
  >
    <mat-icon>arrow_left</mat-icon>
    <span fxShow fxHide.lt-sm> Previous</span>
  </button>
  <button
    class="week-dropdown"
    mat-raised-button
    color="primary"
    [matMenuTriggerFor]="menu"
  >
    <span fxShow fxHide.lt-sm> {{ weekService.weekServiceName }}</span>
    <span fxHide fxShow.lt-sm> {{ weekService.weekServiceShortName }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let item of weekService.weekDateMap"
      (click)="setWeek(item.key)"
    >
      <span fxShow fxHide.lt-sm> {{ item.value }}</span>
      <span fxHide fxShow.lt-sm> {{ item.shortValue }}</span>
    </button>
  </mat-menu>

  <button
    mat-raised-button
    color="secondary"
    [disabled]="weekService.week >= weekService.maxWeek"
    (click)="incrementWeek()"
  >
    <span fxShow fxHide.lt-sm> Next</span>
    <mat-icon>arrow_right</mat-icon>
  </button>

  <button
    *ngIf="isOnline"
    mat-raised-button
    color="accent"
    (click)="currentStandings()"
  >
    <span fxShow fxHide.lt-sm> Update</span>
    <mat-icon>update</mat-icon>
  </button>
  <button *ngIf="!isOnline" mat-raised-button color="accent" disabled>
    <span fxShow fxHide.lt-sm> Offline</span>
    <mat-icon>offline_pin</mat-icon>
  </button>

  <button mat-button [matMenuTriggerFor]="menu2" aria-label="More Actions">
    More<mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu2="matMenu">
    <button mat-menu-item (click)="reset()">
      <mat-icon>cached</mat-icon>
      <span>Reset Schedule</span>
    </button>

    <button mat-menu-item (click)="randomize()">
      <mat-icon> shuffle </mat-icon>
      <span> Randomize (All) </span>
    </button>
    <button mat-menu-item (click)="randomizeAllUnpicked()">
      <mat-icon> shuffle </mat-icon>
      <span> Randomize (Unpicked) </span>
    </button>
    <button mat-menu-item (click)="randomizeAllBestTeamWins()">
      <mat-icon> shuffle </mat-icon>
      <span> Best Record Wins (Unpicked) </span>
    </button>
    <ng-container>
      <ng-container *ngIf="!authenticationService.isLoggedIn">
        <button mat-menu-item (click)="showLoginModal()">
          <mat-icon> login </mat-icon>
          Log in to Save Scenario
        </button>
        <button
          *ngIf="
            userSettings.userConfig.nfl.enableScoring &&
            league.features.allowsScores
          "
          mat-menu-item
          (click)="showLoginModal()"
        >
          <mat-icon> login </mat-icon>
          Log in to Save Scores
        </button>
      </ng-container>

      <ng-container *ngIf="authenticationService.isLoggedIn">
        <ng-container *ngIf="!userSettings.userConfig.nfl.enableScoring">
          <button mat-menu-item (click)="saveScenario()">
            <mat-icon>save</mat-icon>
            <span>Save Scenario</span>
          </button>
        </ng-container>
        <ng-container *ngIf="userSettings.userConfig.nfl.enableScoring">
          <button mat-menu-item disabled>
            <mat-icon>save</mat-icon>
            <span>Disable scoring to Save Scenario</span>
          </button>
          <button
          *ngIf="
            userSettings.userConfig.nfl.enableScoring &&
            league.features.allowsScores
          "
          mat-menu-item
          (click)="saveScores()"
        >
          <mat-icon>save</mat-icon>
          <span>Save My Scores</span>
        </button>
        <button
          *ngIf="
            userSettings.userConfig.nfl.enableScoring &&
            league.features.allowsScores
          "
          mat-menu-item
          [routerLink]="[
            '/football/nfl/' +
              this.league.leagueGuid +
              '/' +
              this.authenticationService.userValue.id
          ]"
        >
          <mat-icon>preview</mat-icon>
          <span>View My Scores</span>
        </button>
        </ng-container>

      </ng-container>
    </ng-container>
  </mat-menu>
</div>
