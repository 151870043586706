import { DialogComponent } from './../templates/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  destroy$ = new Subject<boolean>();
  id = null;
  code = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]);

  constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService, private router: Router, public dialog: MatDialog) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.id = params.get('id');

      this.code.setValue(params.get('code'));
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (!(this.code.invalid)) {
      this.authenticationService
        .resetPassword(this.id, this.code.value, this.password.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          user => {
            this.router.navigate([`/`]);
          },
          errorRes => {
            const res = { title: 'Oops. We ran into a problem.', message: '', status: errorRes.status };
            if (errorRes.error[0].code === 'InvalidToken') {
              res.message = 'Your reset token is invalid. Please try resetting your password again';
            }

            this.dialog.open(DialogComponent, {
              width: '500px',
              data: res
            });
          }
        );
    }
  }
}
