export const paths = {
  // home.routing
  home: { path: 'home', fullUrl: '/home' },
  home_faq: { path: 'faq', fullUrl: '/home/faq' },
  home_subscribersOnly: { path: 'subscribersOnly', fullUrl: '/home/subscribersOnly' },
  home_privacyPolicy: { path: 'privacy-policy', fullUrl: '/home/privacy-policy' },
  home_contactUs: { path: 'contact-us', fullUrl: '/home/contact-us' },
  home_roadMap: { path: 'road-map', fullUrl: '/home/road-map' },
  home_hateAds: { path: 'hate-ads', fullUrl: '/home/hate-ads'},
  home_accessibilityStatement: { path: 'accessibility-statement', fullUrl: '/home/accessibility-statement'}
  // account.routing
};
