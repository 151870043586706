import { Helpers } from '@helpers/helpers';

export class GameRecord {
  wins = 0;
  losses = 0;
  ties = 0;
  get recordString(): string {
    return this.toString();
  }
  get pct(): number {
    return Helpers.calcWLT(this.wins, this.losses, this.ties);
  }

  get pctNaN(): number {
    return Helpers.calcPct(this.wins, this.losses, this.ties);
  }

  differential: number;

  toString(): string {
    let retStr = `${this.wins}-${this.losses}`;
    if (this.ties > 0) {
      retStr += `-${this.ties}`;
    }
    return retStr;
  }
  resetWins() {
    this.wins = 0;
  }
  resetLosses() {
    this.losses = 0;
  }
  resetTies() {
    this.ties = 0;
  }
}
