import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Game, League, Team } from '@models';
import { LeagueService } from '../league/league.service';
import * as Enumerable from 'linq';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LeagueSettingsService } from '../league-settings/league-settings.service';
import { Helpers } from '@helpers/helpers';

@Injectable()
export class WeekService implements OnDestroy {
  week = 1;
  weekDateMap: any[] = [];
  destroy$ = new Subject<boolean>();
  currentWeekGames = new BehaviorSubject<Array<Array<Game>>>(null);
  resetGames: Subject<boolean> = new Subject<boolean>();
  getCurrentStandings: Subject<boolean> = new Subject<boolean>();
  league: League;
  maxWeek;
  weeks: Enumerable.IGrouping<number, Game>[];
  constructor(private leagueService: LeagueService, private leagueSettings: LeagueSettingsService) {
    window['weekService'] = this;
    this.leagueService.league$.pipe(takeUntil(this.destroy$)).subscribe((res: League) => {
      this.weeks = [];
      this.weekDateMap = [];
      this.league = res;
      this.currentWeekGames.next(this.activeGames);
      if (this.league) {
        if (this.league.features.leagueVersion === 2) {
          this.weeks = Enumerable.from(this.league.games)
            .orderBy((game: Game) => {
              if (game.gameDate) {
                return game.gameDate.getTime();
              } else {
                return game.week;
              }
            })
            .groupBy((game: Game) => game.week)
            .orderBy(p => p.key())
            .toArray();
        } else if (this.league.features.leagueVersion === 1) {
          this.weeks = Enumerable.from(this.v1GameSort(this.league.games))
            .groupBy((game: Game) => game.week)
            .orderBy(p => p.key())
            .toArray();
        }
        for (const group of this.weeks) {
          const groupSource = group.getSource();
          const temp = {
            key: 0,
            value: '',
            shortValue: ''
          };
          const key = parseInt(group.key().toString(), 10);
          if (key <= this.leagueSettings.numRegularSeasonWeeks) {
            temp.key = key;
            if (groupSource[0].gameDate) {
              temp.value = `Week ${group.key()}: ${format(groupSource[0].gameDate, 'MMM do')} - ${format(groupSource[groupSource.length - 1].gameDate, 'MMM do')}`;
            } else {
              temp.value = `Week ${group.key()}`;
            }
            temp.shortValue = `Week ${group.key()}`;
            this.weekDateMap.push(temp);
          }
        }
        for (let i = this.leagueSettings.numRegularSeasonWeeks +1; i <= this.leagueSettings.numRegularSeasonWeeks + 4; i++) {
          this.weekDateMap.push({ key: i, value: this.weekKeyToName(i), shortValue: this.weekKeyToName(i) });
        }
      }
      this.maxWeek = this.leagueSettings.numRegularSeasonWeeks + 4;
    });
  }
  get weekServiceName() {
    if (this.weekDateMap.length === 0) {
      return '';
    }
    if (this.week <= this.leagueSettings.numRegularSeasonWeeks) {
      return this.weekDateMap.find(p => p.key === this.week).value;
    } else {
      return this.weekKeyToName(this.week);
    }
  }
  get weekServiceShortName() {
    if (this.weekDateMap.length === 0) {
      return '';
    }
    if (this.week <= this.leagueSettings.numRegularSeasonWeeks) {
      return this.weekDateMap.find(p => p.key === this.week).shortValue;
    } else {
      return this.weekKeyToName(this.week);
    }
  }
  v1GameSort(games: Game[]) {
    try {
      if (games[0] && games[0].gameDate) {
        return Enumerable.from(games)
          .orderBy((game: Game) => {
            return game.sortOrder;
          })
          .thenBy((game: Game) => {
            return game.home.teamName;
          })
          .toArray();
      } else {
        const weekNum = games[0].week;
        if (weekNum >= 18) {
          return Enumerable.from(games)
            .orderBy((p: any) => p.gameScheduleNum)
            .toArray();
        }

        return Enumerable.from(games)
          .orderByDescending((p: any) => {
            return p.sortOrder;
          })
          .thenBy(p => {
            if (p) {
              return p.home.teamName;
            }
          })
          .toArray();
      }
    } catch (ex) {
      return Enumerable.from(games)
        .orderBy((game: Game) => {
          return game.sortOrder;
        })
        .thenBy((game: Game) => {
          return game.home.teamName;
        })
        .toArray();
    }
  }
  public get lastWeekTeamsOnBye(): Array<Team>{
    let teams = [];
    if (this.league && this.league.teamsInWeek && this.league.teams.length) {
      let lastWeek = this.league.teamsInWeek[this.week - 1];
      if(lastWeek){
        teams = [...this.league.teams.filter(t => lastWeek.indexOf(t.teamId) == -1)];
      }
    }
    return teams;

  }
  
  public get teamsOffMiniBye(): Array<Team>{
    let teams = [];
    if (this.league && this.league.games) {
      let thisWeek = this.league.games.filter(p=> p.week == this.week);
      if(thisWeek){
        teams = thisWeek.filter(p => p.awayOffThurs || p.homeOffThurs).map(g => {
          return g.homeOffThurs ? g.home : g.away
        })
      }
    }
    return teams;
  }
  public get teamsOnBye(): Array<Team>{
    let teams = [];
    if (this.league && this.league.teamsInWeek) {
      let thisWeek = this.league.teamsInWeek[this.week];
      if(thisWeek){
        teams = [...this.league.teams.filter(t => thisWeek.indexOf(t.teamId) == -1)];
      }
    }
    return teams;
  }
  public get activeGames(): Array<Array<Game>> {
    const out = [];
    if (this.league && this.league.games && this.league.games.length) {
      const thisWeeksGames = this.league.games.filter(p => p.week === this.week).sort(Helpers.sortByGameDate);
      out[0] = thisWeeksGames.filter(p => p.home.conference === this.league.conf1Name && p.away.conference === this.league.conf1Name);
      out[1] = thisWeeksGames.filter(p => p.home.conference !== p.away.conference);
      out[2] = thisWeeksGames.filter(p => p.home.conference === this.league.conf2Name && p.away.conference === this.league.conf2Name);
      if (this.league.features.leagueVersion === 1) {
        out[0] = this.v1GameSort(out[0]);
        out[1] = this.v1GameSort(out[1]);
        out[2] = this.v1GameSort(out[2]);
      }
    }
    return out;
  }

  private weekKeyToName(weekNum: number) {
    switch (weekNum) {
      case this.leagueSettings.numRegularSeasonWeeks + 1:
        return `Wild Card Round`;

      case this.leagueSettings.numRegularSeasonWeeks + 2:
        return `Divisional Round`;

      case this.leagueSettings.numRegularSeasonWeeks + 3:
        return `Conference Championship Round`;

      case this.leagueSettings.numRegularSeasonWeeks + 4:
        return `Super Bowl`;

      default:
        break;
    }
  }
  incrementWeek() {
    if (this.week < this.maxWeek) {
      this.week++;

      this.currentWeekGames.next(this.activeGames);
    }
  }

  decrementWeek() {
    if (this.week > 0) {
      this.week--;
      this.currentWeekGames.next(this.activeGames);
    }
  }

  setWeek(week) {
    this.week = week;
    this.currentWeekGames.next(this.activeGames);
    //console.log(week);
    //console.log(this.activeGames);
  }

  currentStandings() {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'currentstandings',
      'league': this.league.leagueName
    });

    this.getCurrentStandings.next(true);
  }

  reset() {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'resetSchedule',
      'league': this.league.leagueName
    });
    this.resetGames.next(true);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
