import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UrlSerializerService } from "..";
@Injectable({
  providedIn: "root",
})
export class ShareService {
  canShare = false;
  constructor(
    private router: Router,
    private urlService: UrlSerializerService
  ) {
    if (window.navigator && window.navigator["share"] !== undefined) {
      this.canShare = true;
    }
  }
  sharePage() {
    let url = this.router.url;
    if (this.urlService.saveString) {
      url = url.split("?")[0];
      url += "?L=" + this.urlService.saveString;
    }
    if (this.canShare) {
      window.navigator["share"]({
        title: `Playoff Predictors`,
        text: "Check out this link on #PlayoffPredictors: ",
        url: url,
      });
    }
  }

  shareItem(title: string, text: string, url: string) {
    if (this.canShare) {
      window.navigator["share"]({
        title: title,
        text: text,
        url: url,
      });
    }
  }
}
