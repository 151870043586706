<h1 mat-dialog-title>Let's set up a display name first</h1>
<mat-dialog-content #dialog>
  <p>Before we save your picks, we need to an external name.</p>
  <p>This name will be displayed on leaderboards and around the site, and can be viewed by anyone.</p>
  <p>Using profaine, racist, or other inappropriate terms will result in an account suspension/permanent ban from leaderboards.</p>
  <p>This can be changed any time on the settings page</p>
  <form (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input matInput placeholder="Display Name" [formControl]="displayName" required type="text" />
      <mat-error *ngIf="displayName.invalid"> <span *ngIf="displayName.hasError('required')"> field is required </span> </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-raised-button [disabled]="displayName.invalid " (click)="onSubmit()" color="accent">Save</button>
  <button mat-button (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
