import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PlayoffSubmission, PoolDetails } from '@models';
import { AuthenticationService, PlayoffPoolService, SeoService } from '@services/index';
import { Subject } from 'rxjs';
import { switchMap, map, takeUntil, take, debounce } from "rxjs/operators";
import { AddSubmissionModalComponent } from '../add-submission-modal/add-submission-modal.component';
import { CreatePoolModalComponent } from '../templates/pools/create-pool-modal/create-pool-modal.component';
import { EditPoolModalComponent } from '../templates/pools/edit-pool/edit-pool.component';

@Component({
  selector: 'app-playoff-pool',
  templateUrl: './playoff-pool.component.html',
  styleUrls: ['./playoff-pool.component.css']
})
export class PlayoffPoolComponent implements OnInit {
  pools: Array<PoolDetails> = [];
  submissions: Array<PlayoffSubmission> = [];
  isGroupSelected = false;
  selectedGroupId: string = null;
  selectedGroup$ = new Subject<PoolDetails>();
  selectedGroup: PoolDetails = null;
  destroy$ = new Subject<boolean>();
  constructor(private poolService: PlayoffPoolService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthenticationService,
    private dialog: MatDialog,
    private seoService: SeoService) {

  }

  ngOnInit(): void {
    this.seoService.updateTitle("Playoff Pools - Playoff Predictors");
    this.seoService.updateDescription("Create Playoff Pools and Playoff Brackets for the NFL Playoffs - Playoff Predictors");
    this.authService.user.subscribe(user => {
      if(user != null && user.loaded){
        //this.poolService.getUserPools();
      }
    })
    this.poolService.pools$.subscribe(pools => {
      this.pools = pools;
      if(this.selectedGroupId){
        this.selectGroup(this.selectedGroupId);
      }
    })
    this.selectedGroup$.subscribe(g => {
      this.selectedGroup = g;
      this.selectedGroupId = g?.groupGuid;
    })
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return {
            groupId: params.get("groupId")
          };
        }),
        switchMap((params) => {
          if (params.groupId) {
            this.selectedGroupId = params.groupId;
          } else {
            this.isGroupSelected = false;
            this.selectedGroup$.next(null);
          }

          return this.selectedGroup$;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {

      });
      this.poolService.submissions$.subscribe(subs => {
        this.submissions = subs;
      })
  }
  selectGroup(groupGuid: string){
    if(groupGuid != null){
      this.selectedGroup$.next(this.pools.find(p => p.groupGuid == groupGuid));
      let url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      if(url.endsWith('playoff-pool')){
        let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}/${groupGuid}`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
      this.isGroupSelected = true;
      this.poolService.getSubmissionsForGroup(groupGuid);
    }
    else{
      this.selectedGroup$.next(null);
      this.submissions.length = 0;
      let url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      if(!url.endsWith('playoff-pool')){
        let pathSplit = window.location.pathname.split('/')
        pathSplit.pop();
        let newurl = `${pathSplit.join('/')}`;
        window.history.pushState({ path: newurl }, "", newurl);
      }

      this.isGroupSelected = false;
    }
  }
  updateScores(groupGuid: string){
    this.poolService.postScoreSubmissions(groupGuid);
  }
  viewSubmission(url:string){
    this.router.navigate([`/football/nfl/playoffpicture/${this.selectedGroup.leagueGuid}`], { queryParams: { L: url } })
  }
  addSubmission(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      groupGuid: this.selectedGroup.groupGuid
    };

    this.dialog
    .open(AddSubmissionModalComponent, dialogConfig)
    .afterClosed()
    .pipe(take(1))
    .subscribe(() => {});
  }

  openCreatePoolModal(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    this.dialog
    .open(CreatePoolModalComponent, dialogConfig)
    .afterClosed()
    .pipe(take(1))
    .subscribe(() => {});
  }

  editPool(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {selectedPool: this.selectedGroup };
    this.dialog
    .open(EditPoolModalComponent, dialogConfig)
    .afterClosed()
    .pipe(take(1))
    .subscribe(() => {});
  }

}
