import { DialogData } from './../dialog/dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { LoginTemplateComponent } from '../login/login.component';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.css']
})
export class LoginErrorComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LoginTemplateComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {}
  onOkClick(): void {
    this.dialogRef.close();
  }
}
