import { LeagueSettingsService } from './../../services/league-settings/league-settings.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { League } from '@models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LeagueService } from '../../services/league/league.service';

@Component({
  selector: 'app-scores',
  templateUrl: './scores.component.html',
  styleUrls: ['./scores.component.css']
})
export class ScoresComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  numPlayoffTeams: number = this.leagueSettings.numPlayoffTeamsPerConference;
  league: League;
  @Input()
  conference: string;
  constructor(private leagueService: LeagueService, private leagueSettings: LeagueSettingsService) {}

  ngOnInit() {
    this.league = this.leagueService.league$.value;
    this.leagueService.league$.pipe(takeUntil(this.destroy$)).subscribe((res: League) => {
      this.league = res;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
