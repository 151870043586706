<h2>Latest from the PlaySheet</h2>
<ng-container *ngIf="playsheetPosts.length != 0">
  <ng-container *ngFor="let post of playsheetPosts">
    <app-playsheet-post [post]="post"></app-playsheet-post>
  </ng-container>
</ng-container>
<h3>Announcements</h3>
<ng-container *ngIf="announcements.length != 0">
  <ng-container *ngFor="let post of announcements">
    <app-playsheet-post [post]="post"></app-playsheet-post>
  </ng-container>
</ng-container>
