import { Component, Input, OnInit } from '@angular/core';
import { PlaySheetPost } from '@models';

@Component({
  selector: 'app-playsheet-post',
  templateUrl: './playsheet-post.component.html',
  styleUrls: ['./playsheet-post.component.scss']
})
export class PlaysheetPostComponent implements OnInit {

  @Input('post')
  post: PlaySheetPost;
  constructor() { }

  ngOnInit(): void {
  }

}
