import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlineService {
  public isOnline$ = new BehaviorSubject<boolean>(false);
  constructor() { 
    window.addEventListener('online',  this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    this.isOnline$.next(window.navigator.onLine);
  }
    
  private updateOnlineStatus(): void {
    this.isOnline$.next(window.navigator.onLine);
  }
}
