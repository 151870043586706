<app-freestar [adName]="'playoffpredictors_incontent_2'" [adDimensions]="'__970x250 __336x280'"></app-freestar>

<ng-container *ngIf="conference == 'AFC' || conference == 'NFC'">
  <div class="header-wrapper">
    <div class="conference-header pg-{{conference}}">
      <div class="conference-logo"><img src="https://static.playoffpredictors.com/nfl/{{ conference }}.svg" height="120px" class="pull-left conference-logo" /></div>
      <div class="conference-information">
        <div class="conference-name">
          {{ conference }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
<mat-form-field>
  <mat-select placeholder="Columns" [formControl]="selectedColumns" multiple>
    <mat-option *ngFor="let column of allColumns" [value]="column">{{ column }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field>
  <mat-select placeholder="Teams" [formControl]="selectedTeams" multiple>
    <mat-option *ngFor="let team of teams" [value]="team.teamId">{{ team.teamName }}</mat-option>
  </mat-select>
</mat-form-field>
<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef> # </th>
    <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
  </ng-container>
  <ng-container matColumnDef="Conference Rank">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Conf Rank</th>
    <td mat-cell *matCellDef="let element">
      <span [class]="element.conferenceRank <= numPlayoffTeams ? 'in-post-season' : 'out-post-season'"> {{ element.conferenceRankText }} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="Logo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Logo</th>
    <td mat-cell *matCellDef="let element" class="interactable" (click)="teamSelected(element)">
      <div><img width="32" height="32" src="https://static.playoffpredictors.com/nfl/{{ element.teamLogo || element.teamAbbreviation }}.svg" />
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element" class="interactable" (click)="teamSelected(element)">
      <div>
        <span>{{ element.teamName }}</span></div>
    </td>
  </ng-container>
  <ng-container matColumnDef="Division">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Division</th>
    <td mat-cell *matCellDef="let element">
      <span>{{ conference == 'All' ? element.conference : '' }} {{ element.division }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="Overall Record">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Rec</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.gameRecords.allRecord.pct || 0 | number: '1.3-3' }}">{{ element.gameRecords.allRecord.toString() }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="Conference Record">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Conf</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.gameRecords.conferenceRecord.pct || 0 | number: '1.3-3' }}"> {{ element.gameRecords.conferenceRecord.toString() }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="Division Record">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Div</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.gameRecords.divisionRecord.pct || 0 | number: '1.3-3' }}">{{ element.gameRecords.divisionRecord.toString() }} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="Home Record">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Home</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.gameRecords.homeRecord.pct || 0 | number: '1.3-3' }}">{{ element.gameRecords.homeRecord.toString() }} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="Away Record">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Away</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.gameRecords.awayRecord.pct || 0 | number: '1.3-3' }}">{{ element.gameRecords.awayRecord.toString() }} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="vs Playoff Teams">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="vs Playoff Teams">vs PT</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.PTRecord || 0 | number: '1.3-3' }}">{{ element.ptRecText }} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="SOS">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOS</th>
    <td mat-cell *matCellDef="let element">
      <span>{{ element.SOS || 0 | number: '1.3-3' }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="SOS Rec">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOS Rec</th>
    <td mat-cell *matCellDef="let element">{{ element.sosRecText }}</td>
  </ng-container>
  <ng-container matColumnDef="SOS Fractional">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOS F</th>
    <td mat-cell *matCellDef="let element">{{ element.sosFractionalDisplay }}</td>
  </ng-container>
  <ng-container matColumnDef="SOV">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOV</th>
    <td mat-cell *matCellDef="let element">
      <span>{{ element.SOV || 0 | number: '1.3-3' }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="SOV Rec">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOV Rec</th>
    <td mat-cell *matCellDef="let element">{{ element.sovRecText }}</td>
  </ng-container>
  <ng-container matColumnDef="SOV Fractional">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOV F</th>
    <td mat-cell *matCellDef="let element">{{ element.sovFractionalDisplay }}</td>
  </ng-container>
  <ng-container matColumnDef="SOL">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOL</th>
    <td mat-cell *matCellDef="let element">
      <span>{{ element.SOL || 0 | number: '1.3-3' }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="SOL Rec">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOL Rec</th>
    <td mat-cell *matCellDef="let element">{{ element.solRecText }}</td>
  </ng-container>
  <ng-container matColumnDef="SOL Fractional">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SOL F</th>
    <td mat-cell *matCellDef="let element">{{ element.solFractionalDisplay }}</td>
  </ng-container>
  <ng-container matColumnDef="Strength Differential">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Str Diff</th>
    <td mat-cell *matCellDef="let element">{{ element.strengthDifferential || 0 | number: '1.3-3' }}</td>
  </ng-container>
  <ng-container matColumnDef="vs Good">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>vs Good</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.gameRecords.winningTeamsRecord.pct || 0 | number: '1.3-3' }}">{{ element.gameRecords.winningTeamsRecord.toString() }} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="vs Bad">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>vs Bad</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.gameRecords.losingTeamsRecord.pct || 0 | number: '1.3-3' }}">{{ element.gameRecords.losingTeamsRecord.toString() }} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="PF">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>PF</th>
    <td mat-cell *matCellDef="let element">{{ element.pf }}</td>
  </ng-container>
  <ng-container matColumnDef="PPG">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Points Per Game">PPG</th>
    <td mat-cell *matCellDef="let element">{{ element.ppg || 0 | number: '1.1-1' }}</td>
  </ng-container>
  <ng-container matColumnDef="PA">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>PA</th>
    <td mat-cell *matCellDef="let element">{{ element.pa }}</td>
  </ng-container>
  <ng-container matColumnDef="PPGA">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Points Per Game Allowed">PPGA</th>
    <td mat-cell *matCellDef="let element">{{ element.ppga || 0 | number: '1.1-1' }}</td>
  </ng-container>
  <ng-container matColumnDef="PD">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="PF - PA">PD</th>
    <td mat-cell *matCellDef="let element">{{ element.pd }}</td>
  </ng-container>
  <ng-container matColumnDef="Pythagorean Expectation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Pythagorean Expectation">PE</th>
    <td mat-cell *matCellDef="let element">{{ element.nflPythagoreanExpectation || 0 | number: '1.1-1' }}</td>
  </ng-container>
  <ng-container matColumnDef="CPF">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF</th>
    <td mat-cell *matCellDef="let element">{{ element.cpf }}</td>
  </ng-container>
  <ng-container matColumnDef="CPA">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>CPA</th>
    <td mat-cell *matCellDef="let element">{{ element.cpa }}</td>
  </ng-container>
  <ng-container matColumnDef="CPD">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="CPF - CPA">CPD</th>
    <td mat-cell *matCellDef="let element">{{ element.cpd }}</td>
  </ng-container>
  <ng-container matColumnDef="Points For Rank">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Points For Rank">Points For Rank</th>
    <td mat-cell *matCellDef="let element">{{ element.pfRank }}</td>
  </ng-container>

  <ng-container matColumnDef="Points Against Rank">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Points Against Rank">Points Against Rank</th>
    <td mat-cell *matCellDef="let element">{{ element.paRank }}</td>
  </ng-container>
  <ng-container matColumnDef="League Combined Ranking">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="PA# + PF#">League Combined Rank</th>
    <td mat-cell *matCellDef="let element">{{ element.leagueCombinedRanking }}</td>
  </ng-container>
  <ng-container matColumnDef="Conference Points For Rank">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Conference Points For Rank">Points For Rank (Conf)</th>
    <td mat-cell *matCellDef="let element">{{ element.cpfRank }}</td>
  </ng-container>
  <ng-container matColumnDef="Conference Points Against Rank">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Points Against Rank">Points Against Rank (Conf)</th>
    <td mat-cell *matCellDef="let element">{{ element.cpaRank }}</td>
  </ng-container>
  <ng-container matColumnDef="Conference Combined Ranking">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="CPA# + CPF#">Conference Combined Rank</th>
    <td mat-cell *matCellDef="let element">{{ element.conferenceCombinedRanking }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
<mat-expansion-panel>
  <mat-expansion-panel-header>Legend</mat-expansion-panel-header>
  <table>
    <thead>
      <tr>
        <th>
Abbreviation
        </th>
        <th style="text-align:left">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr><td>Rec</td><td>Overall Record</td></tr>
<tr><td>Conf</td><td>Conference Record</td></tr>
<tr><td>Div</td><td>Division Record</td></tr>
<tr><td>Home</td><td>Home Record</td></tr>
<tr><td>Away</td><td>Away Record</td></tr>
<tr><td>vs PT</td><td>vs Playoff Teams - how this team performed against teams <em>currently</em> in Playoff Position (Conference Rank ≤ 7)</td></tr>
<tr><td>SOS</td><td>Strength of Schedule, Decimal Display</td></tr>
<tr><td>SOS Rec</td><td>Strength of Schedule, Record Display</td></tr>
<tr><td>SOS F</td><td>Strength of Schedule, Fractional Display</td></tr>
<tr><td>SOV</td><td>Strength of Victory, Decimal Display</td></tr>
<tr><td>SOV Rec</td><td>Strength of Schedule, Record Display</td></tr>
<tr><td>SOV F</td><td>Strength of Schedule, Fractional Display</td></tr>
<tr><td>SOL</td><td>Strength of Loss, Decimal Display</td></tr>
<tr><td>SOL Rec</td><td>Strength of Loss, Record Display</td></tr>
<tr><td>SOL F</td><td>Strength of Loss, Fractional Display</td></tr>
<tr><td>Str Diff</td><td>Strength Differential - Difference of Strength of Victory (decimal) - Strength of Loss (decimal)</td></tr>
<tr><td>vs Good</td><td>How this team performed against teams that are &gt; 0.500</td></tr>
<tr><td>vs Bad</td><td>How this team performed against teams that are &lt; 0.500</td></tr>
<tr><td>PF</td><td>Points For</td></tr>
<tr><td>PA</td><td>Points Against</td></tr>
<tr><td>PD</td><td>Point Differential</td></tr>
<tr><td>PPG</td><td>Points Per Game</td></tr>
<tr><td>PPGA</td><td>Points Per Game Against</td></tr>
<tr><td>PE</td><td>Pythagorean Expectation - requires scoring to be enabled in order to work</td></tr>
<tr><td>CPF</td><td>Conference Points For</td></tr>
<tr><td>CPA</td><td>Conference Points Against</td></tr>
<tr><td>CPD</td><td>Conference Points Differential</td></tr>
<tr><td>Points For Rank</td><td>Points For Rank (all teams)</td></tr>
<tr><td>Points Against Rank</td><td>Points Against Rank (all teams)</td></tr>
<tr><td>League Combined Ranking</td><td>Points For Rank + Points Against Rank</td></tr>
<tr><td>Conference Points For Rank</td><td>Points For Rank (conference teams)</td></tr>
<tr><td>Conference Points Against Rank</td><td>Conference Points Against Rank (conference teams)</td></tr>
<tr><td>Conference Combined Ranking</td><td>Conference Points For Rank + Conference Points Against Rank</td></tr>

    </tbody>
  </table>
</mat-expansion-panel>
