import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-tweet',
  template: `
  
    <a href="{{ tweetUrl }}" target="_blank">
    <button mat-raised-button color="accent">  
      Tweet
        <fa-icon [icon]="faTwitter"></fa-icon>
    </button>
    </a>
    
    `
})
export class TweetComponent implements OnInit {
  tweetUrl:string;
  private _url: string;
  faTwitter = faTwitter;
  constructor(private router: Router) { }
  @Input() 
  set url(value: string){
    this._url = value;
    this.twitterReload();
  }
  get url():string{
    return this._url
  }
  @Input() text = '';
  ngOnInit(){
    this.twitterReload();
  }
  twitterReload(){
    this.text = this.text.replace(/\#/gi,'%23')
    this.tweetUrl = 'https://twitter.com/intent/tweet';
    this.tweetUrl += `?text=${this.text}`;
    if(this.url){
      this.tweetUrl += `&url=${this.url}`;
    }else{
      this.tweetUrl += `&url=${location.origin + this.router.url }`
    }
  }
}
