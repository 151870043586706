<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="rank">
    <th mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let element">
      <span matTooltip="{{ element.RankMethodText }}">
        <span [class.in-post-season]="element.conferenceRank <= numPlayoffTeams" [class.out-post-season]="element.conferenceRank > numPlayoffTeams"> {{ element.conferenceRankText }} </span>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="team">
    <th mat-header-cell *matHeaderCellDef>Team</th>
    <td mat-cell *matCellDef="let element" title="{{ element.regionName }} {{ element.teamName }}">
      <img src="https://static.playoffpredictors.com/nfl/{{ element.teamLogo || element.teamAbbreviation }}.svg" width="32" height="32"/> <span class="team-name" fxHide.lt-xl> {{ element.teamName }} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="overallRec">
    <th mat-header-cell *matHeaderCellDef>Rec</th>
    <td mat-cell *matCellDef="let element"><app-pct-format [gameRecord]="element.gameRecords.allRecord"></app-pct-format></td
  ></ng-container>

  <ng-container matColumnDef="confRec">
    <th mat-header-cell *matHeaderCellDef>Conf</th>
    <td mat-cell *matCellDef="let element"><app-pct-format [gameRecord]="element.gameRecords.conferenceRecord"></app-pct-format></td
  ></ng-container>

  <tr mat-header-row *matHeaderRowDef="['rank', 'team', 'overallRec', 'confRec']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['rank', 'team', 'overallRec', 'confRec']" (click)="selectTeam(row)" class="interactable" [class.faded]="row.conferenceRank > numPlayoffTeams"></tr>
</table>
