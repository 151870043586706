import { DialogComponent } from './../templates/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormControl } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../../services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit, OnDestroy {
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  destroy$ = new Subject<boolean>();
  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, public dialog: MatDialog) {}
  email = new FormControl('', [Validators.required, Validators.email]);
  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' : this.email.hasError('email') ? 'Not a valid email' : '';
  }
  ngOnInit() {
    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (!this.email.invalid) {
      this.loading = true;
      this.authenticationService
        .recover(this.email.value)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(
          data => {
            this.dialog.open(DialogComponent, {
              width: '500px',
              data: { title: 'Your account has been reset', message: `In just a few minutes, you'll receive an email with further instructions. If you don't receive it, please check your junk mail.`, status: 200, email: this.email.value, navigateRoute: `/` }
            });
          },
          errorRes => {
            const res = { title: 'Oops. We ran into a problem.', message: 'Please try again later.', status: errorRes.status };

            this.dialog.open(DialogComponent, {
              width: '500px',
              data: res
            });
          }
        );
    }
  }
}
