<mat-card>
  <mat-card-header> <mat-card-title>Reset Password</mat-card-title> </mat-card-header>
  <mat-card-content>
    <form (ngSubmit)="onSubmit()">
      <mat-form-field> <input matInput placeholder="Code" type="text" [formControl]="code" required /> </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Password" type="password" [formControl]="password" required />
        <mat-error>
          <p *ngIf="password.hasError('required')">Password is required</p>
          <p *ngIf="password.hasError('minlength')">A minimum length of 8 characters is required</p>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions> <button mat-raised-button [disabled]="password.invalid" (click)="onSubmit()" color="accent">Reset</button> </mat-card-actions>
</mat-card>
