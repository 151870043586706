<article>
  <mat-card>
    <mat-card-header
      ><mat-card-title> Subscriptions </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container *ngIf="!loaded">
            <mat-spinner></mat-spinner>
        </ng-container>
      <ng-container *ngIf="loaded && data.length">
        <table mat-table [dataSource]="data">
          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element.key }}</td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element.value }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['key', 'value']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['key', 'value']"></tr>
        </table>
      </ng-container>

      <ng-container *ngIf="data.length === 0 && loaded">
        <p>
          You're not currently a subscriber. Want to
          <a [routerLink]="['/home/hate-ads']">subscribe?</a>
        </p>
      </ng-container>
    </mat-card-content>
    <mat-card-actions>
      <ng-container *ngIf="data.length > 0">
        <button mat-raised-button color="accent" (click)="manageSubscription()">
          Manage Subscription
        </button>
        <p><em>Subscriptions are managed by Stripe and can be paused/canceled at any time for any reason.</em></p>
      </ng-container>
    </mat-card-actions>
  </mat-card>
</article>
