import { SelectionType } from "../SelectionType";

export class SubmitPicksToPoolRequest {
  week: number;
  results: UserPickResult[] = [];
  groupGuid: string;
}

export class UserPickResult {
  gameGuid: string;
  outcome: SelectionType;
  teamPicked: number;
}

export class UserPickResponse {
  actualOutcome: SelectionType;
  awayAbbreviation: string;
  awayLogo: string;
  awayId: number;
  awayName: string;
  gameGuid: string;
  gameScheduleNum: number;
  homeAbbreviation: string;
  homeLogo: string;
  homeId: number;
  homeName: string;
  teamPicked: number;
  teamThatWonAbbreviation?: string;
  teamThatWon?: number;
  userRight?: boolean;
  canUpdate?: boolean;
}
