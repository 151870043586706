import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  navigateTo(fragment: string) {
    this.router.navigate(['home/privacy-policy'], { fragment: fragment });
    document.getElementById(fragment).scrollIntoView();
  }
}
