import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ResultingService } from '@services/resulting/resulting.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LeagueService } from '../../../services/league/league.service';
import { League, PoolDropdownResponse, Resulting, UserPercentileRanking } from '@models';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { WeekService } from '../../../services/week/week.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.css']
})
export class LeaderBoardComponent implements OnInit, OnDestroy {
  @Input("selectedPool")
  selectedPool$ = new BehaviorSubject<PoolDropdownResponse>(null);
  selectedPool: PoolDropdownResponse;
  destroy$ = new Subject<boolean>();
  resultingData: Resulting[] = [];
  public league: League;
  userPercentileRanking: UserPercentileRanking;
  selectedWeek: number = 0;
  weeks = [];
  constructor(private resultingService: ResultingService,
    private leagueService: LeagueService,
    public authService: AuthenticationService,
    public weekService: WeekService) { }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.selectedPool$.pipe(takeUntil(this.destroy$)).subscribe(p => {
      if(p){
        if(this.selectedPool?.groupGuid == p.groupGuid){
          return;
        }
        this.selectedPool = p;
        this.resultingService.getTopForPool(this.selectedPool.groupGuid).subscribe(r => this.resultingData = r)
      }
    })
    this.authService.user.pipe(takeUntil(this.destroy$)).subscribe(user =>{
      if(user.id){
        this.resultingService.getUserPercentileRankForLeague(this.selectedPool.groupGuid).subscribe(r => {
          this.userPercentileRanking = r;
        })
      }
    })
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((league) => {
        this.league = league;
        this.weeks = [{value: 0, viewValue:"All"}, ...this.weekService.weeks.map((g) => {
          return { value: g.key(), viewValue: g.key() };
        })];

      });
  }

  getRanks(){
    this.resultingService.getTopForWeek(this.selectedPool.groupGuid, this.selectedWeek)
      .subscribe(r => {
        this.resultingData = r;
      })
  }

  selectWeek(event: MatSelectChange) {
    this.selectedWeek = event.value;
    this.getRanks();
  }

  prevWeek() {
    if (this.selectedWeek > 0) {
      this.selectedWeek--;
      this.getRanks();
    }
  }
  nextWeek() {
    if (this.selectedWeek < this.weekService.weeks.length) {
      this.selectedWeek++;
      this.getRanks();
    }
  }
}
