import {
  ShareService,
  LinkService,
  UserSettingsService,
} from "@services/index";
import { Link, LinkChild } from "@models";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AuthenticationService, OnlineService } from "@services/index";
import { LoginErrorComponent } from "app/account/templates/login-error/login-error.component";
import { first, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
  selector: "app-top-navbar",
  templateUrl: "./top-navbar.component.html",
  styleUrls: ["./top-navbar.component.scss"],
  styles: [":host ::ng-deep .mat-card-header-text {margin: 0;}"],
  encapsulation: ViewEncapsulation.None,
})
export class TopNavbarComponent implements OnInit {
  links: Link[];
  opened = false;
  destroy$ = new Subject<boolean>();
  isOnline = false;
  isSmallScreen = false;
  public topFootballLink: string;
  constructor(
    public authService: AuthenticationService,
    private linkService: LinkService,
    public shareService: ShareService,
    private router: Router,
    private onlineService: OnlineService,
    public userSettings: UserSettingsService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(["(max-width: 800px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.isSmallScreen = true;
        } else {
          this.isSmallScreen = false;
        }
      });
  }

  ngOnInit() {
    this.linkService.links$.subscribe((links) => {
      if (links) {
        this.links = links;
      }
    });
    this.onlineService.isOnline$.subscribe((isOnline) => {
      this.isOnline = isOnline;
    });
  }
  toggleNightmode() {
    this.userSettings.userConfig.nightmodeEnabled =
      !this.userSettings.userConfig.nightmodeEnabled;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  logOut() {
    this.authService
      .logout()
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.router.navigate(["/"]);
        },
        error: (error) => {},
      });
  }

  getLink(uri: string) {
    if (uri.indexOf("?") !== -1) {
      return uri.split("?")[0];
    }
    return uri;
  }

  getParamMap(uri: string) {
    const ret = {};

    if (uri.indexOf("?") !== -1) {
      const split = uri.split("?");
      const token = split[1].split("=");
      ret[token[0]] = token[1];
    }
    return ret;
  }

  onToggleSidenav() {
    this.opened = !this.opened;
  }
}
