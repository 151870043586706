<mat-dialog-content #dialog *ngIf="fc != null">
  <form (ngSubmit)="onSubmit()" style="display: flex; flex-direction: column" [formGroup]="editPoolForm">
    <mat-form-field appearance="fill">
      <mat-label>Pool Name</mat-label>
      <input
        matInput
        formControlName="groupName"
        required
        type="text"
        [placeholder]="'Pool Name'"
      />

      <mat-error *ngIf="fc.groupName.invalid">
        <span *ngIf="fc.groupName.hasError('required')">
          Name is required
        </span>
      </mat-error>
    </mat-form-field>
    <h3>Playoff Pool Points Systems</h3>
    <mat-form-field appearance="fill">
      <mat-label>Base Multiplier</mat-label>
      <input
        matInput
        formControlName="baseMultiplier"
        required
        type="number"
        [placeholder]="'1'"
      />
      <mat-error *ngIf="fc.baseMultiplier.invalid">
        <span *ngIf="fc.baseMultiplier.hasError('required')">
          Field is required
        </span>
      </mat-error>
      <mat-hint>Scalar applied to each playoff round's point value when computing wins</mat-hint>
    </mat-form-field>
    
    <mat-form-field appearance="fill">
      <mat-label>Wild Card Round</mat-label>
      <input
        matInput
        formControlName="wildcardPoints"
        required
        type="number"
        [placeholder]="'1'"
      />
      <mat-error *ngIf="fc.wildcardPoints.invalid">
        <span *ngIf="fc.wildcardPoints.hasError('required')">
          Field is required
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Division Round</mat-label>
      <input
        matInput
        formControlName="divisionPoints"
        required
        type="number"
        [placeholder]="'2'"
      />
      <mat-error *ngIf="fc.divisionPoints.invalid">
        <span *ngIf="fc.divisionPoints.hasError('required')">
          Field is required
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Conference Championship Round</mat-label>
      <input
        matInput
        formControlName="championshipPoints"
        required
        type="number"
        [placeholder]="'4'"
      />
      <mat-error *ngIf="fc.championshipPoints.invalid">
        <span *ngIf="fc.championshipPoints.hasError('required')">
          Field is required
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Super Bowl</mat-label>
      <input
        matInput
        formControlName="superBowlPoints"
        required
        type="number"
        [placeholder]="'8'"
      />
      <mat-error *ngIf="fc.superBowlPoints.invalid">
        <span *ngIf="fc.superBowlPoints.hasError('required')">
          Field is required
        </span>
      </mat-error>
    </mat-form-field>
  </form>
  {{ error }}
</mat-dialog-content>
<div class="button-row">
  <button
    mat-raised-button
    [disabled]="fc && editPoolForm.invalid || loading"
    (click)="onSubmit()"
    color="accent"
  >
    {{ loading ? "saving..." : "Save" }}
  </button>
  <button mat-raised-button color="warn" (click)="onCancel()" >Cancel</button>
</div>