import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { lzstringValidator } from "app/shared/validators/lzstring-validator.directive";
interface Leagues {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-scorer",
  templateUrl: "./scorer.component.html",
  styleUrls: ["./scorer.component.css"],
})
export class ScorerComponent implements OnInit {
  leagues: Leagues[] = [
    {
      value: "842CB5EC-193A-4D6D-8002-801297F04F53",
      viewValue: "NFL 2020-2021 Season",
    },
  ];

scorerForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.scorerForm = this.formBuilder.group({
      league: [this.leagues[0].value, Validators.required],
      saveUrl: [null, [Validators.required, lzstringValidator()]]
    })
  }

  submit(){
    if(!this.scorerForm.valid){
      return;
    }
  }
}
