<mat-card>
  <mat-card-header> <mat-card-title>Register</mat-card-title> </mat-card-header>
  <mat-card-content>
    <form (ngSubmit)="onSubmit()">
      <p><em>Please Note:</em> if you want to make full use of this website, you <strong>must</strong> enter a valid e-mail address, as you will be required to confirm the account before continuing.</p>
      <p>A large number of our emails sent to school e-mail accounts are rejected -- for this reason we do not suggest signing up with school accounts.</p>
      <mat-form-field>
        <input matInput placeholder="Email" [formControl]="username" required type="text" />
        <mat-error *ngIf="username.invalid"> <span *ngIf="username.hasError('required')"> Username is required </span> <span *ngIf="username.hasError('email')"> Username must be an email. </span> </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Password" type="password" [formControl]="password" required />
        <mat-error>
          <p *ngIf="password.hasError('required')">Password is required</p>
          <p *ngIf="password.hasError('minlength')">A minimum length of 8 characters is required</p>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions> <button mat-raised-button [disabled]="username.invalid || password.invalid" (click)="onSubmit()" color="accent">{{ password.value && password.invalid ? 'Password too weak...' : 'Register' }}</button> </mat-card-actions>
</mat-card>
