import { AuthenticationService } from './../../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
export interface DialogData {
  id: string;
}
@Component({
  selector: 'app-resend-confirmation',
  templateUrl: './resend-confirmation.component.html',
  styleUrls: ['./resend-confirmation.component.css'],
})
export class ResendConfirmationComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ResendConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthenticationService,
    public dialog: MatDialog
  ) {}
  destroy$ = new Subject<boolean>();

  onOkClick(): void {
    this.authService
      .resendConfirmationToken(this.data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user) => {
          this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
              title: 'Check your email',
              message:
                'You\'ll receive an email from us shortly with a new confirmation code.',
            },
          });
          this.router.navigate([`/`]);
        },
        (errorRes) => {}
      );
    this.dialogRef.close();
  }
}
