import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Team, Game, UpdateReason } from '@models';
import { LeagueService } from '../../services/league/league.service';
import { UserSettingsService } from '@services/index';

@Component({
  selector: 'app-selected-team',
  templateUrl: './selected-team.component.html',
  styleUrls: ['./selected-team.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectedTeamComponent implements OnInit {
  
  public Object = Object;

  @Input()
  team: Team | null;

  @Input()
  tabGroup: MatTabGroup;

  constructor(private leagueService: LeagueService, public userSettings: UserSettingsService) {}

  ngOnInit() {
  }

  pickTeam(game: Game) {
    game.updateReason = UpdateReason.UserPick;
    this.leagueService.gamePicked$.next(game);
  }

  returnToSchedule(){
    this.tabGroup.selectedIndex = 0
  }
}
