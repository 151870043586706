<article>
  <mat-card>
    <mat-card-header>
      <mat-card-title>NFL Scorer</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>Curious how you did in a prior year?</p>
      <div class="form-wrapper">
      <form [formGroup]="scorerForm" (submit)="submit()">
        <mat-form-field class="full-width">
          <mat-label>Season</mat-label>
          <mat-select formControlName="league" required>
            <mat-option *ngFor="let league of leagues" [value]="league.value">
              {{ league.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="scorerForm.controls.league.touched && scorerForm.controls.league.invalid">
            <span *ngIf="scorerForm.controls.league.errors.required">This field is mandatory.</span>
            <span *ngIf="scorerForm.controls.league.errors.pattern">This field is invalid.</span>
          </mat-error>
         
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Save Value</mat-label>
          <input
            type="text"
            formControlName="saveUrl"
            matInput
            placeholder="EwRmvNIstEH3KAzLKtnSTmlOLyZKFHEYKVnob7HKFVnWJy570V4RE8OUU46GLTb8RNBiQYAGOfIWKlMoA"
            required
          />
          <mat-hint>the <a target="_blank" href="https://static.playoffpredictors.com/site/savevalue.PNG">save value</a> for your schedule</mat-hint>
          <mat-error *ngIf="scorerForm.controls.saveUrl.touched && scorerForm.controls.saveUrl.invalid">
            <span *ngIf="scorerForm.controls.saveUrl.errors.required">This field is mandatory.</span>
            <span *ngIf="scorerForm.controls.saveUrl.errors.pattern">This field is invalid.</span>
            <span *ngIf="scorerForm.controls.saveUrl.errors.lzstring">Value is not a valid <a target="_blank" href="https://static.playoffpredictors.com/site/savevalue.PNG">save value</a>.</span>
          </mat-error>
        </mat-form-field>
            <button type="submit" mat-button mat-raised-button color="primary" [disabled]="!scorerForm.valid">Submit</button>         
      </form>
    </div>
    </mat-card-content>
    <mat-card-footer></mat-card-footer>
</mat-card>
</article>
