import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { League, Team, DraftOrder } from "@models";
import * as Enumerable from "linq";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LeagueService } from "../../services/league/league.service";

@Component({
  selector: "app-draft-order",
  templateUrl: "./draft-order.component.html",
  styleUrls: ["./draft-order.component.scss"],
})
export class DraftOrderComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  draftOrderedTeams: DraftOrder[];
  public league: League;

  constructor(
    private leagueService: LeagueService
  ) {}

  ngOnInit(): void {
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((league) => {
        this.league = league;
        this.draftOrderedTeams = [];
        let ordered = Enumerable.from(this.league.teams)
          .where((p) => p.draftOrderNumber > 0)
          .orderBy((p) => p.draftOrderNumber)
          .toArray();

        for (let team of ordered) {
          let trade = this.league.draftTrades.find(
            (p) => p.givingTeamId == team.teamId
          );
          if (trade) {
            // this team traded their pick
            this.draftOrderedTeams.push({
              draftPick: team.draftOrderNumber,
              pickTraded: true,
              tradedTo: this.league.teams.find(
                (p) => p.teamId == trade.receivingTeamId
              ),
              originalPickOwner: team,
            });
          } else {
            this.draftOrderedTeams.push({
              draftPick: team.draftOrderNumber,
              pickTraded: false,
              originalPickOwner: team,
            });
          }
        }
      });
    this.leagueService.standingsSet$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        var ordered = Enumerable.from(this.league.teams)
          .where((p) => p.draftOrderNumber > 0)
          .orderBy((p) => p.draftOrderNumber)
          .toArray();
        this.draftOrderedTeams = [];
        for (let team of ordered) {
          let trade = this.league.draftTrades.find(
            (p) => p.givingTeamId == team.teamId
          );
          if (trade) {
            // this team traded their pick
            this.draftOrderedTeams.push({
              draftPick: team.draftOrderNumber,
              pickTraded: true,
              tradedTo: this.league.teams.find(
                (p) => p.teamId == trade.receivingTeamId
              ),
              originalPickOwner: team,
            });
          } else {
            this.draftOrderedTeams.push({
              draftPick: team.draftOrderNumber,
              pickTraded: false,
              originalPickOwner: team,
            });
          }
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
