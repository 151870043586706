import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlaySheetPost } from '@models';
import { CategoryMap, PlaysheetService } from '@services/playsheet/playsheet.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, OnDestroy {
  announcements: PlaySheetPost[] = [];
  playsheetPosts: PlaySheetPost[] = [];
  private destroy$ = new Subject<boolean>();
  constructor(private playSheet: PlaysheetService) {}

  ngOnInit() {
    this.playSheet.getPostsInCategory(CategoryMap.Announcements).pipe(takeUntil(this.destroy$))
    .subscribe((posts)=>{
      this.announcements = posts;
    })
    this.playSheet.getPostsInCategory(CategoryMap.All,3, [CategoryMap.Announcements]).pipe(takeUntil(this.destroy$))
    .subscribe((posts)=>{
      this.playsheetPosts = posts;
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
