<article>
  <mat-card>
    <mat-card-header>
      <mat-card-title>PlayoffPredictors Pulse</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <nav mat-tab-nav-bar>
        <a
          mat-tab-link
          *ngFor="let link of navLinks"
          [routerLink]="[link.path]"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
        >
          {{ link.label }}
        </a>
      </nav>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
</article>
