import { Injectable } from "@angular/core";
import { League } from "@models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LeagueService } from "../league/league.service";

@Injectable({
  providedIn: "root",
})
export class LeagueSettingsService {
  numRegularSeasonWeeks: number;
  numRegularSeasonGames: number;
  numPlayoffTeamsPerConference: number;
  numWcGamesPerConf: number;
  numPostseasonGames: number;
  numTotalGames: number;
  postSeasonVersion: number;
  destroy$ = new Subject<boolean>();
  constructor(private leagueService: LeagueService) {
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: League) => {
        if (!res) {
          return;
        }
        const map = {};
        this.numRegularSeasonGames = res.games.length;
        res.games.map((game) => (map[game.week] = 1));
        const weeks = Object.keys(map).map((p) => parseInt(p, 10));

        this.numPlayoffTeamsPerConference = 7;
        this.postSeasonVersion = 2;
        this.numWcGamesPerConf = 3;
        this.numPostseasonGames = (this.numWcGamesPerConf * 2 + 2 + 2 + 1) * 2;

        this.numTotalGames =
          this.numPostseasonGames + this.numRegularSeasonGames;
        this.numRegularSeasonWeeks = weeks.length;
      });
  }
}
