import * as Enumerable from 'linq';
import { GameRecords } from './game-records.model';
import { Game } from './game.model';
import { RankMethod } from './RankMethod';

export class Team {
  conference: string;
  division: string;
  regionName: string;
  sport?: any;
  sportId: number;
  teamAbbreviation: string;
  teamLogo?: string;
  teamId: number;
  teamName: string;
  SOV: number;
  SOS: number;
  SOL: number;
  PTRecord: number;
  draftOrderRankMethod: RankMethod;
  rankMethod: RankMethod;
  tiebreakers: any = {};
  teamChecked: boolean;
  wins: Game[];
  losses: Game[];
  ties: Game[];
  gameRecords: GameRecords;
  conferenceRank = 0;
  schedule: Game[];
  bestCommonCalcPct: any;
  hthDivCalcPct: any;
  skipRanking = false;
  bestCommonPct: number;
  worstCommonCalcPct: number;
  byeWeek: number;
  sosRec: number[];
  sovRec: number[];
  solRec: number[];
  ptRec: number[];
  primaryColor: string;
  secondaryColor: string;
  draftOrderNumber?: number;
  pfRank: number;
  cpfRank: number;
  paRank: number;
  cpaRank: number;
  leagueCombinedRanking: number;
  conferenceCombinedRanking: number;
  get ppg(){
    return this.pf / this.gameRecords.gamesPlayed;
  }
  get ppga(){
    return this.pa / this.gameRecords.gamesPlayed;
  }
  get nflPythagoreanExpectation(){
    const ppf = Math.pow(this.pf, 2.37);
    const ppa = Math.pow(this.pa, 2.37);
    return (ppf / (ppf + ppa)) * 17;
  }
  get cpd() {
    return this.cpf - this.cpa;
  }
  get cpf() {
    let cpf = 0;
    for (let game of this.schedule.filter(game => game.home.conference == game.away.conference && game.week <= 18)) {
      if (game.homeId != this.teamId) { // the current team is the away team, so pf should accumulate from there
        cpf += game.scores.a;
      } else {
        cpf += game.scores.h;
      }
    }
    return cpf;
  }
  get cpa() {
    let cpa = 0;
    for (let game of this.schedule.filter(game => game.home.conference == game.away.conference && game.week <= 18)) {
      if (game.homeId == this.teamId) {
        cpa += game.scores.a;
      } else {
        cpa += game.scores.h;
      }
    }
    return cpa;
  }
  get pa() {
    let pa = 0;
    for (let game of this.schedule.filter(g => g.week <= 18)) {
      if (game.homeId == this.teamId) {
        pa += game.scores.a;
      } else {
        pa += game.scores.h;
      }
    }
    return pa;
  }
  get pf() {
    let pf = 0;
    for (let game of this.schedule.filter(g => g.week <= 18)) {
      if (game.homeId != this.teamId) { // the current team is the away team, so pf should accumulate from there
        pf += game.scores.a;
      } else {
        pf += game.scores.h;
      }
    }
    return pf;
  }
  get displaySchedule() {
    const ret = [];
    for (let i = 0; i < this.schedule.length + 1; i++) {
      const gameObj = this.schedule.find(p => p.week === i + 1);
      if (gameObj) {
        ret.push(this.schedule.find(p => p.week === i + 1));
      } else {
        ret.push(null);
      }
    }
    return ret;
  }

  get pd() {
    return this.pf - this.pa;
  }
  get solFractionalDisplay() {
    if (this.solRec && this.solRec.length === 3) {
      return `${this.solRec[0] + (this.solRec[2] * 0.5)} / ${this.solRec[0] + this.solRec[1] + this.solRec[2]}`
    }
  }
  get sosFractionalDisplay() {
    if (this.sosRec && this.sosRec.length === 3) {
      return `${this.sosRec[0] + (this.sosRec[2] * 0.5)} / ${this.sosRec[0] + this.sosRec[1] + this.sosRec[2]}`
    }
  }
  get sovFractionalDisplay() {
    if (this.sovRec && this.sovRec.length === 3) {
      return `${this.sovRec[0] + (this.sovRec[2] * 0.5)} / ${this.sovRec[0] + this.sovRec[1] + this.sovRec[2]}`
    }
  }
  get solRecText() {
    if (this.solRec && this.solRec.length === 3) {
      let retStr = `${this.solRec[0]}-${this.solRec[1]}`;
      if (this.solRec[2] > 0) {
        retStr += `-${this.solRec[2]}`;
      }
      return retStr;
    }
    return '';
  }
  get ptRecText() {
    if (this.ptRec && this.ptRec.length === 3) {
      let retStr = `${this.ptRec[0]}-${this.ptRec[1]}`;
      if (this.ptRec[2] > 0) {
        retStr += `-${this.ptRec[2]}`;
      }
      return retStr;
    }
    return '';
  }
  get strengthDifferential(){
    return this.SOV - this.SOL;
  }
  get sosRecText() {
    if (this.sosRec && this.sosRec.length === 3) {
      let retStr = `${this.sosRec[0]}-${this.sosRec[1]}`;
      if (this.sosRec[2] > 0) {
        retStr += `-${this.sosRec[2]}`;
      }
      return retStr;
    }
    return '';
  }
  get sovRecText() {
    if (this.sovRec && this.sovRec.length === 3) {
      let retStr = `${this.sovRec[0]}-${this.sovRec[1]}`;
      if (this.sovRec[2] > 0) {
        retStr += `-${this.sovRec[2]}`;
      }
      return retStr;
    }
    return '';
  }
  get conferenceRankText() {
    let rank = this.conferenceRank + '';
    if (this.conferenceRank === 0) {
      return '-';
    }
    if (this.rankMethod === RankMethod.coinFlip) {
      rank += '*';
    }
    return rank;
  }

  get rankMethodText() {
    switch (this.rankMethod) {
      case RankMethod.allPct:
        return 'Pct vs League';
      case RankMethod.confPct:
        return 'Pct vs Conference';
      case RankMethod.divPct:
        return 'Pct vs Div';
      case RankMethod.sos:
        return 'Strength of Schedule';
      case RankMethod.sov:
        return 'Strength of Victory';
      case RankMethod.divTopAllPct:
        return 'Top of Division - Pct vs League';
      case RankMethod.divTopDivPct:
        return 'Top of Division - Pct vs Div';
      case RankMethod.divTopConfPct:
        return 'Top of Division - Pct vs Conference';
      case RankMethod.divTopSos:
        return 'Top of Division - Strength of Schedule';
      case RankMethod.divTopSov:
        return 'Top of Division - Strength of Victory';
      case RankMethod.divTopHth:
        return 'Top of Division - Head to Head';
      case RankMethod.hth:
        return 'Head-to-Head Tiebreaker';
      case RankMethod.cg:
        return 'Common Games';
      case RankMethod.coinFlip:
        return 'Coin Flip';
      case RankMethod.commonGames:
        return 'Common Games';
      case RankMethod.commonPct:
        return 'Common Pct';
      case RankMethod.divTopCommonPct:
        return 'Top of Division - Common Pct';
      case RankMethod.confPointDifferential:
        return "Conference Point Differential";
      case RankMethod.divTopConfPointDifferential:
        return 'Top of Division - Point Differential';
      case RankMethod.pointDifferential:
        return 'Point Differential';
      case RankMethod.divTopPointDifferential:
        return 'Top of Division - Point Differential';
      case RankMethod.combinedRankingConference:
        return 'Best Combined PA+PF Ranking - Conference';
      case RankMethod.combinedRankingLeague:
        return 'Best Combined PA+PF Ranking - League';
      case RankMethod.bestNetPointsConferenceGames:
        return 'Best Net Points (all games)'
      case RankMethod.bestNetPointsAllGames:
        return 'Best Net Points (all games)'
    }
  }
  constructor(data) {
    Object.assign(this, data);
    this.wins = [];
    this.losses = [];
    this.ties = [];
    this.tiebreakers = {
      DivHTH: [],
      HTH: [],
      Explanations: [],
      CommonGames: []
    };
    this.gameRecords = new GameRecords(this);
  }

  get getExplanationsDistinct():any[]{
    if(this.tiebreakers && this.tiebreakers.Explanations){
      return Enumerable.from(this.tiebreakers.Explanations).distinct().toArray();
    }
    return [];
  }
  clear() {
    this.gameRecords.clearAll();
    this.wins.splice(0, this.wins.length);
    this.losses.splice(0, this.losses.length);
    this.ties.splice(0, this.ties.length);
    this.SOS = 0;
    this.SOV = 0;
    this.PTRecord = 0;
    this.SOL = 0;
    this.ptRec = [];
    this.sovRec = [];
    this.sosRec = [];
    this.solRec = [];
    this.draftOrderNumber = null;
    this.tiebreakers = {
      DivHTH: [],
      HTH: [],
      Explanations: [],
      CommonGames: []
    };
  }
}
