import { League, Team } from '@models';
import { SportType } from './sport-type.enum';
export class LeagueResponse {
  league: League;
  teams: Array<Team>;
  scores: ScoreDict;
  sportId: SportType
  playoffTeams: Array<PlayoffTeamResponse>;
}
export class NumberDict{
  [weekNum: number]: number[];
}
export class ScoreDict {
  [gameScheduleNum: string]: ScoreRecord;
}
export class PlayoffTeamResponse {
  teamId?: number;
  seed: number;
}
export class ScoreRecord {
  a: number;
  h: number;
  status: string;
}
