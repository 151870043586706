<h2>Subscriptions</h2>
<p>
  Playoff Predictors makes it easy to support the site through different pricing
  options, often offering season passes if you're a fan of just a specific
  sport, or recurring subscriptions if you want to support the site on an
  ongoing basis.
</p>

<table class="mat-table">
  <tr class="mat-header-row">
    <th class="mat-header-cell">Name</th>
    <th class="mat-header-cell">Ad-Free Experience</th>
    <th class="mat-header-cell">Premium Features</th>
    <th class="mat-header-cell">Early Access</th>
    <th class="mat-header-cell">Price</th>
    <th class="mat-header-cell">Frequency</th>
    <th class="mat-header-cell">Notes</th>
    <th class="mat-header-cell">Subscribe</th>
  </tr>
  <tr class="mat-row">
    <td class="mat-cell">Free</td>
    <td class="mat-cell"><span class="bad"> &#x2718; </span></td>
    <td class="mat-cell"><span class="bad"> &#x2718; </span></td>
    <td class="mat-cell"><span class="bad"> &#x2718; </span></td>
    <td class="mat-cell">Free</td>
    <td class="mat-cell"></td>
    <td class="mat-cell"></td>
    <td class="mat-cell"></td>
  </tr>
  <tr class="mat-row">
    <td class="mat-cell">Adblock Absolution - Monthly</td>
    <td class="mat-cell"><span class="good"> &#x2713; </span></td>
    <td class="mat-cell"><span class="bad"> &#x2718; </span></td>
    <td class="mat-cell"><span class="bad"> &#x2718; </span></td>
    <td class="mat-cell">$2</td>
    <td class="mat-cell">Monthly</td>
    <td class="mat-cell"></td>
    <td class="mat-cell">
      <ng-container *ngIf="authService.isLoggedIn && !authService.isAdsFree">
        <form #f="ngForm" (ngSubmit)="ngOnSubmit(f)" novalidate>
          <input
            [ngModel]="'adblock-absolution'"
            type="hidden"
            name="priceId"
          />
          <input [ngModel]="'subscription'" type="hidden" name="mode" />
          <button mat-button mat-raised-button type="submit">
            Subscribe Now
          </button>
        </form>
      </ng-container>
      <ng-container *ngIf="authService.isAdsFree">
        <a
          mat-button
          mat-raised-button
          accent="primary"
          [routerLink]="['/account/subscriptions']"
          >Manage</a
        >
      </ng-container>
      <ng-container *ngIf="!authService.isLoggedIn">
        <button mat-button
          mat-raised-button
          color="accent" (click)="showLoginModal()">Log in to purchase</button>
      </ng-container>
    </td>
  </tr>
  <!--
  <tr class="mat-row">
    <td class="mat-cell">Adblock Absolution - NFL Season Pass</td>
    <td class="mat-cell"><span class="good"> &#x2713; </span></td>
    <td class="mat-cell"><span class="bad"> &#x2718; </span></td>
    <td class="mat-cell"><span class="bad"> &#x2718; </span></td>
    <td class="mat-cell">$10</td>
    <td class="mat-cell">One-Time</td>
    <td class="mat-cell">Valid from 8/1/2021 - 3/1/2022</td>
    <td class="mat-cell">
      <ng-container *ngIf="authService.isLoggedIn && !authService.isAdsFree">
        <form #f="ngForm" (ngSubmit)="ngOnSubmit(f)" novalidate>
          <input [ngModel]="'2021-nfl-pass'" type="hidden" name="priceId" />
          <input [ngModel]="'payment'" type="hidden" name="mode" />
          <button mat-button mat-raised-button type="submit">
            Purchase Now
          </button>
        </form>
      </ng-container>
      <ng-container *ngIf="!authService.isLoggedIn">
        <button mat-button
          mat-raised-button
          color="accent" (click)="showLoginModal()">Log in to purchase</button>
      </ng-container>
    </td>
  </tr>-->
</table>

<dl>
  <dt>Ad-Free Experience</dt>
  <dd>
    Nobody likes ads, but as a small independent company we need to keep the
    lights on. All of our tiers receive an ad-free experience. If you hate ads,
    or use an adblocker and feel bad about it, this is the tier for you!
    <p>Ads will be removed from:</p>
    <ol>
      <li>PlayoffPredictors.com</li>
      <li>v2.playoffpredictors.com</li>
    </ol>
  </dd>
  <!--<dt>Premium Features</dt>
  <dd>
    <p>This includes things like:</p>
    <ol>
      <li>Custom team logo uploads for Custom Leagues</li>
      <li>Managing custom leagues beyond the free tier limits</li>
      <li>
        Support for custom leagues should you encounter any issues or have
        questions
      </li>
    </ol>
  </dd>
  <dt>Early Access</dt>
  <dd>
    <p>
      When building new features into the site, tiers with Early Access are the
      first to get access to the alpha/beta features, often a year or more in
      advance of the free tiers
    </p>
  </dd>-->
</dl>
<p>
  Subscriptions can be canceled at any time through your
  <a [routerLink]="['/account/subscriptions']">Account Page</a>. Entitlements
  expire at the end of the month users canceled on. Refunds will not be issued under any ordinary circumstances.
</p>
